import React, { useRef, useEffect, useLayoutEffect, useState, useCallback } from 'react';
import {
  CircleCheckIcon,
  CloseIcon,
  CommentIcon,
  Edit3Icon,
  FlagIcon,
  FlowUnFlowIcon,
  HeartActiveIcon,
  HeartIcon,
  IconBookmark,
  PlayIcon,
  RePostIcon,
  RePostIconV2,
  SendChatIcon,
  ShareIcon,
  ThreePointIcon,
  TrashIcon,
  UnFlowIcon,
} from 'images';

import {
  IMG_NO_WATER_MARK,
  convertToCurrentTime,
  formatCoin,
  formatNumber,
  isAdmin,
  linkS3,
  linkS3Video,
  onErrImage,
  decryptPath,
  isProdEnableLiveStream,
} from '../../utils/utils';
import { ChevronLeft, ChevronRight } from 'images';
import Slider from 'react-slick';
import {
  ImgCustom,
  PopupConfirmFan,
  PopupGeneral,
  PopupLockPostNormal,
  PopupSharePost,
  PopupUserBuyPostF021,
  ReadMoreLess,
  Textarea,
} from '..';
import { useTranslation } from 'react-i18next';
import useUserRole from 'hooks/useUserRole';
import { useSelector } from 'react-redux';
import CommentPostTimeLine from 'components/comment/commentPostTimeLine';
import { InputWrapper } from 'components/Input/InputWrapper';
import Avatar from 'images/Avatar.png';
import { useDispatch } from 'react-redux';
import useRedirect from 'hooks/useRedirect';
import { getEnv } from 'configs/env';
import './PopupViewFullSaleContent.scss';
import { newPathUser } from 'constants/layout/constant';
import { useNavigate } from 'react-router-dom';
import {
  creatorDeleteArticle,
  postBookMarkDetail,
  creatorGetPostDetail,
  postCommentDetail,
  deleteCommentDetail,
  deleteReplyCommentDetail,
  postFollowCommentDetail,
  postFollowReplyCommentDetail,
  fanFollowCreator,
  updateLikeCommentDetail,
  updateLikeReplyComment,
  updatePostLikeDetail,
} from 'store/actions/usersNew';
import ReactPlayer from 'react-player';
import LoadingIconV2 from 'images/LoadingIconV2';
import ZipFileIconV2 from 'images/ZipFileIconV2';
import ViewPostIcon from 'images/ViewPostIcon';
import ListIconV2 from 'images/ListIconV2';
import { AVATAR_DEFAULT } from 'utils';
import { isSafari } from 'react-device-detect';

const PopupViewFullSaleContent = ({ item, close, idx = 0 }) => {
  const slideRef = useRef(null);
  const refVideoType2 = useRef(null);
  const refVideoType3 = useRef(null);
  // const { image, listImage, postInfo } = dataViewFull;
  const [pageActive, setPageActive] = useState(idx);
  const { t } = useTranslation();
  const widthScreen = window.innerWidth;
  const [popupCopyLink, setPopupCopyLink] = useState(null);
  const [popupDeleteComment, setPopupDeleteComment] = useState(null);

  const { detailPost, error } = useSelector((state) => state.usersNew);
  const { data: dataProfile, dataUser } = useSelector((state) => state.users);
  const { check_buy_post, loadingBuyPost } = useSelector((state) => state.sellPost);
  const [currentBookmark, setCurrentBookmark] = useState(detailPost?.user_bookmark_status);
  const [valueComment, setValueComment] = useState('');
  const [checkSendChat, setCheckSendChat] = useState(null);
  const [isVisiblePopup, setIsVisiblePopup] = useState(-1);
  const [flgRender, setFlgRender] = useState(false);
  const [widthContent, setWidthContent] = useState();
  const [isPlaying, setIsPlaying] = useState(true);
  const [isPlaying1, setIsPlaying1] = useState(true);
  const [isPlaying2, setIsPlaying2] = useState(true);
  const [isPlaying3, setIsPlaying3] = useState(true);
  const [isVisiblePopUpDeletePost, setIsVisiblePopDeletePost] = useState(null);
  const [isVisiblePopUpDeletePostComplete, setIsVisiblePopDeletePostComplete] =
    useState(false);
  const [showButton, setShowButton] = useState(true);
  const [dataDetail, setDataDetail] = useState({});
  const [sellBy, setSellBy] = useState();
  const navigate = useNavigate();
  const [thumbnail, setThumbnail] = useState(IMG_NO_WATER_MARK);
  const [zipFile, setZipFile] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(creatorGetPostDetail(item?.id, false));
  }, []);

  useRedirect(error);

  useEffect(() => {
    if (detailPost) {
      setDataDetail(detailPost);
    }
  }, [detailPost]);

  useEffect(() => {
    detailPost &&
      detailPost?.medias?.map((item) => {
        if (item?.type === 4 && item?.public_flag === 1) {
          setThumbnail(item);
        }
        if (item?.type === 5) {
          setZipFile(item);
        }
      });
  }, [detailPost]);

  const NextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div
        className={className}
        onClick={() => {
          if (pageActive === 1) {
            setIsPlaying1(false);
          }
          setIsPlaying(false);
          setIsPlaying2(false);
          setIsPlaying3(false);
          refVideoType3?.current?.pause();
          refVideoType2?.current?.pause();
          onClick();
        }}>
        <ChevronRight />
      </div>
    );
  };
  const PrevArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div
        className={className}
        onClick={() => {
          if (pageActive === 1) {
            setIsPlaying1(false);
          }
          setIsPlaying(false);
          setIsPlaying2(false);
          setIsPlaying3(false);
          refVideoType3?.current?.pause();
          refVideoType2?.current?.pause();
          onClick();
        }}>
        <ChevronLeft />
      </div>
    );
  };
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    draggable: true,
    swipe: false,
    afterChange: (current) => {
      setPageActive(current);
      slideRef?.current?.innerSlider?.list.setAttribute('tabindex', current);
      slideRef?.current?.innerSlider?.list?.focus();
    },
    initialSlide: idx,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  useEffect(() => {
    slideRef?.current?.innerSlider?.list.setAttribute('tabindex', 0);
    slideRef?.current?.innerSlider?.list?.focus();
  }, [slideRef]);

  const handleLikePost = (post) => {
    dispatch(updatePostLikeDetail(post));
  };

  const handlePostBookMark = (id) => {
    dispatch(postBookMarkDetail(id));
  };

  const handleSharePost = (dataPC) => {
    setPopupCopyLink(dataPC);
  };

  const GroupIcon = (comment) => {
    return (
      <div
        className={`GroupIcon scroll-bar-custom-horizontal ${
          item?.is_followed === 0 && item?.follow_flag === 1 && 'disabled'
        } ${isAdmin && 'disabled'}`}
        style={{ padding: '0 16px' }}>
        <div className={`d-middle-popup-all`}>
          <div className='d-flex align-items-center justify-content-center'>
            {detailPost?.is_liked === 1 ? (
              <>
                {/* đã like */}
                <HeartActiveIcon
                  className={`like-icon active`}
                  onClick={() => {
                    handleLikePost(detailPost);
                  }}
                />
              </>
            ) : (
              <>
                {/* chưa like */}
                <HeartIcon
                  className={`like-icon`}
                  onClick={() => {
                    handleLikePost(detailPost);
                  }}
                />
              </>
            )}
            <p className='mb-0 cls-text'>{formatNumber(detailPost?.number_like)}</p>
          </div>

          <div className='d-middle'>
            <label
              style={{ display: 'flex', alignItems: 'center' }}
              htmlFor={`comment-item-${item?.id}`}>
              <CommentIcon className='like-icon' />
              <p className='mb-0 cls-text'>{formatNumber(detailPost?.number_comment) || 0}</p>
            </label>
          </div>
          {/* <div className={`d-middle`}>
            <RePostIcon style={{ color: '#787880' }} />
            <span className='number ms-1' style={{ color: '#666565' }}>
              {item?.number_repost ?? 0}
            </span>
          </div> */}
          <ShareIcon
            onClick={() => {
              handleSharePost({
                link: `${getEnv('REACT_APP_FAN_URL')}${newPathUser}post-sale/${item?.id}`,
                title: item?.title,
                id: item?.id,
              });
            }}
            className='like-icon'
          />
        </div>
        <div>
          {detailPost?.is_bookmarked === 1 ? (
            <>
              {/* đã bookmart */}
              <IconBookmark
                style={{
                  color: '#dc143c',
                }}
                fill={'#dc143c'}
                className='cls-icon'
                onClick={() => {
                  handlePostBookMark(detailPost?.id);
                }}
              />
            </>
          ) : (
            <>
              {/* chưa bookmart */}
              <IconBookmark
                style={{
                  color: '#787880',
                }}
                fill={'none'}
                className='cls-icon'
                onClick={() => {
                  handlePostBookMark(detailPost?.id);
                }}
              />
            </>
          )}
        </div>
      </div>
    );
  };

  const handleFollowCreator = (dt) => dispatch(fanFollowCreator(dt.user_id));

  const handleDeleteComment = () => {
    const { id, type, idParent } = popupDeleteComment;
    type === 'comment'
      ? dispatch(deleteCommentDetail(id))
      : dispatch(deleteReplyCommentDetail(id, idParent));
    setPopupDeleteComment(false);
  };

  const handleLikeComment = (data) => {
    // const { id, type } = data;
    // type === 'comment'
    //   ? dispatch(likeCommentDetail(id))
    //   : dispatch(likeReplyCommentDetail(id));
    const { commentId, type, dataPost } = data;
    type === 'comment'
      ? dispatch(updateLikeCommentDetail(commentId, dataPost))
      : dispatch(updateLikeReplyComment(commentId, dataPost));
  };

  const handleFollowUserComment = (data) => {
    const { id, type } = data;
    type === 'comment'
      ? dispatch(postFollowCommentDetail(id))
      : dispatch(postFollowReplyCommentDetail(id));
  };

  const handleTextarea = (e, id, height) => {
    if (e.target.scrollHeight > e.target.clientHeight) {
      e.target.style.height = e.target.scrollHeight + 'px';
    }
    var code = e.keyCode || e.charCode;
    if (code === 13) {
      e.target.style.height = 'inherit';
      e.target.style.height = `${e.target.scrollHeight}px`;
    }
    setValueComment(e.target.value);
    setCheckSendChat(id);
    if (e.target.value?.toString().trim() === '') {
      e.target.style.height = height;
      e.target.style.padding = '9px  30px  9px 12px';
      setCheckSendChat(null);
    }
  };

  const handlePostComment = (id, parent_id) => {
    const data = {
      parent_id: parent_id,
      content: valueComment,
    };
    checkSendChat && dispatch(postCommentDetail(id, data));
    setValueComment('');
    setCheckSendChat(null);
    const textareaId = document.getElementById(`comment-item1-${id}`);
    if (textareaId) textareaId.style.height = '44px';
  };

  const navigateByLink = (postId, type) => {
    navigate(
      `${newPathUser}post${
        Boolean(type === 2) ? '-sale' : Boolean(type === 3 || type === 4) ? '' : ''
      }/update/${postId}`
    );
  };

  const deletePostTimeLine = () => {
    setIsVisiblePopDeletePost(null);
    const { id } = isVisiblePopUpDeletePost;
    dispatch(creatorDeleteArticle(id, () => setIsVisiblePopDeletePostComplete(true)));
    close();
  };

  useLayoutEffect(() => {
    const heightContent = document?.querySelector('.div-content')?.offsetHeight;
    setWidthContent(heightContent);
  }, [detailPost, flgRender]);

  const checkDisabledAction = (item) => {
    if (item?.is_followed === 0 && item?.follow_flag === 1) {
      return true;
    }
    return false;
  };

  const handleDownloadFile = (item) => {
    if (+isProdEnableLiveStream === 1) {
      const a = document.createElement('a');
      const linkDownLoad = `${linkS3}${decryptPath(item.url)}`;
      a.href = linkDownLoad;
      a.download = linkDownLoad;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };
  return (
    <>
      {popupCopyLink && (
        <PopupSharePost
          isVisiblePopup={() => setPopupCopyLink(false)}
          data={popupCopyLink || {}}
        />
      )}
      {isVisiblePopUpDeletePost && (
        <PopupConfirmFan
          isVisiblePopup={(e) => setIsVisiblePopDeletePost(e)}
          colorSvg='#FF9F43'
          iconNote={true}
          text={t('Popup.confirmDeletePost')}>
          <div className='btn cancel' onClick={() => setIsVisiblePopDeletePost(null)}>
            {t('Common.buttonNo')}
          </div>
          <div className='ok btn' onClick={deletePostTimeLine}>
            {t('Common.buttonYes')}
          </div>
        </PopupConfirmFan>
      )}
      {check_buy_post === 'BUY_SUCCESS' && (
        <PopupGeneral
          className={`popup-user-selll-post`}
          generalClass='popup-user-selll-post-view1'
          title=''>
          <div className='post-sale-success1'>
            <div className='bg-white'>
              <div className='my-page-item sell-post-success'>
                <svg
                  width={80}
                  height={81}
                  viewBox='0 0 80 81'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M75.7441 47.1247L75.7209 47.1363C72.9787 49.2393 71.8168 52.8178 72.7929 56.1294L72.8045 56.1527C74.3613 61.4161 70.504 66.7258 65.0196 66.8653H64.9965C61.534 66.9583 58.4898 69.1658 57.3395 72.4308V72.4424C55.5035 77.6244 49.2527 79.6577 44.7329 76.5321C41.9263 74.6157 38.2162 74.5157 35.2634 76.5321H35.2518C30.7321 79.646 24.481 77.6243 22.6568 72.4307C22.0937 70.8371 21.0603 69.4519 19.6932 68.4581C18.326 67.4644 16.6895 66.9089 14.9999 66.8652H14.9766C9.49258 66.7257 5.63492 61.416 7.19195 56.1525L7.20352 56.1293C8.1793 52.8177 7.01742 49.2391 4.27555 47.1361L4.25227 47.1246C-0.104922 43.7782 -0.104922 37.2252 4.25227 33.8789L4.27555 33.8674C7.01742 31.7644 8.1793 28.1857 7.19195 24.8743V24.851C5.6232 19.5877 9.49242 14.2777 14.9766 14.1383H14.9999C18.4507 14.0453 21.5065 11.8377 22.6568 8.57285V8.56129C24.4809 3.37925 30.7321 1.34597 35.2518 4.4716H35.2634C38.1099 6.43519 41.8745 6.43519 44.7329 4.4716C49.2982 1.31894 55.5143 3.41035 57.3395 8.56129V8.57285C58.4898 11.8261 61.5338 14.0455 64.9965 14.1383H65.0196C70.5038 14.2777 74.3613 19.5877 72.8045 24.851L72.7929 24.8743C71.8168 28.1857 72.9787 31.7644 75.7209 33.8674L75.7441 33.8789C80.1013 37.2252 80.1013 43.7783 75.7441 47.1247Z'
                    fill='#00D566'
                  />
                  <path
                    opacity='0.1'
                    d='M39.9987 62.1459C51.952 62.1459 61.642 52.4559 61.642 40.5027C61.642 28.5494 51.952 18.8594 39.9987 18.8594C28.0455 18.8594 18.3555 28.5494 18.3555 40.5027C18.3555 52.4559 28.0455 62.1459 39.9987 62.1459Z'
                    fill='#1A1A1A'
                  />
                  <path
                    opacity='0.1'
                    d='M56.6177 26.6456C52.8741 23.5659 48.0845 21.7148 42.8633 21.7148C30.9102 21.7148 21.2148 31.4102 21.2148 43.3633C21.2148 48.5845 23.0659 53.3741 26.1455 57.1177C21.3891 53.15 18.3594 47.1817 18.3594 40.4995C18.3594 28.5463 28.0464 18.8594 39.9995 18.8594C46.6817 18.8594 52.65 21.8891 56.6177 26.6456Z'
                    fill='#1A1A1A'
                  />
                  <path
                    d='M34.8495 48.9719L30.0634 43.88C28.81 42.5462 28.8747 40.4492 30.2079 39.1958C31.5412 37.9408 33.6392 38.0081 34.8915 39.3409L37.1762 41.7706L46.8876 30.6709C48.0914 29.2934 50.1851 29.1537 51.5637 30.3592C52.9412 31.5647 53.0803 33.6578 51.8754 35.0353L39.7575 48.8845C38.4665 50.3584 36.1882 50.397 34.8495 48.9719Z'
                    fill='white'
                  />
                </svg>
                <img src='' alt='' />
                <div className='text-confirm'>
                  {+sellBy === 1 || sellBy === '1'
                    ? t('Popup.buyPostSuccess1', { code: detailPost?.code })
                    : t('Popup.buyPostTicketSuccess', { code: detailPost?.code })}
                </div>
                <div
                  className='btn btn-reload1'
                  style={{ padding: '10px 40px' }}
                  onClick={() => navigate(`${newPathUser}post-sale/${detailPost?.id}`)}>
                  {t('PostDetail.buttonReload')}
                </div>
              </div>
            </div>
          </div>
        </PopupGeneral>
      )}
      {isVisiblePopUpDeletePostComplete && (
        <PopupConfirmFan
          isVisiblePopup={(e) => setIsVisiblePopDeletePostComplete(e)}
          iconNote={true}
          text={t('Popup.deletePostSucess')}>
          <div className='ok btn' onClick={() => setIsVisiblePopDeletePostComplete(false)}>
            {t('Common.buttonYes')}
          </div>
        </PopupConfirmFan>
      )}
      {popupDeleteComment && (
        <PopupConfirmFan
          isVisiblePopup={() => setPopupDeleteComment(false)}
          colorSvg='#FF9F43'
          text={t('Popup.confirmDeleteComment')}
          className='popup-delete'>
          <div className='ok btn' onClick={handleDeleteComment}>
            {t('Common.buttonDelete')}
          </div>
          <div className='btn cancel' onClick={() => setPopupDeleteComment(false)}>
            {t('Common.buttonCancel')}
          </div>
        </PopupConfirmFan>
      )}
      <div className='popup-wrap'>
        <div
          className='popup-view-full-post-timeline1 my-page-item'
          style={{ borderBottom: 'none' }}>
          {!detailPost && (
            <div className='loading-timeline'>
              <LoadingIconV2 className='spinner-icon' />
            </div>
          )}
          {detailPost?.is_followed === 0 && detailPost?.follow_flag === 1 ? (
            <div className='lock-screen'>
              <div className='text-follow'>{t('DetailCard.notYetFollow')}</div>
              {!isAdmin && (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleFollowCreator(item);
                  }}>
                  {t('Common.follow')}
                </button>
              )}
            </div>
          ) : (
            ''
          )}
          <CloseIcon className='close-icon' onClick={close} />
          {detailPost?.medias?.length > 1 && (
            <div
              className={`pagination-slide ${detailPost?.medias?.length === 1 && 'hidden'}`}>
              <span>
                {`${pageActive + 1}/${
                  detailPost?.is_media_display === 1
                    ? detailPost?.medias?.filter((item) => item.type !== 4).length
                    : detailPost?.medias?.filter(
                        (item) => item.type !== 5 && item.type !== 2 && item.type !== 4
                      ).length
                }`}
              </span>
            </div>
          )}
          {detailPost?.medias?.length > 0 ? (
            <Slider ref={slideRef} {...settings} className='slide-image-full-view'>
              {detailPost?.is_media_display === 1
                ? detailPost?.medias
                    ?.filter((i) => i.type !== 4)
                    ?.map((item2, index) => (
                      <div className='list-images-item' key={index}>
                        {item2.type === 5 ? (
                          <div
                            className='file-zip'
                            style={{ position: 'relative' }}
                            onClick={() => handleDownloadFile(zipFile)}>
                            <div
                              className='file-zip-content d-flex justify-content-center'
                              style={{ padding: '32px 0' }}>
                              <ZipFileIconV2 className='anticon file-zip-anticon' />
                              <div
                                className='file_name name-file-zip d-flex align-items-center ms-3'
                                style={{
                                  color: '#1480FF',
                                  cursor: 'pointer',
                                }}>
                                {zipFile?.file_name}
                              </div>
                            </div>
                          </div>
                        ) : item2.type === 1 ? (
                          <ImgCustom
                            noIMGPlaceholder
                            className='change-bg'
                            screen='post_775_720_pc'
                            src={item2.url}
                            alt=''
                            onError={(e) => onErrImage(e, false)}
                          />
                        ) : item2.type === 2 ? (
                          <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                            <ReactPlayer
                              light={`${decryptPath(thumbnail?.url)}?width=775px&height=auto`}
                              ref={refVideoType3.current}
                              url={linkS3Video + decryptPath(item2?.url) + '#t=0.1'}
                              playing={isPlaying}
                              controls={true}
                              onPlay={() => {
                                setIsPlaying(true);
                              }}
                              onPause={() => {
                                setIsPlaying(false);
                              }}
                              playsInline={true}
                              width='100%'
                              height='100%'
                              className={`${isSafari && 'video-safari'}`}
                              config={{
                                file: {
                                  attributes: {
                                    onContextMenu: e => e.preventDefault(),
                                    controlsList:
                                      'nodownload noremoteplayback noplaybackrate foobar',
                                    disablepictureinpicture: 'true',
                                  },
                                },
                              }}
                            />
                          </div>
                        ) : (
                          <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                            <ReactPlayer
                              light={`${decryptPath(thumbnail?.url)}?width=775px&height=auto`}
                              ref={refVideoType3.current}
                              url={linkS3Video + decryptPath(item2?.url) + '#t=0.1'}
                              playing={isPlaying1}
                              controls={true}
                              onPlay={() => {
                                setIsPlaying1(true);
                              }}
                              onPause={() => {
                                setIsPlaying1(false);
                              }}
                              playsInline={true}
                              width='100%'
                              height='100%'
                              className={`${isSafari && 'video-safari'}`}
                              config={{
                                file: {
                                  attributes: {
                                    onContextMenu: e => e.preventDefault(),
                                    controlsList:
                                      'nodownload noremoteplayback noplaybackrate foobar',
                                    disablepictureinpicture: 'true',
                                  },
                                },
                              }}
                            />
                          </div>
                        )}
                      </div>
                    ))
                : detailPost?.medias
                    ?.filter((item) => item.type !== 5 && item.type !== 2 && item.type !== 4)
                    ?.map((item2, index) => (
                      <div className='list-images-item' key={index}>
                        {item2.type === 1 || item2.type === 4 ? (
                          <ImgCustom
                            noIMGPlaceholder
                            className='change-bg'
                            screen='post_775_720_pc'
                            src={item2.url}
                            alt=''
                            onError={(e) => onErrImage(e, false)}
                          />
                        ) : item2.type === 2 ? (
                          <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                            <ReactPlayer
                              light={`${decryptPath(thumbnail?.url)}?width=775px&height=auto`}
                              ref={refVideoType3.current}
                              url={linkS3Video + decryptPath(item2?.url) + '#t=0.1'}
                              playing={isPlaying2}
                              controls={true}
                              onPlay={() => {
                                setIsPlaying2(true);
                              }}
                              onPause={() => {
                                setIsPlaying2(false);
                              }}
                              playsInline={true}
                              width='100%'
                              height='100%'
                              className={`${isSafari && 'video-safari'}`}
                              config={{
                                file: {
                                  attributes: {
                                    onContextMenu: e => e.preventDefault(),
                                    controlsList:
                                      'nodownload noremoteplayback noplaybackrate foobar',
                                    disablepictureinpicture: 'true',
                                  },
                                },
                              }}
                            />
                          </div>
                        ) : (
                          <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                            <ReactPlayer
                              light={`${decryptPath(thumbnail?.url)}?width=775px&height=auto`}
                              ref={refVideoType3.current}
                              url={linkS3Video + decryptPath(item2?.url) + '#t=0.1'}
                              playing={isPlaying3}
                              controls={true}
                              onPlay={() => {
                                setIsPlaying3(true);
                              }}
                              onPause={() => {
                                setIsPlaying3(false);
                              }}
                              playsInline={true}
                              width='100%'
                              height='100%'
                              className={`${isSafari && 'video-safari'}`}
                              config={{
                                file: {
                                  attributes: {
                                    onContextMenu: e => e.preventDefault(),
                                    controlsList:
                                      'nodownload noremoteplayback noplaybackrate foobar',
                                    disablepictureinpicture: 'true',
                                  },
                                },
                              }}
                            />
                          </div>
                        )}
                      </div>
                    ))}
            </Slider>
          ) : (
            <></>
          )}
        </div>

        <div className='card-item--detail left-content-comment'>
          <div
            style={{
              display: 'flex',
              gap: '12px',
              position: 'relative',
              padding: '16px',
              minHeight: '101px',
            }}>
            <ImgCustom
              isPlaceholderAvatar
              screen='avatar_44_44'
              style={{
                minWidth: 44,
                maxWidth: 44,
                minHeight: 44,
                maxHeight: 44,
                borderRadius: '50%',
                objectFit: 'cover',
              }}
              src={`${detailPost?.avatar}`}
              alt=''
              className='cls-img'
            />
            <div className='wrap--text'>
              <p className='mb-0 cls-title' style={{ marginTop: '-3px' }}>
                {detailPost?.account_name}
              </p>
              <p className='cls-title-2nd' style={{ marginTop: '-5px' }}>
                {convertToCurrentTime(
                  detailPost?.public_time || detailPost?.created_at,
                  'YYYY/MM/DD HH:mm'
                )}
              </p>
            </div>

            <div className={`box-popup ${isAdmin && 'disabled'}`}>
              <ThreePointIcon
                className='three-point-icon'
                onClick={() => {
                  setIsVisiblePopup(isVisiblePopup === item?.id ? -1 : item?.id);
                }}
                onBlur={() => setIsVisiblePopup(-1)}
              />
              {isVisiblePopup === item?.id && (
                <div className='popup-edit-comment' style={{ width: '240px' }}>
                  {item?.user_id === dataUser?.id && (
                    <>
                      <div
                        className='item'
                        onMouseDown={() => {
                          navigate(`${newPathUser}post/update/${detailPost?.id}`);
                        }}>
                        <Edit3Icon color='#282727' />
                        <span className='text'>{t('comment.listComment.iconEdit')}</span>
                      </div>
                      <div
                        style={{ marginLeft: '-2px' }}
                        className='item'
                        onMouseDown={() =>
                          navigate(`${newPathUser}post-content-mode-2/${detailPost?.id}`)
                        }>
                        <ViewPostIcon />
                        <span className='text'>{t('PostDetail.pageAfterBuy')}</span>
                      </div>
                      <div
                        className='item'
                        style={{ marginBottom: '5px' }}
                        onMouseDown={() =>
                          navigate(`${newPathUser}list-post-buyed`, {
                            state: { userID: detailPost?.user_id },
                          })
                        }>
                        <ListIconV2 />
                        <span className='text'>{t('Common.buyerListForEachContent')}</span>
                      </div>
                    </>
                  )}
                  {item?.user_id !== dataUser?.id && (
                    <>
                      <div
                        className='item'
                        style={{ marginBottom: `${checkDisabledAction(item) && '5px'}` }}
                        onMouseDown={() => {
                          handleFollowCreator(item);
                          setIsVisiblePopup(-1);
                        }}>
                        {detailPost?.is_followed === 0 || !detailPost?.is_followed ? (
                          <FlowUnFlowIcon />
                        ) : (
                          <UnFlowIcon />
                        )}
                        <span className='text'>
                          {detailPost?.is_followed === 0 || !detailPost?.is_followed
                            ? t('comment.listComment.flow')
                            : t('comment.listComment.unflow')}
                        </span>
                      </div>
                      {/* {!checkDisabledAction(item) && ( */}
                      <div
                        className='item'
                        onMouseDown={() => {
                          setIsVisiblePopup(-1);
                          navigate(`${newPathUser}post-report`, {
                            state: {
                              post_id: item?.id,
                            },
                          });
                        }}>
                        <FlagIcon />
                        <span className='text'>{t('comment.listComment.iconFlag')}</span>
                      </div>
                      {/* )} */}
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className='div-content' style={{ padding: '0 16px' }}>
            {detailPost?.title && (
              <div className='package' style={{ marginTop: '0' }}>
                <span className='text-title'>{t('PostDetail.productTitle')}</span>
                <div className='text-content display-linebreak'>{detailPost?.title}</div>
              </div>
            )}
            {detailPost?.content && (
              <div className='package'>
                <span className='text-title'>{t('PostDetail.productDesc')}</span>
                {/* <div className='text-content display-linebreak mt-2'>
                  {detailPost?.content}
                </div> */}
                <ReadMoreLess
                  content={detailPost?.content}
                  charLimit={50}
                  onClick={() => {
                    setFlgRender(!flgRender);
                  }}
                />
              </div>
            )}
            {detailPost?.packages?.length > 0 && (
              <div className='package'>
                <span className='text-title'>{t('PostDetail.planContent')}</span>
                <div className='text-content'>
                  {detailPost?.all_package === 1
                    ? t('Common.allPlan')
                    : detailPost.package_names}
                </div>
              </div>
            )}
            {detailPost?.number_part && (
              <div className='number-part package'>
                <span className='text-title'>{t('PostDetail.partNunber')}</span>
                <div className='text-content'>{detailPost.number_part}</div>
              </div>
            )}
            {detailPost?.public_time && detailPost?.unpublic_time && (
              <div className='date package'>
                <span className='text-title'>{t('PostDetail.salesPeriod')}</span>
                <div className='text-content'>
                  {detailPost?.public_time &&
                    convertToCurrentTime(detailPost?.public_time, 'YYYY/MM/DD HH:mm:ss')}
                  {` ~ `}
                  {detailPost?.unpublic_time &&
                    convertToCurrentTime(detailPost?.unpublic_time, 'YYYY/MM/DD HH:mm:ss')}
                </div>
              </div>
            )}
            <div className='package'>
              <span className='text-title'>{t('PostDetail.purchasePremiumTicket')}</span>
              <div className='text-content'>
                {detailPost?.number_premium_ticket
                  ? t('PostDetail.premiumTicketTarget')
                  : t('PostDetail.notPremiumTicketTarget')}
              </div>
            </div>
            {detailPost?.number_premium_ticket && (
              <div className='number_premium_ticket package'>
                <span className='text-title'>{t('PostDetail.numberPremiumTicket')} </span>
                <div className='text-content'>
                  {detailPost.number_premium_ticket}
                  {t('Common.sheet')}
                </div>
              </div>
            )}
            {item?.user_id !== dataUser?.id && !item?.is_media_display && (
              <div className='price'>
                <div className='package left'>
                  <div className='text-title'>{t('Popup.sellingPrice')}</div>
                  <div className='text-price' style={{ color: '#DC143C' }}>
                    {formatCoin(detailPost?.price)} {t('Common.pt')}
                  </div>
                </div>

                <div className='package right'>
                  <PopupUserBuyPostF021
                    data_post={detailPost}
                    data_profile={dataUser}
                    sellBy={setSellBy}
                  />
                </div>
              </div>
            )}
          </div>
          <div className='line'></div>

          {GroupIcon(item?.number_comment)}

          <div className='line'></div>
          <div
            className='comment-wrap-popup'
            style={{ height: `calc(100% - ${widthContent}px - 187px)` }}>
            <div className='sroll-list' id='sroll-list-comment'>
              <CommentPostTimeLine
                dataPost={detailPost}
                handleDeleteComment={(e) => {
                  setPopupDeleteComment(e);
                }}
                handleLikeComment={handleLikeComment}
                dataProfile={dataUser}
                handleFollowUserComment={handleFollowUserComment}
                id={item?.id}
                isPopupSaleContent
              />
            </div>
            <div className='div-list-cmt'>
              <div className={`comment block`}>
                {dataUser?.avatar ? (
                  <ImgCustom screen='avartar_navbar_60_60' src={dataUser?.avatar} />
                ) : (
                  <img src={AVATAR_DEFAULT} alt='AVATAR_DEFAULT' />
                )}
                <form onSubmit={(e) => e.preventDefault()}>
                  <InputWrapper id='fsite-comment' className='form-item'>
                    <Textarea
                      disabled={
                        item?.enable_comment === 0 ||
                        (item?.is_followed === 0 && item?.follow_flag === 1) ||
                        isAdmin
                      }
                      onChange={(e) => handleTextarea(e, item?.id, '44px')}
                      placeholder={t('comment.formAddRepComment.placeholder')}
                      onKeyDown={(e) => handleTextarea(e, item?.id, '44px')}
                      value={valueComment}
                      maxLength='500'
                      id={`comment-item1-${item?.id}`}
                      overflowHidden
                    />
                    <SendChatIcon
                      className={`${valueComment !== '' && 'active'}`}
                      onClick={() => valueComment !== '' && handlePostComment(item?.id, 0)}
                    />
                  </InputWrapper>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PopupViewFullSaleContent;
