import { ImgCustom, PopupLoading } from 'components';
import MarkDownRead from 'components/MarkDownRead';
import { newPathUser } from 'constants/layout/constant';
import Loading from 'components/Paging/Loading';
import { useClickOutSize } from 'hooks/v2/clickOutSide';
import {
  Edit2Icon,
  Edit3Icon,
  FlagIcon,
  FlowUnFlowIcon,
  HeartIcon,
  ThreePointIcon,
  ThreePointIconV3,
  TrashIcon,
  UnFlowIcon,
} from 'images';
import { useCallback, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  editCommentDetailReply,
  getReplyListCommentDetail,
  logUserProfile,
  postFollowCommentDetailNoApi,
} from 'store/actions/myPage';
import { AVATAR_BLOCK_USER, AVATAR_DEFAULT, AVATAR_DEL_USER, STORAGE } from 'utils';
import Storage from 'utils/storage';
import { convertToCurrentTime, isAdmin, isAgency } from 'utils/utils';
import useUserRole from '../../hooks/useUserRole';
import './index.scss';
import { FormEditReplyComment } from './commentListShowAll.editReply';
import { useContext } from 'react';
import { ContextTimeline } from 'pages/timeline/contextTimeline';
import { useMemo } from 'react';
import {
  followOrUnFollowUserNoApi,
  resetDataInfoOther,
  resetIDHomePage,
} from 'store/actions/usersNew';
import HideCommentIcon from 'images/HideCommentIcon';

const updateNumberComment = (id, list) => {
  const objectToUpdate = list.find((item) => item.id === id);
  if (objectToUpdate) {
    objectToUpdate.number_comment += 1;
  } else {
    console.log(`${id} not found`);
  }
  return list || [];
};
const updateNumberCommentDelete = (id, list) => {
  const objectToUpdate = list.find((item) => item.id === id);
  if (objectToUpdate) {
    objectToUpdate.number_comment -= 1;
  } else {
    console.log(`${id} not found`);
  }
  return list || [];
};

function updateArrayWithNewData(originData, newData) {
  const idToUpdate = newData.id;
  const indexToUpdate = originData.findIndex((item) => item.id === idToUpdate);

  if (indexToUpdate !== -1) {
    originData[indexToUpdate] = newData;
  } else {
    originData.push(newData);
  }

  return originData;
}

function deleteItemById(dataArray, idToDelete) {
  const updatedArray = dataArray.filter((item) => item.id !== idToDelete);
  return updatedArray;
}
function hideItemById(dataArray, idToHide) {
  const updatedArray = dataArray?.map((item) => {
    if (item.id === idToHide) {
      return {
        ...item,
        is_hidden: 1,
      };
    }
    return item;
  });
  return updatedArray;
}

const ListRepComment = ({
  handleDeleteComment,
  handleLikeComment,
  handleHideComment = () => {},
  handleFollowUserComment,
  item_comment,
  dataPost,
  isEnableComment = true,
  isPopupSaleContent = false,
  setTrickReply = () => {},
  trickReply = null,
  setCountComment = () => {},
  isShowRepComment = false,
  commentDetail = [],
  setCommentDetail = () => {},
  replyCommentDetail1,
  setReplyCommentDetail1,
  flg
}) => {
  const { setCountNumberView } = useContext(ContextTimeline);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [replyCommentDetail, setReplyCommentDetail] = useState([]);
  const [pagination, setPagination] = useState({});
  const [statusEditRepComment, setStatusEditRepComment] = useState(null);
  const dataProfile = useSelector((state) => state.users.dataUser);
  const [showFormEditComment, setFormEditComment] = useState(null);
  const [page, setPage] = useState(0);
  const [isVisiblePopup, setIsVisiblePopup] = useState(-1);
  const [isVisible, setIsVisible] = useState(false);
  const [idFan, seIdFan] = useState();
  const { isIdol } = useUserRole();
  const isAuth = Storage.has(STORAGE.USER_ACCESS_TOKEN);
  const [visibleComments, setVisibleComments] = useState(1);
  const [showOpenRep, setShowOpenRep] = useState(false);
  const [loadingMess, setLoadingMess] = useState(false);
  const [isLoadingFollow, setIsLoadingFollow] = useState(false);

  useEffect(() => {
    if (replyCommentDetail1?.length > 0 && flg) {
      setReplyCommentDetail(replyCommentDetail1);
    }
  }, [replyCommentDetail1, flg]);

  useEffect(() => {
    if (isShowRepComment[item_comment?.id]) {
      setShowOpenRep(true);
    }
  }, []);
  // esc func
  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      setFormEditComment(null);
    }
  }, []);
  // start func esc
  useEffect(() => {
    if (statusEditRepComment) {
      setFormEditComment(null);
      const textareaId = document.getElementById('textarea-edit-rep-comment');
      if (textareaId) textareaId.style.height = '38px';
    }
    document.addEventListener('keydown', escFunction);
    return () => {
      document.removeEventListener('keydown', escFunction);
    };
  }, [escFunction, statusEditRepComment]);

  const { search } = useLocation();
  const token = search?.split('&');
  const tokenAgency = localStorage.getItem(STORAGE.AGENCY_TOKEN)?.replace(/"/g, '') || false;

  const adminToken = isAgency
    ? tokenAgency
    : token[1]?.split('=')[1] ?? token[0]?.split('=')[1];

  const handleButtonShowList = () => {
    setLoadingMess(true);
    const onSuccess = (res) => {
      setLoadingMess(false);
      const uniqueComments = {};
      const uniqueCommentArray = [];
      [...replyCommentDetail, ...(res?.data || [])].forEach((comment) => {
        if (!uniqueComments[comment.id]) {
          uniqueComments[comment.id] = true;
          uniqueCommentArray.push(comment);
        }
      });
      setReplyCommentDetail(uniqueCommentArray);
      setReplyCommentDetail1(uniqueCommentArray);
      setPagination(res?.pagination || {});
      setStatusEditRepComment(null);
    };
    const onFailed = () => {};
    dispatch(
      getReplyListCommentDetail({
        id: item_comment?.id,
        page: 1,
        limit: 10,
        onSuccess,
        onFailed,
        adminToken,
      })
    );
  };
  const getCommentListMore = ({ nextPage }) => {
    setLoadingMess(true);
    const onSuccess = (res) => {
      setLoadingMess(false);
      const uniqueComments = {};
      const uniqueCommentArray = [];
      [...replyCommentDetail, ...(res?.data || [])].forEach((comment) => {
        if (!uniqueComments[comment.id]) {
          uniqueComments[comment.id] = true;
          uniqueCommentArray.push(comment);
        }
      });
      setReplyCommentDetail(uniqueCommentArray);
      setPage(nextPage);
      setPagination(res?.pagination || {});
      setStatusEditRepComment(null);
    };
    const onFailed = () => {};
    dispatch(
      getReplyListCommentDetail({
        id: item_comment?.id,
        page: nextPage,
        limit: 10,
        onSuccess,
        onFailed,
        adminToken,
      })
    );
  };
  const handleViewRepComment = (comment_id) => {
    setShowOpenRep(false);
    setVisibleComments((prev) => replyCommentDetail.length + 10);
    const cacuPage = Math.ceil((replyCommentDetail.length + 1) / 10);
    setPage(cacuPage);

    handleButtonShowList();
  };
  const loadMoreComments = () => {
    const cacuPage = Math.ceil((replyCommentDetail.length + 1) / 10);
    setVisibleComments((prev) => replyCommentDetail.length + 10);
    setPage(cacuPage);
    getCommentListMore({ nextPage: cacuPage });
  };
  const checkDisabledAction = (item) => {
    if (isPopupSaleContent) return false;
    if (isAdmin || isAgency) return true;
    if (
      (item?.is_followed === 0 && item?.follow_flag === 1 && dataPost?.is_my_post === 0) ||
      (item?.is_media_display === 0 && dataPost?.is_my_post === 0)
    ) {
      return true;
    }
    return false;
  };

  const [refOutSideThree] = useClickOutSize(() => {
    setIsVisiblePopup(-1);
  });

  //  Trick reset comment
  useEffect(() => {
    if (`${trickReply?.id}` === `${item_comment?.id}`) {
      setVisibleComments((prev) => prev + 1);
      const newComment = trickReply?.data || [];
      setReplyCommentDetail((prev) => [newComment, ...prev]);
      setCountNumberView((prev) => ({
        ...prev,
        countNumberComment: prev.countNumberComment + 1,
      }));
      setCountComment((prev) => prev + 1);
      const updateCount = updateNumberComment(trickReply?.id, commentDetail);
      setCommentDetail(updateCount);
      setTrickReply(null);
    }
  }, [trickReply?.id]);

  const handleUpdateComment = (newData) => {
    const newArray = updateArrayWithNewData(replyCommentDetail, newData);
    setReplyCommentDetail(newArray);
  };
  const updateCommentLikes = (id) => {
    const updatedComments = replyCommentDetail.map((comment) => {
      if (comment.id === id) {
        comment.is_liked = comment.is_liked === 0 ? 1 : 0;
        comment.number_like =
          comment.is_liked === 1 ? comment.number_like + 1 : comment.number_like - 1;
      }
      return comment;
    });
    setReplyCommentDetail(updatedComments);
  };

  const isCreator = useMemo(() => {
    return +dataPost?.user_id === +dataProfile?.id;
  }, [dataPost?.user_id, dataProfile?.id]);

  const checkIsShowComment = useCallback(
    (item_rep) => {
      if (+item_rep?.is_hidden === 1) {
        if (item_rep?.user_id === dataProfile?.id) {
          return true;
        }
        return false;
      }
      return true;
    },
    [dataProfile?.id]
  );

  const updateCommentFollow = (id, cb = () => {}) => {
    const updateFollow = (idUser, list) => {
      for (let i = 0; i < list.length; i++) {
        // Check if the idUser matches the provided idUser
        if (list[i].user_id === idUser) {
          // Toggle the value of is_followed (1 to 0 or 0 to 1)
          list[i].is_followed = list[i].is_followed === 1 ? 0 : 1;
          cb(list[i].is_followed === 1 ? 0 : 1);
        }
      }
      return list;
    };
    const updatedComments = updateFollow(id, commentDetail);
    setCommentDetail(updatedComments);
  };

  const updateCommentFollowSub = (id, cb = () => {}) => {
    const updateFollow = (idUser, list) => {
      for (let i = 0; i < list.length; i++) {
        // Check if the idUser matches the provided idUser
        if (list[i].user_id === idUser) {
          // Toggle the value of is_followed (1 to 0 or 0 to 1)
          list[i].is_followed = list[i].is_followed === 1 ? 0 : 1;
          cb(list[i].is_followed === 1 ? 0 : 1);
        }
      }
      return list;
    };
    const updatedComments = updateFollow(id, replyCommentDetail);
    setReplyCommentDetail(updatedComments);
  };

  function FollowCommentUser({
    handleFollowUserComment,
    isAuth,
    setIsVisiblePopup,
    updateCommentFollow,
    item_rep,
    setIsLoadingFollow,
    callbackFollow,
    type = 'default',
    updateCommentFollowSub
  }) {
    const { listPostCreator, listPostTimeLine, detailPost, searchPostTimeline } = useSelector(
      (state) => state.usersNew
    );
    const { fanListBookmark } = useSelector((state) => state.users);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [isFollowed, setIsFollowed] = useState(false);
    // typeFollow = timeline
    useEffect(() => {
      if (type === 'timeline') {
        const findUser = listPostTimeLine.find(
          (item) => item.user_id === item_rep?.user_id
        );
        if (findUser) {
          setIsFollowed(findUser?.is_followed);
        } else {
          setIsFollowed(item_rep?.is_followed);
        }
      }
    }, []);
    // typeFollow = detailHome
    useEffect(() => {
      if (type === 'detailHome') {
        const findUser = listPostCreator.find(
          (item) => item.user_id === item_rep?.user_id
        );
        if (findUser) {
          setIsFollowed(findUser?.is_followed);
        } else {
          setIsFollowed(item_rep?.is_followed);
        }
      }
    }, []);
    // typeFollow = detailPost || postDetail
    useEffect(() => {
      if (type === 'detailPostSale' || type === 'postDetail') {
        setIsFollowed(item_rep?.is_followed);
      }
    }, []);
    // typeFollow = fav
    useEffect(() => {
      if (type === 'fav') {
        const findUser = fanListBookmark.find(
          (item) => item.user_id === item_rep?.user_id
        );
        if (findUser) {
          setIsFollowed(findUser?.is_followed);
        } else {
          setIsFollowed(item_rep?.is_followed);
        }
      }
    }, []);
    // typeFollow = searchShort
    useEffect(() => {
      if (type === 'searchShort') {
        const findUser = searchPostTimeline.find(
          (item) => item.user_id === item_rep?.user_id
        );
        if (findUser) {
          setIsFollowed(findUser?.is_followed);
        } else {
          setIsFollowed(item_rep?.is_followed);
        }
      }
    }, []);

    return (
      <div
        style={{ cursor: `${item_rep?.status === null && 'not-allowed'}` }}
        className='item'
        onMouseDown={() => {
          if (!isAuth) {
            setIsVisiblePopup(-1);
            dispatch(logUserProfile());
          } else {
            item_rep?.status !== null && updateCommentFollowSub(item_rep?.user_id)
            item_rep?.status !== null &&  updateCommentFollow(item_rep?.user_id);
            setTimeout(() => {
              switch (type) {
                case 'timeline':
                case 'detailHome':
                case 'detailPostSale':
                case 'postDetail':
                case 'default':
                  item_rep?.status !== null &&  dispatch(
                    postFollowCommentDetailNoApi(
                      {
                        is_followed: isFollowed === 1 ? 0 : 1,
                      },
                      item_rep?.user_id
                    )
                  );
                  callbackFollow && callbackFollow(item_rep?.is_followed === 1 ? 0 : 1);
                  break;
                // case 'fav':
                case 'searchShort':
                  item_rep?.status !== null &&  dispatch(
                    followOrUnFollowUserNoApi(
                      {
                        is_followed: isFollowed === 1 ? 0 : 1,
                      },
                      item_rep?.user_id
                    )
                  );
                  callbackFollow && callbackFollow(item_rep?.is_followed === 1 ? 0 : 1);
                  break;
                default:
                  break;
              }
            }, 50);
            item_rep?.status !== null &&  handleFollowUserComment(
              {
                id: item_rep?.user_id,
                type: 'comment',
              },
              () => {
                setIsLoadingFollow(true);
              },
              () => {
                setIsLoadingFollow(false);
              }
            );
            setIsVisiblePopup(-1);
          }
        }}>
        {(
          type === 'default'
            ? !item_rep?.is_followed || item_rep?.is_followed !== 1
            : isFollowed !== 1
        ) ? (
          <FlowUnFlowIcon />
        ) : (
          <UnFlowIcon />
        )}
        <span className='text'>
          {(
            type === 'default'
              ? !item_rep?.is_followed || item_rep?.is_followed !== 1
              : isFollowed !== 1
          )
            ? t('comment.listComment.flow')
            : t('comment.listComment.unflow')}
        </span>
      </div>
    );
  }

  const checkStatus = (status) => {
    let tmp = '';
    switch (status) {
      case null: // del
        tmp = AVATAR_DEL_USER
        break;
      case 2: // stop
        tmp = AVATAR_BLOCK_USER
        break;
      default:
        tmp = AVATAR_DEFAULT;
    }
    return tmp;
  };

  const View = replyCommentDetail?.slice(0, visibleComments)?.map(
    (item_rep) =>
      item_rep.parent_id === item_comment.id &&
      checkIsShowComment(item_rep) && (
        <div key={item_rep?.id} className='comment-item comment-item-reply 23'>
          <div
            className='avatar'
            onClick={() => {
              dispatch(resetIDHomePage());
              dispatch(resetDataInfoOther());
              navigate(`${newPathUser}${item_rep?.account_id}`);
            }}>
            {item_rep?.avatar ? (
              <ImgCustom alt='' screen='avatar_44_44' src={item_rep?.avatar} />
            ) : (
              <img src={(isAdmin || isAgency) ? AVATAR_DEFAULT : checkStatus(item_rep?.status)} alt='AVATAR_DEFAULT' />
            )}
          </div>
          {showFormEditComment?.id === item_rep?.id ? (
            <>
              <FormEditReplyComment
                item_rep={showFormEditComment}
                handleUpdateComment={handleUpdateComment}
                handleCancelEdit={() => {
                  setFormEditComment(null);
                }}
              />
            </>
          ) : (
            <>
              <div className='content'>
                <div className='info-comment'>
                  <div
                    className='left'
                    onClick={() => {
                      if (+dataProfile?.user_type !== 3) {
                        setIsVisible(true);
                        seIdFan(item_rep?.user_id);
                      }
                    }}>
                    <div className='dp-flex'>
                      <div
                        className='name custom_name'
                        style={{ maxWidth: `${!isMobile && '166px'}` }}
                        onClick={() => {
                          dispatch(resetIDHomePage());
                          dispatch(resetDataInfoOther());
                          navigate(`${newPathUser}${item_rep?.account_id}`);
                        }}>
                        {item_rep?.account_name}
                      </div>
                      <div className='time'>
                        {convertToCurrentTime(item_rep?.created_at, 'YYYY/MM/DD HH:mm')}
                      </div>
                      {item_rep.is_edited === 1 && <Edit2Icon className='icon-edited mb-1' />}
                    </div>

                    <div className='d-flex w-100 justify-content-between align-items-end'>
                      <div
                        className={`comment-content display-linebreak ${
                          isMobile ? '' : 'comment-content_v2'
                        }`}>
                        <MarkDownRead
                          charLimit={isMobile ? 90 : 160}
                          isMeta
                          content={item_rep?.content?.toString()}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='content-right'>
                    {(dataPost?.user_id === dataProfile?.id ||
                      item_rep?.user_id === dataProfile?.id) && (
                      <div className='box-popup'>
                        {checkDisabledAction(dataPost) ? (
                          <ThreePointIconV3 />
                        ) : (
                          <span
                            className='btn-three-point'
                            ref={refOutSideThree}
                            onClick={() => {
                              !checkDisabledAction(dataPost) &&
                                setIsVisiblePopup(
                                  isVisiblePopup === item_rep?.id ? -1 : item_rep?.id
                                );
                              // setIsVisiblePopup(
                              //   isVisiblePopup === item_rep?.id ? -1 : item_rep?.id
                              // );
                            }}>
                            <ThreePointIcon
                              className='three-point-icon'
                              onBlur={() => setIsVisiblePopup(-1)}
                            />
                          </span>
                        )}
                        {isVisiblePopup === item_rep?.id && (
                          <div className='popup-edit-comment' style={{ top: '25px' }}>
                            {item_rep?.user_id !== dataProfile?.id && (
                              <FollowCommentUser
                                {...{
                                  handleFollowUserComment,
                                  isAuth,
                                  setIsVisiblePopup,
                                  updateCommentFollow,
                                  item_rep,
                                  setIsLoadingFollow,
                                  updateCommentFollowSub
                                }}
                                callbackFollow={() => {}}
                                type={'postDetail'}
                                dataPost={dataPost}
                              />
                            )}
                            {isCreator && item_rep?.user_id !== dataProfile?.id && (
                              <div
                                className='item item1'
                                onMouseDown={() => {
                                  if (!isAuth) {
                                    setIsVisiblePopup(-1);
                                    dispatch(logUserProfile());
                                  } else {
                                    setIsVisiblePopup(-1);
                                    handleHideComment({
                                      id: item_rep?.id,
                                      cb: () => {
                                        const newData = hideItemById(
                                          replyCommentDetail,
                                          item_rep?.id
                                        );
                                        setReplyCommentDetail(newData);
                                      },
                                    });
                                  }
                                }}>
                                <HideCommentIcon color='#1A1A1A' />
                                <span className='text'>{t('Common.hide')}</span>
                              </div>
                            )}
                            {item_rep?.user_id === dataProfile?.id && (
                              <div
                                className='item item1'
                                onMouseDown={() => {
                                  setStatusEditRepComment(true);
                                  dispatch(
                                    editCommentDetailReply(item_rep?.id, ({ data }) => {
                                      setFormEditComment(data);
                                    })
                                  );
                                  //setFormEditComment(item_rep);
                                  setIsVisiblePopup(-1);
                                }}>
                                <Edit3Icon color='#282727' />
                                <span className='text'>
                                  {t('comment.listRepComment.iconEdit')}
                                </span>
                              </div>
                            )}
                            {item_rep?.user_id !== dataProfile?.id && isIdol && (
                              <div
                                style={{
                                  cursor: item_rep?.status === null ? 'not-allowed' : 'auto'
                                }}
                                className='item'
                                onMouseDown={() => {
                                  setIsVisiblePopup(-1);
                                  item_rep?.status !== null && navigate(`${newPathUser}report`, {
                                    state: {
                                      fan_id: item_rep?.user_id,
                                      fan_name: item_rep?.account_name,
                                    },
                                  });
                                }}>
                                <FlagIcon />
                                <span className='text'>
                                  {t('comment.listRepComment.iconFlag')}
                                </span>
                              </div>
                            )}
                            <div
                              className='item'
                              onMouseDown={() => {
                                setIsVisiblePopup(-1);
                                handleDeleteComment({
                                  id: item_rep?.id,
                                  type: 'reply',
                                  postId: item_rep.parent_id,
                                  idParent: item_rep.parent_id,
                                  cb: () => {
                                    const newData = deleteItemById(
                                      replyCommentDetail,
                                      item_rep?.id
                                    );
                                    setCountNumberView((prev) => ({
                                      ...prev,
                                      countNumberComment: prev.countNumberComment - 1,
                                    }));
                                    const updateParentComment = updateNumberCommentDelete(
                                      item_rep.parent_id,
                                      commentDetail
                                    );
                                    setCommentDetail(updateParentComment);
                                    setCountComment((prev) => prev - 1);
                                    setReplyCommentDetail(newData);
                                  },
                                });
                              }}>
                              <TrashIcon />
                              <span className='text'>{t('Common.buttonDelete')}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className={`like-reply `}
                  style={{
                    pointerEvents: isAdmin || isAgency ? 'none' : 'pointer',
                    color: isAdmin || isAgency ? '#B1B6BB' : '#787880',
                  }}>
                  {/* <span
                    className={`like ${item_rep?.status_like_comment === 1 && 'active'}`}
                    onClick={() => {
                      if (!isAdmin) {
                        handleLikeComment({ id: item_rep?.id, type: 'reply' });
                      }
                    }}>{`${t('comment.listRepComment.textLike')} ${
                    item_rep?.number_like
                  }`}</span> */}
                  <span
                    className={`like`}
                    style={{
                      cursor: `${checkDisabledAction(dataPost) ? 'default' : 'pointer'}`,
                    }}
                    onClick={() => {
                      if (!isAuth) {
                        dispatch(logUserProfile());
                      } else {
                        if (!isAdmin) {
                          !checkDisabledAction(dataPost) &&
                            dataPost?.enable_comment !== 0 &&
                            handleLikeComment(
                              {
                                dataPost: item_rep,
                                commentId: item_rep?.id,
                                type: 'reply',
                              },
                              () => {
                                updateCommentLikes(item_rep?.id);
                              }
                            );
                        }
                      }
                    }}>
                    <HeartIcon
                      style={{
                        color:
                          item_rep?.is_liked === 1
                            ? '#dc143c'
                            : checkDisabledAction(dataPost) || !isEnableComment
                            ? '#BEBEBE'
                            : '#787880',
                        cursor: `${
                          checkDisabledAction(dataPost) || !isEnableComment
                            ? 'default'
                            : 'pointer'
                        }`,
                      }}
                      fill={item_rep?.is_liked === 1 ? '#dc143c' : 'none'}
                      className='cls-icon'
                    />
                    <p
                      style={{
                        color: `${
                          checkDisabledAction(dataPost) || !isEnableComment
                            ? '#BEBEBE'
                            : '#787880'
                        }`,
                        cursor: `${
                          checkDisabledAction(dataPost) || !isEnableComment
                            ? 'default'
                            : 'pointer'
                        }`,
                        marginBottom: '0px',
                      }}>
                      {item_rep?.number_like}
                    </p>
                  </span>
                </div>
              </div>
            </>
          )}
        </div>
      )
  );
  return (
    <>
      {isLoadingFollow && <PopupLoading className={'popup-loading'} />}
      <div className='reply-comment'>
        {View}
        {pagination?.total_pages > pagination?.current_page && (
          <button className='btn-view-all' onClick={loadMoreComments}>
            {t('comment.listRepComment.viewMore')}
          </button>
        )}
      </div>

      {showOpenRep && isShowRepComment[item_comment?.id] ? (
        <span
          className='view-more-comment'
          style={{
            cursor: `${
              checkDisabledAction(dataPost) || !isEnableComment ? 'default' : 'pointer'
            }`,
            marginBottom: '16px',
          }}
          onClick={() => {
            if (isEnableComment) {
              handleViewRepComment(item_comment.id);
            }
          }}>
          {t('comment.listRepComment.viewMore')} ...
        </span>
      ) : null}
      {loadingMess && <Loading className='popup-loading' />}
    </>
  );
};

export default ListRepComment;
