import { newPathUser } from 'constants/layout/constant';
import useDisableContextMenu from 'hooks/useDisableContextMenu';
import { PlayIcon } from 'images';
import { TimelineWrapperContext8 } from 'pages/postlogin';
import { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import ReactHlsPlayer from 'react-hls-player';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { ImgCustom } from '../../../components';
import {
  decryptPath,
  funcThumbnailIMG,
  linkS3VideoM3U8,
} from '../../../utils/utils';
import { VideoInList } from './VideoInList';
import { TimelineContext } from './index';
import './index.scss';
import { StyledTimeline } from './styled';
import { VideoTag } from './VideoTag';
import { getIDTimeline } from 'store/actions/usersNew';
import styled, { css } from 'styled-components';
import useCheckFullVideo from 'hooks/v2/useCheckFullVideo';

const WrapperHideIconVideo = styled.div`
  width: 100%;
  //height: 100%;
  position: relative;
  padding-top: 56.25%;
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  video::-webkit-media-controls-overlay-play-button {
    ${({ isFull = false }) =>
      isFull
        ? css`
            display: block;
          `
        : css`
            display: none;
          `};
  }

  *::-webkit-media-controls-start-playback-button {
    ${({ isFull = false }) =>
      isFull
        ? css`
            display: block;
          `
        : css`
            display: none !important;
            -webkit-appearance: none;
          `};
  }
`;
const delay = 15000;
export const Card = () => {
  const { dataUser } = useSelector((state) => state.users);
  const { data, item, flgPause, tab, itemParent, page } =
    useContext(TimelineContext);
  const { runTimeline } = useContext(TimelineWrapperContext8);
  const [run, setRun] = useState(false);
  const [index, setIndex] = useState(0);
  const timeoutRef = useRef(null);
  const refVideo = useRef();
  useDisableContextMenu(refVideo);
  const buttonRef = useRef();
  const resetTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };
  const [isPlaying, setIsPlaying] = useState(false);
  const [controlCustom, setControlCustom] = useState(false);
  const [isFullScreen, setIsFullScreen] = useCheckFullVideo(refVideo);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tmpData = data.filter((item) => item?.type !== 4 && item?.public_flag === 1);
  useDisableContextMenu(refVideo);
  const isMiniUrl = () => {
    return (
      (item?.is_followed === 0 && item?.follow_flag === 1 && item?.user_id !== dataUser?.id) ||
      item?.is_media_display === 0
    );
  };

  useEffect(() => {
    if (run) {
      resetTimeout();
      timeoutRef.current = setTimeout(() => {
        setIndex((prev) => (prev === tmpData.length - 1 ? 0 : prev + 1));
      }, delay);

      return () => {
        resetTimeout();
      };
    }
  }, [index, run]);

  useEffect(() => {
    if (flgPause) {
      refVideo?.current?.pause();
    }
  }, [flgPause]);

  const refCard = useRef();

  useEffect(() => {
    if (!tmpData[0]?.post_id || !runTimeline) {
    } else {
      if (runTimeline === tmpData[0]?.post_id) {
        refVideo?.current?.play();
      } else {
        refVideo?.current?.pause();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tmpData[0]?.post_id, runTimeline, refVideo && buttonRef]);

  const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

  return (
    <>
      {/* case 1 img or video */}
      {tmpData?.length === 1 && (
        <div
          ref={refCard}
          // className={`${isMobile && showButton ? 'show-play-icon' : 'unshow-play-icon'}`}
          style={{
            display: 'flex',
            height: '100%',
            width: '100%',
            position: 'relative',
            //padding: '0 16px',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '5px'
            //aspectRatio: '16/9'
          }}>
          {tmpData[0]?.type === 1 ? ( // img
            <StyledTimeline.BlueImg1
              url={`${decryptPath(tmpData[0].url, { isMini: true })}`}
              height={`${isMobile ? 193 : 360}`}
              width={`${isMobile ? 372 : 640}`}>
              <div className='bg-img'></div>
              <ImgCustom
                onClick={() => {
                  dispatch(getIDTimeline({ id: itemParent?.id, tab: tab, page: page }));
                  navigate(`${newPathUser}post/${item?.id}`);
                }}
                isMini={isMiniUrl()}
                screen={'post_640_auto_pc'}
                style={{
                  height: '100%',
                  width: '100%',
                  margin: 'auto',
                  top: '0',
                  left: '0',
                  right: '0',
                  bottom: '0',
                  objectFit: 'contain',
                  position: 'absolute',
                  zIndex: '10',
                  background: 'transparent',
                }}
                src={tmpData[0].url}
                alt=''
              />
            </StyledTimeline.BlueImg1>
          ) : (
            // video
            <>
              {decryptPath(tmpData[0]?.url) !== 'NULL' &&
              decryptPath(tmpData[0]?.url)?.indexOf('.m3u8') === -1 ? (
                <VideoTag
                  isMini={isMiniUrl() && isFirefox}
                  tmpData={tmpData}
                  item={item}
                  handleNavigate={() => {
                    dispatch(getIDTimeline({ id: itemParent?.id, tab: tab, page: page }));
                    navigate(`${newPathUser}post/${item?.id}`);
                  }}
                />
              ) : (
                <>
                  <WrapperHideIconVideo
                    onClick={() => {
                      if (!isPlaying) {
                        const video = refVideo.current;
                        if (video) {
                          video.controls = !video.controls;
                        }
                        setControlCustom(false);
                        dispatch(getIDTimeline({ id: itemParent?.id, tab: tab, page: page }));
                        navigate(`${newPathUser}post/${item?.id}`);
                      }
                    }}>
                    {!isPlaying && controlCustom && (
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          dispatch(
                            getIDTimeline({ id: itemParent?.id, tab: tab, page: page })
                          );
                          navigate(`${newPathUser}post/${item?.id}`);
                        }}
                        style={{
                          position: 'absolute',
                          width: '100%',
                          height: '70%',
                          zIndex: 32,
                          top: 0,
                        }}></div>
                    )}
                    <ReactHlsPlayer
                      style={{ objectFit: 'contain', background: '#000', zIndex: 30 }}
                      className='video-16-9'
                      disablePictureInPicture
                      preload='none'
                      playerRef={refVideo}
                      src={`${linkS3VideoM3U8}${decryptPath(tmpData[0]?.url) + '#t=0.1'}`}
                      autoPlay={false}
                      controls={isFullScreen ? true : controlCustom}
                      controlsList='nodownload noremoteplayback noplaybackrate foobar'
                      onPause={(e) => {
                        if (refVideo.current.seeking) return;
                        refVideo.current.pause();
                        setControlCustom(true);
                        setIsPlaying(false);
                      }}
                      onPlay={() => {
                        refVideo.current.play();
                        setIsPlaying(true);
                        setControlCustom(true);
                      }}
                      width='100%'
                      height='100%'
                      playsInline={true}
                    />
                    {!isFullScreen && (
                      <StyledTimeline.ButtonPlay
                        widthButton='56px'
                        heightButton='56px'
                        onClick={(e) => {
                          e.stopPropagation();
                          if (isPlaying) {
                            setIsPlaying(false);
                            setControlCustom(true);
                            refVideo.current.pause();
                          } else {
                            setIsPlaying(true);
                            setControlCustom(true);
                            refVideo.current.play();
                          }
                        }}>
                        {!isPlaying && (
                          <PlayIcon
                            style={{
                              filter: 'drop-shadow(0px 0px 0.5px #000)',
                            }}
                          />
                        )}
                      </StyledTimeline.ButtonPlay>
                    )}
                  </WrapperHideIconVideo>
                </>
              )}
            </>
          )}
        </div>
      )}
      {/* case 2 img or video */}
      {tmpData?.length === 2 && (
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '319px',
            //padding: '0 16px',
            gap: '2px',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '5px'
          }}>
          <VideoInList
            isMini={isMiniUrl() && isFirefox}
            tmpData={tmpData}
            item={item}
            isMiniUrl={isMiniUrl}
            handleNavigate={() => {
              dispatch(getIDTimeline({ id: itemParent?.id, tab: tab, page: page }));
              navigate(`${newPathUser}post/${item?.id}`);
            }}
          />
          <div
            className='position-relative w-100'
            style={{ width: '319px', height: 'auto' }}
            onClick={() => {
              dispatch(getIDTimeline({ id: itemParent?.id, tab: tab, page: page }));
              navigate(`${newPathUser}post/${item?.id}`);
            }}>
            {tmpData[1]?.type === 1 ? (
              <StyledTimeline.BlueImg
                url={`${decryptPath(tmpData[1].url, { isMini: true })}`}
                height={319}>
                <div className='bg-img'></div>
                <ImgCustom
                  isMini={isMiniUrl()}
                  screen={'post_319_auto_pc'}
                  src={tmpData[1]?.url}
                  alt=''
                  className='set-w-h-img'
                />
              </StyledTimeline.BlueImg>
            ) : (
              <>
                <StyledTimeline.BlueImg
                  url={`${funcThumbnailIMG(
                    decryptPath(tmpData[1].url, {
                      isMini: true,
                    })
                  )}`}
                  height={319}>
                  <div className='bg-img'></div>
                  <ImgCustom
                    isMini={isMiniUrl()}
                    screen={'post_319_auto_pc'}
                    src={funcThumbnailIMG(decryptPath(tmpData[1]?.url))}
                    alt=''
                    className='set-w-h-img'
                  />
                </StyledTimeline.BlueImg>
                <StyledTimeline.ButtonPlay>
                  <PlayIcon />
                </StyledTimeline.ButtonPlay>
              </>
            )}
          </div>
        </div>
      )}
      {/* case 3 img or video */}
      {tmpData?.length === 3 && (
        <div
          style={{
            display: 'flex',
            height: `${isMobile ? '343px' : '640px'}`,
            width: '100%',
            //padding: '0 16px',
            gap: '2px',
            marginBottom: '5px'
          }}>
          <VideoInList
            isMini={isMiniUrl() && isFirefox}
            tmpData={tmpData}
            item={item}
            isMiniUrl={isMiniUrl}
            handleNavigate={() => {
              dispatch(getIDTimeline({ id: itemParent?.id, tab: tab, page: page }));
              navigate(`${newPathUser}post/${item?.id}`);
            }}
            typeShow='three_items'
          />

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '2px',
              width: '100%',
            }}>
            <div
              className='position-relative w-100'
              style={{ height: `${isMobile ? '171px' : '319px'}` }}
              onClick={() => {
                dispatch(getIDTimeline({ id: itemParent?.id, tab: tab, page: page }));
                navigate(`${newPathUser}post/${item?.id}`);
              }}>
              {tmpData[1]?.type === 1 ? (
                <StyledTimeline.BlueImg
                  url={`${decryptPath(tmpData[1].url, { isMini: true })}`}
                  height={`${isMobile ? 171 : 319}`}
                  width={`${isMobile ? 185 : 319}`}>
                  <div className='bg-img'></div>
                  <ImgCustom
                    isMini={isMiniUrl()}
                    screen={'post_319_auto_pc'}
                    className='set-w-h-img'
                    src={tmpData[1]?.url}
                    alt=''
                  />
                </StyledTimeline.BlueImg>
              ) : (
                <div className='d-flex align-items-center h-100'>
                  <StyledTimeline.BlueImg
                    url={`${funcThumbnailIMG(
                      decryptPath(tmpData[1].url, {
                        isMini: true,
                      })
                    )}`}
                    height={`${isMobile ? 171 : 319}`}
                    width={`${isMobile ? 185 : 319}`}>
                    <div className='bg-img'></div>
                    <ImgCustom
                      isMini={isMiniUrl()}
                      screen={'post_319_auto_pc'}
                      className='set-w-h-img'
                      src={funcThumbnailIMG(decryptPath(tmpData[1]?.url))}
                      alt=''
                    />
                  </StyledTimeline.BlueImg>
                  <StyledTimeline.ButtonPlay>
                    <PlayIcon />
                  </StyledTimeline.ButtonPlay>
                </div>
              )}
            </div>
            <div
              className='position-relative w-100'
              onClick={() => {
                dispatch(getIDTimeline({ id: itemParent?.id, tab: tab, page: page }));
                navigate(`${newPathUser}post/${item?.id}`);
              }}>
              {tmpData[2]?.type === 1 ? (
                <StyledTimeline.BlueImg
                  url={`${decryptPath(tmpData[2].url, { isMini: true })}`}
                  height={`${isMobile ? 171 : 319}`}
                  width={`${isMobile ? 185 : 319}`}>
                  <div className='bg-img'></div>
                  <ImgCustom
                    isMini={isMiniUrl()}
                    screen={'post_319_auto_pc'}
                    className='set-w-h-img'
                    src={tmpData[2]?.url}
                    alt=''
                  />
                </StyledTimeline.BlueImg>
              ) : (
                <div className='d-flex align-items-center h-100'>
                  <StyledTimeline.BlueImg
                    url={`${funcThumbnailIMG(
                      decryptPath(tmpData[2].url, {
                        isMini: true,
                      })
                    )}`}
                    height={`${isMobile ? 171 : 319}`}
                    width={`${isMobile ? 185 : 319}`}>
                    <div className='bg-img'></div>
                    <ImgCustom
                      isMini={isMiniUrl()}
                      screen={'post_319_auto_pc'}
                      className='set-w-h-img'
                      src={funcThumbnailIMG(decryptPath(tmpData[2]?.url))}
                      alt=''
                    />
                  </StyledTimeline.BlueImg>
                  <StyledTimeline.ButtonPlay>
                    <PlayIcon />
                  </StyledTimeline.ButtonPlay>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {/* case >= 4 img or video */}
      {tmpData?.length >= 4 && (
        <div
          style={{
            display: 'flex',
            width: '100%',
            //padding: '0 16px',
            marginBottom: '5px',
            gap: '2px',
          }}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '2px', width: '100%' }}>
            <VideoInList
              isMini={isMiniUrl() && isFirefox}
              tmpData={tmpData}
              item={item}
              isMiniUrl={isMiniUrl}
              handleNavigate={() => {
                dispatch(getIDTimeline({ id: itemParent?.id, tab: tab, page: page }));
                navigate(`${newPathUser}post/${item?.id}`);
              }}
            />
            <div
              className='position-relative w-100'
              onClick={() => {
                dispatch(getIDTimeline({ id: itemParent?.id, tab: tab, page: page }));
                navigate(`${newPathUser}post/${item?.id}`);
              }}>
              {tmpData[2]?.type === 1 ? (
                <StyledTimeline.BlueImg
                  url={`${decryptPath(tmpData[2].url, { isMini: true })}`}
                  height={319}>
                  <div className='bg-img'></div>
                  <ImgCustom
                    isMini={isMiniUrl()}
                    screen={'post_319_auto_pc'}
                    className='set-w-h-img'
                    src={tmpData[2]?.url}
                    alt=''
                  />
                </StyledTimeline.BlueImg>
              ) : (
                <div style={{ height: `${isMobile ? '171px' : '319px'}` }}>
                  <StyledTimeline.BlueImg
                    url={`${funcThumbnailIMG(
                      decryptPath(tmpData[2].url, {
                        isMini: true,
                      })
                    )}`}
                    height={319}>
                    <div className='bg-img'></div>
                    <ImgCustom
                      isMini={isMiniUrl()}
                      screen={'post_319_auto_pc'}
                      className='set-w-h-img'
                      src={funcThumbnailIMG(decryptPath(tmpData[2]?.url))}
                      alt=''
                    />
                  </StyledTimeline.BlueImg>
                  <StyledTimeline.ButtonPlay>
                    <PlayIcon />
                  </StyledTimeline.ButtonPlay>
                </div>
              )}
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '2px', width: '100%' }}>
            <div
              className='position-relative w-100'
              onClick={() => {
                dispatch(getIDTimeline({ id: itemParent?.id, tab: tab, page: page }));
                navigate(`${newPathUser}post/${item?.id}`);
              }}>
              {tmpData[1]?.type === 1 ? (
                <StyledTimeline.BlueImg
                  url={`${decryptPath(tmpData[1].url, { isMini: true })}`}
                  height={319}>
                  <div className='bg-img'></div>
                  <ImgCustom
                    isMini={isMiniUrl()}
                    screen={'post_319_auto_pc'}
                    className='set-w-h-img'
                    src={tmpData[1]?.url}
                    alt=''
                  />
                </StyledTimeline.BlueImg>
              ) : (
                <div style={{ height: '319px', cursor: 'pointer' }}>
                  <StyledTimeline.BlueImg
                    url={`${funcThumbnailIMG(
                      decryptPath(tmpData[1].url, {
                        isMini: true,
                      })
                    )}`}
                    height={319}>
                    <div className='bg-img'></div>
                    <ImgCustom
                      isMini={isMiniUrl()}
                      screen={'post_319_auto_pc'}
                      className='set-w-h-img'
                      src={funcThumbnailIMG(decryptPath(tmpData[1]?.url))}
                      alt=''
                    />
                  </StyledTimeline.BlueImg>
                  <StyledTimeline.ButtonPlay>
                    <PlayIcon />
                  </StyledTimeline.ButtonPlay>
                </div>
              )}
            </div>
            <div
              className='position-relative w-100 h-100'
              onClick={() => {
                dispatch(getIDTimeline({ id: itemParent?.id, tab: tab, page: page }));
                navigate(`${newPathUser}post/${item?.id}`);
              }}>
              {tmpData[3]?.type === 1 ? (
                <>
                  <StyledTimeline.BlueImg
                    url={`${decryptPath(tmpData[3].url, { isMini: true })}`}
                    height={319}>
                    <div className='bg-img'></div>
                    <ImgCustom
                      isMini={isMiniUrl()}
                      screen={'post_319_auto_pc'}
                      className='set-w-h-img'
                      src={tmpData[3]?.url}
                      alt=''
                    />
                    {tmpData?.length > 4 && (
                      <>
                        <div className='div-blue-img w-100'></div>
                        <div className='number-img'>+{Number(tmpData?.length) - 3}</div>
                      </>
                    )}
                  </StyledTimeline.BlueImg>
                </>
              ) : (
                <div style={{ height: '319px ', position: 'relative', width: '100%' }}>
                  <StyledTimeline.BlueImg
                    url={`${funcThumbnailIMG(
                      decryptPath(tmpData[3].url, {
                        isMini: true,
                      })
                    )}`}
                    height={319}>
                    <div className='bg-img'></div>
                    <ImgCustom
                      isMini={isMiniUrl()}
                      screen={'post_319_auto_pc'}
                      className='set-w-h-img'
                      src={funcThumbnailIMG(decryptPath(tmpData[3]?.url))}
                      alt=''
                    />
                  </StyledTimeline.BlueImg>
                  {tmpData?.length === 4 && (
                    <StyledTimeline.ButtonPlay>
                      <PlayIcon />
                    </StyledTimeline.ButtonPlay>
                  )}
                  {tmpData?.length > 4 && (
                    <>
                      <div className='div-blue-img' style={{ top: '0' }}></div>
                      <div className='number-img' style={{ top: '0' }}>
                        +{Number(tmpData?.length) - 3}
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
