/* eslint-disable no-unused-vars */

import {
  AvatarInfor,
  ImgCustom,
  LayoutCreatorRight,
  ListImages,
  PopupLockPostNormal,
  PopupSharePost,
  ReadMoreLess,
  Textarea,
  ToggleButton,
} from 'components';
import InfiniteLoad from 'components/Paging/InfiniteLoad';
import { getEnv } from 'configs/env.js';
import { newPathUser } from 'constants/layout/constant';
import useLoading from 'hooks/useLoading.js';
import {
  AvatarDefault,
  BookmarkIcon,
  ChatIcon,
  FlowUnFlowIcon,
  HeartActiveIcon,
  HeartIcon,
  IconBookmark,
  NoBookmarkList,
  NoSearchList,
  RePostIcon,
  ReportIcon,
  SendChatIcon,
  ShareIcon,
  ThreePointIcon,
  UnFlowIcon,
  ThreePointIconV3,
} from 'images';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { postBookMark, postFollowCommentDetail, postLike } from 'store/actions/myPage';
import { postCommentDetail } from 'store/actions/usersNew';
import {
  fanGetListBookmark,
  followOrUnFollowUser,
  searchHashtagPostsAction,
} from 'store/actions/users';
import { convertToCurrentTime } from 'utils/utils';
import '../index.scss';
import { TimelinePost } from './elements';
import { InputWrapper } from 'components/Input/InputWrapper';
import ListTopFirstComment from './elements/comments/listTopFirstComment';
import { deleteCommentDetail, likeCommentDetail } from 'store/actions/usersNew';
import { ShimmerPostDetails } from 'react-shimmer-effects';
import { StyledTimeline } from './elements/styled';

const HashtagPosts = ({ isSearchPage = true, keyword, loadingClickSearch }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();

  const [dataGetListBookmark, setDataGetListBookmark] = useState([]);

  const [keywordSearch, setKeywordSearch] = useState('');
  const [idHashtag, setIdHashtag] = useState('');
  const [hashtagTitle, setHashtagTitle] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const { keyword, id, hashtagName } = location?.state;
    if (keyword) {
      setKeywordSearch(keyword);
    }
    if (hashtagName) {
      setHashtagTitle(hashtagName);
    }
    if (id) {
      setIdHashtag(id);
      dispatch(
        searchHashtagPostsAction(id, keywordSearch, page, itemsPerPage, ({ data }) =>
          setDataGetListBookmark([...data])
        )
      );
    }
    setIsLoading(false);
  }, [location.state]);

  useEffect(() => {
    return () => {
      setDataGetListBookmark([]);
    };
  }, []);

  const [page, setPage] = useState(1);

  const itemsPerPage = 30;
  const { loading, searchHashtagPostsPagination, dataUser, searchHashtagPosts } = useSelector(
    (state) => state.users
  );
  const { loadingAction } = useSelector((state) => state.myPage);
  const [isVisiblePopup, setIsVisiblePopup] = useState(-1);

  const [popupCopyLink, setPopupCopyLink] = useState(null);
  const initLoading = useLoading();
  const { t } = useTranslation();
  // api list page
  const fetchNext = () => {
    const pages = searchHashtagPostsPagination?.total / searchHashtagPostsPagination?.per_page;
    pages > page && setPage(page + 1);
  };

  useEffect(() => {
    if (page > 1 && idHashtag) {
      dispatch(
        searchHashtagPostsAction(idHashtag, keywordSearch, page, itemsPerPage, ({ data }) =>
          setDataGetListBookmark([...dataGetListBookmark, ...data])
        )
      );
    }
  }, [page]);

  useEffect(() => {
    return () => {
      setIdHashtag();
      setDataGetListBookmark([]);
      setHashtagTitle();
    };
  }, []);

  const handleNavigate = (type, post_id) => {
    if (type === 2) {
      navigate(`${newPathUser}post/${post_id}`);
    } else {
      navigate(`${newPathUser}post/${post_id}`);
    }
  };

  //api like post

  const handleLikePostSuccess = ({ data }) => {
    setDataGetListBookmark(
      dataGetListBookmark.map((item) => {
        if (+item.id === +data?.post_id) {
          const newItem = { ...item };
          if (newItem.is_liked === 1) {
            newItem.is_liked = 0;
            --newItem.number_like;
          } else {
            newItem.is_liked = 1;
            ++newItem.number_like;
          }
          return newItem;
        } else return item;
      })
    );
  };

  const handleFollowSuccess = (id) => {
    const tempDataList = [...dataGetListBookmark];

    tempDataList.forEach((item) => {
      if (+item.user_id === +id) {
        if (item.is_followed === 1) {
          item.is_followed = 0;
        } else {
          item.is_followed = 1;
        }
      }
      if (+item?.latest_comment?.user_id === +id) {
        if (item.latest_comment?.is_followed === 1) {
          item.latest_comment.is_followed = 0;
        } else {
          item.latest_comment.is_followed = 1;
        }
      }
    });

    setDataGetListBookmark(tempDataList);
  };

  const handleFollowCommentUser = (id) => {
    dispatch(followOrUnFollowUser(id));
    handleFollowSuccess(id);
  };

  const handleLikeComment = (id) => {
    dispatch(likeCommentDetail(id));
    setDataGetListBookmark(
      dataGetListBookmark.map((item) => {
        if (+item?.latest_comment?.id === +id) {
          const newItem = { ...item };
          if (newItem.latest_comment.is_liked === 1) {
            newItem.latest_comment.is_liked = 0;
            --newItem.latest_comment.number_like;
          } else {
            newItem.latest_comment.is_liked = 1;
            ++newItem.latest_comment.number_like;
          }
          return newItem;
        } else return item;
      })
    );
  };

  const handleLikePost = (id) => {
    dispatch(postLike(id, handleLikePostSuccess));
  };

  const handleDeleteComment = (id) => {
    dispatch(
      deleteCommentDetail(id, null, (data) => {
        setDataGetListBookmark(
          dataGetListBookmark.map((item) => {
            if (+item?.latest_comment?.id === +id) {
              const newItem = { ...item };
              newItem.latest_comment = null;
              newItem.number_comment = newItem.number_comment - data?.data?.count;

              return newItem;
            } else return item;
          })
        );
      })
    );
  };

  const checkDisabledAction = (item) => {
    if (
      (item?.is_followed === 0 && item?.follow_flag === 1 && item?.user_id !== dataUser?.id) ||
      (!item?.is_media_display && item?.type === 1 && item?.user_id !== dataUser?.id)
    ) {
      return true;
    }
    return false;
  };

  const handleSharePost = (data) => {
    setPopupCopyLink(data);
  };

  const [valueComments, setValueComments] = useState(() =>
    Array.from({ length: 10000 }, () => '')
  );

  const [checkSendChat, setCheckSendChat] = useState(null);

  const handleBookmarkPost = (id) => {
    dispatch(postBookMark(id));
    setDataGetListBookmark(
      dataGetListBookmark.map((item) => {
        if (+item?.id === +id) {
          const newItem = { ...item };
          if (newItem.is_bookmarked === 1) {
            newItem.is_bookmarked = 0;
          } else {
            newItem.is_bookmarked = 1;
          }

          return newItem;
        } else return item;
      })
    );
  };

  const handlePostComment = (id, parent_id, index) => {
    const data = {
      parent_id: parent_id,
      content: valueComments[index],
    };
    checkSendChat &&
      dispatch(
        postCommentDetail(id, data, (data) => {
          setDataGetListBookmark(
            dataGetListBookmark.map((item) => {
              if (+item?.id === +data?.data?.post_id) {
                const newItem = { ...item };
                ++newItem.number_comment;
                if (newItem?.latest_comment) {
                  newItem.latest_comment.id = data?.data?.id;
                  newItem.latest_comment.content = data?.data?.content;
                  newItem.latest_comment.created_at = data?.data?.created_at;
                  newItem.latest_comment.avatar = data?.data?.avatar;
                  newItem.latest_comment.account_name = data?.data?.account_name;
                  newItem.latest_comment.user_id = data?.data?.user_id;
                  newItem.latest_comment.number_like = data?.data?.number_like;
                  newItem.latest_comment.is_liked = data?.data?.is_liked;
                } else {
                  newItem.latest_comment = {
                    id: data?.data?.id,
                    avatar: data?.data?.avatar,
                    user_id: data?.data?.user_id,
                    account_name: data?.data?.account_name,
                    content: data?.data?.content,
                    created_at: data?.data?.created_at,
                    number_like: data?.data?.number_like,
                    is_liked: data?.data?.is_liked,
                  };
                }
                return newItem;
              } else return item;
            })
          );
        })
      );

    setCheckSendChat(null);
    setValueComments(() => Array.from({ length: dataGetListBookmark?.length }, () => ''));
    const textareaId = document.getElementById(`comment-item-${id}`);
    if (textareaId) textareaId.style.height = '44px';
  };

  const handleTextarea = (index, e, id, height) => {
    if (e.target.scrollHeight > e.target.clientHeight) {
      e.target.style.height = e.target.scrollHeight + 'px';
    }
    var code = e.keyCode || e.charCode;
    if (code === 13) {
      e.target.style.height = 'inherit';
      e.target.style.height = `${e.target.scrollHeight}px`;
    }
    const { value } = e.target;
    const newCommentValues = [...valueComments].map((comment, idx) => {
      if (idx !== index) return comment;
      return value;
    });
    setValueComments(newCommentValues);
    //setValueComment(e.target.value);
    setCheckSendChat(id);
    if (e.target.value?.toString().trim() === '') {
      e.target.style.height = height;
      e.target.style.padding = '9px  30px  9px 12px';
      setCheckSendChat(null);
    }
  };

  const listImgFunc = (data, item) => <TimelinePost data={data} item={item} />;

  const renderPostItem = (item, index) => (
    <>
      <div style={{ position: 'relative' }}>
        <div className='d-flex justify-content-between user-infor'>
          <AvatarInfor
            images={item?.avatar}
            name={item?.account_name}
            content1={item}
            onClick={() => navigate(`${newPathUser}${item?.account_id}`)}
          />
          <div className='box-popup'>
            {checkDisabledAction(item) ? (
              <ThreePointIconV3 />
            ) : (
              <ThreePointIcon
                style={{ color: '#666565' }}
                className='three-point-icon'
                onClick={() => {
                  setIsVisiblePopup(isVisiblePopup === item?.id ? -1 : item?.id);
                }}
                onBlur={() => setIsVisiblePopup(-1)}
              />
            )}

            {isVisiblePopup === item?.id && (
              <div className='popup-edit-comment'>
                <div
                  className='item'
                  onMouseDown={() => {
                    dispatch(followOrUnFollowUser(item?.user_id));
                    handleFollowSuccess(item?.user_id);
                    setIsVisiblePopup(-1);
                  }}>
                  {item?.is_followed === 0 ? <FlowUnFlowIcon /> : <UnFlowIcon />}

                  <span className='text'>
                    {item?.is_followed === 0
                      ? t('comment.listComment.flow')
                      : t('comment.listComment.unflow')}
                  </span>
                </div>
                <div
                  className='item'
                  onMouseDown={() => {
                    setIsVisiblePopup(-1);

                    navigate(`${newPathUser}post-report`, {
                      state: {
                        fan_id: item?.user_id,
                        fan_name: item?.account_name,
                        post_id: item?.id,
                      },
                    });
                  }}>
                  <ReportIcon className='share-icon' />
                  <span className='text'>{t('comment.listComment.iconFlag')}</span>
                </div>
              </div>
            )}
          </div>
        </div>
        {
          item?.medias?.length > 0 && (
            <>
              {item?.type === 1 && (
                <>
                  {item?.medias?.[0].type === 1 && (
                    <>
                      {(() => {
                        const data = item?.medias
                          ?.filter((item) => item?.type === 1)
                          .filter((item) => item.public_flag === 1);
                        return data.length === 0;
                      })()}
                    </>
                  )}
                </>
              )}
            </>
          )
          // bookmarkFunc(item)
        }
      </div>
      <div
        style={{
          position: 'relative',
          minHeight: !item?.is_media_display && item?.type === 1 ? 400 : 'unset',
        }}
        className='mobile-timeline'>
        {item?.is_followed === 0 &&
        item?.follow_flag === 1 &&
        item?.user_id !== dataUser?.id ? (
          <div style={item?.medias?.length === 0 ? { paddingBottom: '35px' } : {}}>
            <StyledTimeline.LockScreen>
              <div className='text-follow'>{t('DetailCard.notYetFollow')}</div>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(followOrUnFollowUser(item?.user_id));
                  handleFollowSuccess(item?.user_id);
                }}>
                {t('Common.follow')}
              </button>
            </StyledTimeline.LockScreen>
          </div>
        ) : (
          ''
        )}
        {item?.is_media_display === 0 && (
          <div>
            <PopupLockPostNormal
              post_id={item.id}
              detailPost={item}
              in_my_page
              point={dataUser?.points}
            />
          </div>
        )}
        <>
          <div
            className={`content ${
              item?.is_media_display === 0 && item?.type === 1 && 'bg-blur'
            }`}>
            <div className='title'>
              <ReadMoreLess content={item?.title?.toString()} charLimit={50} />
            </div>
            <div className='text'>
              <ReadMoreLess content={item?.content?.toString()} />
            </div>
          </div>

          {item?.medias?.length > 0 && (
            <>
              {item?.type === 1 ? (
                <>
                  {item?.medias?.[0].type === 1 ? (
                    <>
                      {(() => {
                        const data = item?.medias
                          ?.filter((item) => item?.type === 1)
                          .filter((item) => item.public_flag === 1);
                        return data.length > 0 && listImgFunc(data, item);
                      })()}
                    </>
                  ) : (
                    listImgFunc(item?.medias, item)
                  )}
                </>
              ) : (
                listImgFunc(item?.medias, item)
              )}
            </>
          )}
        </>
      </div>
      <div className={`interactive ${checkDisabledAction(item) && 'disabled'}`}>
        <div className='left'>
          <div className={`item ${checkDisabledAction(item) && 'disabled'}`}>
            {item?.is_liked === 1 ? (
              <>
                {/* đã like */}
                <HeartActiveIcon
                  className={`like-icon active`}
                  onClick={() => !checkDisabledAction(item) && handleLikePost(item?.id)}
                  disabled={checkDisabledAction(item)}
                />
              </>
            ) : (
              <>
                {/* chưa like */}
                <HeartIcon
                  className={`like-icon`}
                  onClick={() => !checkDisabledAction(item) && handleLikePost(item?.id)}
                  disabled={checkDisabledAction(item)}
                />
              </>
            )}
            <span className='number'>{item?.number_like}</span>
          </div>
          <div
            className={`item ${
              (checkDisabledAction(item) || item?.enable_comment === 0) && 'disabled'
            }`}>
            <ChatIcon
              className='chat-icon'
              onClick={() => handleNavigate(item?.type, item?.id)}
            />
            <span className='number'>{item?.number_comment}</span>
          </div>

          {/* <div className={`item ${checkDisabledAction(item) && 'disabled'}`}>
            <RePostIcon />
            <span className='number'>{item?.number_repost ?? 0}</span>
          </div> */}

          <div className={`item ${checkDisabledAction(item) && 'disabled'}`}>
            <ShareIcon
              className={`share-icon ${checkDisabledAction(item) && 'disabled'}`}
              onClick={() => {
                if (item?.type === 2) {
                  !checkDisabledAction(item) &&
                    handleSharePost({
                      link: `${getEnv('REACT_APP_FAN_URL')}${newPathUser}post/${
                        item?.id
                      }`,
                      title: item.title,
                    });
                } else {
                  !checkDisabledAction(item) &&
                    handleSharePost({
                      link: `${getEnv('REACT_APP_FAN_URL')}${newPathUser}post/${item?.id}`,
                      title: item.title,
                    });
                }
              }}
              disabled={checkDisabledAction(item)}
            />
          </div>
        </div>

        <div className={`item ${checkDisabledAction(item) && 'disabled'}`}>
          <IconBookmark
            style={{
              color: item?.is_bookmarked === 1 ? '#dc143c' : 'inherit',
            }}
            fill={item?.is_bookmarked === 1 ? '#dc143c' : 'none'}
            onClick={() => {
              if (!checkDisabledAction(item)) {
                handleBookmarkPost(item?.id);
              }
              // !checkDisabledAction(item) && dispatch(postBookMark(item?.id));
            }}
            className='cls-icon'
          />
        </div>
      </div>
      <div className='comment-wrap'>
        {item?.latest_comment && (
          <ListTopFirstComment
            dataPost={item}
            dataProfile={dataUser}
            handleFollowUserComment={handleFollowCommentUser}
            handleLikeComment={handleLikeComment}
            handleDeleteComment={handleDeleteComment}
            handleNavigate={handleNavigate}
          />
        )}
        {item?.latest_comment && (
          <button
            style={{ marginTop: `${item?.latest_comment && '16px'}` }}
            className='btn-view-all'
            onClick={() => {
              handleNavigate(item?.type, item?.id);
            }}>
            {t('DetailCard.viewAllComment')}
          </button>
        )}
      </div>
      <div className={`comment block`} style={{ marginBottom: '16px' }}>
        <ImgCustom
          screen='avatar_44_44'
          src={dataUser?.avatar ? `${dataUser?.avatar}` : AvatarDefault}
        />
        <form onSubmit={(e) => e.preventDefault()}>
          <InputWrapper id='fsite-comment' className='form-item'>
            <Textarea
              disabled={
                (item?.is_followed === 0 && item?.follow_flag === 1) ||
                (!item?.is_media_display && item?.type === 1) ||
                item?.enable_comment === 0
              }
              onChange={(e) => handleTextarea(index, e, item?.id, '44px')}
              placeholder={t('comment.formAddRepComment.placeholder')}
              onKeyDown={(e) => handleTextarea(index, e, item?.id, '44px')}
              value={valueComments[index]}
              maxLength='500'
              id={`comment-item-${item?.id}`}
              overflowHidden
              style={{
                border: '1px solid #E5E6EB',
                background: `${
                  (item?.is_followed === 0 && item?.follow_flag === 1) ||
                  (!item?.is_media_display && item?.type === 1) ||
                  item?.enable_comment === 0
                    ? '#efefef'
                    : '#fff'
                }`,
              }}
            />
            <SendChatIcon
              style={{
                cursor: `${item?.is_followed === 0 && item?.follow_flag === 1 && 'default'}`,
              }}
              className={`${valueComments[index] && 'active'}`}
              onClick={() => valueComments[index] && handlePostComment(item?.id, 0, index)}
            />
          </InputWrapper>
        </form>
      </div>
    </>
  );

  return (
    <>
      {popupCopyLink && (
        <PopupSharePost
          isVisiblePopup={() => setPopupCopyLink(false)}
          data={popupCopyLink || {}}
        />
      )}
      <LayoutCreatorRight
        titlePage={hashtagTitle}
        linkBack={`${newPathUser}search`}
        className={`${isSearchPage ? 'search-list-post' : ''} hashtag-post-header`}>
        <div className={`fan-favorite-wrapper`}>
          <ul className='header header-bookmarks'>
            <li className='active'>{t('Favorite.bookmarkList')}</li>
            <li onClick={() => navigate(`${newPathUser}like-list`)}>
              {t('Favorite.likeList')}
            </li>
          </ul>

          <div className='bookmark-navigation-checkbox'>
            <div className='navigate-label'>
              <span className='first-label'>{t('Favorite.tabLeft')}</span>
              <span className='active-label'>{t('Favorite.tabRight')}</span>
            </div>
            <div className='checkbox-container'>
              <span className='checkbox-label'>{t('Common.changeDisplay')}</span>

              <ToggleButton
                id='navigation-checkbox'
                defaultChecked={true}
                onChange={() => navigate(`${newPathUser}favorite/view-on-timeline`)}
              />
            </div>
          </div>
          {((loading && !dataGetListBookmark?.length) || loadingClickSearch || isLoading) && (
            <div className='shimmer-loading'>
              <ShimmerPostDetails hasImage imageType='circular' title />
            </div>
          )}
          <div className={`fan-list-post my-page scroll-bar-custom hashtag-posts `}>
            {dataGetListBookmark?.length > 0 && !isLoading ? (
              <InfiniteLoad loading={loading} data={dataGetListBookmark} fetchNext={fetchNext}>
                {dataGetListBookmark?.length > 0 &&
                  dataGetListBookmark.map((item, index) => (
                    <div
                      key={item?.id}
                      className={`my-page-item ${
                        !item?.unlock_package && item?.type === 1 && 'popup_lock_post'
                      }`}>
                      {renderPostItem(item, index)}
                    </div>
                  ))}
              </InfiniteLoad>
            ) : (
              !loading && (
                <span className='no-data'>
                  <NoSearchList />
                  {t('Search.listNull')}
                </span>
              )
            )}
          </div>
        </div>
      </LayoutCreatorRight>
    </>
  );
};

export default HashtagPosts;
