import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ShimmerPostDetails } from 'react-shimmer-effects';

import { ImgCustom, Tooltip2 } from 'components';
import InfiniteLoad from 'components/Paging/InfiniteLoad';
import { newPathUser } from 'constants/layout/constant';
import DefaultAvatar from 'images/DefaultAvatar.svg';
import NoNotification from 'images/NoNotification.svg';

import { getIDNoti, userGetListNotification } from 'store/actions/notification';
import { AVATAR_BLOCK_USER, AVATAR_DEFAULT, AVATAR_DEL_USER, getTitleByType, isMappable } from 'utils';
import { useCallback } from 'react';
import { resetDataInfoOther, resetIDHomePage } from 'store/actions/usersNew';

const TYPE_USE_NOTICE = 2;
const UserNotices = ({ itemsPerPage, notifiRead, handleClickNotify, setFirstLoading }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { listNotifyFan, idNoti1, paginationNoti, initPageNoti } = useSelector((state) => state.notification);
  const [page, setPage] = useState(idNoti1 && initPageNoti ? +initPageNoti : 1);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const fetchNext = () => {
    setPage(page + 1);
    funCallApi(page + 1);
  };

  const funCallApi = useCallback(
    (page) => {
      setIsLoadingMore(true);
      dispatch(
        userGetListNotification(
          { type: TYPE_USE_NOTICE, page: page, limit: itemsPerPage },
          (_response) => {
            setIsLoadingMore(false);
          }
        )
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page]
  );

  useEffect(() => {
    if (idNoti1) {
      const idPost = document.getElementById(idNoti1.id);
      if (idPost) {
        idPost.scrollIntoView({
          block: 'center',
        });
      }
    }
  }, [idNoti1]);

  useEffect(() => {
    if (initPageNoti < 1) {
      const top = document.getElementById('app_cocofans_0');
      top && top.scrollIntoView({ top: 0, left: 0, block: 'start' });
      setFirstLoading(true);
      setIsLoading(true);
      dispatch(
        userGetListNotification(
          { type: TYPE_USE_NOTICE, page: page, limit: itemsPerPage },
          (_response) => {
            setFirstLoading(false);
            setIsLoading(false);
          }
        )
      );
    }
  }, []);

  // useEffect(() => {
  //   if (page === 1) return;

  //   setIsLoadingMore(true);
  //   dispatch(
  //     userGetListNotification(
  //       { type: TYPE_USE_NOTICE, page: page, limit: itemsPerPage },
  //       (_response) => {
  //         setIsLoadingMore(false);
  //       }
  //     )
  //   );
  // }, [page]);

  const checkStatus = (status) => {
    let tmp = '';
    switch (status) {
      case null: // del
        tmp = AVATAR_DEL_USER
        break;
      case 2: // stop
        tmp = AVATAR_BLOCK_USER
        break;
      default:
        tmp = AVATAR_DEFAULT;
    }
    return tmp;
  };

  return (
    <>
      <div className='list'>
        <InfiniteLoad
          key={TYPE_USE_NOTICE}
          loading={isLoadingMore}
          data={listNotifyFan}
          fetchNext={fetchNext}>
          {isLoading && !isLoadingMore ? (
            <div>
              <ShimmerPostDetails />
            </div>
          ) : (
            <>
              {isMappable(listNotifyFan)
                ? listNotifyFan.map((item, index) => (
                    <div key={index} id={item?.id} className='item'>
                      <ImgCustom
                        src={item?.user?.avatar || checkStatus(item?.user?.status)}
                        alt='avatar image'
                        screen={item?.user?.avatar && 'avartar_navbar_60_60'}
                        className={'logo-fan'}
                        onClick={() => {
                          dispatch(resetIDHomePage());
                          dispatch(resetDataInfoOther());
                          dispatch(getIDNoti({ id: item?.id, tab: 1, page: page }));
                          navigate(`${newPathUser}${item?.user?.account_id}`, {
                            state: { name: 'homepage' },
                          });
                        }}
                      />
                      <div
                        className={`${
                          item?.is_read !== 0 || notifiRead.includes(item?.id)
                            ? '--isRead'
                            : ''
                        } `}>
                        <div className={`d-flex justify-content-between w-100`}>
                          <div
                            className='name fw-bold'
                            onClick={() =>  { 
                              console.log('first',!notifiRead.includes(item?.id))
                              if (!notifiRead.includes(item?.id)) {
                                handleClickNotify(item)
                              }
                            }}>
                            {item?.user?.account_name || item?.user?.full_name}
                          </div>
                          <div className='date'>
                            {item?.start_time &&
                              moment.utc(item?.start_time).local().format('YYYY/MM/DD HH:mm')}
                          </div>
                        </div>
                        <div className={`content mt-2 fw-normal`}>
                          <Tooltip2
                            className={'custom-text creator'}
                            onClick={() => {
                              if (!notifiRead.includes(item?.id)) {
                                handleClickNotify(item)
                              }
                            }}
                            text={getTitleByType(
                              item?.content_type,
                              item?.user?.account_name || item?.user?.full_name,
                              true,
                              item?.package_title,
                              item?.post_type
                            )}>
                            {getTitleByType(
                              item?.content_type,
                              item?.user?.account_name || item?.user?.full_name,
                              true,
                              item?.package_title,
                              item?.post_type
                            )}
                          </Tooltip2>
                        </div>
                      </div>
                    </div>
                  ))
                : !isLoading && (
                    <div className='no-result'>
                      <img
                        src={NoNotification}
                        alt='No Notification'
                        width={100}
                        height={100}
                      />
                      {t('Creator.NotificationList.noNotifications')}
                    </div>
                  )}
            </>
          )}
        </InfiniteLoad>
      </div>
    </>
  );
};
export default UserNotices;
