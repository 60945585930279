import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { creatorGetPostDetail } from 'store/actions/usersNew';
import PostContentDetail from 'pages/creator/layout/post-article/post-content-detail';
import PostContentSaleDetail from 'pages/creator/layout/post-article/post-content-sale';
import { isAgency, STORAGE } from 'utils';
import Storage from 'utils/storage';
import { LayoutCreatorRight, PopupLoading } from 'components';
import { ProviderTimeline } from 'pages/timeline/contextTimeline';
import '../post-content-detail/index.scss';
import '../post-content-sale/index.scss';
import { newPathUser } from 'constants/layout/constant';
import { ShimmerPostDetails } from 'react-shimmer-effects';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

const PostDetail = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { search } = useLocation();
  const { t } = useTranslation();

  const { isCheckResetDetailPost, isLoadingFollowCreate, loading, detailPost } = useSelector(
    (state) => state.usersNew
  );
  const { dataUser } = useSelector((state) => state.users);
  const [type, setType] = useState(1);
  const [listTimeLine, setListTimeLine] = useState([]);
  const [loaddingDown, setLoaddingDown] = useState(false);
  const [loadingDetail, setLoadingDetail] = useState(false);

  useEffect(() => {
    setLoadingDetail(true);
    setListTimeLine([]);
    const top = document.getElementById('app_cocofans_0');
    top && top.scrollIntoView({ top: 0, left: 0, block: 'start' });
    const agencyToken = Storage.get(STORAGE.AGENCY_TOKEN);
    const adminTokenAccess = Storage.get(STORAGE.USER_ACCESS_TOKEN);
    if (!search?.includes('adminToken') && !search?.includes('agencyToken')) {
      dispatch(
        creatorGetPostDetail(id, 1, false, isAgency ? agencyToken : adminTokenAccess, () => {
          const top = document.getElementById('app_cocofans_0');
          top && top.scrollIntoView({ top: 0, left: 0, block: 'start' });
          setLoadingDetail(false);
        })
      );
    }
  }, [id]);

  useEffect(() => {
    setType(detailPost?.type);
  }, [detailPost]);

  const canGoBack = useMemo(() => {
    return window.history.state && window.history.state.idx > 0;
  }, []);

  const cbTypeOne = () => {
    if (canGoBack) {
      if (location.state?.isPostShortMyPage) {
        return navigate(`${newPathUser}${dataUser?.account_id}?post=post-shorts`);
      }
      if (location.state?.isEditHomePage) {
        if (location.state?.account_id) {
          return navigate(-1);
        }
        return navigate(`${newPathUser}`, { state: {} });
      }
      if (location?.state?.name === 'notification') {
        if (location?.state?.tab === 1) {
          return navigate(`${newPathUser}profile/notification`, {
            state: { tab: 1 },
          });
        } else {
          return navigate(`${newPathUser}profile/notification`, {
            state: { tab: 2 },
          });
        }
      }
      if (location?.state?.tab === 2) {
        return navigate(`${newPathUser}timeline`, { state: { tab: 2 } });
      } else {
        if (location?.state?.tab === 1) {
          return navigate(`${newPathUser}timeline`, { state: { tab: 1 } });
        } else {
          return navigate(-1);
        }
      }
    } else {
      navigate(`${newPathUser}timeline`);
    }
  };

  const cbTypeTwo = () => {
    if (canGoBack) {
      if (location.state?.isEditHomePage) {
        if (location?.state?.account_id) {
          return navigate(-1);
        } else if (dataUser?.account_id) {
          return navigate(`${newPathUser}${dataUser?.account_id}?post=post-sales`, {
            state: {},
          });
        } else {
          return navigate(`${newPathUser}`, {
            state: {},
          });
        }
      } else {
        if (location.state?.redirectBack) {
          return navigate(-1);
        }
        return navigate(location.state?.redirectBack || -1);
      }
    } else {
      navigate(`${newPathUser}timeline`);
    }
  };

  return (
    <>
      <ProviderTimeline>
        <LayoutCreatorRight
          cb={() => {
            type === 1 ? cbTypeOne() : cbTypeTwo();
          }}
          className={`${
            type === 1
              ? 'detail-post-normal-header detail-post-custom'
              : 'detail-post-sale-header'
          }`}
          titlePage={t('PostDetail.creator.title.type1')}>
          {
            <div style={{ padding: `${isMobile ? '0px' : '24px'}`, borderRadius: '20px' }}>
              {(isLoadingFollowCreate || loaddingDown) && (
                <PopupLoading className={'popup-loading'} />
              )}
              {loadingDetail && loading && isCheckResetDetailPost && !listTimeLine.length ? (
                <div style={{ padding: `${isMobile ? '16px' : '0px'}` }}>
                  <ShimmerPostDetails hasImage imageType='circular' title />
                </div>
              ) : (
                <>
                  {(type === 1 || type === 4) && (
                    <PostContentDetail
                      setLoaddingDown={setLoaddingDown}
                      setListTimeLine={setListTimeLine}
                      listTimeLine={listTimeLine}
                    />
                  )}
                  {type === 2 && (
                    <PostContentSaleDetail
                      listTimeLine={listTimeLine}
                      setListTimeLine={setListTimeLine}
                    />
                  )}
                </>
              )}
            </div>
          }
        </LayoutCreatorRight>
      </ProviderTimeline>
    </>
  );
};

export default PostDetail;
