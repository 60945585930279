import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';

import './index.scss';

import { newPathUser } from 'constants/layout/constant';
import IconAccount from 'images/footer-mobile/IconAccount';
import IconHome from 'images/footer-mobile/IconHome';
import IconRanking from 'images/footer-mobile/IconRanking';
import IconSearch from 'images/footer-mobile/IconSearch';
import IconCreatePost from 'images/footer-mobile/create-post.png';
import { STATUS_ACCEPT, STORAGE } from 'utils';
import Storage from 'utils/storage';
import { logUserProfile } from 'store/actions/myPage';
import {
  resetDataInfoOther,
  resetIDHomePage,
  resetIDSearchPost,
  resetIDSearchSale,
  resetIDSearchVideo,
  resetIDTimeline,
} from 'store/actions/usersNew';
import { resetIDSearchUser } from 'store/actions/users';
import { clearListRanking } from 'store/actions/ranking';

const Footer = () => {
  const { t } = useTranslation();
  const { dataUser } = useSelector((state) => state.users);
  const { pathname } = useLocation();
  const isAuth = Storage.has(STORAGE.USER_ACCESS_TOKEN);
  const dispatch = useDispatch();

  return (
    <div className='hide-greater-than-1024 footer-mobile'>
      <ul className='list-menu'>
        <li
          className={`menu-item ${
            pathname === `${newPathUser}timeline` ? 'default-click' : ''
          }`}
          key={'HomePage'}
          onClick={() => {
            if (isAuth) {
              dispatch(resetIDTimeline());
            }
            !isAuth && dispatch(logUserProfile());
          }}>
          <NavLink
            to={isAuth ? `${newPathUser}timeline` : 'javascript:void(0)'}
            children={({ isActive }) => (
              <>
                <IconHome isActive={isActive} />
                <span className={isActive ? 'link-active' : 'link-not-active'}>
                  {t('MenuSiderbar.homeSidebar')}
                </span>
              </>
            )}
          />
        </li>
        <li
          className={`menu-item ${pathname === `${newPathUser}search` ? 'default-click' : ''}`}
          key={'SearchPage'}
          onClick={() => {
            if (isAuth) {
              dispatch(resetIDSearchVideo());
              dispatch(resetIDSearchUser());
              dispatch(resetIDSearchPost());
              dispatch(resetIDSearchSale());
            }
          }}>
          <NavLink
            to={`${newPathUser}search`}
            children={({ isActive }) => (
              <>
                <IconSearch isActive={isActive} />
                {isAuth && (
                  <span className={isActive ? 'link-active' : 'link-not-active'}>{t('Common.search')} </span>
                )}
                {!isAuth && (
                  <span className={!isAuth && pathname === `/search` ? 'link-active' : 'link-not-active'}>
                    {t('Common.search')}
                  </span>
                )}
              </>
            )}
          />
        </li>
        <li
          className={`menu-item ${
            pathname === `${newPathUser}post/create` ? 'default-click' : ''
          }`}
          key={'CreatePostPage'}
          onClick={() => {
            !isAuth && dispatch(logUserProfile());
          }}>
          <NavLink to={isAuth ? `${newPathUser}post/create` : 'javascript:void(0)'}>
            <img alt='Icon create post' src={IconCreatePost} height={48} width={48} />
          </NavLink>
        </li>
        {(!isAuth || (isAuth && STATUS_ACCEPT.includes(+dataUser?.status))) && (
          <li
            className={`menu-item ${
              pathname === `${newPathUser}ranking` ? 'default-click' : ''
            }`}
            key={'RankingPage'}
            onClick={() => {
              if (isAuth) {
                dispatch(clearListRanking());
              }
            }}>
            <NavLink
              to={`${newPathUser}ranking`}
              children={({ isActive }) => (
                <>
                  <IconRanking isActive={isActive} />
                  <span className={isActive ? 'link-active' : 'link-not-active'}>
                    {t('homePageHeader.ranking')}{' '}
                  </span>
                </>
              )}
            />
          </li>
        )}
        <li
          className={`menu-item ${
            pathname === `${newPathUser}${dataUser?.account_id}` ||
            pathname === `${newPathUser}`
              ? 'default-click'
              : ''
          }`}
          key={'AccountPage'}
          onClick={() => {
            if (isAuth) {
              dispatch(resetIDHomePage());
              dispatch(resetDataInfoOther());
            }
            !isAuth && dispatch(logUserProfile());
          }}>
          <NavLink
            to={isAuth ? `${newPathUser}${dataUser?.account_id}` : 'javascript:void(0)'}
            children={({ isActive }) => (
              <>
                <IconAccount
                  isActive={[
                    `${newPathUser}`,
                    `${newPathUser}${dataUser?.account_id}`,
                  ].includes(pathname)}
                />
                <span
                  className={
                    [`${newPathUser}`, `${newPathUser}${dataUser?.account_id}`].includes(
                      pathname
                    )
                      ? 'link-active'
                      : 'link-not-active'
                  }>
                  {t('Common.account')}{' '}
                </span>
              </>
            )}
          />
        </li>
      </ul>
    </div>
  );
};
export default Footer;
