import { ImgCustom, LayoutCreatorRight, MessageItem } from 'components';
import { InputWrapper } from 'components/FormLogin';
import InfiniteLoad from 'components/Paging/InfiniteLoad';
import PopupConfirmDelete from 'components/PopupConfirmDelete';
import { newPathUser } from 'constants/layout/constant';
import withCreatorRole from 'hocs/withCreatorRole';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Select from 'react-select';
import {
  getIDMess,
  userDeleteGroupMessage,
  userGetListMessage,
  userGetListMessageToAdmin,
} from 'store/actions/message';
import i18n from 'translate/i18n';
import {
  ADMIN_TYPE,
  AVATAR_DEFAULT,
  CustomDropdownIndicator,
  CustomOption,
  customStylesReselect,
  formatCoin,
  isAdmin,
  userIdToAdmin,
  LIST_POINT_ICON_DONATE,
  LOGO_COCO_FANS,
  AVATAR_DEL_USER,
  AVATAR_BLOCK_USER,
} from 'utils';
import { PlusOutlined } from '@ant-design/icons';
import './index.scss';
import { ShimmerPostDetails } from 'react-shimmer-effects';
import LoadingIconV2 from 'images/LoadingIconV2';
import EmptyMessageIconV2 from 'images/EmptyMessageIconV2';
import { useCallback } from 'react';

const ListMessage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const {
    listMessage,
    paginationListMessage,
    listMessageToAdmin,
    paginationListMessageToAdmin,
    loadingDeleteMessageGroup,
    idMessScroll,
    initPageMess,
  } = useSelector((state) => state.message);
  const { t } = useTranslation();
  const isAdminPage = isAdmin;
  const isCheckListMessage = isAdminPage ? listMessageToAdmin : listMessage;
  const isCheckPaginationListMessageToAdmin = isAdminPage
    ? paginationListMessageToAdmin
    : paginationListMessage;
  const listSortMessage = useMemo(() => {
    return (
      [
        { label: i18n.t('Creator.Message.newOrder'), value: 'desc' },
        { label: i18n.t('Creator.Message.oldOrder'), value: 'asc' },
      ] || []
    );
  }, [i18n.language]);

  const sortParams = searchParams.get('sort');

  const dataFromSortParams = useMemo(() => {
    if (sortParams) {
      return listSortMessage?.find((e) => e?.value === sortParams);
    }
    return [];
  }, [sortParams, listSortMessage]);

  const [sort, setSort] = useState(
    dataFromSortParams || idMessScroll?.selectID || listSortMessage[0]
  );
  const [page, setPage] = useState(idMessScroll && initPageMess ? +initPageMess : 1);
  const [groupMessageDelete, setGroupMessageDelete] = useState({});
  const [showPopupConfirm, setShowPopupConfirm] = useState(false);

  useEffect(() => {
    if (initPageMess < 1) {
      const top = document.getElementById('app_cocofans_0');
      top && top.scrollIntoView({ top: 0, left: 0, block: 'start' });
      setLoading(true);
      if (isAdminPage) {
        dispatch(
          userGetListMessageToAdmin(
            userIdToAdmin,
            {
              page: page,
              limit: 10,
              sort: sort.value,
            },
            (_response) => setLoading(false)
          )
        );
      } else {
        dispatch(
          userGetListMessage(
            {
              page: page,
              limit: 10,
              sort: sort.value,
            },
            (_response) => setLoading(false)
          )
        );
      }
    }
  }, []);

  const funCallApi = useCallback(
    (page) => {
      setLoading(true);
      if (isAdminPage) {
        dispatch(
          userGetListMessageToAdmin(
            userIdToAdmin,
            {
              page: page,
              limit: 10,
              sort: sort.value,
            },
            (_response) => setLoading(false)
          )
        );
      } else {
        dispatch(
          userGetListMessage(
            {
              page: page,
              limit: 10,
              sort: sort.value,
            },
            (_response) => setLoading(false)
          )
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page, sort]
  );

  const funCallApiSelect = useCallback(
    (page, value) => {
      setLoading(true);
      if (isAdminPage) {
        dispatch(
          userGetListMessageToAdmin(
            userIdToAdmin,
            {
              page: page,
              limit: 10,
              sort: value.value,
            },
            (_response) => setLoading(false)
          )
        );
      } else {
        dispatch(
          userGetListMessage(
            {
              page: page,
              limit: 10,
              sort: value.value,
            },
            (_response) => setLoading(false)
          )
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const fetchNext = () => {
    const pages =
      isCheckPaginationListMessageToAdmin?.total /
      isCheckPaginationListMessageToAdmin?.per_page;
    if (pages > page) {
      setPage(page + 1);
      funCallApi(page + 1);
    }
  };

  const onChangeSelect = (value) => {
    navigate(`/message?sort=${value?.value}`);
    setPage(1);
    setSort(value);
    funCallApiSelect(1, value);
  };
  const handleDelete = () => {
    dispatch(
      userDeleteGroupMessage(groupMessageDelete?.user_id, groupMessageDelete?.index, () => {
        setGroupMessageDelete({});
        setShowPopupConfirm(false);
      })
    );
  };

  const renderButtonNewMess = () => {
    return isAdmin ? (
      ''
    ) : (
      <div className='message-add-new' style={{ padding: '5px 0 0 16px' }}>
        <button
          onClick={() => {
            dispatch(getIDMess({ id: 'create mess', selectID: sort, page: page }));
            navigate(`${newPathUser}message/create`);
          }}
          className='btn btn-outline-danger  d-flex align-items-center'>
          <PlusOutlined />
          <span className='ms-2'>{t('Messages.buttonCreateMessage')}</span>
        </button>
      </div>
    );
  };

  const checkStatus = (status) => {
    let tmp = '';
    switch (status) {
      case null: // del
        tmp = AVATAR_DEL_USER
        break;
      case 2: // stop
        tmp = AVATAR_BLOCK_USER
        break;
      default:
        tmp = AVATAR_DEFAULT;
    }
    return tmp;
  };

  return (
    <>
      <LayoutCreatorRight
        noButtonBack
        titlePage={`${t('Common.listMessage')}（${
          isCheckPaginationListMessageToAdmin?.total || 0
        }${i18n.language === 'jp' ? '件' : ''}）`}
        className='message-layout'
        link={`${newPathUser}`}>
        {loadingDeleteMessageGroup && (
          <div className='layer-spinner'>
            <LoadingIconV2 className='spinner-icon' />
          </div>
        )}
        {showPopupConfirm && (
          <PopupConfirmDelete
            title={t('Popup.confirmDeteleFullMess', {
              name: ADMIN_TYPE.includes(groupMessageDelete?.user?.user_type)
                ? t('Common.isAdmin')
                : groupMessageDelete?.user?.account_name ||
                  groupMessageDelete?.user?.full_name,
            })}
            setShowPopup={setShowPopupConfirm}
            handleOnSubmit={handleDelete}
          />
        )}

        <>
          <div className='buyer-page-creator message-page'>
            <form
              className='fansite-form pt-3'
              style={{ marginBottom: '-15px' }}
              onSubmit={(e) => e.preventDefault()}>
              <div className='d-flex w-100 justify-content-between'>
                <InputWrapper id='fsite-select'>
                  <div className='select'>
                    <Select
                      options={listSortMessage}
                      isSearchable={false}
                      isClearable={false}
                      defaultValue={!idMessScroll?.selectID && listSortMessage[0]}
                      onChange={onChangeSelect}
                      className='select'
                      value={listSortMessage?.find((e) => e.value === sort?.value)}
                      width='120px'
                      styles={customStylesReselect}
                      components={{
                        Option: CustomOption,
                        DropdownIndicator: CustomDropdownIndicator,
                      }}
                    />
                  </div>
                </InputWrapper>
                {listMessage?.length > 0 && renderButtonNewMess()}
              </div>
            </form>
            {loading && !isCheckListMessage?.length ? (
              <div className='shimmer-loading'>
                <ShimmerPostDetails hasImage imageType='circular' title />
              </div>
            ) : (
              <InfiniteLoad
                loading={loading && !!isCheckListMessage?.length}
                data={isCheckListMessage || []}
                fetchNext={fetchNext}>
                <div className='list-buyer-creator messages'>
                  {isCheckListMessage?.length > 0
                    ? isCheckListMessage.map((item, index) => {
                        const { user, id, created_at, user_id, not_seen, message } = item;
                        const isAdmin = ADMIN_TYPE.includes(user?.user_type);
                        const avatar = isAdmin
                          ? LOGO_COCO_FANS
                          : user?.avatar
                          ? `${user?.avatar}`
                          : checkStatus(user?.status);
                        return (
                          <MessageItem
                            avatar={avatar}
                            item={item}
                            key={index}
                            id={id}
                            name={
                              isAdmin
                                ? t('Common.isAdmin')
                                : user?.account_name || user?.full_name
                            }
                            message={
                              message?.type === 5 ? (
                                <>
                                  {(() => {
                                    const srcImg = LIST_POINT_ICON_DONATE.find((x) => {
                                      return x.name === item?.point?.icon;
                                    });
                                    if (srcImg)
                                      return (
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                          <ImgCustom
                                            src={srcImg?.icon}
                                            alt=''
                                            style={{
                                              maxWidth: 22,
                                              minWidth: 22,
                                              maxHeight: 22,
                                              minHeight: 22,
                                              marginRight: 7,
                                            }}
                                          />
                                          <span className='number'>
                                            {formatCoin(item?.point?.point)}
                                          </span>
                                        </div>
                                      );
                                  })()}
                                </>
                              ) : (
                                message?.content
                              )
                            }
                            format='YYYY/MM/DD HH:mm:ss'
                            dateTime={created_at}
                            handleDelete={() => {
                              if (!isAdminPage) {
                                setGroupMessageDelete({ ...item, index });
                                setShowPopupConfirm(true);
                              }
                            }}
                            isAdmin={isAdmin}
                            isAdminPage={isAdminPage}
                            isRead={!Number(not_seen)}
                            clickDetail={() => {
                              dispatch(getIDMess({ id: id, selectID: sort, page: page }));
                              navigate(`${newPathUser}message/${user_id}`);
                            }}
                          />
                        );
                      })
                    : !loading && (
                        <div className='block-empty-box'>
                          <EmptyMessageIconV2 />
                          <div className='block-empty-box_text'>
                            {t('Messages.listMessagesNull')}
                          </div>
                          {renderButtonNewMess()}
                        </div>
                      )}
                </div>
              </InfiniteLoad>
            )}
          </div>
        </>
      </LayoutCreatorRight>
    </>
  );
};

export default withCreatorRole(ListMessage);
