import {
  AvatarInfoCreator,
  ImgCustom,
  LayoutCreatorRight,
  ListComment,
  ListImages,
  PopupConfirmFan,
  PopupLoading,
  PopupSharePost,
  Textarea,
  VideoDetail,
  ViewFullImage,
} from 'components';
import { InputWrapper } from 'components/Input/InputWrapper';
import withCreatorRole from 'hocs/withCreatorRole';
import useRedirect from 'hooks/useRedirect';
import { SendChatIcon } from 'images';
import Avatar from 'images/Avatar.png';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  creatorGetPostDetail,
  deleteCommentDetail,
  deleteReplyCommentDetail,
  postCommentDetail,
  postFollowCommentDetail,
  postFollowReplyCommentDetail,
  postLikeDetail,
} from 'store/actions/myPage';
import {
  convertToCurrentTime,
  IMG_NO_WATER_MARK,
  isAdmin,
  isAgency,
  decryptPath,
  linkS3Video,
  renderTimeAfter24h,
  linkS3VideoM3U8,
} from 'utils';
import GroupLikeChatShared from '../components/GroupLikeChatShared';
import '../detail.scss';
import VideoCustomDetail from 'pages/creator/layout/recording/VideoCustomDetail';
import { newPathUser } from 'constants/layout/constant';
import { updateLikeReplyComment, updateLikeCommentDetail } from 'store/actions/usersNew';

const Post = () => {
  const dispatch = useDispatch();
  const { detailPost, loading, loadingAction, error, statusPostComment, loadingFollow } = useSelector(
    (state) => state.myPage
  );
  const dataProfile = useSelector((state) => state.users.data);
  const [popupCopyLink, setPopupCopyLink] = useState(null);
  const [popupDeleteComment, setPopupDeleteComment] = useState(null);
  const [checkSendChat, setCheckSendChat] = useState(null);
  const [valueComment, setValueComment] = useState('');
  const { id } = useParams();
  const [dataViewFull, setDataViewFull] = useState({});

  const handleSharePost = (dataPC) => {
    setPopupCopyLink(dataPC);
  };

  const handleTextarea = (e, id, height) => {
    if (e.target.scrollHeight > e.target.clientHeight) {
      e.target.style.height = e.target.scrollHeight + 'px';
    }
    var code = e.keyCode || e.charCode;
    if (code === 13) {
      e.target.style.height = 'inherit';
      e.target.style.height = `${e.target.scrollHeight}px`;
    }
    setValueComment(e.target.value);
    setCheckSendChat(id);
    if (e.target.value?.toString().trim() === '') {
      e.target.style.height = height;
      e.target.style.padding = '9px  30px  9px 12px';
      setCheckSendChat(null);
    }
  };

  // ESC FUNC
  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      setCheckSendChat(null);
      setValueComment('');
      const textareaId = document.getElementById('textarea-post-comment');
      if (textareaId) textareaId.style.height = '38px';
    }
  }, []);

  useRedirect(error);
  // START FUNC ESC
  useEffect(() => {
    if (statusPostComment) {
      setCheckSendChat(null);
      setValueComment('');
      const textareaId = document.getElementById('textarea-post-comment');
      if (textareaId) textareaId.style.height = '38px';
    }
    document.addEventListener('keydown', escFunction);
    return () => {
      document.removeEventListener('keydown', escFunction);
    };
  }, [escFunction, statusPostComment]);

  useEffect(() => {
    dispatch(creatorGetPostDetail(id, false));
  }, [id]);

  //API LIKE POST
  const handleLikePost = (id) => {
    dispatch(postLikeDetail(id));
  };

  //API LIKE COMMENT
  const handleLikeComment = (data) => {
    const { commentId, type, dataPost } = data;
    type === 'comment'
      ? dispatch(updateLikeCommentDetail(commentId, dataPost))
      : dispatch(updateLikeReplyComment(commentId, dataPost));
  };

  // API POST/EDIT COMMENT
  const handlePostComment = (id, parent_id) => {
    const data = {
      parent_id: parent_id,
      content: valueComment,
    };
    checkSendChat && dispatch(postCommentDetail(id, data));
  };

  // DELETE
  const handleDeleteComment = () => {
    const { id, type, idParent } = popupDeleteComment;
    type === 'comment'
      ? dispatch(deleteCommentDetail(id))
      : dispatch(deleteReplyCommentDetail(id, idParent));
    setPopupDeleteComment(false);
  };

  // FOLLOW USER IN COMMENT
  const handleFollowUserComment = (data) => {
    const { id, type } = data;
    type === 'comment'
      ? dispatch(postFollowCommentDetail(id))
      : dispatch(postFollowReplyCommentDetail(id));
  };
  const { t } = useTranslation();

  return (
    <>
      {popupCopyLink && (
        <PopupSharePost
          isVisiblePopup={() => setPopupCopyLink(false)}
          data={popupCopyLink || {}}
        />
      )}

      {popupDeleteComment && (
        <PopupConfirmFan
          isVisiblePopup={() => setPopupDeleteComment(false)}
          colorSvg='#FF9F43'
          text={t('Popup.confirmDeleteComment')}
          className='popup-delete'>
          <div className='ok btn' onClick={handleDeleteComment}>
            {t('Common.buttonDelete')}
          </div>
          <div className='btn cancel' onClick={() => setPopupDeleteComment(false)}>
            {t('Common.buttonCancel')}
          </div>
        </PopupConfirmFan>
      )}
      {dataViewFull?.image?.url && (
        <ViewFullImage dataViewFull={dataViewFull} close={() => setDataViewFull({})} />
      )}
      {(loading || loadingFollow) && <PopupLoading className={'popup-loading'} />}
      {detailPost && (
        <LayoutCreatorRight titlePage={t('PostDetail.creator.title.type1')}>
          <div className='fansite-creator-post-detail creator-post-normal-detail'>
            <div className='account block d-flex justify-content-between'>
              <AvatarInfoCreator
                name={detailPost?.user?.account_name}
                content2={`${t('Common.id')}: ${detailPost?.user?.account_id}`}
                className='account__creator'
                type={`${
                  (dataProfile?.account_id || dataProfile?.id) ===
                    (detailPost?.user?.account_id || detailPost?.user?.id) && 'edit'
                }`}
                images={detailPost?.user?.avatar || detailPost?.user?.avatar}
                link={`${newPathUser}${detailPost?.type === 1 ? 'post' : 'post'}/update/${
                  detailPost?.id
                }`}
              />
            </div>
            <div className='content block'>
              {detailPost?.packages?.length > 0 && (
                <div className='package'>
                  <span className='text1'>{t('PostDetail.browserTarget')}</span>
                  <span className='text2'>
                    {detailPost?.all_package === 1
                      ? t('Common.allPlan')
                      : detailPost.package_names}
                  </span>
                </div>
              )}
              {detailPost?.public_time || detailPost?.unpublic_time ? (
                <div className='date'>
                  <span className='text1'>{t('PostDetail.publicationPeriod')}</span>
                  <span className='text2'>
                    {(detailPost?.public_time
                      ? detailPost?.public_time
                      : detailPost?.created_at) &&
                      convertToCurrentTime(
                        detailPost?.public_time
                          ? detailPost?.public_time
                          : detailPost?.created_at,
                        'YYYY/MM/DD HH:mm:ss'
                      )}
                    {` ～ `}
                    {detailPost?.unpublic_time &&
                      convertToCurrentTime(detailPost?.unpublic_time, 'YYYY/MM/DD HH:mm:ss')}
                    {detailPost?.in_24h_flag === 1 &&
                      convertToCurrentTime(
                        renderTimeAfter24h(detailPost?.public_time),
                        'YYYY/MM/DD HH:mm:ss'
                      )}
                  </span>
                </div>
              ) : (
                <div className='date'>
                  <span className='text1'>{t('PostDetail.publicationPeriod')}</span>
                  <span className='text2'>
                    {detailPost?.created_at &&
                      convertToCurrentTime(detailPost?.created_at, 'YYYY/MM/DD HH:mm:ss')}
                    {` ～ `}
                    {detailPost?.in_24h_flag === 1 &&
                      convertToCurrentTime(
                        renderTimeAfter24h(detailPost?.created_at),
                        'YYYY/MM/DD HH:mm:ss'
                      )}
                  </span>
                </div>
              )}
              <div className='content--title display-linebreak'>{detailPost?.title}</div>
              <div className='text display-linebreak'>{detailPost?.content}</div>
              {detailPost?.medias?.length > 0 && (
                <>
                  {detailPost?.medias?.[0].type === 1 ? (
                    <>
                      {(() => {
                        const data = detailPost?.medias
                          ?.filter((item) => item?.type === 1)
                          .filter((item) => item?.public_flag === 1);

                        return (
                          data.length > 0 && (
                            <ListImages
                              images={data}
                              number_bookmark={detailPost?.bookmark_total}
                              id_post={detailPost?.id}
                              bookmark_status={detailPost?.user_bookmark_status}
                              className='custom-creator'
                              is_idol={true}
                              onClickImage={setDataViewFull}
                              isPostDetail
                            />
                          )
                        );
                      })()}
                    </>
                  ) : (
                    <>
                      {detailPost.medias[0].public_flag === 1 &&
                      detailPost.medias[0]?.url?.indexOf('.m3u8') === -1 ? (
                        <VideoDetail
                          link={detailPost.medias[0].url}
                          className='video-detail'
                        />
                      ) : detailPost.medias[0].public_flag === 1 &&
                        detailPost.medias[0]?.url?.indexOf('.m3u8') !== -1 ? (
                        <VideoCustomDetail
                          src={`${linkS3VideoM3U8}${
                            decryptPath(detailPost.medias[0].url) + '#t=0.1'
                          }`}
                          className='video-detail'
                          isDetailPost
                          handleNavigate={() => {}}
                        />
                      ) : (
                        <ImgCustom
                          src={IMG_NO_WATER_MARK}
                          className='video-detail trailer_video img_no_water_mark'
                        />
                      )}
                    </>
                  )}
                </>
              )}
            </div>

            {isAdmin || isAgency ? (
              <>
                <GroupLikeChatShared detailPost={detailPost} loadingAction={loadingAction} />
                {detailPost?.enable_comment === 1 && (
                  <ListComment
                    dataPost={detailPost}
                    handleDeleteComment={(e) => {
                      setPopupDeleteComment(e);
                    }}
                    handleLikeComment={handleLikeComment}
                    dataProfile={dataProfile}
                    handleFollowUserComment={handleFollowUserComment}
                  />
                )}
              </>
            ) : (
              <>
                {(dataProfile?.user_type === 5
                  ? dataProfile?.account_id === detailPost?.user?.account_id
                  : false) && (
                  <>
                    <GroupLikeChatShared
                      detailPost={detailPost}
                      loadingAction={loadingAction}
                      handleLikePost={(e) => handleLikePost(e)}
                      handleSharePost={(e) => handleSharePost(e)}
                    />
                    {detailPost?.enable_comment === 1 && (
                      <>
                        <div className={`comment block`}>
                          <ImgCustom
                            src={dataProfile?.avatar ? `${dataProfile.avatar}` : Avatar}
                          />
                          <form onSubmit={(e) => e.preventDefault()}>
                            <InputWrapper id='fsite-comment' className='form-item'>
                              <Textarea
                                onChange={(e) => handleTextarea(e, detailPost?.id, '38px')}
                                placeholder={t('comment.formAddRepComment.placeholder')}
                                onKeyDown={(e) => handleTextarea(e, detailPost?.id, '38px')}
                                value={valueComment}
                                maxLength='500'
                                id='textarea-post-comment'
                                overflowHidden
                              />
                              <SendChatIcon
                                className={`${valueComment !== '' && 'active'}`}
                                onClick={() =>
                                  valueComment !== '' && handlePostComment(detailPost?.id, 0)
                                }
                              />
                            </InputWrapper>
                          </form>
                        </div>
                        <ListComment
                          dataPost={detailPost}
                          handleDeleteComment={(e) => {
                            setPopupDeleteComment(e);
                          }}
                          handleLikeComment={handleLikeComment}
                          dataProfile={dataProfile}
                          handleFollowUserComment={handleFollowUserComment}
                        />
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </LayoutCreatorRight>
      )}
    </>
  );
};

export default withCreatorRole(Post);
