/* eslint-disable react-hooks/exhaustive-deps */
import classNames from 'classnames';
import useUserRole from 'hooks/useUserRole';
import { ArrowIcon, PostMenuIcon, SidebarArrow } from 'images';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  logout,
  resetIDBlock,
  resetIDRankingBuy,
  resetIDSearchBuyed,
  resetIDSearchBuyer,
  resetIDSearchUser,
  resetIDSubcribe,
  resetIdSubscriber,
} from 'store/actions/users';
import {
  isAdmin,
  isAgency,
  isProdEnable,
  isProdEnableLiveStream,
  setOverflowTablet,
} from '../../utils/utils';
import {
  ROUTER_MENU_CREATOR_NOT_APPROVED_OPTION,
  ROUTER_MENU_CREATOR_OPTION,
  ROUTER_MENU_CREATOR_OPTION_NOT_LOGIN,
  ROUTER_MENU_FAN_OPTION,
} from 'constants/layout/options';
import './index.scss';
import { useTranslation } from 'react-i18next';
import { settingMinimalMenu, logUserProfile } from 'store/actions/myPage';
import { newPathUser } from 'constants/layout/constant';
import { isMobile, isTablet } from 'react-device-detect';
import Storage from 'utils/storage';
import { STORAGE, widthSreen } from 'utils';
import { ImgCustom, PopupBlockUser, PopupConfirmFan } from 'components';
import bannerLeftMobile from 'images/sp-oppai-coin-banner-330-90.gif';
import PopupBlockUserNoLogin from 'components/Popup/blockUserNoLogin';
import {
  checkIsShortMypage,
  resetDataFavor,
  resetIDHomePage,
  resetIDSearchPost,
  resetIDSearchSale,
  resetIDSearchVideo,
  resetIDTimeline,
  resetTabTimeline,
  setIdScrollBackRecommend,
} from 'store/actions/usersNew';
import { clearListRanking } from 'store/actions/ranking';
import { resetIDMess } from 'store/actions/message';
import { resetActionGetListLive, resetListLiveStream } from 'store/actions/lives';
import { clearIdScrollBackRecording } from 'store/actions/creator';
import { resetIDSale } from 'store/actions/saleConfirmation';
import { resetActionCalendar } from 'store/actions/calendar';
import { resetActionFeedBack } from 'store/actions/feedback';
import { clearListSurvey } from 'store/actions/survey';
import { clearScrollIdAndData } from 'store/actions/card';
import ImgRTA from 'images/RTA.png';
import Epoch from 'images/Epoch.png';

const MenuSidebarCustom = ({ setShowPopupBlockUserNoLogin }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname, state } = useLocation();

  const { isIdol } = useUserRole();
  const [openMenu, setOpenMenu] = useState({});
  const [currentKey, setCurrentKey] = useState('');
  const [lastSubMenuId, setLastSubMenuId] = useState(null);
  const [openSubMenu, setOpenSubMenu] = useState({});
  const { isMinimal, isLogUser } = useSelector((state) => state.myPage);
  const { data: userProfile, dataUser } = useSelector((state) => state.users);
  const { loading } = useSelector((state) => state.maintain);

  const sidebarElement = document.getElementById('/home-page/profile-settings');
  const isAuth = Storage.has(STORAGE.USER_ACCESS_TOKEN);
  const isNotApproved = useMemo(() => {
    return dataUser?.status !== 1;
  }, [dataUser]);

  useEffect(() => {
    if (lastSubMenuId || Object.keys(openMenu).length) {
      document
        .getElementById(lastSubMenuId)
        ?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
    }
  }, [lastSubMenuId, openMenu]);

  const clickMenu = (id, isDisabled) => {
    window.scrollTo(0, 0);
    // document.getElementById('root').style.overflow = 'auto';
    setOverflowTablet();
    document.getElementById('root').style.position = 'unset';
    if (isDisabled || !id) return null;
    id !== pathname && navigate(id);
  };

  let childrenTitle = isIdol
    ? ROUTER_MENU_CREATOR_OPTION?.find((item) =>
        item?.children?.find((child) => pathname?.includes(child.id))
      )
    : ROUTER_MENU_FAN_OPTION?.find((item) =>
        item?.children?.find((child) =>
          child?.id === '#' || !child?.id
            ? child?.children?.find((subChild) => pathname?.includes(subChild?.id))
            : pathname?.includes(child.id)
        )
      );

  const handleOpenMenu = (key) => {
    if (openMenu[key]) {
      setOpenMenu({});
    } else {
      setOpenMenu({ [key]: true });
    }
  };

  const handleOpenSubMenu = (key) => {
    setOpenSubMenu({ [key]: true });
  };

  const checkPathNameNow = useCallback(
    (id, idSub) => {
      const idCheck = id || idSub;
      if (idCheck) {
        return pathname === idCheck;
      }
      return false;
    },
    [pathname]
  );

  const handleClickMenu = (
    type,
    children,
    label,
    callbackType,
    id,
    disabled,
    sub_menu,
    sub_menu_2,
  ) => {
    if (!checkPathNameNow(id, sub_menu?.id)) {
      dispatch(resetIDHomePage());
      dispatch(checkIsShortMypage({ ishow: false }));
      dispatch(setIdScrollBackRecommend(`sidebar`, pathname))
      // scroll submenu
      if (pathname?.includes(sub_menu?.id) && pathname !== sub_menu?.id) {
        return window.location.href = `${sub_menu?.id}`
      }
      if (sub_menu?.id === '/profile/recording') {
        dispatch(clearIdScrollBackRecording());
      }
      if (sub_menu?.id === '/card-management') {
        dispatch(clearScrollIdAndData());
      }
      if (sub_menu?.id === '/live/list-livestream') {
        dispatch(resetListLiveStream());
      }
      if (sub_menu?.id === '/lives/list/live') {
        dispatch(resetActionGetListLive());
      }
      if (children?.length) {
        setLastSubMenuId(children?.[children?.length - 1]?.id);
      }

      if (id === `${newPathUser}timeline`) {
        dispatch(resetIDTimeline());
        dispatch(resetTabTimeline());
      }

      if (sub_menu?.id === `${newPathUser}help`) {
        dispatch(resetActionFeedBack());
      }

      if (sub_menu?.id === `${newPathUser}subscriber`) {
        dispatch(resetIdSubscriber());
      }

      if (id === `${newPathUser}search`) {
        dispatch(resetIDSearchPost());
        dispatch(resetIDSearchVideo());
        dispatch(resetIDSearchUser());
        dispatch(resetIDSearchSale());
      }

      if (sub_menu?.id === `${newPathUser}buyer`) {
        dispatch(resetIDSearchBuyer());
      }

      if (sub_menu?.id === `${newPathUser}calendar`) {
        dispatch(resetActionCalendar());
      }

      if (sub_menu?.id === `${newPathUser}block`) {
        dispatch(resetIDBlock());
      }

      if (sub_menu?.id === `${newPathUser}ranking-buyer`) {
        dispatch(resetIDRankingBuy());
      }

      if (sub_menu?.id === `${newPathUser}sale-confirmation`) {
        dispatch(resetIDSale());
      }

      if (id === `${newPathUser}list-post-buyed`) {
        dispatch(resetIDSubcribe());
        dispatch(resetIDSearchBuyed());
      }

      if (id === `${newPathUser}ranking`) {
        dispatch(clearListRanking());
      }

      if (id === `${newPathUser}surveys`) {
        dispatch(clearListSurvey());
      }

      if (id === `${newPathUser}message`) {
        dispatch(resetIDMess());
      }

      if (id === `${newPathUser}favorite/list-post`) {
        dispatch(resetDataFavor());
      }

      if (children?.length) {
        setLastSubMenuId(children?.[children?.length - 1]?.id);
      }

      setCurrentKey(label);
      if (isMinimal) {
        dispatch(settingMinimalMenu());
      } else {
        if (!isAuth && id !== '/search' && id !== '/list-short-video' && id !== '/ranking') {
          if (!isMobile) {
            dispatch(logUserProfile());
          } else setShowPopupBlockUserNoLogin(true);
        } else {
          if (!type) {
            setOpenMenu({ [label]: true });
          }
          if (type === 0) {
            children?.length > 0 && handleOpenMenu(label);
            callbackType === 'logout' && dispatch(logout());
          } else if (type === 1) {
            if (
              id === '/terms-of-use' ||
              id === '/transactions-law' ||
              id === '/privacy-policy'
            ) {
              window.open(id);
            } else {
              clickMenu(
                id,
                disabled && callbackType !== 'logout' && callbackType !== 'fan-message'
              );
              setOpenMenu({});
            }
          } else if (type === 2) {
            if (sub_menu?.children?.length > 0) {
              handleOpenMenu(label);
              handleOpenSubMenu(sub_menu?.label);
            }
            // sub_menu?.children?.length > 0 && handleOpenSubMenu(sub_menu?.label);
          } else if (type === 3) {
            if (sub_menu_2?.tab_navigate_list_live) {
              // document.getElementById('root').style.overflow = 'auto';
              setOverflowTablet();
              document.getElementById('root').style.position = 'unset';
              navigate(`${newPathUser}lives/list/live`, {
                state: {
                  tab_navigate_list_live: sub_menu_2?.tab_navigate_list_live,
                },
              });
            } else clickMenu(sub_menu_2?.id, disabled && callbackType !== 'logout');
          } else if (type === 4) {
            if (callbackType === 'logout' || sub_menu?.callbackType === 'logout') {
              dispatch(logout());
            } else {
              clickMenu(sub_menu?.id, disabled && callbackType !== 'logout');
              // setOpenMenu({});
              // setOpenMenu({ [label]: true });
              // handleOpenMenu(label);
            }
          }
        }
      }
    }
  };

  const checkStatusAccept = (listStatus) => {
    if (!listStatus || listStatus?.includes(dataUser?.status)) {
      return true;
    }
    return false;
  };

  const checkIsProd = (isProd) => {
    if (isProd) {
      if (+isProdEnable === 1) {
        return true;
      }
      return false;
    }
    return true;
  };

  const MenuItem = ({
    children,
    label,
    icon,
    id,
    disabled,
    callbackType,
    subMenu,
    subMenuExact,
    role,
    t,
  }) => {
    const childrenId = !!children?.length && children.map((child) => child?.id);
    const isActiveParentMenu = (childrenId) => {
      if (childrenId?.length && childrenId?.includes(pathname)) {
        return true;
      } else return false;
    };

    const isActive =
      pathname === id ||
      isActiveParentMenu(childrenId) === true ||
      subMenu?.filter((subLink) => pathname?.includes(subLink))?.length ||
      subMenuExact?.find((subLinkExact) => pathname === subLinkExact);

    return (
      <>
        {/* Menu item */}
        {id === '#' || !id ? (
          <div
            key={label}
            className={classNames(
              `menu-item ${
                disabled &&
                callbackType !== 'logout' &&
                callbackType !== 'fan-message' &&
                label !== 'Common.setting' &&
                'disabled-menu-click'
              } ${isActive && 'is-actived'}`
            )}
            onClick={() => handleClickMenu(0, children, label, callbackType)}>
            <div className={`link ${isMinimal && 'is_minimal'} `}>
              <span className='menu-icon'>{icon}</span>
              {!isMinimal && <span className={`label`}>{t(`${label}`)}</span>}
            </div>
            {!isMinimal && (
              <SidebarArrow
                className={`${openMenu[label] && 'active_icon'}  ${
                  callbackType === 'logout' && 'd-none'
                } ${!children?.length > 0 && 'd-none'} arrow-icon`}
              />
            )}
          </div>
        ) : (
          <div
            key={label}
            className={classNames(
              `menu-item ${
                role === 'affiliator' ? (userProfile?.is_affiliate === 1 ? '' : 'd-none') : ''
              }
              ${
                disabled &&
                callbackType !== 'logout' &&
                callbackType !== 'fan-message' &&
                'disabled-menu-click'
              }`
            )}
            style={{ cursor: `${loading ? 'default' : 'pointer'}` }}
            onClick={() => {
              !loading && handleClickMenu(1, null, null, callbackType, id, disabled);
            }}>
            <div
              style={{ cursor: `${loading ? 'default' : 'pointer'}` }}
              className={`link ${isActive && 'activeMenuItem'} ${isMinimal && 'is_minimal'}`}>
              <span className='menu-icon'>{icon}</span>
              {!isMinimal && <span className={`label`}>{t(`${label}`)}</span>}
            </div>
          </div>
        )}
        {/* map menu item */}
        {children?.length > 0 &&
          !isMinimal &&
          (openMenu[label] || childrenTitle) &&
          openMenu[label] !== undefined && (
            <>
              <div className='box-item'>
                <div className={`menu-child 22`}>
                  {children.map((sub_menu) => {
                    if (
                      checkStatusAccept(sub_menu?.statusAccept) &&
                      checkIsProd(sub_menu?.isProdPageEnable)
                    ) {
                      return (
                        <>
                          {sub_menu?.children?.length > 0 ? (
                            <>
                              <div
                                className={`menu-item`}
                                key={sub_menu.label}
                                id={sub_menu?.id}
                                onClick={() => {
                                  !loading &&
                                    handleClickMenu(
                                      2,
                                      children,
                                      null,
                                      null,
                                      null,
                                      null,
                                      sub_menu
                                    );
                                }}>
                                <div
                                  style={{ cursor: `${loading ? 'default' : 'pointer'}` }}
                                  className={`link ${
                                    pathname?.includes(sub_menu?.id) && 'activeMenuItem'
                                  }`}>
                                  <span className='menu-icon'>{sub_menu?.icon}</span>
                                  <span className={`label`}>{t(`${sub_menu?.label}`)}</span>
                                </div>
                                <SidebarArrow
                                  className={`${
                                    (openSubMenu[sub_menu?.label] || childrenTitle) &&
                                    'active_icon'
                                  } ${sub_menu?.callbackType === 'logout' && 'd-none'} ${
                                    !sub_menu?.children?.length > 0 && 'd-none'
                                  } arrow-icon`}
                                />
                              </div>
                              {sub_menu?.children?.length > 0 &&
                                openSubMenu[sub_menu?.label] &&
                                sub_menu?.children.map((sub_menu_2) => (
                                  <div className='box-item box-item-2'>
                                    <div
                                      className='menu-child'
                                      onClick={() =>
                                        !loading &&
                                        handleClickMenu(
                                          3,
                                          null,
                                          null,
                                          callbackType,
                                          null,
                                          disabled,
                                          null,
                                          sub_menu_2
                                        )
                                      }></div>
                                  </div>
                                ))}
                            </>
                          ) : (
                            <div
                              className={`menu-item  ${
                                disabled &&
                                sub_menu?.label !== 'Common.logOut' &&
                                'disabled-menu-click'
                              }`}
                              id={sub_menu?.id}
                              key={sub_menu.label}
                              onClick={() =>
                                !loading &&
                                handleClickMenu(
                                  4,
                                  null,
                                  label,
                                  callbackType,
                                  null,
                                  disabled,
                                  sub_menu
                                )
                              }>
                              <div
                                style={{ cursor: `${loading ? 'default' : 'pointer'}` }}
                                className={`link ${
                                  pathname?.includes(sub_menu?.id) && 'activeMenuItem'
                                }`}>
                                <span className='menu-icon'>{sub_menu?.icon}</span>
                                <span className={`label`}>{t(`${sub_menu?.label}`)}</span>
                              </div>
                            </div>
                          )}
                        </>
                      );
                    }
                  })}
                </div>
              </div>
            </>
          )}
      </>
    );
  };

  const LIST_MENU = useMemo(() => {
    if (isIdol && !isNotApproved) {
      return ROUTER_MENU_CREATOR_OPTION;
    } else if (!isAuth) {
      return ROUTER_MENU_CREATOR_OPTION_NOT_LOGIN;
    } else {
      return ROUTER_MENU_CREATOR_NOT_APPROVED_OPTION;
    }
  }, [isIdol, isNotApproved]);

  const renderMenuItem = ({
    children,
    id,
    label,
    icon,
    subMenu,
    subMenuExact,
    callbackType,
  }) => {
    return (
      <MenuItem
        key={label}
        children={children}
        subMenu={subMenu}
        subMenuExact={subMenuExact}
        id={id}
        label={label}
        icon={icon}
        disabled={(isIdol && [2, 6].includes(userProfile?.status)) || isAdmin || isAgency}
        callbackType={callbackType}
        t={t}
      />
    );
  };

  return (
    <>
      <div
        id='sidebar_creator'
        style={{
          maxHeight: `${isMinimal ? 'calc(100% - 40px)' : ''}`,
        }}
        className={`menu-sidebar custom ${isAdmin ? 'admin' : ''} ${
          +isProdEnableLiveStream === 1 ? '' : 'is-d-none'
        }`}>
        {LIST_MENU?.map(
          ({
            children,
            id,
            label,
            icon,
            subMenu,
            subMenuExact,
            callbackType,
            isProdEnablePageLiveStream,
            statusAccept,
          }) => {
            if (+isProdEnableLiveStream === 1) {
              return renderMenuItem({
                children,
                id,
                label,
                icon,
                subMenu,
                subMenuExact,
                callbackType,
              });
            } else {
              if (!isProdEnablePageLiveStream) {
                if (statusAccept) {
                  if (statusAccept?.includes(+dataUser?.status)) {
                    return renderMenuItem({
                      children,
                      id,
                      label,
                      icon,
                      subMenu,
                      subMenuExact,
                      callbackType,
                    });
                  }
                } else {
                  return renderMenuItem({
                    children,
                    id,
                    label,
                    icon,
                    subMenu,
                    subMenuExact,
                    callbackType,
                  });
                }
              }
            }
          }
        )}
        <>
          <div
            className={`is-mobile post-menu-item ${isAdmin || isAgency ? 'admin' : ''}`}
            onClick={() => {
              if (!isAdmin && !isAgency) {
                // document.getElementById('root').style.overflow = 'auto';
                setOverflowTablet();
                document.getElementById('root').style.position = 'unset';
                navigate(`${newPathUser}post/create`);
              }
            }}>
            <PostMenuIcon />
            {t('Common.post')}
          </div>
          {/* {((isMobile && widthSreen < 1024 ) || (isTablet && widthSreen < 1024)) && bannerLeftMobile && (
            <div className='banner-mobile'>
               <Link to='https://sharegram-blog.com/oppai-coin-lp/' target='_blank' className='link_route'>
                <ImgCustom
                  screen='banner_343_93_pc'
                  src={bannerLeftMobile}
                />
               </Link>
            </div>
          )} */}
          <div className='border' style={{ marginTop: '16px' }}></div>
          {/* <div className='border'></div> */}
          <div
            className={`list-terms is-mobile `}
            style={{ padding: '16px', color: '#666565', fontSize: '12px' }}>
            <div className={`list-link `}>
              <Link to='/terms-of-use' target='_blank' className='link_route'>
                {t('Common.terms')}
              </Link>
              <Link to='/privacy-policy' target='_blank' className='link_route'>
                {t('Common.privacyPolicy')}
              </Link>
              <Link to='javascript:void(0)' className='link_route'>
                {t('Common.cookiePolicy')}
              </Link>
              <Link to='javascript:void(0)' className='link_route'>
                {t('Common.accessibility')}
              </Link>
              <Link to={`${!isAuth ? 'javascript:void(0)' : '/help'}`} className='link_route'>
                {t('Common.help')}
              </Link>
              <Link to='/removal-request' target='_blank' className='link_route'>
                {t('Footer.FooterV2.removalRequest')}
              </Link>
              <Link to='/marketing-policy' target='_blank' className='link_route'>
                {t('Footer.FooterV2.marketting')}
              </Link>
            </div>
            <div className='list-link 1'>
              <Link to='/usc2257' target='_blank' className='link_route'>
                {t('Footer.FooterV2.usc2257')}
              </Link>
            </div>
            <p className='mt-1' style={{ marginBottom: '0' }}>©2023 Sharegram. All Rights Reserved</p>
            <img
              style={{ cursor: 'pointer' }}
              onClick={() => window.open('https://www.rtalabel.org', '_blank')}
              width='88px'
              height='31px'
              src={ImgRTA}
              alt=''
            />
            <img
              style={{ cursor: 'pointer', marginLeft: '8px' }}
              onClick={() => window.open('https://epoch.com', '_blank')}
              width='88px'
              height='31px'
              src={Epoch}
              alt=''
            />
          </div>
        </>
      </div>
      <div
        className={`post-menu-item not-mobile ${isAdmin ? 'admin' : ''}`}
        onClick={() => {
          if (!isAuth) {
            dispatch(logUserProfile());
          } else {
            if (!isAdmin && !isAgency) {
              // document.getElementById('root').style.overflow = 'auto';
              setOverflowTablet();
              document.getElementById('root').style.position = 'unset';
              navigate(`${newPathUser}post/create`);
            }
          }
        }}>
        <PostMenuIcon />
        {t('Common.post')}
      </div>
    </>
  );
};
export default MenuSidebarCustom;
