/* eslint-disable no-undef */
import { ImgCustom, PopupLockPostNormal, PopupSharePost } from 'components';
import InfiniteLoad from 'components/Paging/InfiniteLoad';

import { newPathUser } from 'constants/layout/constant';
import { CommentIcon, HeartIcon, Image2Icon, NoSearchList, Video2Icon } from 'images';
import { useCallback, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { ShimmerPostDetails } from 'react-shimmer-effects';
import {
  fanFollowCreator,
  getIDSearchPost,
  postNormalPaymentBuyPackageSuccess,
  resetPostSearch,
  searchPostAction,
  setIdScrollBackRecommend,
  updatePostLikeDetail,
} from 'store/actions/usersNew';
import {
  IMG_NO_WATER_MARK_MOBILE_2_COLUMN_SHORT_VIDEO,
  decryptPath,
  formatNumber,
  funcThumbnailIMG,
  isAdmin,
  linkCloudFront,
  linkS3Video,
} from 'utils/utils';
import Storage from 'utils/storage';
import './index.scss';
import { StyledTimeLine } from 'components/DetailCard/styles';
import { logUserProfile } from 'store/actions/myPage';
import withCreatorRole from 'hocs/withCreatorRole';
import { IMG_DEMO, STORAGE } from 'utils';
import { usePopupPaymentSub } from 'hooks/v2/usePopupPaymentSub';

const SearchPost = ({ keyword, loadingClickSearch, setFirstLoading, page, setPagePost, flgSearch }) => {
  const isAuth = Storage.has(STORAGE.USER_ACCESS_TOKEN);
  const [popupCopyLink, setPopupCopyLink] = useState(null);
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { dataUser } = useSelector((state) => state.users);
  const [isLoading, setIsLoading] = useState(false);
  const { searchPostTimeline = [], searchPostTimelinePagination, idSearchPost, initPageSearchPost } = useSelector(
    (state) => state.usersNew
  );
  const [searchParams] = useSearchParams();
  const keywordParams = searchParams.get('keyword');
  const { idPackage, setIsOpen, setIdPackage, PopupPaymentBySub } = usePopupPaymentSub(
    {
      urlRedirect: '',
      onClickSuccess: () => {
        dispatch(postNormalPaymentBuyPackageSuccess(idPackage))
      },
    }
  );
  const widthScreen = window.innerWidth;
  //const itemsPerPage = widthScreen <= 575 ? 6 : 12;
  const itemsPerPage = 12;

  useEffect(() => {
    if (idSearchPost) {
      const idPost = document.getElementById(idSearchPost.id);
      if (idPost) {
        idPost.scrollIntoView({
          block: 'center',
        });
      }
    }
  }, [idSearchPost]);

  const fetchNext = () => {
    const pages = searchPostTimelinePagination?.total / searchPostTimelinePagination?.per_page;
    if(pages > page) {
      setPagePost(page + 1);
      funCallApi(page + 1);
    }
  };

  const msToTime = (s) => {
    // Pad to 2 or 3 digits, default is 2
    var pad = (n, z = 2) => ('00' + n).slice(-z);
    return (
      pad((s / 3.6e6) | 0) +
      ':' +
      pad(((s % 3.6e6) / 6e4) | 0) +
      ':' +
      pad(((s % 6e4) / 1000) | 0)
    );
  };

  const funCallApi = useCallback(
    (rs) => {
      setIsLoading(true);
      dispatch(
        searchPostAction(
          !flgSearch && !keywordParams ? '' : keyword,
          rs,
          itemsPerPage,
          (_response) => setIsLoading(false),
          (_errors) => () => setIsLoading(false)
        )
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page, keyword]
  );

  useEffect(() => {
    const top = document.getElementById('app_cocofans_0');
    if(initPageSearchPost < 1) {
      top && top.scrollIntoView({ top: 0, left: 0, block: 'start' });
      setIsLoading(true);
      setFirstLoading(true);
      dispatch(
        searchPostAction(
          keyword,
          page,
          itemsPerPage,
          (_response) => {
            setIsLoading(false)
            setFirstLoading(false);
          },
          (_errors) => () => setIsLoading(false)
        )
      );
    }
  }, []);

  const navigateByLink = (postId, type) => {
    navigate(
      `${newPathUser}post${
        Boolean(type === 2) ? '' : Boolean(type === 3 || type === 4) ? '' : ''
      }/update/${postId}`
    );
  };

  const checkDisabledAction = (item) => {
    if (item?.is_followed === 0 && item?.follow_flag === 1 && dataUser?.id !== item?.user_id) {
      return true;
    }
    return false;
  };

  const handleLikePost = (post) => {
    dispatch(updatePostLikeDetail(post));
  };

  const handleFollowCreator = (dt) => dispatch(fanFollowCreator(dt?.user_id));

  const isMiniUrl = (item) => {
    return (
      (item?.is_followed === 0 && item?.follow_flag === 1 && item?.user_id !== dataUser?.id) ||
      item?.is_media_display === 0
    );
  };

  //(1 column) type 1 ,4
  return (
    <>
      {/* <PopupPaymentBySub /> */}
      <div className='search-short-video-page my-page-item' style={{ borderBottom: 'none' }}>
        {((!searchPostTimeline?.length && isLoading) || loadingClickSearch) && (
          <div>
            <ShimmerPostDetails />
          </div>
        )}
        {popupCopyLink && (
          <PopupSharePost
            isVisiblePopup={() => setPopupCopyLink(false)}
            data={popupCopyLink || {}}
          />
        )}
        {searchPostTimeline?.length > 0 && !loadingClickSearch ? (
          <InfiniteLoad
            className='two-column-video list-short-video'
            loading={false}
            data={searchPostTimeline}
            fetchNext={() => fetchNext()}>
            {searchPostTimeline.map((post, index) => (
              <div key={index} id={post?.id} className='col-6 cs-video short-video-item'>
                <div
                  className='align-items-center justify-content-center h-100'
                  style={{ position: 'relative', cursor: 'pointer' }}
                  onClick={() => {
                    dispatch(setIdScrollBackRecommend(`recomend`, pathname))
                    dispatch(getIDSearchPost({id: post?.id, tab: 1, keyword: keyword, page: page }))
                    navigate(`${newPathUser}post/${post?.id}`);
                  }}>
                  <div className='short-label-image'>
                    {
                      post?.medias_count > 1 ? (
                        <Image2Icon />
                      ) : (
                        (post?.media_preview?.type === 2 || post?.media_preview?.type === 4) && (
                          <Video2Icon  />
                        )
                      )
                    }
                  </div>
                  <div
                    className={`infor ${
                      post?.is_followed === 0 &&
                      post?.follow_flag === 1 &&
                      !isAdmin &&
                      dataUser?.id !== post?.user_id
                        ? 'follow-flag'
                        : ''
                    }`}>
                    <div
                      className='account-info'
                      onClick={(e) => {
                        e.stopPropagation();
                        dispatch(setIdScrollBackRecommend(`recomend`, pathname))
                        dispatch(getIDSearchPost({id: post?.id, tab: 1, keyword: keyword, page: page }))
                        navigate(`${newPathUser}${post?.account_id}`);
                      }}>
                      <ImgCustom
                        className='account-avatar'
                        isPlaceholderAvatar
                        screen='avatar_mini_28_28'
                        alt='avatar'
                        src={decryptPath(post?.avatar)}
                      />
                      <span className='account-name account-name-new'>{post?.account_name}</span>
                      {post?.duration !== null && (
                        <div
                          style={{
                            position: 'absolute',
                            bottom: `${isMobile ? '35px': '40px'}`,
                            right: '10px',
                            zIndex: '10',
                          }}>
                          <div className='d-middle' style={{ color: '#fff' }}>
                            <p
                              style={{ fontWeight: '500', marginBottom: '0', marginLeft: '35px' }}>
                              {msToTime(post?.duration) || 0}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className='actions-item'>
                      <div style={{ margin: '0' }} className='content'>
                        {post?.content}
                      </div>
                      <div className='actions-container'>
                        {!(
                          post?.is_followed === 0 &&
                          post?.follow_flag === 1 &&
                          !isAdmin &&
                          dataUser?.id !== post?.user_id
                        ) && (
                          <>
                            <div className='action-item'>
                              <HeartIcon
                                style={{
                                  zIndex: '2',
                                  color: post?.is_liked === 1 ? '#dc143c' : 'inherit',
                                }}
                                fill={post?.is_liked === 1 ? '#dc143c' : 'none'}
                                onClick={(e) => {
                                  if (!isAuth) {
                                    e.stopPropagation();
                                    dispatch(logUserProfile(post));
                                  } else {
                                    e.stopPropagation();
                                    !checkDisabledAction(post) && handleLikePost(post);
                                  }
                                }}
                                disabled={checkDisabledAction(post)}
                                className='cls-icon'
                              />

                              {formatNumber(post?.number_like) > 99
                                ? '99+'
                                : formatNumber(post?.number_like) || 0}
                            </div>
                            <div className='action-item'>
                              <CommentIcon
                                onClick={() => {
                                  dispatch(setIdScrollBackRecommend(`recomend`, pathname))
                                  dispatch(getIDSearchPost({id: post?.id, tab: 1, keyword: keyword, page: page}))
                                  navigate(`${newPathUser}post/${post?.id}`);
                                }}
                                className='cls-icon creator_post_live'
                              />
                              {formatNumber(post?.number_comment) > 99
                                ? '99+'
                                : formatNumber(post?.number_comment) || 0}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>

                  {post?.media_preview?.public_flag === 0 ? (
                    <img src={IMG_NO_WATER_MARK_MOBILE_2_COLUMN_SHORT_VIDEO} alt='' />
                  ) : (
                    <>
                      {post?.media_preview?.url ? (
                        <>
                          {post?.media_preview?.type === 2 ? (
                            <>
                              {post?.is_media_display === 0 && (
                                <PopupLockPostNormal
                                  is_3_column
                                  is_des
                                  post_id={post?.id}
                                  detailPost={post}
                                  //in_my_page
                                  point={dataUser?.points}
                                  // setIsOpen={setIsOpen}
                                  // setIdPackage={setIdPackage}
                                />
                              )}

                              {/* <StyledTimeLine.BlueImg
                                url={`${funcThumbnailIMG(
                                  decryptPath(post?.media_preview?.url, {
                                    isMini: true,
                                  })
                                )}`}
                                height={isMobile ? 163 : 223}
                                width={222}>
                                <div className='bg-img'></div> */}
                                <ImgCustom
                                  className='short-video-search cls-img'
                                  isMini={isMiniUrl(post)}
                                  screen={'image_preview_223_223'}
                                  src={isMiniUrl(post) ? funcThumbnailIMG(decryptPath(post?.media_preview?.url, { isMini: true })) : funcThumbnailIMG(decryptPath(post?.media_preview?.url))}
                                  alt=''
                                  style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                    //position: 'absolute',
                                    zIndex: '10',
                                    background: 'transparent',
                                  }}
                                  avatarErr={false}
                                />
                              {/* </StyledTimeLine.BlueImg> */}
                            </>
                          ) : (
                            <>
                              {post?.is_media_display === 0 && (
                                <PopupLockPostNormal
                                  is_3_column
                                  is_des
                                  post_id={post?.id}
                                  detailPost={post}
                                  //in_my_page
                                  point={dataUser?.points}
                                  // setIsOpen={setIsOpen}
                                  // setIdPackage={setIdPackage}
                                />
                              )}
                              {/* <StyledTimeLine.BlueImg
                                url={`${decryptPath(post?.media_preview?.url, {
                                  isMini: true,
                                })}`}
                                height={isMobile ? 163 : 223}
                                width={222}>
                                <div className='bg-img'></div> */}
                                <ImgCustom
                                  isMini={isMiniUrl(post)}
                                  screen={'image_preview_223_223'}
                                  src={
                                    post?.media_preview?.url
                                      ? post?.media_preview?.url
                                      : IMG_DEMO
                                  }
                                  alt=''
                                  style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                    //position: 'absolute',
                                    zIndex: '10',
                                    background: 'transparent',
                                  }}
                                  avatarErr={false}
                                />
                              {/* </StyledTimeLine.BlueImg> */}
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <ImgCustom
                            src={IMG_DEMO}
                            className='img-default'
                            onClick={() => navigateByLink(post?.id)}
                          />
                        </>
                      )}
                    </>
                  )}
                </div>
                {post?.is_followed === 0 && post?.follow_flag === 1 && !isAdmin ? (
                  <StyledTimeLine.WrapperBlur>
                    <div className='text-follow'>{t('DetailCard.notYetFollow')}</div>
                    <button
                      className='button-follow-search-short'
                      onClick={(e) => {
                        if (!isAuth) {
                          e.stopPropagation();
                          dispatch(logUserProfile(post));
                        } else {
                          e.stopPropagation();
                          handleFollowCreator(post);
                        }
                      }}>
                      {t('Common.follow')}
                    </button>
                  </StyledTimeLine.WrapperBlur>
                ) : (
                  ''
                )}
              </div>
            ))}
            {isLoading && !!searchPostTimeline.length && (
              <div className='loading-dots-custom'>
                <div className={`lds-ellipsis`}>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            )}
          </InfiniteLoad>
        ) : (
          !isLoading && (
            <div className='no-data'>
              <NoSearchList />
              {t('Search.listNull')}
            </div>
          )
        )}
      </div>
    </>
  );
};

export default withCreatorRole(SearchPost);
