import { useEffect, useState } from "react";
import { AVATAR_DEFAULT, AVATAR_DEL_USER, AVATAR_BLOCK_USER} from 'utils';

export function useAvartar(status) {
  const [imageStatus, setImageStatus] = useState(AVATAR_DEFAULT)
  useEffect(() => {
    const checkStatus = () => {
      switch (status) {
        case null: // del
          setImageStatus(AVATAR_DEL_USER);
          break;
        case 2: // stop
          setImageStatus(AVATAR_BLOCK_USER);
          break;
        default:
          setImageStatus(AVATAR_DEFAULT);
      }
    };

    checkStatus();
  }, [status]);

  return imageStatus
}
