import { Navigate, Outlet, useLocation } from 'react-router-dom';
import useCheckLoginAdminOrAgency from 'hooks/useCheckLoginAdminOrAgency';
import useUserRole from 'hooks/useUserRole';
import { STORAGE } from 'utils/constant';
import Storage from 'utils/storage';
import { USER_ROUTES } from 'routes';
import { differenceBy } from 'lodash';

const publicRoutes = [
  { path: '/ranking' },
  { path: '/search' },
  { path: '/list-short-video' },
  { path: '/:account_id' },
  { path: '/post/:id' },
  // { path: '/post-sale/:id' },
];
const routerHasParams = 2;

const ProtectedRoutes = () => {
  const location = useLocation();
  const isAuth = Storage.has(STORAGE.USER_ACCESS_TOKEN);
  const { isIdol } = useUserRole();
  const routesProtected = differenceBy(USER_ROUTES, publicRoutes, 'path');
  const arrPath = ['message', 'surveys', 'follow-list', 'follower-list', 'live', 'card', 'change-password', 'user']

  useCheckLoginAdminOrAgency();
  const pathCondition = location.pathname.split('/')?.length > routerHasParams;
  
  const hasRoute = () => {
    if (arrPath.includes(location.pathname.split('/')[1])) {
      return true;
    }
    //return routesProtected.some((route) => route.path === location.pathname || pathCondition);
    return routesProtected.some((route) => route.path === location.pathname);
  };

  return hasRoute() && !isAuth ? (
    <>
      {isIdol ? (
        <Navigate to={`/sign-in`} />
      ) : (
        <Navigate
          to={`/sign-in${
            location?.pathname?.includes('/post/') ? `?${location?.pathname}` : ''
          }`}
          state={{ pathname: location?.pathname }}
        />
      )}
    </>
  ) : (
    <Outlet />
  );
};

export default ProtectedRoutes;
