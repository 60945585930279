import { LayoutCenter } from 'components';
import { useTranslation } from 'react-i18next';
import { COMPLIANCE_NOTICE, COMPLIANCE_NOTICE_JP } from '../constant';
import './styles.scss';

const ComplianceNotice = () => {
  const { t, i18n } = useTranslation();
  return (
    <>
      {i18n.language === 'en' ? (
        <LayoutCenter
          titlePage={COMPLIANCE_NOTICE.title_page}
          notLogin={true}
          isShowLeftIcon={false}
          className={'title-page-creator page-legal'}
          uiMobile={true}>
          <div className={`container-fluid w-100 bg-white compliance-notice-page creator`}>
            <div className='title'>{COMPLIANCE_NOTICE?.title || ''}</div>
            <div className='content'>
              {COMPLIANCE_NOTICE?.desc || ''}
            </div>
            <a
              href={`mailto:info@share-gram.com`}
              target='_blank'
              rel='noreferrer'
              className='link'>
              {COMPLIANCE_NOTICE?.link || ''}
            </a>

            <div className='end-text'>{COMPLIANCE_NOTICE?.endtext1}</div>
            <div className='end-text'>{COMPLIANCE_NOTICE?.endtext2}</div>
          </div>
        </LayoutCenter>
      ) : (
        <LayoutCenter
          titlePage={COMPLIANCE_NOTICE_JP.title_page}
          notLogin={true}
          isShowLeftIcon={false}
          className={'title-page-creator page-legal'}
          uiMobile={true}>
          <div className={`container-fluid w-100 bg-white compliance-notice-page creator`}>
            <div className='title'>{COMPLIANCE_NOTICE_JP?.title || ''}</div>
            <div className='content'>
              {COMPLIANCE_NOTICE_JP?.desc || ''}
            </div>
            <a
              href={`mailto:info@share-gram.com`}
              target='_blank'
              rel='noreferrer'
              className='link'>
              {COMPLIANCE_NOTICE_JP?.link || ''}
            </a>

            <div className='end-text'>{COMPLIANCE_NOTICE_JP?.endtext1}</div>
            <div className='end-text'>{COMPLIANCE_NOTICE_JP?.endtext2}</div>
          </div>
        </LayoutCenter>
      )}
    </>
  );
};

export default ComplianceNotice;
