import {
  IconTypePost,
  ImgCustom,
  PopupSharePost,
  PopupConfirmFan,
  PopupLockPostNormal,
  PopupLoading,
} from 'components';
import InfiniteLoad from 'components/Paging/InfiniteLoad';
import { getEnv } from 'configs/env';
import useUserRole from 'hooks/useUserRole';
import {
  CommentIcon,
  Edit3Icon,
  FlagIcon,
  FlowUnFlowIcon,
  HeartIcon,
  IconBookmark,
  ReportIcon,
  ShareIcon,
  ThreePointIcon,
  TrashIcon,
  PlayIcon,
  DeleteRecoreIcon,
  UnFlowIcon,
  EyeV2Icon,
  HiddenV2Icon,
  GhimIcon,
  RePostIconV2,
  RePostIcon,
  CancelRetweetIcon,
  QuotedIcon,
  GhimPostIcon,
  CancelSussRetweetIcon,
  ThreePointIconV4,
  DelGhimIcon,
  SusessPackageIcon,
} from 'images';
import PropsType from 'prop-types';
import { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  creatorDeleteArticle,
  deleteCommentDetail,
  deleteReplyCommentDetail,
  fanFollowCreator,
  postLikeLive,
  updateLikeCommentDetail,
  updateDetailRecordingV2,
  hiddenPostMypage,
  postRetweet,
  postNormalPaymentBuyPackageSuccess,
  getListPostCreator,
  getIDHomePage,
  checkIsShortMypage,
  resetIDHomePage,
} from 'store/actions/usersNew';
import {
  convertToCurrentTime,
  formatNumber,
  IMG_NO_WATER_MARK_MOBILE,
  linkS3Video,
  isAdmin,
  decryptPath,
  linkS3VideoM3U8,
  renderTimeAfter24h,
  funcThumbnailIMG,
  isAgency,
} from 'utils/utils';
import img_no_water_mark_mobile from 'images/img_no_water_mark_mobile.jpg';
import img_no_water_mark_mobile_en from 'images/img_no_water_mark_mobile_en.jpg';
import img_no_water_mark_mobile_3_column from 'images/img_no_water_mark_3_column.jpg';
import img_no_water_mark_mobile_3_column_en from 'images/img_no_water_mark_3_column_en.jpg';
import img_no_water_mark_mobile_2_column_short_video_en from 'images/img_no_water_mark_mobile_2_column_short_video_en.jpg';
import img_no_water_mark_mobile_2_column_short_video from 'images/img_no_water_mark_mobile_2_column_short_video.jpg';
import img_no_water_mark_mobile_2_column_post_content_en from 'images/img_no_water_mark_mobile_2_column_post_content_en.jpg';
import img_no_water_mark_mobile_2_column_post_content from 'images/img_no_water_mark_mobile_2_column_post_content.jpg';
import sale_lable from 'images/sale.png';
import { AVATAR_DEFAULT, ENV, IMG_DEMO, LOGO_THUMBNAIL_COCOFAN, STORAGE } from '../../utils';
import './index.scss';
import { StyledDetailCard, StyledTimeLine } from './styles';
import VideoCustomDetail from 'pages/creator/layout/recording/VideoCustomDetail';
import Avatar from 'images/Avatar.png';
import i18n from 'translate/i18n';
import { hideCommentPost, logUserProfile } from 'store/actions/myPage';
import {
  resetDetailPost,
  postBookMark,
  ghimPostMypage,
  postFollowCommentDetail,
  postFollowReplyCommentDetail,
} from 'store/actions/usersNew';
import { newPathUser } from 'constants/layout/constant';
import PopupViewFullMess from 'components/Account/PopupViewFullMess';
import PopupViewFullShort from 'components/Account/timeline';
import ImgEmptyData from 'images/img-empty-data.png';
import { formatCoin } from 'utils';
import PopupViewFullTimeLineV2 from 'components/Account/PopupViewFullTimeLineV2';
import PopupRecordingDetailMyPage from 'components/Popup/recordingDetailMyPage';
import QuotePostMyPage from 'components/Popup/quotePostMyPage';
import { isMobile, isTablet } from 'react-device-detect';
import Storage from 'utils/storage';
import MarkDownRead from 'components/MarkDownRead';
import { usePopupPaymentSub } from 'hooks/v2/usePopupPaymentSub';
import { TimelinePost } from './elements';
import CommentForm from './elements/commentForm';
import { CommentPostTimeLineShowAll } from 'components/comment/commentListShowAll';
import { ContextTimeline, ProviderTimeline } from 'pages/timeline/contextTimeline';
import { useContext } from 'react';
import HideCommentIcon from 'images/HideCommentIcon';
import { clearIdAndDataHideComment } from 'store/actions/commentHide';
import { useFixedPopup } from 'hooks/v2/useFixedPopup';
import Blur_img from 'images/Blur_Right.png';

const CountComment = () => {
  const { countNumberView } = useContext(ContextTimeline);
  return formatNumber(countNumberView.countNumberComment);
};

const GroupIcon = ({
  postId,
  like,
  statusLike,
  item,
  item2,
  dataUser,
  index,
  checkDisabledAction,
  handleLikePost,
  isVisiblePopupGhim,
  handleRetweet,
  setIsVisiblePopupGhim,
  setItemPostPopup,
  setIsVisibleQuote,
  bookmarkChange,
  handleSharePost,
  list,
  page,
}) => {
  const isAuth = Storage.has(STORAGE.USER_ACCESS_TOKEN);
  const { isFan } = useUserRole();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const isCreator = useMemo(() => {
    return +dataUser?.id === +item?.user_id;
  }, [dataUser?.id, item?.user_id]);

  const checkIsShowButtonHideComment = useMemo(() => {
    return isCreator;
  }, [isCreator]);

  return (
    <div
      className={`block-img1 GroupIcon scroll-bar-custom-horizontal ${
        (isAdmin || isAgency) && 'disabled'
      }`}
      style={{
        pointerEvents: checkDisabledAction(item) ? 'none' : 'auto',
        color: checkDisabledAction(item) ? '#BEBEBE' : '#666565',
      }}>
      <div className='d-middle' style={{ gap: '26px' }}>
        <div className={`d-middle ${(isAdmin || isAgency) && 'disabled'}`}>
          <HeartIcon
            style={{
              outline: 'none',
              color: statusLike === 1 ? '#dc143c' : 'inherit',
            }}
            fill={statusLike === 1 ? '#dc143c' : 'none'}
            onClick={() => {
              if (!isAdmin && !isAgency) {
                if (!isAuth) {
                  dispatch(logUserProfile(item));
                } else {
                  !checkDisabledAction(item) && handleLikePost(item);
                }
              }
            }}
            disabled={checkDisabledAction(item)}
            className='cls-icon'
          />
          <span
            style={{ color: `${checkDisabledAction(item) ? '#BEBEBE' : '#666565'}` }}
            className='mb-0 cls-text'>
            {formatNumber(like)}
          </span>
        </div>

        <div className={`d-middle ${(isAdmin || isAgency) && 'disabled'}`}>
          <label
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            htmlFor={`comment-item-${postId}`}>
            <CommentIcon
              onClick={() => {
                dispatch(
                  getIDHomePage({
                    id: item2?.id,
                    tab: 1,
                    isMedia: true,
                    page: page,
                    type: 'post-normal',
                  })
                );
                navigate(`${newPathUser}post/${item?.id}`, {
                  state: {
                    isEditHomePage: true,
                  },
                });
              }}
              className='cls-icon creator_post_live'
            />
            <p
              style={{ color: `${checkDisabledAction(item) ? '#BEBEBE' : '#666565'}` }}
              className='mb-0 cls-text'>
              {/* <CountComment /> */}
              {item?.number_comment}
            </p>
          </label>
        </div>
        <div
          className={`d-middle div-ghim ${(isAdmin || isAgency) && 'disabled'}`}
          style={{
            pointerEvents: `${
              item2?.pin_share_enable === 0 || isAdmin || isAgency ? 'none' : 'auto'
            }`,
            color: `${
              item2?.pin_share_enable === 0 || isAdmin || isAgency ? '#BEBEBE' : '#666565'
            }`,
          }}>
          {checkDisabledAction(item) ? (
            <RePostIconV2 />
          ) : (
            <>
              {item?.is_share === 1 ? (
                <CancelSussRetweetIcon
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    if (!isAuth) {
                      dispatch(logUserProfile(item));
                    } else {
                      setIsVisiblePopupGhim(isVisiblePopupGhim === index ? -1 : index);
                    }
                  }}
                  onBlur={() => setIsVisiblePopupGhim(-1)}
                />
              ) : (
                <RePostIcon
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    if (!isAuth) {
                      dispatch(logUserProfile(item));
                    } else {
                      setIsVisiblePopupGhim(isVisiblePopupGhim === index ? -1 : index);
                    }
                  }}
                  onBlur={() => setIsVisiblePopupGhim(-1)}
                />
              )}
              {isVisiblePopupGhim === index && (
                <div className='popup-ghim'>
                  <div
                    className='item'
                    onMouseDown={() => {
                      handleRetweet(postId, {
                        share_type: 1,
                      });
                      setIsVisiblePopupGhim(-1);
                    }}>
                    <CancelRetweetIcon />
                    <span className='text'>
                      {item?.is_share === 0 ? t('Common.repost') : t('Common.Unrepost')}
                    </span>
                  </div>
                  <div
                    className='item'
                    onMouseDown={() => {
                      setItemPostPopup(item);
                      setIsVisibleQuote(true);
                      setIsVisiblePopupGhim(-1);
                    }}>
                    <QuotedIcon />
                    <span className='text'>{t('Common.QuotePost')}</span>
                  </div>
                </div>
              )}
            </>
          )}
          <span
            style={{
              pointerEvents: `${isAdmin || isAgency ? 'none' : 'auto'}`,
            }}
            className={`number ms-1 ${checkDisabledAction(item) && 'disabled'}`}>
            {item?.number_share ?? 0}
          </span>
        </div>
        <ShareIcon
          onClick={() => {
            if (!isAuth) {
              dispatch(logUserProfile(item));
            } else {
              handleSharePost({
                link: `${getEnv('REACT_APP_FAN_URL')}${newPathUser}post/${item?.id}`,
                title: list.title,
                id: item?.id,
              });
            }
          }}
          className={`cls-icon ${(isAdmin || isAgency) && 'disabled'}`}
        />
      </div>
      <div className='d-middle' style={{ marginRight: '-10px' }}>
        {checkIsShowButtonHideComment && (
          <HideCommentIcon
            disabled={checkDisabledAction(item)}
            className='btn-list-hide-comment'
            onClick={() => {
              dispatch(
                getIDHomePage({
                  id: item2?.id,
                  tab: 1,
                  isMedia: true,
                  page: page,
                  type: 'post-normal',
                })
              );
              dispatch(clearIdAndDataHideComment());
              navigate(`/comment-hide/${item?.id}`);
            }}
          />
        )}
        <IconBookmark
          style={{
            outline: 'none',
            color: item?.is_bookmarked === 1 ? '#dc143c' : 'inherit',
          }}
          fill={item?.is_bookmarked === 1 ? '#dc143c' : 'none'}
          onClick={() => {
            if (!isAuth) {
              dispatch(logUserProfile(item));
            } else {
              !checkDisabledAction(item) && bookmarkChange(item);
            }
          }}
          disabled={checkDisabledAction(item)}
          className='cls-icon mg-left-auto'
        />
      </div>
      {isFan && (
        <ReportIcon
          className='cls-icon share-icon'
          onClick={() => navigate('/post-report', { state: { post_id: postId } })}
        />
      )}
    </div>
  );
};
export const CardDetail = ({
  list,
  type = 1,
  fetchNext,
  chooseUrl = 0,
  isSearch = false,
  isPageLive = false,
  isMedia = false,
  loadingChangeTab,
  setPage,
  page,
  setCreatorProfile,
  creatorProfile,
  searchWord,
}) => {
  const isAuth = Storage.has(STORAGE.USER_ACCESS_TOKEN);
  const { account_id } = useParams();
  const location = useLocation();
  const { loading, detailCreator } = useSelector((state) => state.creator);
  const { isFan, isIdol } = useUserRole();
  const [popupCopyLink, setPopupCopyLink] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const widthScreen = window.innerWidth;
  const { dataUser, loading: loadingUser } = useSelector((state) => state.users);
  const { isLoadingFollowCreate, dataInfoOther, idHomePage, pagination } = useSelector(
    (state) => state.usersNew
  );
  const [isShowPopup, setShowPopup] = useState({
    status: false,
    dataItem: {},
  });
  const [checkSendChat, setCheckSendChat] = useState(null);
  const [valueComments, setValueComments] = useState(() =>
    Array.from({ length: 0 }, () => '')
  );
  const [isVisiblePopup, setIsVisiblePopup] = useState(-1);
  const [isShowPopupMess, setShowPopupMess] = useState({
    status: false,
    dataItemMess: {},
  });
  const [isShowPopupShort, setShowPopupShort] = useState({
    status: false,
    dataItemShort: {},
    indexIdx: 0,
  });
  const [popupDeleteComment, setPopupDeleteComment] = useState(null);
  const [popupHideComment, setPopupHideComment] = useState(null);
  const [isVisiblePopUpDeletePost, setIsVisiblePopDeletePost] = useState(null);
  const [isVisiblePopUpDeletePostComplete, setIsVisiblePopDeletePostComplete] =
    useState(false);
  const [isVisiblePopUpDeletePost1, setIsVisiblePopDeletePost1] = useState(null);
  const [isVisiblePopUpDeletePostComplete1, setIsVisiblePopDeletePostComplete1] =
    useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isVisibleDel, setIsVisibleDel] = useState(false);
  const [liveKey, setLiveKey] = useState();
  const [isVisibleQuote, setIsVisibleQuote] = useState(false);
  const [itemPostPopup, setItemPostPopup] = useState();
  const [isVisiblePopupGhim, setIsVisiblePopupGhim] = useState(-1);
  const [trickReloadComment, setTrickReloadComment] = useState(null);
  useFixedPopup(isVisibleQuote);
  const { idPackage, setIsOpen, setIdPackage, PopupPaymentBySub } = usePopupPaymentSub({
    urlRedirect: '',
    onClickSuccess: () => {
      dispatch(postNormalPaymentBuyPackageSuccess(idPackage));
    },
  });

  useEffect(() => {
    if (list.length > 0) {
      setValueComments(() => Array.from({ length: list.length }, () => ''));
    }
  }, [list.length]);

  const msToTime = (s) => {
    // Pad to 2 or 3 digits, default is 2
    var pad = (n, z = 2) => ('00' + n).slice(-z);
    return (
      pad((s / 3.6e6) | 0) +
      ':' +
      pad(((s % 3.6e6) / 6e4) | 0) +
      ':' +
      pad(((s % 6e4) / 1000) | 0)
    );
  };

  const navigateByLink = (postId, type) => {
    // return navigate(
    //   `${newPathUser}post${
    //     Boolean(type === 2) ? '-sale' : Boolean(type === 3 || type === 4) ? '' : ''
    //   }/update/${postId}`,
    //   {
    //     state: {
    //       isEditHomePage: true,
    //     },
    //   }
    // );
    return navigate(
      `${newPathUser}post/update/${postId}`,
      {
        state: {
          isEditHomePage: true,
        },
      }
    );
  };

  const handleSharePost = (data) => {
    setPopupCopyLink(data);
  };

  const listImgFunc = (data, item, itemParent) => {
    const listItemHasWaterMark = item?.medias && item?.medias[0]?.public_flag === 0;
    if (listItemHasWaterMark) {
      return <StyledDetailCard.Wrapper>{imageWaterMarkFunc(item)}</StyledDetailCard.Wrapper>;
    }
    return (
      <TimelinePost
        //tab={tab}
        data={data}
        setShowPopup={setShowPopup}
        isShowPopup={isShowPopup}
        item={item}
        itemParent={itemParent}
        searchWord={searchWord}
        page={page}
      />
    );
  };

  const handleLikePost = (post) => dispatch(postLikeLive(post));

  const handleRetweet = (id, data) => dispatch(postRetweet(id, data));

  useEffect(() => {
    if (idHomePage) {
      const idPost = document.getElementById(idHomePage.id);
      if (idPost) {
        idPost.scrollIntoView({
          block: 'center',
        });
      }
    }
  }, [idHomePage]);

  const checkDisabledAction = (item) => {
    if (item?.is_active === 0) return true;
    if (item?.post_status === 'reject' || item?.post_status === 'reviewing') return true;
    if (
      (item?.is_followed === 0 && item?.follow_flag === 1 && item?.user_id !== dataUser?.id) ||
      (item?.is_media_display === 0 && item?.type === 1) ||
      isAdmin ||
      isAgency
    ) {
      return true;
    }
    return false;
  };

  const funcDelPost = (item2) => {
    if (
      ((item2?.share_type === 1 || item2?.share_type === 2) && !item2?.share_post) ||
      (item2?.share_type === 1 &&
        !item2?.share_post?.share_post &&
        (item2?.share_post?.share_type === 2 || item2?.share_post?.share_type === 1))
    ) {
      return true;
    }
    return false;
  };

  const funcNoimgLinkPost = (item, itemParent) => {
    return (
      <div
        style={{ cursor: 'pointer' }}
        className={`block-repost-no-img-1 54 ${
          itemParent?.share_type === 1 &&
          itemParent?.share_post?.share_post &&
          itemParent?.share_post?.share_type === 2
            ? 'mt-0'
            : ' mt-2'
        }`}
        onClick={() => {
          dispatch(
            getIDHomePage({
              id: itemParent?.id,
              tab: 1,
              isMedia: true,
              page: page,
              type: 'post-normal',
            })
          );
          navigate(`${newPathUser}post/${item?.id}`, {
            state: {
              isEditHomePage: true,
              account_id,
            },
          });
        }}>
        <div className='d-flex' style={{ gap: '12px' }}>
          {item?.avatar ? (
            <ImgCustom
              onClick={(e) => {
                e.stopPropagation();
                navigate(`${newPathUser}${item?.account_id}`);
              }}
              style={{
                minWidth: 44,
                maxWidth: 44,
                minHeight: 44,
                maxHeight: 44,
                borderRadius: '50%',
                objectFit: 'cover',
                cursor: 'pointer',
              }}
              className='cls-img'
              screen='avatar_44_44'
              src={item.avatar}
            />
          ) : (
            <img width={44} height={44} src={AVATAR_DEFAULT} alt='AVATAR_DEFAULT' />
          )}
          <div
            className='wrap--text'
            onClick={(e) => {
              e.stopPropagation();
              navigate(`${newPathUser}${item?.account_id}`);
            }}>
            <p className='mb-0 cls-title'>{item?.account_name || 'no account name'}</p>
          </div>
        </div>
        <div
          className='mt-3'
          style={{
            whiteSpace: 'pre-wrap',
            margin: '0',
          }}>
          <MarkDownRead isMeta content={item?.content || ''} charLimit={isMobile ? 90 : 160} />
        </div>
        <div className='d-flex align-items-center mt-2'>
          <p
            className='cls-title-2nd cls-date'
            style={{ color: '#666565', marginTop: '0px', fontSize: '12px' }}>
            {convertToCurrentTime(item?.public_time || item?.created_at, 'YYYY/MM/DD HH:mm')}
          </p>
          &nbsp;
          {item?.unpublic_time && (
            <>
              <p
                className='cls-title-2nd cls-date ms-1'
                style={{
                  color: '#DC143C',
                  marginTop: '0px',
                  border: '1px solid',
                  padding: '0px 5px',
                }}>
                <span className='me-1'>{t('Common.publicDay')}</span>
                {convertToCurrentTime(item?.unpublic_time, 'YYYY/MM/DD HH:mm')}
              </p>
            </>
          )}
          {item?.in_24h_flag === 1 && (
            <>
              <p
                className='cls-title-2nd cls-date ms-1'
                style={{
                  color: '#DC143C',
                  marginTop: '0px',
                  border: '1px solid',
                  padding: '0px 5px',
                }}>
                <span className='me-1'>{t('Common.publicDay')}</span>
                {convertToCurrentTime(
                  renderTimeAfter24h(item?.public_time),
                  'YYYY/MM/DD HH:mm'
                )}
              </p>
            </>
          )}
        </div>
        <div
          className='mt-3 2'
          style={{
            whiteSpace: 'pre-wrap',
            margin: '0',
            fontWeight: '500',
          }}>
          {`${getEnv('REACT_APP_FAN_URL')}${newPathUser}post/${item?.id}`}
        </div>
      </div>
    );
  };

  const bookmarkChange = (post) => dispatch(postBookMark(post));

  const imageWaterMarkFunc = (item) => {
    return (
      <ImgCustom
        src={i18n.language === 'jp' ? img_no_water_mark_mobile : img_no_water_mark_mobile_en}
        title={`${item?.media_preview?.url}`}
        alt={item?.id}
        className='img_no_water_mark'
        screen={`${isMiniUrl(item) ? 'mini_32_auto_pc' : ''}`}
        onClick={() => {
          navigate(`${newPathUser}post/${item?.id}`, {
            state: {
              isEditHomePage: true,
              account_id,
            },
          });
        }}
        avatarErr={false}
      />
    );
  };

  const funcNoData = () => (
    <div
      className='d-flex align-items-center justify-content-center flex-column'
      style={{ marginBottom: '50px', color: '#b1b6bb' }}>
      <ImgCustom
        src={ImgEmptyData}
        alt='empty-data'
        style={{ backgroundColor: 'transparent', paddingBottom: '10px' }}
      />
      <div>{isSearch ? t('DetailCard.searchNoData') : t('DetailCard.noPost')}</div>
    </div>
  );
  const isMiniUrl = (item) => {
    return (
      (item?.is_followed === 0 && item?.follow_flag === 1 && item?.user_id !== dataUser?.id) ||
      item?.is_media_display === 0
    );
  };

  const isMiniUrlShortVideo = (item) => {
    return (
      item?.is_followed === 0 && item?.follow_flag === 1 && item?.user_id !== dataUser?.id
    );
  };
  /**
   *
   * Type 1 ,2
   */
  const renderImageToPostTab2 = (post) => {
    const item = post?.media_preview;
    return (
      <StyledTimeLine.BlueImg
        url={`${decryptPath(item?.url, { isMini: true })}`}
        height={isMobile ? 193 : 185}
        width={isMobile ? 343 : 326}>
        <div className='bg-img br-10'></div>
        <ImgCustom
          screen={`${isMobile ? 'post_183_auto_pc' : 'post_326_auto_pc'}`}
          src={item ? `${item?.url}` : account_id ? IMG_DEMO : IMG_NO_WATER_MARK_MOBILE}
          alt=''
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'contain',
            cursor: 'pointer',
            position: 'absolute',
            zIndex: '10',
            background: 'transparent',
          }}
          avatarErr={false}
          onClick={() => {
            dispatch(
              getIDHomePage({
                id: post?.id,
                tab: 2,
                isMedia: false,
                keySearch: searchWord,
                page: page,
                type: 'post-sales',
              })
            );
          }}
        />
      </StyledTimeLine.BlueImg>
    );
  };

  const renderImageToPostTab1 = (post) => {
    const item = post?.media_preview;

    let tmp = item?.url?.indexOf('.m3u8');
    if (item?.type !== 2) {
      return (
        <div
          className={`${isMobile ? 'item-post-card-mobile' : 'item-post-card'}`}
          style={{
            position: 'relative',
            width: '100%',
          }}>
          {post?.is_followed === 0 &&
          post?.follow_flag === 1 &&
          !isAdmin &&
          dataUser?.id !== dataInfoOther?.id ? (
            <StyledTimeLine.WrapperBlur>
              <div className='text-follow'>{t('DetailCard.notYetFollow')}</div>
              <button
                disabled={isAgency}
                onClick={(e) => {
                  if (!isAuth) {
                    e.stopPropagation();
                    dispatch(logUserProfile());
                  } else {
                    e.stopPropagation();
                    handleFollowCreator(post);
                  }
                }}>
                {t('Common.follow')}
              </button>
            </StyledTimeLine.WrapperBlur>
          ) : (
            ''
          )}
          {post?.follow_flag === 0 && post?.is_media_display === 0 && !isAdmin && (
            <PopupLockPostNormal
              is_3_column
              is_des
              post_id={post?.id}
              detailPost={post}
              point={dataUser?.points}
              setIsOpen={setIsOpen}
              setIdPackage={setIdPackage}
            />
          )}
          <ImgCustom
            screen={`${isMobile ? 'image_preview_171_171' : 'image_preview_223_223'}`}
            isMini={isMiniUrl(post)}
            src={item ? `${item?.url}` : IMG_DEMO}
            alt={item?.id}
            className={`${!account_id ? 'cls-img' : item ? 'cls-img' : 'img-default'}`}
            avatarErr={false}
            onClick={() => {
              dispatch(
                getIDHomePage({
                  id: post?.id,
                  tab: type,
                  isMedia: isMedia,
                  keySearch: searchWord,
                  page: page,
                  type: 'post-normal',
                })
              );
              navigate(`${newPathUser}post/${post?.id}`, {
                state: {
                  isEditHomePage: true,
                  account_id,
                },
              });
            }}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              zIndex: '10',
              background: 'transparent',
            }}
          />
        </div>
      );
    } else if (tmp === -1) {
      return (
        <div
          className={`${isMobile ? 'item-post-card-mobile' : 'item-post-card'}`}
          style={{
            position: 'relative',
            width: '100%',
            overflow: 'hidden',
          }}>
          {post?.is_followed === 0 &&
          post?.follow_flag === 1 &&
          !isAdmin &&
          dataUser?.id !== dataInfoOther?.id ? (
            <StyledTimeLine.WrapperBlur>
              <div className='text-follow'>{t('DetailCard.notYetFollow')}</div>
              <button
                disabled={isAgency}
                onClick={(e) => {
                  if (!isAuth) {
                    e.stopPropagation();
                    dispatch(logUserProfile());
                  } else {
                    e.stopPropagation();
                    handleFollowCreator(post);
                  }
                }}>
                {t('Common.follow')}
              </button>
            </StyledTimeLine.WrapperBlur>
          ) : (
            ''
          )}
          {post?.follow_flag === 0 && post?.is_media_display === 0 && !isAdmin && (
            <PopupLockPostNormal
              is_3_column
              is_des
              post_id={post?.id}
              detailPost={post}
              point={dataUser?.points}
              setIsOpen={setIsOpen}
              setIdPackage={setIdPackage}
            />
          )}
          <video
            style={{ objectFit: 'cover' }}
            src={`${linkS3Video}${decryptPath(item?.url) + '#t=0.1'}`}
            preload='none'
            poster={`${isMiniUrl(post) ? funcThumbnailIMG(decryptPath(item?.url, { isMini: true })) + '#t=0.1' : funcThumbnailIMG(decryptPath(item?.url)) + '#t=0.1'}`}
            alt=''
            className={'cls-img'}
            onClick={() => {
              dispatch(
                getIDHomePage({
                  id: post?.id,
                  tab: type,
                  isMedia: isMedia,
                  keySearch: searchWord,
                  page: page,
                  type: 'post-normal',
                })
              );
              navigate(`${newPathUser}post/${post?.id}`, {
                state: {
                  isEditHomePage: true,
                  account_id,
                },
              });
            }}
          />
        </div>
      );
    } else if (tmp !== -1) {
      return (
        <div
          className={`${isMobile ? 'item-post-card-mobile' : 'item-post-card'}`}
          style={{
            position: 'relative',
            width: '100%',
            background: '#1a1a1a',
          }}>
          {post?.is_followed === 0 && post?.follow_flag === 1 && !isAdmin ? (
            <StyledTimeLine.WrapperBlur>
              <div className='text-follow'>{t('DetailCard.notYetFollow')}</div>
              <button
                disabled={isAgency}
                onClick={(e) => {
                  if (!isAuth) {
                    e.stopPropagation();
                    dispatch(logUserProfile());
                  } else {
                    e.stopPropagation();
                    handleFollowCreator(post);
                  }
                }}>
                {t('Common.follow')}
              </button>
            </StyledTimeLine.WrapperBlur>
          ) : (
            ''
          )}
          {post?.follow_flag === 0 && post?.is_media_display === 0 && !isAdmin && (
            <PopupLockPostNormal
              is_3_column
              is_des
              post_id={post?.id}
              detailPost={post}
              point={dataUser?.points}
              setIsOpen={setIsOpen}
              setIdPackage={setIdPackage}
            />
          )}
          <VideoCustomDetail
            src={`${linkS3VideoM3U8}${decryptPath(item?.url) + '#t=0.1'}`}
            className={'cls-img'}
            handleNavigate={() => navigateByLink(post?.id)}
            noThumbnail
          />
        </div>
      );
    }
  };
  //(2 column)
  const twoColumn =
    list?.length > 0 ? (
      <InfiniteLoad
        className='row two-column d-flex'
        loading={loading}
        data={list}
        fetchNext={() => fetchNext()}>
        {list.map((post, index) => (
          <div
            key={index}
            id={post?.id}
            className='col-6 cs-col mobile-tab3 style-mobile'
            style={{
              marginBottom: `${isTablet ? '115px' : ''}`,
            }}>
            <div
              className='align-items-center justify-content-center'
              onClick={() => {
                dispatch(
                  getIDHomePage({
                    id: post?.id,
                    tab: 2,
                    isMedia: false,
                    keySearch: searchWord,
                    page: page,
                    type: 'post-sales',
                  })
                );
                return navigate(`${newPathUser}post/${post?.id}`, {
                  state: {
                    isEditHomePage: true,
                    account_id,
                    pathname: `${location.pathname}${location.search}`,
                  },
                });
              }}
              style={{
                position: 'relative',
                borderTopLeftRadius: '10px',
                borderTopRightRadius: '10px',
                overflow: 'hidden',
                cursor: 'pointer',
                aspectRatio: '16/9',
              }}>
              {post?.media_preview?.public_flag === 0 ? (
                <img
                  width='100%'
                  height='100%'
                  src={
                    i18n.language === 'jp'
                      ? img_no_water_mark_mobile_2_column_post_content
                      : img_no_water_mark_mobile_2_column_post_content_en
                  }
                  alt=''
                />
              ) : (
                <>
                  {post?.media_preview?.url ? (
                    <>
                      {renderImageToPostTab2(post)}
                      {post?.price_discount && (
                        <>
                          <img
                            src={sale_lable}
                            width={'68'}
                            height={'44'}
                            alt='sale'
                            style={{
                              position: 'absolute',
                              top: '8px',
                              left: '0px',
                              zIndex: '20',
                            }}
                          />
                          <div className='content-sale'>
                            <p>{t('PostDetail.sale')}</p>
                            <p className='d-flex justify-content-center'>-{post?.discount}%</p>
                          </div>
                        </>
                      )}
                      {post?.post_status && post?.post_status !== 'public' && (
                        <div className='post-status'>
                          <label
                            className={`${post?.post_status}-status`}
                            htmlFor={`comment-item-${post?.id}`}>
                            {t(`DetailCard.${post?.post_status}`)}
                          </label>
                        </div>
                      )}
                      {post?.creator_only === 1 && (
                        <div
                          className={`post-creator ${
                            post?.post_status && post?.post_status !== 'public' && 'have-top'
                          }`}>
                          <label
                            className='creator-status'
                            htmlFor={`comment-item-${post?.id}`}>
                            {t(`DetailCard.onlyCreator`)}
                          </label>
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      <ImgCustom
                        src={IMG_DEMO}
                        className='img-default'
                        onClick={() => navigateByLink(post?.id)}
                      />
                    </>
                  )}
                </>
              )}
              {post?.media_preview?.public_flag !== 0 && (
                <div
                  className={`wrap--text1 ${isAdmin && 'disabled'}`}
                  onClick={() => {
                    if (!isAuth) {
                      dispatch(logUserProfile());
                    } else {
                      dispatch(
                        getIDHomePage({
                          id: post?.id,
                          tab: 2,
                          isMedia: false,
                          page: page,
                          type: 'post-sales',
                        })
                      );
                    }
                  }}
                  style={{
                    position: 'absolute',
                    bottom: '0px',
                    left: '0px',
                    zIndex: '10',
                  }}>
                  <div className='d-middle'>
                    <HeartIcon
                      style={{
                        outline: 'none',
                        color: post?.is_liked === 1 ? '#dc143c' : 'inherit',
                        cursor:
                          (post?.is_active === 0 || post?.is_active === 2) && 'not-allowed',
                      }}
                      fill={post?.is_liked === 1 ? '#dc143c' : 'none'}
                      onClick={(e) => {
                        if (!isAuth) {
                          e.stopPropagation();
                          dispatch(logUserProfile(post));
                        } else if (post?.is_active === 0 || post?.is_active === 2) {
                          e.stopPropagation();
                        } else {
                          e.stopPropagation();
                          !checkDisabledAction(post) && handleLikePost(post);
                        }
                      }}
                      disabled={checkDisabledAction(post)}
                      className='cls-icon'
                    />
                    <p className='mb-0 cls-text' style={{ color: '#fff' }}>
                      {formatNumber(post?.number_like)}
                    </p>
                  </div>
                  <div className='d-middle'>
                    <label
                      onClick={() => !isAuth && dispatch(logUserProfile())}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                      htmlFor={`comment-item-${post?.id}`}>
                      <CommentIcon
                        style={{
                          marginTop: '0px',
                          cursor:
                            (post?.is_active === 0 || post?.is_active === 2) && 'not-allowed',
                        }}
                        onClick={(e) => {
                          if (post?.is_active === 0 || post?.is_active === 2) {
                            e.stopPropagation();
                          } else
                            dispatch(
                              getIDHomePage({
                                id: post?.id,
                                tab: 2,
                                isMedia: false,
                                keySearch: searchWord,
                                page: page,
                                type: 'post-sales',
                              })
                            );
                        }}
                        className='cls-icon creator_post_live'
                      />
                      <p className='mb-0 cls-text' style={{ color: '#fff' }}>
                        {formatNumber(post?.number_comment) || 0}
                      </p>
                    </label>
                  </div>
                  <ShareIcon
                    style={{
                      cursor:
                        (post?.is_active === 0 || post?.is_active === 2) && 'not-allowed',
                    }}
                    onClick={(e) => {
                      if (!isAuth) {
                        e.stopPropagation();
                        dispatch(logUserProfile(post));
                      } else if (post?.is_active === 0 || post?.is_active === 2) {
                        e.stopPropagation();
                      } else {
                        e.stopPropagation();
                        handleSharePost({
                          link: `${getEnv('REACT_APP_FAN_URL')}${newPathUser}post/${
                            post?.id
                          }`,
                          title: post.title,
                          id: post?.id,
                        });
                      }
                    }}
                    className='cls-icon'
                  />
                  {post?.duration !== null && (
                    <div
                      style={{
                        position: 'absolute',
                        bottom: '10px',
                        right: '30px',
                        zIndex: '10',
                      }}>
                      <div className='d-middle' style={{ color: '#fff' }}>
                        <p
                          style={{ fontWeight: '500', marginBottom: '0', marginLeft: '35px' }}>
                          {msToTime(post?.duration) || 0}
                        </p>
                      </div>
                    </div>
                  )}
                  {post?.is_active === 1 && post?.user_id === dataUser?.id && (
                    <div
                      className='d-flex w-100 justify-content-end me-2'
                      style={{ marginTop: '10px' }}>
                      <ThreePointIconV4
                        className='three-point-icon'
                        onClick={(e) => {
                          e.stopPropagation();
                          setIsVisiblePopup(isVisiblePopup === post?.id ? -1 : post?.id);
                        }}
                        onBlur={() => setIsVisiblePopup(-1)}
                      />
                      {isVisiblePopup === post?.id && (
                        <div
                          className='popup-edit-comment'
                          style={{ zIndex: '10000', top: '-25px', right: '5px' }}>
                          <div
                            className='item'
                            style={{ borderRadius: '0px' }}
                            onMouseDown={() => {
                              if (!isAuth) {
                                setIsVisiblePopup(-1);
                                dispatch(logUserProfile(post));
                              } else {
                                handleHiddenPost(post);
                                setIsVisiblePopup(-1);
                              }
                            }}>
                            {post?.is_hided === 0 ? <EyeV2Icon /> : <HiddenV2Icon />}
                            <span className='text'>
                              {post?.is_hided === 0
                                ? t('Common.hiddenPost')
                                : t('Common.openPost')}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>

            <div className='wrap--text2'>
              <div className='d-flex justify-content-between'>
                {post?.content ? (
                  <p className='mb-0 cls-title-2nd2' style={{ margin: 0, height: '34px', marginTop: '10px' }}>
                    <MarkDownRead content={post?.content} />
                  </p>
                ) : (
                  <p
                    style={{
                      width: '60%',
                      color: 'transparent',
                      textIndent: '-9999px',
                      height: 0,
                      overflow: 'hidden',
                      marginTop: '15px'
                    }}
                  >
                    no data
                  </p>
                )}
                <div className='d-flex' style={{ gap: '20px' }}>
                  {post?.is_media_display === 1 && dataUser?.id !== dataInfoOther?.id && (
                    <div style={{ marginTop: '12px', marginRight: '-10px' }}>
                      <SusessPackageIcon />
                    </div>
                  )}
                  <div
                    className={`d-middle ${isAdmin && 'disabled'}`}
                    style={{ marginRight: '-10px' }}>
                    <IconBookmark
                      style={{
                        color: post?.is_bookmarked === 1 ? '#dc143c' : '#666565',
                        cursor: checkDisabledAction(post) ? 'default' : 'pointer',
                      }}
                      fill={post?.is_bookmarked === 1 ? '#dc143c' : 'none'}
                      onClick={() => {
                        if (!isAuth) {
                          dispatch(logUserProfile(post));
                        } else {
                          !checkDisabledAction(post) && bookmarkChange(post);
                        }
                      }}
                      disabled={checkDisabledAction(post)}
                      className='cls-icon mg-left-auto'
                    />
                  </div>
                </div>
              </div>

              <div className='d-flex w-100 mb-1 mt-1 align-items-center'>
                <div className='text-label'>{t('Purchase.point')}: </div>
                {post?.price !== null || post?.price_discount !== null ? (
                  <>
                    <p className='point' style={{ marginBottom: '0' }}>
                      {post?.price_discount
                        ? formatCoin(post?.price_discount)
                        : formatCoin(post?.price) || 0}
                      {i18n.language === 'en' && ' '}
                      Pt
                    </p>
                    {post?.price_discount && (
                      <p className='point-price' style={{ marginBottom: '0px' }}>
                        {formatCoin(post?.price)} {i18n.language === 'en' && ' '}Pt
                      </p>
                    )}
                  </>
                ) : (
                  '-'
                )}
              </div>
              <div className='d-flex w-100'>
                <div className='text-label'>{t('Purchase.premium')}: </div>
                {post?.number_premium_ticket ? (
                  <p className='point'>
                    {formatCoin(post?.number_premium_ticket) || 0}
                    {i18n.language === 'en' && ' '}
                    {t('Creator.PostArticle.postContent.afterContentCondition')}
                  </p>
                ) : (
                  '-'
                )}
              </div>
            </div>
          </div>
        ))}
      </InfiniteLoad>
    ) : (
      !loading && funcNoData()
    );

  //(2 column) short video
  const twoColumnVideo =
    list?.length > 0 ? (
      <InfiniteLoad
        className='row two-column-video d-flex w-100 three-column'
        loading={loading}
        data={list}
        fetchNext={() => fetchNext()}>
        {list.map((post, index) => (
          <div
            key={index}
            id={post?.id}
            className='col-6 cs-video'
            style={{ height: '604px', position: 'relative' }}>
            <div
              className='align-items-center justify-content-center h-100'
              style={{ position: 'relative', cursor: 'pointer' }}
              onClick={() => {
                dispatch(checkIsShortMypage({ ishow: true }));
                dispatch(
                  getIDHomePage({
                    id: post?.id,
                    tab: 4,
                    isMedia: false,
                    page: page,
                    type: 'post-shorts',
                  })
                );
                setShowPopupShort({
                  ...isShowPopupShort,
                  status: true,
                  dataItemShort: post,
                  indexIdx: index,
                });
              }}>
              {post?.media_preview?.public_flag === 0 ? (
                <img
                  width='100%'
                  height='100%'
                  src={
                    i18n.language === 'jp'
                      ? img_no_water_mark_mobile_2_column_short_video
                      : img_no_water_mark_mobile_2_column_short_video_en
                  }
                  alt=''
                />
              ) : (
                <>
                  {post?.media_preview?.url ? (
                    <>
                      {post?.media_preview?.type === 2 ? (
                        <ImgCustom
                          isMini={isMiniUrlShortVideo(post)}
                          screen={`${isMobile ? 'post_343_auto_pc' : 'post_355_auto_pc'}`}
                          src={isMiniUrlShortVideo(post) ? funcThumbnailIMG(decryptPath(post?.media_preview?.url, true)) : funcThumbnailIMG(decryptPath(post?.media_preview?.url))}
                          alt=''
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'contain',
                            position: 'absolute',
                            zIndex: '10',
                            background: '#1a1a1a',
                          }}
                          avatarErr={false}
                        />
                      ) : (
                        <ImgCustom
                          isMini={isMiniUrlShortVideo(post)}
                          screen={`${isMobile ? 'post_343_auto_pc' : 'post_355_auto_pc'}`}
                          src={post?.media_preview?.url ? post?.media_preview?.url : IMG_DEMO}
                          alt=''
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'contain',
                            position: 'absolute',
                            zIndex: '10',
                            background: '#1a1a1a',
                          }}
                          avatarErr={false}
                        />
                      )}
                      {post?.post_status && post?.post_status !== 'public' && (
                        <div className='post-status'>
                          <label
                            className={`${post?.post_status}-status`}
                            htmlFor={`comment-item-${post?.id}`}>
                            {t(`DetailCard.${post?.post_status}`)}
                          </label>
                        </div>
                      )}
                      {post?.creator_only === 1 && (
                        <div
                          className={`post-creator ${
                            post?.post_status && post?.post_status !== 'public' && 'have-top'
                          }`}>
                          <label
                            className='creator-status'
                            htmlFor={`comment-item-${post?.id}`}>
                            {t(`DetailCard.onlyCreator`)}
                          </label>
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      <ImgCustom
                        src={IMG_DEMO}
                        className='img-default'
                        onClick={() => navigateByLink(post?.id)}
                      />
                    </>
                  )}
                </>
              )}
              {post?.media_preview?.public_flag !== 0 && (
                <div className='button-play'>
                  <PlayIcon />
                </div>
              )}
            </div>
            {post?.is_followed === 0 &&
            post?.follow_flag === 1 &&
            !isAdmin &&
            dataUser?.id !== dataInfoOther?.id ? (
              <StyledTimeLine.WrapperBlur>
                <div className='text-follow'>{t('DetailCard.notYetFollow')}</div>
                <button
                  disabled={isAgency}
                  onClick={(e) => {
                    if (!isAuth) {
                      e.stopPropagation();
                      dispatch(logUserProfile(post));
                    } else {
                      e.stopPropagation();
                      handleFollowCreator(post);
                    }
                  }}>
                  {t('Common.follow')}
                </button>
              </StyledTimeLine.WrapperBlur>
            ) : (
              ''
            )}
            {post?.media_preview?.public_flag !== 0 && (
              <div className='block-info' style={{ zIndex: '19' }}>
                <div
                  className='avatar'
                  onClick={() => navigate(`${newPathUser}${post?.account_id}`)}>
                  {dataInfoOther?.avatar ? (
                    <ImgCustom
                      screen='avatar_44_44'
                      src={dataInfoOther?.avatar}
                      alt='avatar'
                    />
                  ) : (
                    <img width={44} height={44} src={AVATAR_DEFAULT} alt='AVATAR_DEFAULT' />
                  )}
                  <span className='name'>{dataInfoOther?.account_name}</span>
                  {/* {post?.is_active === 0 ? (
                    <div className='ms-1 mb-1'>
                      <WaitApprovePostWhiteIcon />
                    </div>
                  ) : post?.is_active === 2 ? (
                    <div className='ms-1 mb-1'>
                      <LockedIcon1 />
                    </div>
                  ) : (
                    post?.is_hided === 1 && (
                      <div className='ms-1 mb-1'>
                        <HiddenShortVideo />
                      </div>
                    )
                  )} */}
                </div>
                <div
                  className='content'
                  style={
                    post?.content?.length > 180 ? { overflow: 'auto' } : { overflow: 'hidden' }
                  }>
                  <div className='text'>
                    <MarkDownRead
                      charLimit={widthScreen > 575 ? 35 : 25}
                      content={post?.content}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
      </InfiniteLoad>
    ) : (
      !loading && funcNoData()
    );

  //(2 column) Regular
  const twoColumnRegular =
    list?.length > 0 ? (
      <InfiniteLoad loading={loading} data={list} fetchNext={() => fetchNext()}>
        <div className={`fan-view-on-timeline `}>
          {list
            .filter((i) => i?.is_show === 1)
            ?.map((post) => (
              <>
                <div className='item' key={post?.id}>
                  <div
                    className='img-wrapper'
                    onClick={() => {
                      setLiveKey(post);
                      setIsVisible(true);
                    }}>
                    {!post?.img_background || decryptPath(post?.img_background) === 'NULL' ? (
                      <img src={LOGO_THUMBNAIL_COCOFAN} alt='' />
                    ) : (
                      <StyledTimeLine.BlueImg
                        url={`${decryptPath(post?.img_background, { isMini: true })}`}
                        height={isMobile ? 171 : 223}
                        width={isMobile ? 330 : 330}>
                        <div className='bg-img'></div>
                        <ImgCustom
                          screen={
                            isMiniUrl(post)
                              ? 'mini_32_auto_pc'
                              : `${isMobile ? 'post_343_auto_pc' : 'post_330_auto_pc'}`
                          }
                          src={post?.img_background ? post?.img_background : IMG_DEMO}
                          alt=''
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'contain',
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            zIndex: '10',
                            background: 'transparent',
                          }}
                          avatarErr={false}
                        />
                      </StyledTimeLine.BlueImg>
                    )}
                    {post?.img_background && (
                      <div className='button-play'>
                        <PlayIcon />
                      </div>
                    )}
                    {post?.is_followed === 0 && post?.follow_flag === 1 && !isAdmin ? (
                      <StyledTimeLine.WrapperBlur>
                        <div className='text-follow'>{t('DetailCard.notYetFollow')}</div>
                        <button
                          disabled={isAgency}
                          onClick={(e) => {
                            if (!isAuth) {
                              e.stopPropagation();
                              dispatch(logUserProfile());
                            } else {
                              e.stopPropagation();
                              handleFollowCreator(post);
                            }
                          }}>
                          {t('Common.follow')}
                        </button>
                      </StyledTimeLine.WrapperBlur>
                    ) : (
                      ''
                    )}
                    {post?.user_id === dataUser?.id && (
                      <DeleteRecoreIcon
                        className='bookmark-label1'
                        onClick={(e) => {
                          e.stopPropagation();
                          setIsVisiblePopDeletePost1({ id: post?.id });
                          setIsVisibleDel(true);
                        }}
                      />
                    )}
                  </div>
                  <div className='content-recore'>{post?.live_title}</div>
                  <div className='action-post'>
                    <div className='time-post'>{convertToCurrentTime(post.live_date)}</div>
                    <div className='button-wrapper'>{post?.duration}</div>
                  </div>
                </div>
              </>
            ))}
        </div>
      </InfiniteLoad>
    ) : (
      !loading && funcNoData()
    );

  const handleLeftIcon = (item) => {
    if (item?.type === 1 && item?.media_preview) {
      return item?.medias_count > 1 ? (
        <IconTypePost className='customPosition' type='image' />
      ) : (
        item?.medias?.filter((i) => i.type === 2)?.length > 0 && (
          <IconTypePost className='customPosition' type='video' />
        )
      );
    }
  };

  // DELETE COMMENT
  const handleDeleteComment = () => {
    const { id, type, idParent, cb = () => {}, postId } = popupDeleteComment;
    type === 'comment'
      ? dispatch(deleteCommentDetail(id, postId || null, cb))
      : dispatch(deleteReplyCommentDetail(id, postId || null, cb));
    setPopupDeleteComment(false);
  };
  // HIDE COMMENT
  const handleHideComment = () => {
    const { id, cb = () => {} } = popupHideComment;
    dispatch(hideCommentPost(id, cb));
    setPopupHideComment(false);
  };

  //threeColumn
  const listFillter = list && list?.filter((post) => post?.media_preview !== null);
  const threeColumn =
    listFillter?.length > 0 ? (
      <InfiniteLoad
        className={`three-col ${
          isMobile && 'd-flex justify-content-start'
        } row three-column d-flex`}
        style={{ gap: '2px' }}
        loading={loading}
        data={listFillter}
        fetchNext={() => fetchNext()}>
        {listFillter.map((post, index) => (
          <div
            key={index}
            id={post?.id}
            style={{ position: 'relative' }}
            className={`${isMobile ? 'col-6 mobile-3 cls-card 3_column' : 'col-4 cls-card'}`}>
            {post?.media_preview?.public_flag === 0 ? (
              <img
                onClick={() => navigate(`${newPathUser}post/${post?.id}`)}
                width='100%'
                height='100%'
                src={
                  i18n.language === 'jp'
                    ? img_no_water_mark_mobile_3_column
                    : img_no_water_mark_mobile_3_column_en
                }
                alt=''
              />
            ) : (
              <>
                {post?.media_preview?.url ? (
                  type === 2 ? (
                    renderImageToPostTab2(post)
                  ) : (
                    renderImageToPostTab1(post)
                  )
                ) : (
                  <>
                    <ImgCustom
                      src={IMG_DEMO}
                      className='img-default'
                      onClick={() => navigateByLink(post?.id)}
                    />
                  </>
                )}
              </>
            )}
            {post?.media_preview?.public_flag !== 0 && (
              <>
                <div className='type'>{handleLeftIcon(post)}</div>
                {post?.post_status && post?.post_status !== 'public' && (
                  <div className='post-status'>
                    <label
                      className={`${post?.post_status}-status`}
                      htmlFor={`comment-item-${post?.id}`}>
                      {t(`DetailCard.${post?.post_status}`)}
                    </label>
                  </div>
                )}
                {post?.creator_only === 1 && (
                  <div
                    className={`post-creator ${
                      post?.post_status && post?.post_status !== 'public' && 'have-top'
                    }`}>
                    <label className='creator-status' htmlFor={`comment-item-${post?.id}`}>
                      {t(`DetailCard.onlyCreator`)}
                    </label>
                  </div>
                )}
                <div className='blue-div'>
                  <div
                    className='mt-3'
                    style={{ position: 'absolute', zIndex: '10', bottom: '36px' }}>
                    <span
                      className='text-mobile'
                      style={{
                        display: 'block',
                        color: '#fff',
                        maxWidth: '200px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}>
                      {post?.content}
                    </span>
                  </div>
                  <div
                    style={{
                      position: 'absolute',
                      bottom: '10px',
                      left: '10px',
                      zIndex: '10',
                      cursor: `${(isAdmin || isAgency) && 'default'}`,
                    }}>
                    <div
                      className={`d-middle ${(isAdmin || isAgency) && 'disabled'}`}
                      style={{ gap: `${isMobile ? '5px' : '10px'}` }}>
                      <div
                        className={`d-middle ${(isAdmin || isAgency) && 'disabled'}`}
                        style={{ color: '#fff' }}>
                        <HeartIcon
                          style={{
                            color: post?.is_liked === 1 ? '#dc143c' : 'inherit',
                            cursor:
                              (post?.is_active === 0 || post.is_active === 2) && 'not-allowed',
                          }}
                          fill={post?.is_liked === 1 ? '#dc143c' : 'none'}
                          onClick={() => {
                            if (!isAdmin && !isAgency) {
                              if (post?.is_active === 0 || post.is_active === 2) {
                              } else if (!isAuth) {
                                dispatch(logUserProfile());
                              } else {
                                handleLikePost(post);
                              }
                            }
                          }}
                          className='cls-icon'
                        />
                        <p className='mb-0 cls-text' style={{ color: '#fff' }}>
                          {formatNumber(post?.number_like)}
                        </p>
                      </div>

                      <div className='d-middle' style={{ color: '#fff' }}>
                        <label
                          style={{ display: 'flex' }}
                          htmlFor={`comment-item-${post?.id}`}>
                          <CommentIcon
                            onClick={() => {
                              if (post?.is_active === 0 || post.is_active === 2) {
                              } else if (!isAuth) {
                                dispatch(logUserProfile());
                              } else {
                                dispatch(
                                  getIDHomePage({
                                    id: post?.id,
                                    tab: type,
                                    isMedia: isMedia,
                                    keySearch: searchWord,
                                    page: page,
                                    type: 'post-normal',
                                  })
                                );
                                navigate(`${newPathUser}post/${post?.id}`, {
                                  state: {
                                    isEditHomePage: true,
                                    account_id,
                                  },
                                });
                              }
                            }}
                            style={{
                              marginTop: '2px',
                              cursor:
                                (post?.is_active === 0 || post.is_active === 2) &&
                                'not-allowed',
                            }}
                            className='cls-icon creator_post_live'
                          />
                          <p className='mb-0 cls-text' style={{ color: '#fff' }}>
                            {formatNumber(post?.number_comment) || 0}
                          </p>
                        </label>
                      </div>
                    </div>
                  </div>
                  {post?.duration !== null && (
                    <div
                      style={{
                        position: 'absolute',
                        bottom: '10px',
                        right: '10px',
                        zIndex: '10',
                      }}>
                      <div className='d-middle' style={{ color: '#fff' }}>
                        <p
                          style={{ fontWeight: '500', marginBottom: '0', marginLeft: '35px' }}>
                          {msToTime(post?.duration) || 0}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        ))}
      </InfiniteLoad>
    ) : (
      !loading && funcNoData()
    );

  /**
   *
   * Type 3 ,4
   */
  const renderImgOrVideoType4 = (listImgOrVideo, item) => {
    if (listImgOrVideo?.type === 2) {
      // video
      return (
        <StyledTimeLine.Wrapper
          style={{ marginTop: `${item?.share_type === 2 && '0px'}` }}
          onClick={() => {
            navigate(`${newPathUser}post/${item?.id}`, {
              state: {
                isEditHomePage: true,
                account_id,
              },
            });
          }}>
          <StyledTimeLine.BlueImg
            url={`${funcThumbnailIMG(
              decryptPath(listImgOrVideo?.url, {
                isMini: true,
              })
            )}`}
            height={isMobile ? 193 : 418}
            width={isMobile ? 343 : 640}>
            <div className='bg-img'></div>
            <ImgCustom
              isMini={isMiniUrl(item)}
              screen={`${isMobile ? 'post_343_auto_pc' : 'post_640_auto_pc'}`}
              className='mobile-img'
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                zIndex: '10',
                background: 'transparent',
              }}
              src={funcThumbnailIMG(decryptPath(listImgOrVideo?.url))}
              avatarErr={false}
            />
          </StyledTimeLine.BlueImg>
          <div className='button-play'>
            <PlayIcon />
          </div>
          {item?.is_followed === 0 &&
          item?.follow_flag === 1 &&
          item?.is_media_display === 0 &&
          !isAdmin ? (
            <StyledTimeLine.WrapperBlur>
              <div className='text-follow'>{t('DetailCard.notYetFollow')}</div>
              <button
                disabled={isAgency}
                onClick={(e) => {
                  if (!isAuth) {
                    e.stopPropagation();
                    dispatch(logUserProfile(item));
                  } else {
                    e.stopPropagation();
                    handleFollowCreator(item);
                  }
                }}>
                {t('Common.follow')}
              </button>
            </StyledTimeLine.WrapperBlur>
          ) : (
            ''
          )}
          {item?.follow_flag === 0 && item?.is_media_display === 0 && !isAdmin && (
            <PopupLockPostNormal
              one_column
              post_id={item?.id}
              detailPost={item}
              //in_my_page
              point={dataUser?.points}
              setIsOpen={setIsOpen}
              setIdPackage={setIdPackage}
            />
          )}
          {item?.medias_count > 1 ? (
            <IconTypePost className='customPosition' type='image' />
          ) : (
            <IconTypePost className='customPosition' type='video' />
          )}
        </StyledTimeLine.Wrapper>
      );
    } else {
      // img
      return (
        <StyledTimeLine.Wrapper
          onClick={() => {
            navigate(`${newPathUser}post/${item?.id}`, {
              state: {
                isEditHomePage: true,
                account_id,
              },
            });
          }}>
          {item?.medias_count > 1 && <IconTypePost className='customPosition' type='image' />}
          {item?.is_followed === 0 &&
          item?.follow_flag === 1 &&
          item?.is_media_display === 0 &&
          !isAdmin ? (
            <StyledTimeLine.WrapperBlur>
              <div className='text-follow'>{t('DetailCard.notYetFollow')}</div>
              <button
                disabled={isAgency}
                onClick={(e) => {
                  if (!isAuth) {
                    e.stopPropagation();
                    dispatch(logUserProfile(item));
                  } else {
                    e.stopPropagation();
                    handleFollowCreator(item);
                  }
                }}>
                {t('Common.follow')}
              </button>
            </StyledTimeLine.WrapperBlur>
          ) : (
            ''
          )}
          {item?.follow_flag === 0 && item?.is_media_display === 0 && !isAdmin && (
            <PopupLockPostNormal
              one_column
              post_id={item?.id}
              detailPost={item}
              point={dataUser?.points}
              setIsOpen={setIsOpen}
              setIdPackage={setIdPackage}
            />
          )}
          <StyledTimeLine.BlueImg
            url={`${decryptPath(listImgOrVideo?.url, { isMini: true })}`}
            height={isMobile ? 193 : 418}
            width={isMobile ? 343 : 640}>
            <div className='bg-img'></div>
            <ImgCustom
              isMini={isMiniUrl(item)}
              screen={`${isMobile ? 'post_343_auto_pc' : 'post_640_auto_pc'}`}
              className='mobile-img'
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                zIndex: '10',
                background: 'transparent',
              }}
              src={`${listImgOrVideo?.url}`}
              avatarErr={false}
            />
          </StyledTimeLine.BlueImg>
          {item?.medias_count > 1 && <IconTypePost className='customPosition' type='image' />}
        </StyledTimeLine.Wrapper>
      );
    }
  };

  //API LIKE COMMENT
  // const handleLikeComment = (data) => {
  //   const { commentId, type, dataPost } = data;
  //   type === 'comment'
  //     ? dispatch(updateLikeCommentDetail(commentId, dataPost))
  //     : dispatch(updateLikeReplyComment(commentId, dataPost));
  // };
  const handleLikeComment = (data, cb = () => {}) => {
    const { dataPost, type, commentId } = data;
    cb();
    dispatch(updateLikeCommentDetail(commentId, dataPost));
  };

  // FOLLOW USER IN COMMENT
  const handleFollowUserComment = (data) => {
    const { id, type } = data;
    type === 'comment'
      ? dispatch(postFollowCommentDetail(id))
      : dispatch(postFollowReplyCommentDetail(id));
  };

  // SETHEIGHT TEXTAREA
  const handleTextarea = (index, e, id, height) => {
    if (e.target.scrollHeight > e.target.clientHeight) {
      e.target.style.height = e.target.scrollHeight + 'px';
    }
    var code = e.keyCode || e.charCode;
    if (code === 13) {
      e.target.style.height = 'inherit';
      e.target.style.height = `${e.target.scrollHeight}px`;
    }
    const { value } = e.target;
    const newCommentValues = [...valueComments].map((comment, idx) => {
      if (idx !== index) return comment;
      return value;
    });
    setValueComments(newCommentValues);
    setCheckSendChat(id);
    if (e.target.value?.toString().trim() === '') {
      e.target.style.height = height;
      e.target.style.padding = '9px  30px  9px 12px';
      setCheckSendChat(null);
    }
  };

  const funcRenderImgOrVideoByType = (item) => {
    if (!item?.media_preview?.url) {
      return;
    }
    const listItemHasWaterMark = item.media_preview?.public_flag === 0;
    if (listItemHasWaterMark) {
      return <StyledDetailCard.Wrapper>{imageWaterMarkFunc(item)}</StyledDetailCard.Wrapper>;
    }
    return renderImgOrVideoType4(item?.media_preview, item);
  };
  const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

  const fungFollowLockPostNormal = (item, isCustom) => {
    return (
      <>
        {item?.is_followed === 0 &&
        item?.follow_flag === 1 &&
        item?.user_id !== dataUser?.id &&
        !isAdmin ? (
          <StyledTimeLine.WrapperBlur1 style={{backgroundImage: `${isFirefox ? `url(${Blur_img})` : 'transparent'}`}}>
            <div className='text-follow'>{t('DetailCard.notYetFollow')}</div>
            <button
              disabled={isAgency}
              onClick={(e) => {
                if (!isAuth) {
                  e.stopPropagation();
                  dispatch(logUserProfile(item));
                } else {
                  e.stopPropagation();
                  handleFollowCreator(
                    item,
                    dataInfoOther?.id === item?.user_id ? true : false
                  );
                }
              }}>
              {t('Common.follow')}
            </button>
          </StyledTimeLine.WrapperBlur1>
        ) : (
          ''
        )}
        {item?.follow_flag === 0 && item?.is_media_display === 0 && !isAdmin && (
          <PopupLockPostNormal
            is_class={isCustom ? true : false}
            one_column
            post_id={item?.id}
            detailPost={item}
            point={dataUser?.points}
            setIsOpen={setIsOpen}
            setIdPackage={setIdPackage}
          />
        )}
      </>
    );
  };

  const fungFollowLockPostNormalFollow = (item, isCustom) => {
    return (
      <>
        {item?.is_followed === 0 &&
        item?.follow_flag === 1 &&
        item?.user_id !== dataUser?.id &&
        !isAdmin ? (
          <StyledTimeLine.WrapperBlur>
            <div className='text-follow'>{t('DetailCard.notYetFollow')}</div>
            <button
              disabled={isAgency}
              onClick={(e) => {
                if (!isAuth) {
                  e.stopPropagation();
                  dispatch(logUserProfile(item));
                } else {
                  e.stopPropagation();
                  handleFollowCreator(
                    item,
                    dataInfoOther?.id === item?.user_id ? true : false
                  );
                }
              }}>
              {t('Common.follow')}
            </button>
          </StyledTimeLine.WrapperBlur>
        ) : (
          ''
        )}
        {item?.follow_flag === 0 && item?.is_media_display === 0 && !isAdmin && (
          <PopupLockPostNormal
            is_class={isCustom ? true : false}
            one_column
            post_id={item?.id}
            detailPost={item}
            point={dataUser?.points}
            setIsOpen={setIsOpen}
            setIdPackage={setIdPackage}
          />
        )}
      </>
    );
  };

  const handleFollowCreator = (dt, ischeck) =>
    dispatch(
      fanFollowCreator(dt?.user_id, (data) => {
        ischeck && setCreatorProfile({ ...creatorProfile, is_followed: data.is_followed });
      })
    );

  const handleHiddenPost = (dt) => dispatch(hiddenPostMypage(dt?.id));

  const deletePostTimeLine = () => {
    setIsVisiblePopDeletePost(null);
    const { id, item } = isVisiblePopUpDeletePost;
    dispatch(creatorDeleteArticle(id, () => setIsVisiblePopDeletePostComplete(true), item));
  };

  const deletePostRegular = () => {
    setIsVisiblePopDeletePost1(null);
    const { id } = isVisiblePopUpDeletePost1;
    dispatch(
      updateDetailRecordingV2(
        id,
        {
          is_show: 0,
        },
        () => setIsVisiblePopDeletePostComplete1(true)
      )
    );
  };

  //(1 column) type 1 ,4
  const [hoverId, setHoverId] = useState(false);
  const oneColumn =
    list?.length > 0 ? (
      <InfiniteLoad
        className='row one-column style-mb-package-homepage'
        loading={loading}
        data={list}
        fetchNext={() => fetchNext()}>
        {list.map((item2, index) => {
          let item = item2?.share_type === 1 && item2?.share_post ? item2?.share_post : item2;
          let itemBlocklike =
            item2?.share_type === 2 && item2?.share_post?.share_type === 1
              ? item2
              : item2?.share_type === 1 && item2?.share_post?.share_type === 2
              ? item2?.share_post
              : item2?.share_type === 2 && item2?.share_post?.share_type === 2
              ? item2
              : item2?.share_type === 2
              ? item2
              : item2?.share_type === 1
              ? item2?.share_post
              : item2;
          return (
            <ProviderTimeline>
              <div className='mb-4 item' key={item2?.id} id={item2?.id}>
                {item2?.is_pinned === 1 && (
                  <div className='d-flex retweeted'>
                    <GhimPostIcon />
                    <p style={{ fontSize: '12px', fontWeight: '700', marginLeft: '10px' }}>
                      {t('Common.buttonGhimPost')}
                    </p>
                  </div>
                )}
                {item2?.is_pinned !== 1 && item2?.share_type === 1 && (
                  <div className='d-flex retweeted'>
                    <RePostIcon />
                    <p style={{ fontSize: '12px', fontWeight: '700', marginLeft: '10px' }}>
                      {t('Common.retweeted')}
                    </p>
                  </div>
                )}
                {item2?.post_status && item2?.post_status !== 'public' && (
                  <div
                    className='post-status-sty'
                    style={{
                      fontWeight: '700',
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                    <label
                      className={`${item2?.post_status}-status`}
                      htmlFor={`comment-item-${item2?.id}`}>
                      {t(`DetailCard.${item2?.post_status}`)}
                    </label>
                  </div>
                )}
                <div
                  className='d-flex justify-content-between block-img1 top-img'
                  style={{
                    display: 'flex',
                    position: 'relative',
                  }}>
                  <div className='d-flex mobile-block-date' style={{ gap: '12px' }}>
                    {item?.avatar ? (
                      <ImgCustom
                        onClick={() => {
                          navigate(
                            `${newPathUser}${
                              item2?.share_type === 1
                                ? item2?.share_post?.account_id
                                : item2?.account_id
                            }`
                          );
                        }}
                        style={{
                          minWidth: 44,
                          maxWidth: 44,
                          minHeight: 44,
                          maxHeight: 44,
                          borderRadius: '50%',
                          objectFit: 'cover',
                          cursor: `${item2?.share_post && 'pointer'}`,
                        }}
                        className='cls-img'
                        screen='avatar_44_44'
                        src={item2?.share_type === 2 ? item2?.avatar : item?.avatar}
                      />
                    ) : (
                      <img
                        onClick={() => {
                          navigate(
                            `${newPathUser}${
                              item2?.share_type === 1
                                ? item2?.share_post?.account_id
                                : item2?.account_id
                            }`
                          );
                        }}
                        width={44}
                        height={44}
                        src={AVATAR_DEFAULT}
                        alt='AVATAR_DEFAULT'
                      />
                    )}
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <div
                        className='wrap--text 23'
                        style={{ cursor: 'pointer' }}
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(
                            `${newPathUser}${
                              item2?.share_type === 1
                                ? item2?.share_post?.account_id
                                : item2?.account_id
                            }`
                          );
                        }}>
                        <p className='mb-0 cls-title 111'>
                          {item2?.share_type === 2
                            ? item2?.account_name
                            : item?.account_name || 'no account name'}
                        </p>
                      </div>
                      {((item2?.user_id !== dataUser?.id && item2?.share_type !== 1) ||
                        (item2?.share_post?.user_id !== dataUser?.id &&
                          item2?.share_type !== 2 &&
                          item2?.share_post)) && (
                        <div style={{ alignSelf: 'flex-start'}}>
                          {item2?.share_type === 1 ? (
                            <div
                              style={{
                                border: '1px solid #DC143C',
                                borderRadius: '20px',
                                alignSelf: 'flex-start',
                                padding: '0 5px',
                                cursor: 'pointer',
                                fontSize: '12px',
                                fontWeight: '500',
                              }}
                              className={`btn-followOrUnFollow ${
                                item2?.share_post?.is_followed === 1
                                  ? 'following'
                                  : 'no-follow'
                              } ${
                                item2?.share_post?.is_followed === 1 &&
                                hoverId === item2?.share_post?.id
                                  ? 'hover'
                                  : ''
                              }`}
                              onMouseOver={() => {
                                if (
                                  item2?.share_post?.is_followed === 1 &&
                                  item2?.share_post?.id
                                ) {
                                  !isMobile && setHoverId(item2?.share_post?.id);
                                }
                              }}
                              onMouseLeave={() => {
                                if (item2?.share_post?.is_followed === 1) {
                                  setHoverId(false);
                                }
                              }}
                              onClick={(e) => {
                                if (!isAuth) {
                                  dispatch(logUserProfile(item2));
                                } else {
                                  e.stopPropagation();
                                  handleFollowCreator(item2?.share_post, true);
                                }
                              }}>
                              {widthScreen > 991 ? (
                                item2?.share_post?.is_followed === 1
                                ? hoverId === item2?.share_post?.id
                                  ? t('Creator.FollowList.unfollow')
                                  : t('Creator.FollowList.following')
                                : t('Creator.FollowList.followUs')
                              ) : (
                                item2?.share_post?.is_followed === 1
                                  ? t('Creator.FollowList.following')
                                : t('Creator.FollowList.followUs')
                              )}
                            </div>
                          ) : (
                            <div
                              style={{
                                border: '1px solid #DC143C',
                                borderRadius: '20px',
                                alignSelf: 'flex-start',
                                padding: '0 5px',
                                cursor: 'pointer',
                                fontSize: '12px',
                                fontWeight: '500',
                              }}
                              className={`btn-followOrUnFollow ${
                                item2?.is_followed === 1 ? 'following' : 'no-follow'
                              } ${
                                item2?.is_followed === 1 && hoverId === item2?.id
                                  ? 'hover'
                                  : ''
                              }`}
                              onMouseOver={() => {
                                if (item2?.is_followed === 1 && item2?.id) {
                                  !isMobile && setHoverId(item2.id);
                                }
                              }}
                              onMouseLeave={() => {
                                if (item2?.is_followed === 1) {
                                  setHoverId(false);
                                }
                              }}
                              onClick={(e) => {
                                if (!isAuth) {
                                  dispatch(logUserProfile(item2));
                                } else {
                                  e.stopPropagation();
                                  handleFollowCreator(item2, true);
                                }
                              }}>
                              {widthScreen > 991 ? (
                                item2?.is_followed === 1
                                ? hoverId === item2?.id
                                  ? t('Creator.FollowList.unfollow')
                                  : t('Creator.FollowList.following')
                                : t('Creator.FollowList.followUs')
                              ) : (
                                item2?.is_followed === 1
                                  ? t('Creator.FollowList.following')
                                : t('Creator.FollowList.followUs')
                              )}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={`box-popup d-flex ${(isAdmin || isAgency) && 'disabled'}`}>
                    <ThreePointIcon
                      style={{ color: '#666565' }}
                      className='three-point-icon'
                      onClick={() => {
                        setIsVisiblePopup(isVisiblePopup === index ? -1 : index);
                      }}
                      onBlur={() => setIsVisiblePopup(-1)}
                    />
                    {isVisiblePopup === index && (
                      <div
                        className='popup-edit-comment'
                        style={{ width: '244px', zIndex: '36' }}>
                        {((item2?.user_id !== dataUser?.id && item2?.share_type !== 1) ||
                          (item2?.share_post?.user_id !== dataUser?.id &&
                            item2?.share_type !== 2 &&
                            item2?.share_post)) && (
                          <>
                            <div
                              className='item'
                              onMouseDown={() => {
                                if (!isAuth) {
                                  setIsVisiblePopup(-1);
                                  dispatch(logUserProfile(item2));
                                } else {
                                  setIsVisiblePopup(-1);
                                  if (item2?.share_type === 1) {
                                    navigate(`${newPathUser}post-report`, {
                                      state: {
                                        fan_id: item2?.share_post?.user_id,
                                        fan_name:
                                          item2?.share_post?.account_name ||
                                          item2?.share_post?.full_name,
                                        post_id: item2?.share_post?.id,
                                      },
                                    });
                                  } else {
                                    navigate(`${newPathUser}post-report`, {
                                      state: {
                                        fan_id: item2?.user_id,
                                        fan_name: item2?.account_name || item2?.full_name,
                                        post_id: item2?.id,
                                      },
                                    });
                                  }
                                }
                              }}>
                              <FlagIcon />
                              <span className='text'>{t('comment.listComment.iconFlag')}</span>
                            </div>
                          </>
                        )}
                        {item2?.user_id === dataUser?.id && (
                          <>
                            {((item2?.pin_share_enable === 1 && item2?.share_type !== 1) ||
                              (item2?.share_post?.pin_share_enable === 1 &&
                                item2?.share_type === 1 &&
                                item2?.share_post?.user_id === dataUser?.id)) && (
                              <div
                                className='item'
                                onMouseDown={() => {
                                  if (!isAuth) {
                                    setIsVisiblePopup(-1);
                                    dispatch(logUserProfile(item2));
                                  } else {
                                    const payload = {
                                      type: 1,
                                      page: 1,
                                      limit: 10,
                                      id: dataInfoOther?.id,
                                    };
                                    dispatch(
                                      ghimPostMypage(
                                        item2?.share_type === 1
                                          ? item2?.share_post?.id
                                          : item2.id,
                                        () => {
                                          setPage(1);
                                          dispatch(getListPostCreator(payload, !account_id));
                                        }
                                      )
                                    );
                                    setIsVisiblePopup(-1);
                                  }
                                }}>
                                {item2?.is_pinned !== 1 ? <GhimIcon /> : <DelGhimIcon />}
                                <span className='text'>
                                  {item2?.is_pinned !== 1
                                    ? t('Common.buttonGhim')
                                    : t('Common.buttonUnGhim')}
                                </span>
                              </div>
                            )}
                            {item2?.is_active !== 0 && (
                              <div
                                className='item'
                                onMouseDown={() => {
                                  if (!isAuth) {
                                    setIsVisiblePopup(-1);
                                    dispatch(logUserProfile(item2));
                                  } else {
                                    const payload = {
                                      type: 1,
                                      page: 1,
                                      limit: 10,
                                      id: dataInfoOther?.id,
                                    };
                                    dispatch(
                                      hiddenPostMypage(item2.id, () => {
                                        setPage(1);
                                        dispatch(getListPostCreator(payload, !account_id));
                                      })
                                    );
                                    setIsVisiblePopup(-1);
                                  }
                                }}>
                                {item2?.is_hided === 0 ? <EyeV2Icon /> : <HiddenV2Icon />}
                                <span className='text'>
                                  {item2?.is_hided === 0
                                    ? t('Common.hiddenPost')
                                    : t('Common.openPost')}
                                </span>
                              </div>
                            )}
                            {item2?.share_type !== 1 && (
                              <>
                                <div
                                  className='item'
                                  onMouseDown={() => {
                                    if (!isAuth) {
                                      setIsVisiblePopup(-1);
                                      dispatch(logUserProfile());
                                    } else {
                                      navigateByLink(item2?.id, type);
                                      setIsVisiblePopup(-1);
                                    }
                                  }}>
                                  <Edit3Icon />
                                  <span className='text'>
                                    {t('comment.listComment.iconEdit')}
                                  </span>
                                </div>
                                <div
                                  style={{ marginBottom: '5px' }}
                                  className='item'
                                  onMouseDown={() => {
                                    if (!isAuth) {
                                      setIsVisiblePopup(-1);
                                      dispatch(logUserProfile());
                                    } else {
                                      setIsVisiblePopDeletePost({
                                        id: item2?.id,
                                        item: item2,
                                      });
                                      setIsVisiblePopup(-1);
                                    }
                                  }}>
                                  <TrashIcon />
                                  <span className='text'>{t('Common.buttonDelete')}</span>
                                </div>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                {/* <div
                  className='mt-3 block-img1 mb-3'
                  style={{
                    whiteSpace: 'pre-wrap',
                    margin: '0',
                    fontWeight: '400',
                  }}>
                  <MarkDownRead
                    isMeta
                    content={item?.content || ''}
                    charLimit={isMobile ? 90 : 160}
                  />
                </div> */}
                {/* func quote post upload img or video */}
                {item2?.share_type === 2 && item2?.media_preview && (
                  <div
                    className={`block-img1 mt-3 3`}
                    style={{
                      cursor: 'pointer',
                      position: 'relative',
                    }}>
                    {fungFollowLockPostNormal(item2)}
                    {listImgFunc(item2?.medias, item2, item2)}
                  </div>
                )}
                {!item2?.share_post && !item2?.share_type && item2?.medias?.length > 0 && (
                  <>
                    <div
                      className='block-img1 33133 mt-3 my-page-item-new'
                      style={{
                        cursor: 'pointer',
                        position: 'relative',
                      }}>
                      {fungFollowLockPostNormal(item2)}
                      {listImgFunc(item2?.medias, item2, item2)}
                    </div>
                  </>
                )}
                {item2?.share_post &&
                  item2?.share_post?.medias?.length > 0 &&
                  item2?.share_type === 1 &&
                  !item2?.share_post?.share_post && (
                    <div
                      className='block-img1 341 mt-3 my-page-item-new'
                      style={{
                        cursor: 'pointer',
                        position: 'relative',
                      }}>
                      {fungFollowLockPostNormal(item2?.share_post)}
                      {listImgFunc(item2?.share_post?.medias, item2?.share_post, item2)}
                    </div>
                  )}
                {((!item2?.share_post && !item2?.share_type) ||
                  (item2?.share_type === 1 && item2?.share_post) ||
                  (item2?.share_type === 2 && item2?.media_preview) ||
                  (item2?.share_type === 2 && item2?.share_post) ||
                  (item2?.share_type === 1 && item2?.share_post?.share_type === 2)) && (
                  <>
                    <div
                      className='mt-2 block-img1'
                      style={{
                        whiteSpace: 'pre-wrap',
                        margin: '0',
                        fontWeight: '400',
                      }}>
                      <MarkDownRead
                        isMeta
                        content={item?.content || ''}
                        charLimit={isMobile ? 90 : 160}
                      />
                    </div>
                    <div className='d-flex align-items-center mobile-date mt-2'>
                      <p
                        className='cls-title-2nd cls-date'
                        style={{ color: '#666565', marginTop: '0px', fontSize: '12px' }}>
                        {item2?.share_type === 2
                          ? convertToCurrentTime(
                              item2?.public_time || item2?.created_at,
                              'YYYY/MM/DD HH:mm'
                            )
                          : convertToCurrentTime(
                              item?.public_time || item?.created_at,
                              'YYYY/MM/DD HH:mm'
                            )}
                      </p>
                      {item?.unpublic_time && (
                        <>
                          <p
                            className={`cls-title-2nd cls-date ${!isMobile && 'ms-1'}`}
                            style={{
                              color: '#DC143C',
                              marginTop: '0px',
                              border: '1px solid',
                              padding: '0px 5px',
                            }}>
                            <span className='me-1'>{t('Common.publicDay')}</span>
                            {convertToCurrentTime(item?.unpublic_time, 'YYYY/MM/DD HH:mm')}
                          </p>
                        </>
                      )}
                      {item?.in_24h_flag === 1 && (
                        <>
                          <p
                            className={`cls-title-2nd cls-date ${!isMobile && 'ms-1'}`}
                            style={{
                              color: '#DC143C',
                              marginTop: '0px',
                              border: '1px solid',
                              padding: '0px 5px',
                            }}>
                            <span className='me-1'>{t('Common.publicDay')}</span>
                            {convertToCurrentTime(
                              renderTimeAfter24h(item?.public_time),
                              'YYYY/MM/DD HH:mm'
                            )}
                          </p>
                        </>
                      )}
                    </div>
                  </>
                )}
                {item2?.share_type === 1 &&
                  item2?.share_post?.share_post &&
                  item2?.share_post?.share_type === 2 && (
                    <div
                      className='block-img1'
                      style={{
                        position: 'relative',
                        cursor: 'pointer',
                      }}>
                      <div>{fungFollowLockPostNormal(item2?.share_post)}</div>
                      {listImgFunc(item2?.share_post?.medias, item2?.share_post, item2)}
                    </div>
                  )}
                {item2?.share_type === 1 &&
                  item2?.share_post?.share_post &&
                  item2?.share_post?.medias?.length > 0 &&
                  item2?.share_post?.share_type === 2 &&
                  funcNoimgLinkPost(item2?.share_post?.share_post, item2)}
                {item2?.share_type === 2 &&
                  item2?.share_post &&
                  item2?.medias.length > 0 &&
                  funcNoimgLinkPost(item2?.share_post, item2)}
                {/* func sau khi xoa bai viet goc */}

                {/* func quote bai post từ bài quote 3 cấp */}
                {item2?.share_type === 1 &&
                  item2?.share_post?.medias?.length === 0 &&
                  item2?.share_post?.share_post && (
                    <div
                      onClick={() => {
                        dispatch(
                          getIDHomePage({
                            id: item2?.id,
                            tab: 1,
                            isMedia: true,
                            page: page,
                            type: 'post-normal',
                          })
                        );
                        item2?.share_post?.share_post &&
                          navigate(`${newPathUser}post/${item2?.share_post?.share_post?.id}`, {
                            state: {
                              isEditHomePage: true,
                              account_id,
                            },
                          });
                      }}
                      style={{ cursor: 'pointer' }}
                      className={`${
                        item2?.share_post?.share_post?.media_preview
                          ? 'block-repost 44'
                          : 'block-repost-no-img 3 mt-3'
                      }`}>
                      <div
                        className='d-flex'
                        style={{
                          gap: '12px',
                          marginBottom: `${
                            item2?.share_post?.share_post?.medias?.length > 0 ? '16px' : '0px'
                          }`,
                        }}>
                        {item2?.share_post?.share_post?.avatar ? (
                          <ImgCustom
                            onClick={(e) => {
                              e.stopPropagation();
                              navigate(
                                `${newPathUser}${item2?.share_post?.share_post?.account_id}`
                              );
                            }}
                            style={{
                              minWidth: 44,
                              maxWidth: 44,
                              minHeight: 44,
                              maxHeight: 44,
                              borderRadius: '50%',
                              objectFit: 'cover',
                              cursor: 'pointer',
                            }}
                            className='cls-img'
                            screen='avatar_44_44'
                            src={item2?.share_post?.share_post?.avatar}
                          />
                        ) : (
                          <img
                            width={44}
                            height={44}
                            style={{ cursor: 'pointer' }}
                            onClick={(e) => {
                              e.stopPropagation();
                              navigate(
                                `${newPathUser}${item2?.share_post?.share_post?.account_id}`
                              );
                            }}
                            src={AVATAR_DEFAULT}
                            alt='AVATAR_DEFAULT'
                          />
                        )}
                        <div
                          className='wrap--text'
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate(
                              `${newPathUser}${item2?.share_post?.share_post?.account_id}`
                            );
                          }}>
                          <p className='mb-0 cls-title'>
                            {item2?.share_post?.share_post?.account_name || 'no account name'}
                          </p>
                        </div>
                      </div>
                      {item2?.share_type === 1 &&
                        item2?.share_post?.share_type === 2 &&
                        item2?.share_post?.medias?.length === 0 &&
                        item2?.share_post?.share_post && (
                          <div
                            className='block-img1 331'
                            style={{
                              cursor: 'pointer',
                              position: 'relative',
                            }}>
                            <div
                              style={{
                                marginTop: `${isMobile && '0px'}`,
                                margin: `${isMobile ? '0px -32px' : '0 -16px'}`,
                                position: 'relative',
                              }}>
                              {fungFollowLockPostNormalFollow(item2?.share_post?.share_post)}
                              {listImgFunc(
                                item2?.share_post?.share_post?.medias,
                                item2?.share_post?.share_post,
                                item2
                              )}
                            </div>
                            <div style={{ margin: `${isMobile && '0 -16px'}` }}>
                              <div
                                className='mt-3'
                                style={{
                                  whiteSpace: 'pre-wrap',
                                  margin: '0',
                                }}>
                                <MarkDownRead
                                  isMeta
                                  content={item2?.share_post?.share_post?.content || ''}
                                  charLimit={isMobile ? 90 : 160}
                                />
                              </div>
                              <div className='d-flex align-items-center mt-2'>
                                <p
                                  className='cls-title-2nd cls-date'
                                  style={{ color: '#666565', marginTop: '0px' }}>
                                  {convertToCurrentTime(
                                    item2?.share_post?.share_post?.public_time ||
                                      item2?.share_post?.share_post?.created_at,
                                    'YYYY/MM/DD HH:mm'
                                  )}
                                </p>
                                &nbsp;
                                {item2?.share_post?.share_post?.unpublic_time && (
                                  <>
                                    <p
                                      className='cls-title-2nd cls-date ms-1'
                                      style={{
                                        color: '#DC143C',
                                        marginTop: '0px',
                                        border: '1px solid',
                                        padding: '0px 5px',
                                      }}>
                                      <span className='me-1'>{t('Common.publicDay')}</span>
                                      {convertToCurrentTime(
                                        item2?.share_post?.share_post?.unpublic_time,
                                        'YYYY/MM/DD HH:mm'
                                      )}
                                    </p>
                                  </>
                                )}
                                {item2?.share_post?.share_post?.in_24h_flag === 1 && (
                                  <>
                                    <p
                                      className='cls-title-2nd cls-date ms-1'
                                      style={{
                                        color: '#DC143C',
                                        marginTop: '0px',
                                        border: '1px solid',
                                        padding: '0px 5px',
                                      }}>
                                      <span className='me-1'>{t('Common.publicDay')}</span>
                                      {convertToCurrentTime(
                                        renderTimeAfter24h(
                                          item2?.share_post?.share_post?.public_time
                                        ),
                                        'YYYY/MM/DD HH:mm'
                                      )}
                                    </p>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      {item2?.share_post?.share_post &&
                        item2?.share_post?.share_post?.medias?.length === 0 && (
                          <div
                            className='mt-3 3'
                            style={{
                              whiteSpace: 'pre-wrap',
                              margin: '0',
                              fontWeight: '500',
                            }}>
                            {`${getEnv('REACT_APP_FAN_URL')}${newPathUser}post/${
                              item2?.share_post?.share_post?.id
                            }`}
                          </div>
                        )}
                    </div>
                  )}
                {/* func quote bai post từ bài quote 3 cấp */}
                {item2?.share_type === 2 && !item2?.media_preview && !funcDelPost(item2) && (
                  <>
                    {((item2?.share_type === 2 && item2?.share_post) ||
                      (item2?.share_type === 1 && item2?.share_post?.share_type === 2)) && (
                      <div
                        onClick={() => {
                          dispatch(
                            getIDHomePage({
                              id: item2?.id,
                              tab: 1,
                              isMedia: true,
                              page: page,
                              type: 'post-normal',
                            })
                          );
                          item2?.share_post &&
                            navigate(`${newPathUser}post/${item2?.share_post.id}`, {
                              state: {
                                isEditHomePage: true,
                                account_id,
                              },
                            });
                        }}
                        style={{
                          cursor: 'pointer',
                          marginBottom: `${
                            item2?.share_type === 2 && item2?.medias?.length === 0 && '0'
                          }`,
                          borderRadius: `${
                            item2?.share_type === 2 &&
                            item2?.medias?.length === 0 &&
                            item2?.share_post?.medias?.length === 0 &&
                            '8px'
                          }`,
                        }}
                        className={`${
                          item2?.share_post?.media_preview
                            ? 'block-repost 33'
                            : 'block-repost-no-img 4 mt-3'
                        }`}>
                        <div
                          className='d-flex'
                          style={{
                            gap: '12px',
                            marginBottom: `${
                              item2?.share_post?.medias?.length > 0 ? '16px' : '0px'
                            }`,
                          }}>
                          {item2?.share_post?.avatar ? (
                            <ImgCustom
                              onClick={(e) => {
                                e.stopPropagation();
                                dispatch(resetIDHomePage());
                                navigate(`${newPathUser}${item2?.share_post?.account_id}`);
                              }}
                              style={{
                                minWidth: 44,
                                maxWidth: 44,
                                minHeight: 44,
                                maxHeight: 44,
                                borderRadius: '50%',
                                objectFit: 'cover',
                                cursor: 'pointer',
                              }}
                              className='cls-img'
                              screen='avatar_44_44'
                              src={item2?.share_post?.avatar}
                            />
                          ) : (
                            <img
                              style={{ cursor: 'pointer' }}
                              width={44}
                              height={44}
                              onClick={(e) => {
                                e.stopPropagation();
                                navigate(`${newPathUser}${item2?.share_post?.account_id}`);
                              }}
                              src={AVATAR_DEFAULT}
                              alt='AVATAR_DEFAULT'
                            />
                          )}
                          <div
                            className='wrap--text'
                            onClick={(e) => {
                              e.stopPropagation();
                              navigate(`${newPathUser}${item2?.share_post?.account_id}`);
                            }}>
                            <p className='mb-0 cls-title'>
                              {item2?.share_post?.account_name || 'no account name'}
                            </p>
                          </div>
                        </div>
                        <div
                          className={`block-img1 12`}
                          style={{
                            cursor: 'pointer',
                            position: 'relative',
                          }}>
                          <div
                            style={{
                              margin: `${isMobile ? '0 -32px' : '0 -16px'}`,
                              position: 'relative',
                            }}>
                            {fungFollowLockPostNormalFollow(item2?.share_post, true)}
                            {listImgFunc(item2?.share_post?.medias, item2?.share_post, item2)}
                          </div>
                          <div style={{ margin: `${isMobile && '0 -16px'}` }}>
                            <div
                              className='mt-3'
                              style={{
                                whiteSpace: 'pre-wrap',
                                margin: '0',
                              }}>
                              <MarkDownRead
                                isMeta
                                content={item2?.share_post?.content || ''}
                                charLimit={isMobile ? 90 : 160}
                              />
                            </div>
                            <div className='d-flex align-items-center mt-2'>
                              <p
                                className='cls-title-2nd cls-date'
                                style={{ color: '#666565', marginTop: '0px' }}>
                                {convertToCurrentTime(
                                  item2?.share_post?.public_time ||
                                    item2?.share_post?.created_at,
                                  'YYYY/MM/DD HH:mm'
                                )}
                              </p>
                              &nbsp;
                              {item2?.share_post?.unpublic_time && (
                                <>
                                  <p
                                    className='cls-title-2nd cls-date ms-1'
                                    style={{
                                      color: '#DC143C',
                                      marginTop: '0px',
                                      border: '1px solid',
                                      padding: '0px 5px',
                                    }}>
                                    <span className='me-1'>{t('Common.publicDay')}</span>
                                    {convertToCurrentTime(
                                      item2?.share_post?.unpublic_time,
                                      'YYYY/MM/DD HH:mm'
                                    )}
                                  </p>
                                </>
                              )}
                              {item2?.share_post?.in_24h_flag === 1 && (
                                <>
                                  <p
                                    className='cls-title-2nd cls-date ms-1'
                                    style={{
                                      color: '#DC143C',
                                      marginTop: '0px',
                                      border: '1px solid',
                                      padding: '0px 5px',
                                    }}>
                                    <span className='me-1'>{t('Common.publicDay')}</span>
                                    {convertToCurrentTime(
                                      renderTimeAfter24h(item2?.share_post?.public_time),
                                      'YYYY/MM/DD HH:mm'
                                    )}
                                  </p>
                                </>
                              )}
                            </div>
                            {item2?.share_post?.share_post && (
                              <div
                                className='mt-3 3'
                                style={{
                                  whiteSpace: 'pre-wrap',
                                  margin: '0',
                                  fontWeight: '500',
                                }}>
                                {`${getEnv('REACT_APP_FAN_URL')}${newPathUser}post/${
                                  item2?.share_post?.id
                                }`}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                    {!item2?.share_post?.share_post && (
                      <>
                        {item2?.share_post ? ( // post repost
                          <></>
                        ) : (
                          // post gốc
                          <div
                            className='block-img1 3'
                            style={{
                              cursor: 'pointer',
                              position: 'relative',
                            }}>
                            {fungFollowLockPostNormal(item2)}
                            {listImgFunc(item2?.medias, item2, item2)}
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}

                {funcDelPost(item2) && (
                  <div className={`block-repost-no-img-2 3444 mt-3`}>
                    {t('Common.unavailable')}
                  </div>
                )}
                <GroupIcon
                  postId={itemBlocklike?.id}
                  statusLike={itemBlocklike?.is_liked}
                  follow_flag={itemBlocklike?.is_followed}
                  type={itemBlocklike?.type}
                  item={itemBlocklike}
                  item2={item2}
                  index={index}
                  dataUser={dataUser}
                  checkDisabledAction={checkDisabledAction}
                  handleLikePost={handleLikePost}
                  isVisiblePopupGhim={isVisiblePopupGhim}
                  handleRetweet={handleRetweet}
                  setIsVisiblePopupGhim={setIsVisiblePopupGhim}
                  setItemPostPopup={setItemPostPopup}
                  setIsVisibleQuote={setIsVisibleQuote}
                  bookmarkChange={bookmarkChange}
                  handleSharePost={handleSharePost}
                  list={list}
                  like={itemBlocklike?.number_like}
                  page={page}
                />
                <div className='comment-wrap1 block-img1'>
                  <CommentPostTimeLineShowAll
                    defaultCount={item2?.number_comment}
                    dataPost={itemBlocklike}
                    handleDeleteComment={(e) => {
                      setPopupDeleteComment(e);
                    }}
                    handleHideComment={(e) => {
                      setPopupHideComment(e);
                    }}
                    handleLikeComment={handleLikeComment}
                    handleFollowUserComment={handleFollowUserComment}
                    setTrickReloadComment={setTrickReloadComment}
                    trickReloadComment={trickReloadComment}
                    id={item?.id}
                    dataProfile={dataUser}
                    setShowPopup={setShowPopup}
                    isShowPopup={isShowPopup}
                    setShowPopupMess={setShowPopupMess}
                    isShowPopupMess={isShowPopupMess}
                    typeFollow='detailHome'
                    pages='homepage'
                  />
                  <div
                    className={`comment block ${isAdmin && 'disabled'}`}
                    style={{ marginBottom: '16px' }}>
                    {dataUser?.avatar ? (
                      <ImgCustom
                        className={'mt-1'}
                        screen='avatar_44_44'
                        isPlaceholderAvatar
                        src={
                          isAdmin || isAgency
                            ? Avatar
                            : dataUser?.avatar
                            ? `${dataUser?.avatar}`
                            : Avatar
                        }
                      />
                    ) : (
                      <img width={44} height={44} src={AVATAR_DEFAULT} alt='AVATAR_DEFAULT' />
                    )}
                    <CommentForm
                      item={item}
                      item2={item2}
                      index={index}
                      setTrickReloadComment={setTrickReloadComment}
                    />
                  </div>
                </div>
              </div>
            </ProviderTimeline>
          );
        })}
      </InfiniteLoad>
    ) : (
      !loading && funcNoData()
    );

  return (
    <>
      <PopupPaymentBySub />
      {isVisiblePopUpDeletePost1 && (
        <PopupConfirmFan
          isVisiblePopup={(e) => setIsVisiblePopDeletePost1(e)}
          colorSvg='#FF9F43'
          iconNote={true}
          text={t('Popup.deletePostLiveSucess')}>
          <div className='ok btn' onClick={deletePostRegular}>
            {t('Common.buttonYes')}
          </div>
          <div className='btn cancel' onClick={() => setIsVisiblePopDeletePost1(null)}>
            {t('Common.buttonNo')}
          </div>
        </PopupConfirmFan>
      )}
      {isVisiblePopUpDeletePostComplete1 && (
        <PopupConfirmFan
          isVisiblePopup={(e) => setIsVisiblePopDeletePostComplete1(e)}
          iconNote={true}
          text={t('Popup.deletePostLiveSucess1')}>
          <div className='ok btn' onClick={() => setIsVisiblePopDeletePostComplete1(false)}>
            {t('Common.buttonYes')}
          </div>
        </PopupConfirmFan>
      )}
      {isVisibleQuote && (
        <QuotePostMyPage
          closeModal={() => setIsVisibleQuote(false)}
          isVisibleQuote={isVisibleQuote}
          item={itemPostPopup}
          setPage={setPage}
        />
      )}
      {isVisible && (
        <PopupRecordingDetailMyPage
          closeModal={() => setIsVisible(false)}
          isVisible={isVisible}
          setIsVisibleDel={setIsVisibleDel}
          item={liveKey}
        />
      )}
      {isShowPopup?.status && (
        <PopupViewFullTimeLineV2
          item={isShowPopup?.dataItem}
          idx={isShowPopup?.idx}
          setIsOpen={setIsOpen}
          setIdPackage={setIdPackage}
          close={() => {
            setShowPopup({
              ...isShowPopup,
              status: false,
              dataItem: {},
            });
            dispatch(resetDetailPost());
          }}
        />
      )}

      {isShowPopupMess?.status && (
        <PopupViewFullMess
          item={isShowPopupMess?.dataItemMess}
          close={() => {
            setShowPopupMess({
              ...isShowPopupMess,
              status: false,
              dataItem: {},
            });
          }}
        />
      )}

      {isShowPopupShort?.status && (
        <PopupViewFullShort
          item={isShowPopupShort?.dataItemShort}
          indexIdx={isShowPopupShort?.indexIdx}
          close={() => {
            setShowPopupShort({
              ...isShowPopupShort,
              status: false,
              dataItem: {},
            });
          }}
        />
      )}

      {isVisiblePopUpDeletePost && (
        <PopupConfirmFan
          isVisiblePopup={(e) => setIsVisiblePopDeletePost(e)}
          colorSvg='#FF9F43'
          iconNote={true}
          text={t('Popup.confirmDeletePost')}>
          <div className='ok btn' onClick={deletePostTimeLine}>
            {t('Common.buttonYes')}
          </div>
          <div className='btn cancel' onClick={() => setIsVisiblePopDeletePost(null)}>
            {t('Common.buttonNo')}
          </div>
        </PopupConfirmFan>
      )}
      {isVisiblePopUpDeletePostComplete && (
        <PopupConfirmFan
          isVisiblePopup={(e) => setIsVisiblePopDeletePostComplete(e)}
          iconNote={true}
          text={t('Popup.deletePostSucess')}>
          <div className='ok btn' onClick={() => setIsVisiblePopDeletePostComplete(false)}>
            {t('Common.buttonYes')}
          </div>
        </PopupConfirmFan>
      )}

      {popupDeleteComment && (
        <PopupConfirmFan
          isVisiblePopup={() => setPopupDeleteComment(false)}
          colorSvg='#FF9F43'
          iconNote={true}
          text={t('Popup.confirmDeleteComment')}
          className='popup-delete'>
          <div className='ok btn' onClick={handleDeleteComment}>
            {t('Common.buttonDelete')}
          </div>
          <div className='btn cancel' onClick={() => setPopupDeleteComment(false)}>
            {t('Common.buttonCancel')}
          </div>
        </PopupConfirmFan>
      )}
      {popupHideComment && (
        <PopupConfirmFan
          isVisiblePopup={() => setPopupHideComment(false)}
          colorSvg='#FF9F43'
          iconNote
          text={t('Popup.confirmHideComment')}
          className='popup-delete'>
          <div className='ok btn' onClick={handleHideComment}>
            {t('Common.buttonYes')}
          </div>
          <div className='btn cancel' onClick={() => setPopupHideComment(false)}>
            {t('Common.buttonNo')}
          </div>
        </PopupConfirmFan>
      )}
      {isLoadingFollowCreate && <PopupLoading className={'popup-loading'} />}
      <div
        className={`row card-item--detail my-page-item lock-package ${
          isIdol ? 'creator' : 'fan'
        }`}
        style={{
          marginTop: '10px',
          borderBottom: 'none',
        }}
        id='card-item--detail'>
        {popupCopyLink && (
          <PopupSharePost
            isVisiblePopup={() => setPopupCopyLink(false)}
            data={popupCopyLink || {}}
          />
        )}
        {
          type === 1 && isMedia
            ? oneColumn //post-normal 1 column
            : type === 2
            ? twoColumn //post-sales
            : type === 3
            ? twoColumnRegular //live-archives
            : type === 4
            ? twoColumnVideo //post-shorts
            : threeColumn //post-normal 3 column
        }
      </div>
    </>
  );
};

CardDetail.prototype = {
  list: PropsType.array.isRequired,
};
CardDetail.defaultProps = {
  list: [],
};
