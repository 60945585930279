import { LayoutCreatorRight, LayoutFan } from 'components';
import HomePageHeader from 'components/home-page-header';
import HomeHeaderBar from 'components/HomeHeaderBar';
import HomePageContent from 'components/HomePageContent';
import { newPathUser } from 'constants/layout/constant';
import useUserRole from 'hooks/useUserRole';
import { DangerCircleIcon } from 'images';
import PageNotFound from 'pages/not-found';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { isAdmin, isAgency, LIST_PARAMS_CREATOR_REMOVE_IN_ROLE_ADMIN } from 'utils';
import { isMobile } from 'react-device-detect';
import './index.scss';
import { ShimmerPostDetails } from 'react-shimmer-effects';
import HeaderMobileV2 from 'components/HeaderV2/HeaderMobile';
import { getUserInfoOthers } from 'store/actions/usersNew';

const CreatorHomePage = () => {
  const userState = useSelector((state) => state.users);
  const { detailCreator } = useSelector((state) => state.creator);
  const { initPageHome, dataInfoOther, loadingUser, idHomePage } = useSelector((state) => state.usersNew);
  const { t } = useTranslation();
  const { isIdol } = useUserRole();
  const refViewCreator = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { account_id } = useParams();
  const [loadingCustom, setLoadingCustom] = useState(false);
  const [loadingChangeTab, setLoadingChangeTab] = useState(false);
  const { pathname } = useLocation();
  const widthScreen = window.innerWidth;
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const [creatorProfile, setCreatorProfile] = useState(null);
  const top = document.getElementById('app_cocofans_0');
  const location = useLocation();

  useEffect(() => {
    if (widthScreen <= 991) {
      setIsMobileScreen(true);
    }
  }, [widthScreen]);

  useEffect(() => {
    const footer = document.querySelector('#footer-creator.footer.d-flex.w-100.safari_only');
    if (isIdol && footer) {
      footer.style.display = 'none';
    }
    if ([3, 4, 2, 6].includes(userState?.data?.status)) {
      document.body.style.overflow = 'hidden';
      document.body.style.padding = '0';
    }
  }, []);

  useEffect(() => {
    if (idHomePage) {
      const idPost = document.getElementById(idHomePage.id);
      if (idPost) {
        idPost.scrollIntoView({
          block: 'center',
        });
      }
    }
  }, [idHomePage]);
  
  useEffect(() => {
    if(dataInfoOther?.account_id !== account_id) {
      if (!userState?.logoutProcess && account_id) {
        top && top.scrollIntoView({ top: 0, left: 0, block: 'start' });
        dispatch(
          getUserInfoOthers(account_id, ({ data }) => {
            setCreatorProfile(data);
          })
        );
      }
    }
  }, [userState?.logoutProcess, account_id, dispatch, dataInfoOther?.account_id]);

  useEffect(() => {
    if(dataInfoOther?.account_id !== account_id || location?.pathname === '/') {
      if (!userState?.logoutProcess && !account_id && userState?.dataUser?.account_id && !idHomePage) {
        top && top.scrollIntoView({ top: 0, left: 0, block: 'start' });
        dispatch(
          getUserInfoOthers(userState?.dataUser?.account_id, ({ data }) => {
            setCreatorProfile(data);
          })
        );
      }
    }
  }, [userState?.logoutProcess, account_id, dispatch, userState?.dataUser?.account_id, idHomePage]);
  const layoutCreator = () => (
    <LayoutCreatorRight isHome={isMobileScreen} noHeader noButtonBack className='page-home' isFooterHome={isMobileScreen && userState?.dataUser?.id !== dataInfoOther?.id}>
      {/* <HeaderMobileV2 /> */}
      <div
        ref={refViewCreator}
        className={`view-creator--home-page`}
        style={{
          position: 'relative',
        }}>
        {(userState.loading || loadingUser) && (initPageHome < 1 || dataInfoOther?.account_id !== account_id) ? (
          <div className={'shimmer-loading'} style={{ paddingTop: `${isMobile && '30px'}`}}>
            <ShimmerPostDetails />
          </div>
        ) : (
          <>
            {userState?.data?.status === 6 && (
              <>
                <div className='popup-creator-block-lg' />
                <div className='popup-creator-block_content-lg text-center by-fan'>
                  <div className='content'>
                    <DangerCircleIcon style={{ color: '#FF9F43' }} className='check-icon' />
                    <span>{t('Popup.accountCreatedByFan')}</span>
                  </div>
                  <div
                    className='ok btn'
                    onClick={() => navigate(`${newPathUser}profile/update`)}>
                    {t('Common.Ok')}
                  </div>
                </div>
              </>
            )}
            <HomePageHeader
              creatorProfile={dataInfoOther}
              setCreatorProfile={setCreatorProfile}
              notiNumber={userState.stats?.number_noti}
            />
            {!isMobile && (
              <div
                style={{
                  border: '1px solid #EFEFEF',
                  marginBottom: '12px',
                  marginTop: '24px',
                  width: '93%',
                  marginLeft: '24px',
                }}></div>
            )}
            <HomePageContent
              setCreatorProfile={setCreatorProfile}
              creatorProfile={dataInfoOther}
              setLoading={(e) => setLoadingCustom(e)}
              setLoadingChangeTab={(e) => setLoadingChangeTab(e)}
              loadingChangeTab={loadingChangeTab}
            />
          </>
        )}
      </div>
    </LayoutCreatorRight>
  );

  return (
    <>
      {isIdol ? (
        <>
          {(isAdmin || isAgency) &&
          LIST_PARAMS_CREATOR_REMOVE_IN_ROLE_ADMIN.includes(pathname) ? (
            <PageNotFound />
          ) : (
            layoutCreator()
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default CreatorHomePage;
