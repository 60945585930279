/* eslint-disable no-unused-vars */
import { ImgCustom, LayoutCreatorRight, PopupLoading } from 'components';
import MarkDownRead from 'components/MarkDownRead';
import { newPathUser } from 'constants/layout/constant';
import { useClickOutSize } from 'hooks/v2/clickOutSide';
import ImgEmptyData from 'images/img-empty-data.png';
import { createContext, useCallback, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ShimmerPostDetails } from 'react-shimmer-effects';
import {
  getIDTimeline,
  getListPostNoLogin,
  resetDataInfoOther,
  resetIDHomePage,
} from 'store/actions/usersNew';
import { AVATAR_DEFAULT, convertToCurrentTime, onErrImage, renderTimeAfter24h } from 'utils';
import { ProviderTimeline } from './contextTimeline';
import { TimelinePost } from './elements';
import './index.scss';
import { useFixedPopup } from 'hooks/v2/useFixedPopup';
import Loading from 'components/Paging/Loading';

export const TimelineWrapperContext8 = createContext();

const RenderPostItem = ({ item, tab, listImgFunc, page, widthScreen }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleTimeline = () => {
    dispatch(getIDTimeline({ id: item?.id, tab: tab, page: page }));
    dispatch(resetIDHomePage());
    dispatch(resetDataInfoOther());
  };

  return (
    <ProviderTimeline>
      <div style={{ position: 'relative' }}>
        <div className='d-flex justify-content-between'>
          <div className={`avatar-infor `}>
            <div className='content-left'>
              <ImgCustom
                isPlaceholderAvatar
                screen='avatar_44_44'
                src={item?.avatar ? `${item?.avatar}` : AVATAR_DEFAULT}
                alt='avatar'
                onClick={(e) => {
                  e.stopPropagation();
                  handleTimeline && handleTimeline();
                  navigate(`${newPathUser}${item?.account_id}`, {
                    state: { name: 'homepage' },
                  });
                }}
                onError={(e) => onErrImage(e, true)}
              />
              <div style={{ display: 'flex' }}>
                <div
                  className='wrap--text'
                  onClick={(e) => {
                    e.stopPropagation();
                    handleTimeline && handleTimeline();
                    navigate(`${newPathUser}${item?.account_id}`, {
                      state: { name: 'homepage' },
                    });
                  }}>
                  <span style={{ marginTop: '-5px' }} className='name'>
                    {item?.account_name}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          position: 'relative',
        }}
        className='mobile-timeline1'>
        <div
          style={{
            position: 'relative',
            cursor: 'pointer',
          }}>
          {!item?.share_post && listImgFunc(item?.medias, item, item)}
        </div>
        {(item?.content || item?.title) && (
          <div className={`content`} style={{ margin: '0 16px' }}>
            <div className='text'>
              <MarkDownRead
                isMeta
                charLimit={widthScreen > 575 ? 150 : 30}
                content={item?.type === 2 ? item?.title : item?.content}
              />
            </div>
          </div>
        )}
        <div className='d-flex content1 al-center mt-2' style={{ marginLeft: '16px', marginBottom: '16px' }}>
          <p
            className='cls-title-2nd cls-date me-1'
            style={{
              color: '#666565',
              marginTop: '0px',
              marginBottom: '0px',
              fontSize: '12px',
            }}>
            {convertToCurrentTime(item?.public_time || item?.created_at, 'YYYY/MM/DD HH:mm')}
          </p>
          {item?.unpublic_time && (
            <>
              <p
                className='cls-title-2nd cls-date ms-1 mb-left'
                style={{
                  color: '#DC143C',
                  marginTop: '0px',
                  border: '1px solid',
                  padding: '0px 5px',
                  marginBottom: '0',
                  marginRight: '3px',
                  width: 'fit-content',
                }}>
                <span className='me-1'>{t('Common.publicDay')}</span>
                {convertToCurrentTime(item?.unpublic_time, 'YYYY/MM/DD HH:mm')}
              </p>
            </>
          )}
          {item?.in_24h_flag === 1 && (
            <>
              <p
                className='cls-title-2nd cls-date ms-1 mb-left'
                style={{
                  color: '#DC143C',
                  marginTop: '0px',
                  border: '1px solid',
                  padding: '0px 5px',
                  marginBottom: '0',
                  marginRight: '3px',
                  width: 'fit-content',
                }}>
                <span className='me-1'>{t('Common.publicDay')}</span>
                {convertToCurrentTime(
                  renderTimeAfter24h(item?.public_time),
                  'YYYY/MM/DD HH:mm'
                )}
              </p>
            </>
          )}
        </div>
      </div>
    </ProviderTimeline>
  );
};

const PostLogin = ({ isNormal = false, isCreator = false }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const widthScreen = window.innerWidth;
  const [firstLoading, setFirstLoading] = useState(false);
  const top = document.getElementById('app_cocofans_0');
  const {
    paginationTimeLineNoLogin,
    listPostNoLogin,
    isLoadingFollowCreate,
    initPageTimelineNoLogin,
    idTimeline,
    tabTimeline,
  } = useSelector((state) => state.usersNew);
  const [tab, setTab] = useState(
    idTimeline.tab
      ? idTimeline.tab
      : tabTimeline
      ? tabTimeline
      : location?.state?.tab === 2
      ? 2
      : 1
  );
  const [page, setPage] = useState(
    idTimeline && paginationTimeLineNoLogin?.current_page
      ? +paginationTimeLineNoLogin?.current_page
      : 1
  );

  const [isShowPopup, setShowPopup] = useState({
    status: false,
    dataItem: {},
  });
  const [isVisiblePopup, setIsVisiblePopup] = useState(-1);
  useState(false);
  const [runTimeline, setRunTimeLine] = useState();
  const [flgPause, setFlgPause] = useState(false);
  const handleRunTimeLine = (e) => {
    setRunTimeLine(runTimeline !== e ? e : null);
  };
  const [isVisibleQuote, setIsVisibleQuote] = useState(false);
  const [loadingChangeTab, setLoadingChangeTab] = useState(false);

  useFixedPopup(isVisibleQuote);

  useEffect(() => {
    if (idTimeline) {
      const idPost = document.getElementById(idTimeline.id);
      if (idPost) {
        idPost.scrollIntoView({
          block: 'start',
        });
      }
    }
  }, [idTimeline]);

  const funCallApiListPostTimeLine = useCallback(
    (rs) => {
      setLoadingChangeTab(true);
      rs === 1 && setFirstLoading(true); // page = 1
      dispatch(
        getListPostNoLogin(
          {
            page: rs,
            limit: 5,
          },
          () => {
            setFirstLoading(false);
            setLoadingChangeTab(false);
          },
          () => {
            setFirstLoading(false);
            setLoadingChangeTab(false);
          }
        )
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tab]
  );

  useEffect(() => {
    if (initPageTimelineNoLogin < 1) {
      top && top.scrollIntoView({ top: 0, left: 0, block: 'start' });
      funCallApiListPostTimeLine(1);
    }
  }, [funCallApiListPostTimeLine, tab]);

  const fetchNext = () => {
    const pages = paginationTimeLineNoLogin?.total / paginationTimeLineNoLogin?.per_page;
    if (pages > page) {
      setPage(page + 1);
      funCallApiListPostTimeLine(page + 1);
    }
  };
  const pagesNew = paginationTimeLineNoLogin?.total / paginationTimeLineNoLogin?.per_page;

  const listImgFunc = (data, item, itemParent) => (
    <TimelinePost
      tab={tab}
      data={data}
      setShowPopup={setShowPopup}
      isShowPopup={isShowPopup}
      item={item}
      itemParent={itemParent}
      flgPause={flgPause}
      page={page}
    />
  );

  const Content = (
    <>
      {widthScreen > 1024 && <br />}
      {isLoadingFollowCreate && <PopupLoading className={'popup-loading'} />}
      <div className='title-timeline'>{t('MyPage.titleNoLogin')}</div>
      {firstLoading ? (
        <div style={{ padding: `${isMobile ? '0 16px' : '24px'}` }}>
          <ShimmerPostDetails hasImage imageType='circular' title />
        </div>
      ) : (
        <div
          className='my-page'
          id='page-creator'
          style={{ padding: `${isMobile ? '0px' : '24px'}`, borderRadius: '20px' }}>
          {listPostNoLogin?.length > 0 ? (
            <>
              {listPostNoLogin?.length > 0 &&
                listPostNoLogin.map((item, index) => {
                  return (
                    <div
                      style={{
                        borderBottom: 'none',
                        background: '#FAFAFA',
                        marginBottom: '32px',
                        borderRadius: '8px',
                        display: `${tab === 2 && item?.is_followed === 0 && 'none'}`,
                        boxShadow: '0px 0px 10px 0px #0000001A'
                      }}
                      key={index}
                      id={item?.id}
                      className={`my-page-item lock-popup style-mb-package-timeline ${
                        !item?.is_media_display && item?.type === 1 && 'popup_lock_post'
                      }`}>
                      <TimelineWrapperContext8.Provider
                        value={{ setRunTimeLine, runTimeline, handleRunTimeLine }}>
                        {tab === 1 || (tab === 2 && item?.is_followed === 1) ? (
                          <RenderPostItem
                            {...{
                              item,
                              tab,
                              listImgFunc,
                              page,
                              widthScreen,
                            }}
                          />
                        ) : null}
                      </TimelineWrapperContext8.Provider>
                    </div>
                  );
                })}
            </>
          ) : (
            !firstLoading && (
              <div className='empty-box'>
                <ImgCustom
                  src={ImgEmptyData}
                  alt='empty-data'
                  style={{ backgroundColor: 'transparent', paddingBottom: '10px' }}
                />
                <div>{tab === 1 ? t('MyPage.ListPostNull4') : t('MyPage.ListPostNull1')}</div>
                <div>{tab === 1 ? t('MyPage.ListPostNull5') : t('MyPage.ListPostNull2')}</div>
                <div>{tab === 1 && t('MyPage.ListPostNull6')}</div>
              </div>
            )
          )}
          {loadingChangeTab && <Loading />}
          {!loadingChangeTab && pagesNew > page && (
            <div className='d-flex justify-content-center w-100'>
              <button className='button-next-api' onClick={() => fetchNext()}>
                {t('MyPage.seeMore')}
              </button>
            </div>
          )}
        </div>
      )}
    </>
  );
  return (
    <>
      {listPostNoLogin?.length > 0 && (
        <LayoutCreatorRight postLogin className='time-line-home post-login' noHeader>
          {Content}
        </LayoutCreatorRight>
      )}
    </>
  );
};

export default PostLogin;
