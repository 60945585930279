import { PlayIcon } from 'images';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { decryptPath, funcThumbnailIMG, linkS3Video } from 'utils';
import { StyledTimeline } from './styled';
import { isMobile, isSafari } from 'react-device-detect';
import useCheckFullVideo from 'hooks/v2/useCheckFullVideo';
const WrapperHideIconVideo = styled.div`
  width: 100%;
  //height: 100%;
  position: relative;
  padding-top: 56.25%;
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  aspect-ratio: 16/9;
  video::-webkit-media-controls-overlay-play-button {
    ${({ isFull = false }) =>
      isFull
        ? css`
            display: block;
          `
        : css`
            display: none;
          `};
  }

  *::-webkit-media-controls-start-playback-button {
    ${({ isFull = false }) =>
      isFull
        ? css`
            display: block;
          `
        : css`
            display: none !important;
            -webkit-appearance: none;
          `};
  }
`;
export const VideoTag = ({ isMini, tmpData = [], item, handleNavigate = () => {} }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [controlCustom, setControlCustom] = useState(false);
  const videoRef = useRef();
  const containerRef = useRef();
  const [isFullScreen, setIsFullScreen] = useCheckFullVideo(videoRef);

  // const handleIntersection = (entries) => {
  //   const [entry] = entries;
  //   if (entry?.isIntersecting) {
  //   } else {
  //     videoRef?.current?.pause();
  //     setIsPlaying(false);
  //   }
  // };
  const thumbnailTMP = item?.medias?.filter(
    (item) => item?.type === 4 && item?.public_flag === 1
  );

  const checkFollow = (item) => {
    if (
      (item?.is_followed === 0 && item?.follow_flag === 1) ||
      (item?.follow_flag === 0 && item?.is_media_display) === 0
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    const video = videoRef?.current;
    if (checkFollow(item)) {
      video?.pause();
    }
  }, [item]);

  const pauseOtherVideos = () => {
    const videos = document.querySelectorAll('video');

    videos.forEach((video) => {
      if (video !== videoRef.current) {
        video?.pause();
        video.muted = true;
        setIsPlaying(false);
      }
    });
  };

  let shouldPlay = false;
  const handleIntersection = (entries) => {
    const [entry] = entries;
    const video = videoRef?.current;

    const isPlaying =
      videoRef?.current &&
      video.currentTime > 0 &&
      !video.paused &&
      !video.ended &&
      video.readyState > video.HAVE_CURRENT_DATA;

    if (isSafari) {
      video?.setAttribute('playsinline', '');
    }

    if (entry?.isIntersecting && videoRef?.current && !checkFollow(item)) {
      // pauseOtherVideos();
      if (!isPlaying && !shouldPlay) {
        shouldPlay = true;
        video?.play();
        setIsPlaying(true);
      }
    } else if (video?.play && videoRef?.current && shouldPlay) {
      var playPromise = video?.play();
      if (playPromise !== undefined) {
        playPromise
          .then((_) => {
            shouldPlay = false;
            video?.pause();
            setIsPlaying(false);
          })
          .catch((error) => {});
      }
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 1,
    });
    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
  return (
    <div
      style={{
        width: '100%',
        height: '70%',
      }}
      onClick={() => {
        if (!isPlaying) {
          const video = videoRef.current;
          if (video) {
            video.controls = !video.controls;
          }
          setControlCustom(false);
          handleNavigate(item?.type, item?.id);
        }
      }}
      ref={containerRef}>
      <WrapperHideIconVideo isFull={isFullScreen}>
        {funcThumbnailIMG(decryptPath(tmpData[0]?.url)) ? (
          <>
            {!isPlaying && controlCustom && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  handleNavigate(item?.type, item?.id);
                }}
                style={{
                  position: 'absolute',
                  width: '100%',
                  height: '70%',
                  zIndex: 32,
                  top: 0,
                }}></div>
            )}
            <video
              id='full-screenVideo'
              style={{
                objectFit: 'contain',
                background: '#000',
                position: 'absolute',
                zIndex: 30,
              }}
              width='100%'
              height='100%'
              preload='none'
              autoplay
              muted
              poster={`${
                thumbnailTMP?.length === 0
                  ? isMini
                    ? funcThumbnailIMG(decryptPath(tmpData[0]?.url, { isMini: true }))
                    : funcThumbnailIMG(decryptPath(tmpData[0]?.url))
                  : isMini
                  ? decryptPath(item?.medias[0]?.url, { isMini: true }) + '#t=0.1'
                  : decryptPath(item?.medias[0]?.url) + '#t=0.1'
              }`}
              onPause={(e) => {
                if (videoRef.current.seeking) return;
                videoRef.current.pause();
                setIsPlaying(false);
                setControlCustom(true);
              }}
              onPlay={() => {
                videoRef.current.play();
                setIsPlaying(true);
                setControlCustom(true);
              }}
              disablePictureInPicture
              controlsList='nodownload noremoteplayback noplaybackrate foobar'
              src={`${linkS3Video}${decryptPath(tmpData[0]?.url) + '#t=0.1'}`}
              ref={videoRef}
              controls={isFullScreen ? true : controlCustom}>
              Your browser does not support the video tag.
            </video>
            {!isFullScreen && (
              <StyledTimeline.ButtonPlay
                widthButton='56px'
                heightButton='56px'
                onClick={(e) => {
                  e.stopPropagation();
                  if (isPlaying) {
                    setIsPlaying(false);
                    setControlCustom(true);
                    videoRef.current.pause();
                  } else {
                    setIsPlaying(true);
                    setControlCustom(true);
                    videoRef.current.play();
                  }
                }}>
                {!isPlaying && (
                  <PlayIcon
                    style={{
                      filter: 'drop-shadow(0px 0px 0.5px #000)',
                    }}
                  />
                )}
              </StyledTimeline.ButtonPlay>
            )}
          </>
        ) : (
          <video
            preload='none'
            width='100%'
            height='100%'
            controlsList='nodownload'
            src={linkS3Video + decryptPath(tmpData[0]?.url) + '#t=0.1'}></video>
        )}
      </WrapperHideIconVideo>
    </div>
  );
};
