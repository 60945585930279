/* eslint-disable react-hooks/exhaustive-deps */
import { ImgCustom, PopupConfirmFan, PopupLoading } from 'components';
import { InputWrapper } from 'components/FormLogin';
import { getEnv } from 'configs/env';
import useUserRole from 'hooks/useUserRole';
import { HiddenV2Icon, EyeV2Icon } from 'images';
import platform from 'platform';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams, Link } from 'react-router-dom';
import { signIn } from 'store/actions/users';
import { patternEmail, patternFullWidthJp, patternNoFirstSpacing, STORAGE } from 'utils';
import Storage from 'utils/storage.js';
import HeaderListIdolV2 from 'components/HeaderV2/HeaderListIdolV2';
import FooterListIdolV2 from 'components/FooterV2/FooterListIdolV2';
import HomeQuickViewImageSigin from 'components/HomeQuickViewImageSigin';
import IdolDemoN1 from 'images/img-slice-n1.png';
import IdolDemoN2 from 'images/img-slice-n2.png';
import IdolDemoN3 from 'images/img-slice-n3.png';

import './SignIn.scss';
import TwitterIconNew from 'images/twitterIconNew';
import PostLogin from 'pages/postlogin';
const SignIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [userName, setUserName] = useState();
  const [password, setPassword] = useState();
  const [hiddenPassword, setHiddenPassword] = useState(true);
  const [checkEmail, setCheckEmail] = useState(false);
  const [checkPassword, setCheckPassword] = useState(false);
  const { t } = useTranslation();
  const { loading, error } = useSelector((state) => state.users);
  const [popupConfirmLogin, setPopupConfirmLogin] = useState(false);
  const { isIdol } = useUserRole();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'all',
  });
  const watchEmail = watch('email', '');
  const watchPassword = watch('password', '');
  const isDisabledButton =
    !watchPassword || !watchEmail || watchPassword?.length < 8 || checkEmail || checkPassword;
  const onSubmit = (data) => {
    setUserName(data.email);
    setPassword(data.password);
    const newData = {
      email: data.email,
      password: data.password,
      device_os: platform.description,
    };
    dispatch(
      signIn(
        newData,
        0,
        location?.search.substring(1) || location?.state?.link_share?.substring(1),
      )
    )
  };

  useEffect(() => {
    error?.messageId === 'SOMEONE_IS_LOGGED_IN_WITH_THIS_ACCOUNT_DO_YOU_WANT_TO_LOGIN?' &&
      setPopupConfirmLogin(true);
  }, [error, error?.messageId]);

  const handleConfirmLogin = () => {
    const data = { email: userName, password: password, device_os: platform.description };
    dispatch(
      signIn(
        data,
        1,
        (!location.search.includes('verify_age') && location?.search.substring(1)) ||
          location?.state?.link_share?.substring(1)
      )
    );
    setPopupConfirmLogin(false);
  };

  const initialImages = [
    {
      url: IdolDemoN1,
    },
    {
      url: IdolDemoN2,
    },
    {
      url: IdolDemoN3,
    },
  ];

  const UISignin = (
    <>
      <div
        className='block-div-signin sign-in-wrapper'
        style={{ backgroundColor: 'transparent' }}>
        <HeaderListIdolV2 />
        <div className='container-div'>
          <div className='container-left'>
            <HomeQuickViewImageSigin className='mt-50' initialImages={initialImages} />
          </div>
          <div className='container-login'>
            <div className='login-form'>
              <form className='form' onSubmit={(e) => e.preventDefault()} autoComplete='off'>
                <div className='d-center title text-center'>
                  <span>{t('SignIn.title.users')}</span>
                </div>
                <InputWrapper
                  id='fsite-email'
                  label={t('Common.email')}
                  required={true}
                  error={errors?.email ? errors?.email?.message : ''}>
                  <input
                    className='block-div-signin__active'
                    type='text'
                    placeholder={t('ValidateMsg.emailRequired')}
                    autoComplete='off'
                    maxLength={255}
                    {...register('email', {
                      required: ('ValidateMsg.emailRequired'),
                      validate: {
                        noFirstSpacing: (value) => {
                          return (
                            (value && patternNoFirstSpacing.test(value.toString().trim())) ||
                            ('ValidateMsg.emailRequired')
                          );
                        },
                        fullWidth: (value) => {
                          return (
                            (value && !patternFullWidthJp.test(value.toString().trim())) ||
                            ('ValidateMsg.TEXT_HALF_WIDTH')
                          );
                        },
                        email: (value) => {
                          return (
                            (value && patternEmail.test(value.toString().trim())) ||
                            ('ValidateMsg.EMAIL_FORMAT_INVALID')
                          );
                        },
                      },
                      onChange: (e) => {
                        const { value } = e.target;
                        !patternNoFirstSpacing.test(value.toString().trim()) ||
                        !patternEmail.test(value.toString().trim())
                          ? setCheckEmail(true)
                          : setCheckEmail(false);
                      },
                    })}
                  />
                </InputWrapper>
                <InputWrapper
                  id='fsite-password'
                  label={t('Common.password')}
                  required={true}
                  error={errors?.password ? errors?.password?.message : ''}>
                  <div className='password'>
                    <input
                      className='block-div-signin__active'
                      {...register('password', {
                        required: ('ValidateMsg.passwordRequired'),
                        minLength: {
                          value: 8,
                          message: ('ValidateMsg.minPassword'),
                        },
                        validate: {
                          noFirstSpacing: (value) =>
                            (value && patternNoFirstSpacing.test(value.toString().trim())) ||
                            ('ValidateMsg.passwordRequired'),
                        },
                        onChange: (e) => {
                          const { value } = e.target;
                          setValue('password', value?.replace(/\s/g, ''));
                          !patternNoFirstSpacing.test(value.toString().trim())
                            ? setCheckPassword(true)
                            : setCheckPassword(false);
                        },
                      })}
                      autoComplete='off'
                      placeholder={t('ValidateMsg.passwordRequired')}
                      type={`${hiddenPassword ? 'password' : 'text'}`}
                    />
                    {hiddenPassword ? (
                      <EyeV2Icon
                        className='eye-icon'
                        onClick={() => setHiddenPassword(false)}
                      />
                    ) : (
                      <HiddenV2Icon
                        className='eye-icon'
                        onClick={() => setHiddenPassword(true)}
                      />
                    )}
                  </div>
                </InputWrapper>
                <div className='flat-btn'>
                  <label className='reset-password'>{t('SignIn.forgotPassword')}</label>
                  &nbsp;
                  <label
                    className='here'
                    onClick={() => {
                      navigate('/forgot-password');
                    }}>
                    {t('SignIn.clickHere')}
                  </label>
                </div>
                <button
                  className={`btn ${isDisabledButton ? `disabled` : `active`}`}
                  type='submit'
                  disabled={isDisabledButton}
                  onClick={handleSubmit(onSubmit)}>
                  {t('SignIn.login')}
                </button>
                <button
                  className={`btn register cancel`}
                  type='button'
                  onClick={() => {
                    Storage.remove(STORAGE.TOKEN_TWITTER);
                    Storage.remove(STORAGE.SERECT_TOKEN_TWITTER);
                    window.location.href = '/sign-up';
                  }}>
                  {t('Common.signUp')}
                </button>

                <div className='or'>Or</div>
                <a
                  href={getEnv(
                    isIdol ? 'REACT_APP_API_TWITTER_CREATOR' : 'REACT_APP_API_TWITTER_FAN'
                  )}
                  className={`btn twitter`}
                  target='popup'
                  onClick={() =>
                    window.open(
                      getEnv(
                        isIdol ? 'REACT_APP_API_TWITTER_CREATOR' : 'REACT_APP_API_TWITTER_FAN'
                      ),
                      'popup',
                      'width=600,height=600,scrollbars=no,resizable=no'
                    )
                  }>
                  <TwitterIconNew /> {t('Common.buttonLoginTwitter')}
                </a>
              </form>
            </div>
          </div>
        </div>
        <PostLogin />
        <FooterListIdolV2 />
      </div>
    </>
  );
  const isSSOLogin = window.sessionStorage.getItem('check_sso');
  return (
    <>
      {loading && <PopupLoading className={'popup-loading'} />}
      {popupConfirmLogin && (
        <PopupConfirmFan
          isVisiblePopup={() => setPopupConfirmLogin(false)}
          colorSvg='#FF9F43'
          text={t('Popup.accoutLoginOtherBrowser')}
          className='popup-confirm'>
          <div className='btn cancel' onClick={() => setPopupConfirmLogin(false)}>
            {t('Common.buttonNo')}
          </div>
          <div className='ok btn' onClick={handleConfirmLogin}>
            {t('Common.buttonYes')}
          </div>
        </PopupConfirmFan>
      )}
      {isSSOLogin ? <PopupLoading className={'popup-loading'} /> : UISignin}
    </>
  );
};

export default SignIn;
