/* eslint-disable no-undef */
import { ImgCustom, PopupConfirmFan, PopupSharePost } from 'components';
import PopupViewFullMess from 'components/Account/PopupViewFullMess';
import PopupViewFullSaleContent from 'components/Account/PopupViewFullSaleContent';
import PopupViewFullTimeLineV2 from 'components/Account/PopupViewFullTimeLineV2';
import PopupSearchShortVideo from 'components/Account/timeline/PopupSearchShortVideo';
import InfiniteLoad from 'components/Paging/InfiniteLoad';
import QuotePostMyPage from 'components/Popup/quotePostMyPage';
import PopupRecordingDetailMyPage from 'components/Popup/recordingDetailMyPage';
import { newPathUser } from 'constants/layout/constant';
import { CommentIcon, HeartIcon, Image2Icon, NoSearchList, Video2Icon } from 'images';
import PropsType from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { ShimmerPostDetails } from 'react-shimmer-effects';
import { searchShortVideoAction } from 'store/actions/users';
import Blur_img from 'images/Blur_Right.png';
import {
  creatorDeleteArticle,
  creatorDeleteRecore,
  deleteCommentDetail,
  deleteReplyCommentDetail,
  fanFollowCreator,
  resetDetailPost,
  updatePostLikeDetail,
  getIDSearchVideo,
  setIdScrollBackRecommend,
} from 'store/actions/usersNew';
import {
  IMG_NO_WATER_MARK_MOBILE_2_COLUMN_SHORT_VIDEO,
  decryptPath,
  formatNumber,
  funcThumbnailIMG,
  isAdmin,
  isFirefox,
  linkS3Video,
} from 'utils/utils';
import { IMG_DEMO, STORAGE } from '../../utils';
import './index.scss';
import { StyledTimeLine } from './styles';
import { logUserProfile } from 'store/actions/myPage';
import Storage from 'utils/storage';
import MarkDownRead from 'components/MarkDownRead';
import { useFixedPopup } from 'hooks/v2/useFixedPopup';

export const SearchShortVideoPage = ({
  list,
  isSearch = false,
  loadingChangeTab,
  keyword,
  loadingClickSearch,
  setFirstLoading,
  page,
  setPage,
  flgSearch,
}) => {
  const isAuth = Storage.has(STORAGE.USER_ACCESS_TOKEN);
  const [popupCopyLink, setPopupCopyLink] = useState(null);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { dataUser, searchShortVideoPagination } = useSelector((state) => state.users);
  const { searchShortVideo, idSearchVideo, initPageSeachVideo } = useSelector(
    (state) => state.usersNew
  );
  const [searchParams] = useSearchParams();
  const keywordParams = searchParams.get('keyword');
  const [isLoading, setIsLoading] = useState(false);
  const [isShowPopup, setShowPopup] = useState({
    status: false,
    dataItem: {},
  });
  const [isShowPopupContent, setShowPopupContent] = useState({
    status: false,
    dataItemContent: {},
  });
  const [valueComments, setValueComments] = useState(() =>
    Array.from({ length: 0 }, () => '')
  );
  const [isShowPopupMess, setShowPopupMess] = useState({
    status: false,
    dataItemMess: {},
  });
  const [isShowPopupShort, setShowPopupShort] = useState({
    status: false,
    dataItemShort: {},
    indexIdx: 0,
  });
  const [popupDeleteComment, setPopupDeleteComment] = useState(null);
  const [isVisiblePopUpDeletePost, setIsVisiblePopDeletePost] = useState(null);
  const [isVisiblePopUpDeletePostComplete, setIsVisiblePopDeletePostComplete] =
    useState(false);
  const [isVisiblePopUpDeletePost1, setIsVisiblePopDeletePost1] = useState(null);
  const [isVisiblePopUpDeletePostComplete1, setIsVisiblePopDeletePostComplete1] =
    useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isVisibleDel, setIsVisibleDel] = useState(false);
  const [liveKey, setLiveKey] = useState();
  const [isVisibleQuote, setIsVisibleQuote] = useState(false);
  const [itemPostPopup, setItemPostPopup] = useState();
  const widthScreen = window.innerWidth;
  useFixedPopup(isVisibleQuote);
  useEffect(() => {
    if (list.length > 0) {
      setValueComments(() => Array.from({ length: list.length }, () => ''));
    }
  }, [list.length]);

  //const itemsPerPage = widthScreen > 575 ? 10 : 5;
  const itemsPerPage = 10;

  const msToTime = (s) => {
    // Pad to 2 or 3 digits, default is 2
    var pad = (n, z = 2) => ('00' + n).slice(-z);
    return (
      pad((s / 3.6e6) | 0) +
      ':' +
      pad(((s % 3.6e6) / 6e4) | 0) +
      ':' +
      pad(((s % 6e4) / 1000) | 0)
    );
  };

  const fetchNext = () => {
    const pages = searchShortVideoPagination?.total / searchShortVideoPagination?.per_page;
    if (pages > page) {
      setPage(page + 1);
      funCallApi(page + 1);
    }
  };

  useEffect(() => {
    if (idSearchVideo) {
      const idPost = document.getElementById(idSearchVideo.id);
      if (idPost) {
        idPost.scrollIntoView({
          block: 'center',
        });
      }
    }
  }, [idSearchVideo]);

  const funCallApi = useCallback(
    (rs) => {
      setIsLoading(true);
      dispatch(
        searchShortVideoAction(
          !flgSearch && !keywordParams ? '' : keyword,
          rs,
          itemsPerPage,
          (_response) => setIsLoading(false),
          (_errors) => () => setIsLoading(false)
        )
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page, keyword]
  );

  useEffect(() => {
    if (initPageSeachVideo < 1) {
      setIsLoading(true);
      setFirstLoading(true);
      dispatch(
        searchShortVideoAction(
          keyword,
          page,
          itemsPerPage,
          (_response) => {
            setFirstLoading(false);
            setIsLoading(false);
          },
          (_errors) => () => setIsLoading(false)
        )
      );
    }
  }, []);

  const navigateByLink = (postId, type) => {
    navigate(
      `${newPathUser}post${
        Boolean(type === 2) ? '' : Boolean(type === 3 || type === 4) ? '' : ''
      }/update/${postId}`
    );
  };

  const checkDisabledAction = (item) => {
    if (item?.is_followed === 0 && item?.follow_flag === 1 && dataUser?.id !== item?.user_id) {
      return true;
    }
    return false;
  };

  const handleLikePost = (post) => {
    dispatch(updatePostLikeDetail(post));
  };

  // DELETE COMMENT
  const handleDeleteComment = () => {
    const { id, type, idParent } = popupDeleteComment;
    type === 'comment'
      ? dispatch(deleteCommentDetail(id))
      : dispatch(deleteReplyCommentDetail(id, idParent));
    setPopupDeleteComment(false);
  };

  const handleFollowCreator = (dt) => dispatch(fanFollowCreator(dt?.user_id));

  const deletePostTimeLine = () => {
    setIsVisiblePopDeletePost(null);
    const { id } = isVisiblePopUpDeletePost;
    dispatch(creatorDeleteArticle(id, () => setIsVisiblePopDeletePostComplete(true)));
  };

  const deletePostRegular = () => {
    setIsVisiblePopDeletePost1(null);
    const { id } = isVisiblePopUpDeletePost1;
    dispatch(creatorDeleteRecore(id, () => setIsVisiblePopDeletePostComplete1(true)));
  };

  const isMiniUrl = (item) => {
    return (
      (item?.is_followed === 0 && item?.follow_flag === 1 && item?.user_id !== dataUser?.id) ||
      item?.is_media_display === 0
    );
  };
  //(1 column) type 1 ,4
  return (
    <>
      {isVisiblePopUpDeletePost1 && (
        <PopupConfirmFan
          isVisiblePopup={(e) => setIsVisiblePopDeletePost1(e)}
          colorSvg='#FF9F43'
          iconNote={true}
          text={t('Popup.confirmDeletePost')}>
          <div className='ok btn' onClick={deletePostRegular}>
            {t('Common.buttonYes')}
          </div>
          <div className='btn cancel' onClick={() => setIsVisiblePopDeletePost1(null)}>
            {t('Common.buttonNo')}
          </div>
        </PopupConfirmFan>
      )}
      {isVisiblePopUpDeletePostComplete1 && (
        <PopupConfirmFan
          isVisiblePopup={(e) => setIsVisiblePopDeletePostComplete1(e)}
          iconNote={true}
          text={t('Popup.deletePostSucess')}>
          <div className='ok btn' onClick={() => setIsVisiblePopDeletePostComplete1(false)}>
            {t('Common.buttonYes')}
          </div>
        </PopupConfirmFan>
      )}
      {isVisibleQuote && (
        <QuotePostMyPage
          closeModal={() => setIsVisibleQuote(false)}
          isVisibleQuote={isVisibleQuote}
          item={itemPostPopup}
        />
      )}
      {isVisible && (
        <PopupRecordingDetailMyPage
          closeModal={() => setIsVisible(false)}
          isVisible={isVisible}
          setIsVisibleDel={setIsVisibleDel}
          item={liveKey}
        />
      )}
      {isShowPopup?.status && (
        <PopupViewFullTimeLineV2
          item={isShowPopup?.dataItem}
          idx={isShowPopup?.idx}
          close={() => {
            setShowPopup({
              ...isShowPopup,
              status: false,
              dataItem: {},
            });
            dispatch(resetDetailPost());
          }}
        />
      )}

      {isShowPopupContent?.status && (
        <PopupViewFullSaleContent
          item={isShowPopupContent?.dataItemContent}
          close={() => {
            setShowPopupContent({
              ...isShowPopupContent,
              status: false,
              dataItemContent: {},
            });
            dispatch(resetDetailPost());
          }}
        />
      )}

      {isShowPopupMess?.status && (
        <PopupViewFullMess
          item={isShowPopupMess?.dataItemMess}
          close={() => {
            setShowPopupMess({
              ...isShowPopupMess,
              status: false,
              dataItem: {},
            });
            //dispatch(resetDetailPost());
          }}
        />
      )}

      {isShowPopupShort?.status && (
        <PopupSearchShortVideo
          item={isShowPopupShort?.dataItemShort}
          indexIdx={isShowPopupShort?.indexIdx}
          page={page}
          setPage={setPage}
          keyword={keyword}
          close={() => {
            setShowPopupShort({
              ...isShowPopupShort,
              status: false,
              dataItem: {},
            });
          }}
        />
      )}

      {isVisiblePopUpDeletePost && (
        <PopupConfirmFan
          isVisiblePopup={(e) => setIsVisiblePopDeletePost(e)}
          colorSvg='#FF9F43'
          iconNote={true}
          text={t('Popup.confirmDeletePost')}>
          <div className='ok btn' onClick={deletePostTimeLine}>
            {t('Common.buttonYes')}
          </div>
          <div className='btn cancel' onClick={() => setIsVisiblePopDeletePost(null)}>
            {t('Common.buttonNo')}
          </div>
        </PopupConfirmFan>
      )}
      {isVisiblePopUpDeletePostComplete && (
        <PopupConfirmFan
          isVisiblePopup={(e) => setIsVisiblePopDeletePostComplete(e)}
          iconNote={true}
          text={t('Popup.deletePostSucess')}>
          <div className='ok btn' onClick={() => setIsVisiblePopDeletePostComplete(false)}>
            {t('Common.buttonYes')}
          </div>
        </PopupConfirmFan>
      )}

      {popupDeleteComment && (
        <PopupConfirmFan
          isVisiblePopup={() => setPopupDeleteComment(false)}
          colorSvg='#FF9F43'
          text={t('Popup.confirmDeleteComment')}
          className='popup-delete'>
          <div className='ok btn' onClick={handleDeleteComment}>
            {t('Common.buttonDelete')}
          </div>
          <div className='btn cancel' onClick={() => setPopupDeleteComment(false)}>
            {t('Common.buttonCancel')}
          </div>
        </PopupConfirmFan>
      )}

      <div className='search-short-video-page'>
        {((!searchShortVideo?.length && isLoading) || loadingClickSearch) && (
          <div>
            <ShimmerPostDetails />
          </div>
        )}
        {popupCopyLink && (
          <PopupSharePost
            isVisiblePopup={() => setPopupCopyLink(false)}
            data={popupCopyLink || {}}
          />
        )}
        {searchShortVideo?.length > 0 && !loadingClickSearch ? (
          <InfiniteLoad
            className='two-column-video list-short-video'
            // loading={!!searchShortVideo.length && loading}
            loading={false}
            data={searchShortVideo}
            fetchNext={() => fetchNext()}>
            {searchShortVideo.map((post, index) => (
              <div
                key={index}
                id={post?.id}
                className='col-6 cs-video short-video-item'
                // style={{ width: '33%', height: '223px', position: 'relative' }}
              >
                <div
                  className='align-items-center justify-content-center h-100'
                  style={{ position: 'relative', cursor: 'pointer' }}
                  onClick={() => {
                    setShowPopupShort({
                      ...isShowPopupShort,
                      status: true,
                      dataItemShort: post,
                      indexIdx: index,
                    });
                  }}>
                  <div className='short-label-image'>
                    {post?.medias[0]?.type === 1 ? <Image2Icon /> : <Video2Icon />}
                  </div>
                  <div
                    className={`infor ${
                      post?.is_followed === 0 &&
                      post?.follow_flag === 1 &&
                      !isAdmin &&
                      dataUser?.id !== post?.user_id
                        ? 'follow-flag'
                        : ''
                    }`}>
                    <div
                      className='account-info'
                      onClick={() => {
                        dispatch(setIdScrollBackRecommend(`recomend`, pathname));
                        dispatch(
                          getIDSearchVideo({
                            id: post?.id,
                            tab: 4,
                            keyword: keyword,
                            page: page,
                          })
                        );
                        navigate(`${newPathUser}${post?.account_id}`);
                      }}>
                      <ImgCustom
                        className='account-avatar'
                        isPlaceholderAvatar
                        screen='avatar_mini_28_28'
                        alt='avatar'
                        src={decryptPath(post?.avatar)}
                      />
                      <span className='account-name account-name-new'>{post?.account_name}</span>
                      {post?.duration !== null && (
                        <div
                          style={{
                            position: 'absolute',
                            bottom: `${isMobile ? '34px': '40px'}`,
                            right: '10px',
                            zIndex: '10',
                          }}>
                          <div className='d-middle' style={{ color: '#fff' }}>
                            <p
                              style={{ fontWeight: '500', marginBottom: '0', marginLeft: '35px' }}>
                              {msToTime(post?.duration) || 0}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className='actions-item'>
                      <div className='content'>{post?.content}</div>
                      {/* <div className='content'><MarkDownRead content={post?.content} /></div> */}
                      <div className='actions-container'>
                        {!(
                          post?.is_followed === 0 &&
                          post?.follow_flag === 1 &&
                          !isAdmin &&
                          dataUser?.id !== post?.user_id
                        ) && (
                          <>
                            <div className='action-item'>
                              <HeartIcon
                                style={{
                                  zIndex: '2',
                                  color: post?.is_liked === 1 ? '#dc143c' : 'inherit',
                                }}
                                fill={post?.is_liked === 1 ? '#dc143c' : 'none'}
                                onClick={(e) => {
                                  if (!isAuth) {
                                    e.stopPropagation();
                                    dispatch(logUserProfile(post));
                                  } else {
                                    e.stopPropagation();
                                    !checkDisabledAction(post) && handleLikePost(post);
                                  }
                                }}
                                disabled={checkDisabledAction(post)}
                                className='cls-icon'
                              />

                              {formatNumber(post?.number_like) > 99
                                ? '99+'
                                : formatNumber(post?.number_like) || 0}
                            </div>
                            <div className='action-item'>
                              <CommentIcon
                                onClick={(e) => {
                                  if (isMobile) {
                                    navigate(`${newPathUser}post/${post?.id}`);
                                  } else {
                                    setShowPopupShort({
                                      ...isShowPopupShort,
                                      status: true,
                                      dataItemShort: post,
                                      indexIdx: index,
                                    });
                                  }
                                }}
                                className='cls-icon creator_post_live'
                              />
                              {formatNumber(post?.number_comment) > 99
                                ? '99+'
                                : formatNumber(post?.number_comment) || 0}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>

                  {post?.medias[0]?.public_flag === 0 ? (
                    <img src={IMG_NO_WATER_MARK_MOBILE_2_COLUMN_SHORT_VIDEO} alt='' />
                  ) : (
                    <>
                      {post?.medias[0]?.url ? (
                        <>
                          {post?.medias[0]?.type === 2 ? (
                            <StyledTimeLine.BlueImg
                              url={`${funcThumbnailIMG(
                                decryptPath(post?.medias[0]?.url, {
                                  isMini: true,
                                })
                              )}`}
                              height={isMobile ? 163 : 223}
                              width={222}>
                              <div className='bg-img'></div>
                              <ImgCustom
                                isMini={isMiniUrl(post)}
                                screen={
                                  isMiniUrl(post) ? 'mini_32_auto_pc' : 'image_preview_223_223'
                                }
                                src={funcThumbnailIMG(
                                  decryptPath(post?.medias[0]?.url, isMiniUrl(post))
                                )}
                                alt=''
                                style={{
                                  width: '100%',
                                  height: '100%',
                                  objectFit: 'contain',
                                  position: 'absolute',
                                  zIndex: '10',
                                  background: 'transparent',
                                }}
                                avatarErr={false}
                              />
                            </StyledTimeLine.BlueImg>
                          ) : (
                            <StyledTimeLine.BlueImg
                              url={`${decryptPath(post?.medias[0]?.url, { isMini: true })}`}
                              height={isMobile ? 163 : 223}
                              width={222}>
                              <div className='bg-img'></div>
                              <ImgCustom
                                isMini={isMiniUrl(post)}
                                screen={
                                  isMiniUrl(post) ? 'mini_32_auto_pc' : 'image_preview_223_223'
                                }
                                src={post?.medias[0]?.url ? post?.medias[0]?.url : IMG_DEMO}
                                alt=''
                                style={{
                                  width: '100%',
                                  height: '100%',
                                  objectFit: 'contain',
                                  position: 'absolute',
                                  zIndex: '10',
                                  background: 'transparent',
                                }}
                                avatarErr={false}
                              />
                            </StyledTimeLine.BlueImg>
                          )}
                        </>
                      ) : (
                        <>
                          <ImgCustom
                            src={IMG_DEMO}
                            className='img-default'
                            onClick={() => navigateByLink(post?.id)}
                          />
                        </>
                      )}
                    </>
                  )}
                </div>
                {post?.is_followed === 0 &&
                post?.follow_flag === 1 &&
                !isAdmin &&
                dataUser?.id !== post?.user_id ? (
                  <StyledTimeLine.WrapperBlur
                    style={{
                      backgroundImage: `${isFirefox ? `url(${Blur_img})` : 'transparent'}`,
                    }}>
                    <div className='text-follow'>{t('DetailCard.notYetFollow')}</div>
                    <button
                      className='button-follow-search-short'
                      onClick={(e) => {
                        if (!isAuth) {
                          e.stopPropagation();
                          dispatch(logUserProfile(post));
                        } else {
                          e.stopPropagation();
                          handleFollowCreator(post);
                        }
                      }}>
                      {t('Common.follow')}
                    </button>
                  </StyledTimeLine.WrapperBlur>
                ) : (
                  ''
                )}
              </div>
            ))}
            {isLoading && !!searchShortVideo.length && (
              <div className='loading-dots-custom'>
                <div className={`lds-ellipsis`}>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            )}
          </InfiniteLoad>
        ) : (
          !isLoading && (
            <div className='no-data'>
              <NoSearchList />
              {t('Search.listNull')}
            </div>
          )
        )}
      </div>
    </>
  );
};

SearchShortVideoPage.prototype = {
  list: PropsType.array.isRequired,
};
SearchShortVideoPage.defaultProps = {
  list: [],
};
