import { ImgCustom } from 'components';
import Loading from 'components/Paging/Loading';
import { newPathUser } from 'constants/layout/constant';
import {
  ChatIcon,
  Edit2Icon,
  FlagIcon,
  FlowUnFlowIcon,
  HeartActiveIcon,
  HeartIcon,
  ThreePointIcon,
  UnFlowIcon,
} from 'images';
import HideCommentIcon from 'images/HideCommentIcon';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getCommentSubHide, updateLikeCommentHideComment } from 'store/actions/commentHide';
import { convertToCurrentTime, decryptPath } from 'utils';
import './index.scss';
import { CommentHideItemChild } from './itemChild';
import { updateHiddenLastComment } from 'store/actions/usersNew';
import { useAvartar } from 'hooks/v2/useAvartar';

export const CommentHideItem = ({
  item,
  handleFollowCreator,
  handleHideComment,
  handleRedirect,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoadingLoadMoreComment, setIsLoadingLoadMoreComment] = useState(false);
  const [isVisiblePopup, setIsVisiblePopup] = useState(-1);

  const handleGetSubComment = (postId, page = 1) => {
    setIsLoadingLoadMoreComment(true);
    dispatch(
      getCommentSubHide(
        {
          page,
          limit: 20,
          postId,
        },
        () => setIsLoadingLoadMoreComment(false)
      )
    );
  };

  const handleLikeItem = (id, idParent, cb) => {
    dispatch(updateLikeCommentHideComment(id, idParent, cb));
  };

  const avatar = useAvartar(item?.status);

  return (
    <div className='wrap-item-hide-comment'>
      <div className='comment-hide-item-parent'>
        <div className='comment-hide-item-parent-left'>
          <div
            className='avatar'
            onClick={() => {
              handleRedirect(`hide-comment-${item?.id}`, () => {
                navigate(`/${item?.account_id}`);
              });
            }}>
            {!item?.avatar ||
            decryptPath(item?.avatar) === 'NULL' ? (
              <img className='img-common-blk' src={avatar} alt='avt'></img>
            ) : (
              <ImgCustom
                isPlaceholderAvatar
                className='img-avatar'
                screen='avatar_44_44'
                src={item?.avatar}
              />
            )}
          </div>
          <div className='info-user'>
            <div className='info-user-name-edit'>
              <div className='info-user-name'>{item?.account_name}</div>
              <div className='info-user-time-edit'>
                {convertToCurrentTime(item?.created_at, 'YYYY/MM/DD HH:mm')}
              </div>
              {item.is_edited === 1 && <Edit2Icon className='icon-edited-comment' />}
            </div>
            <div className='info-user-content'>{item?.content}</div>
            <div className='interactive'>
              <div className='interactive-item'>
                {item?.is_liked === 1 ? (
                  <HeartActiveIcon
                    className={`like-icon active`}
                    onClick={() => handleLikeItem(item?.id)}
                  />
                ) : (
                  <HeartIcon
                    className={`like-icon`}
                    onClick={() => handleLikeItem(item?.id)}
                  />
                )}
                <span className='count'>{item?.number_like}</span>
              </div>
              {!item?.parent_id && (
                <div className='interactive-item'>
                  <ChatIcon className='chat-icon' />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className='comment-hide-item-parent-right'>
          <ThreePointIcon
            className='icon-three'
            onBlur={() => setIsVisiblePopup(-1)}
            onClick={() => {
              setIsVisiblePopup(isVisiblePopup === item?.id ? -1 : item?.id);
            }}
          />
          {isVisiblePopup === item?.id && (
            <div className='popup-edit-comment'>
              <div
                className='item'
                onMouseDown={() => {
                  handleFollowCreator(item);
                  setIsVisiblePopup(-1);
                }}>
                {item?.is_followed === 0 ? <FlowUnFlowIcon /> : <UnFlowIcon />}
                <span className='text'>
                  {item?.is_followed === 0
                    ? t('comment.listComment.flow')
                    : t('comment.listComment.unflow')}
                </span>
              </div>
              <div
                className='item'
                onMouseDown={() => {
                  handleHideComment({
                    id: item?.id,
                    cb: (data) => {
                      dispatch(
                        updateHiddenLastComment(item?.post_id, data?.data?.comment_id, false)
                      );
                    },
                  });
                  setIsVisiblePopup(-1);
                }}>
                <HideCommentIcon color='#1A1A1A' />
                <span className='text'>{t('CommentHide.unHide')}</span>
              </div>
              <div
                className='item'
                onMouseDown={() => {
                  setIsVisiblePopup(-1);
                  handleRedirect(`hide-comment-${item?.id}`, () => {
                    navigate(`${newPathUser}post-report`, {
                      state: {
                        fan_id: item?.user_id,
                        fan_name: item?.account_name,
                        account_id: item?.account_id,
                      },
                    });
                  });
                }}>
                <FlagIcon />
                <span className='text'>{t('comment.listComment.iconFlag')}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className='wrap-item-child-hide-comment'>
        {item?.listSub?.length > 0 && (
          <>
            {item?.listSub?.map((itemChild) => (
              <CommentHideItemChild
                key={itemChild?.id}
                item={itemChild}
                paginationSub={item?.paginationSub}
                handleRedirect={handleRedirect}
                handleGetSubComment={(page) => {
                  handleGetSubComment(item?.id, page);
                }}
                handleLikeItem={(id, cb) => {
                  handleLikeItem(id, item?.id, cb);
                }}
              />
            ))}
          </>
        )}
        {item?.number_comment > 0 && !item?.listSub && !isLoadingLoadMoreComment && (
          <span
            className='view-more-hide-comment'
            onClick={() => {
              if (item?.number_comment > 0 && !item?.listSub) {
                handleGetSubComment(item?.id);
              }
            }}>
            {t('comment.listRepComment.viewMore')} ...
          </span>
        )}
      </div>

      {isLoadingLoadMoreComment && <Loading className='popup-loading' />}
    </div>
  );
};
