import { PopupLoading, ReactCalendar, Checkbox, HeaderListIdolV2 } from 'components';
// import DragNDrop from 'components/DragNDrop';
import { InputWrapper } from 'components/Input/InputWrapper';
import useCalendar from 'hooks/useCalendar';
import useOutsideClick from 'hooks/useOutsideClick';
import { ArrowLeftIcon, CalendarIcon, HiddenV2Icon, EyeV2Icon } from 'images';
import moment from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { getListCountry, signUpUser } from 'store/actions/users';
import {
  CustomOption,
  customStyles,
  // GENDER,
  // getPresignedUrlS3,
  isEmptyObject,
  patternCharacterLatinhNoSpace,
  // setFileNameS3,
  STORAGE,
  // TEN_MB,
  // uploadS3ByPresigned,
  patternNoFirstSpacing,
  customStylesReselect,
  CustomDropdownIndicator,
  patternCharacterLatinhUnderLineAndDot,
  patternFullWidthJp,
  patternEmail,
} from 'utils';
import storage from 'utils/storage';
import { getInfoUserTwitter } from '../../../store/actions/twitter';
import './index.scss';
import FooterListIdolV2 from 'components/FooterV2/FooterListIdolV2';
import { LIST_COUNTRY_EN, LIST_COUNTRY_JA } from 'constants/shared/country';
import useOutsideClickCustom from 'hooks/useOutsideClickCustom';
import ReCAPTCHA from "react-google-recaptcha";
import { getEnv } from 'configs/env';
export const mapCountriesToSelectlabel = (countries = []) =>
  countries.map((country) => ({
    label: country.country_name,
    value: country.id,
  }));

const SignUp = () => {
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
  } = useForm({
    mode: 'all',
  });
  const { t, i18n } = useTranslation();

  const userState = useSelector((state) => state.users);
  const { signUpFailed, loading, listCountries } = useSelector((state) => state.users);
  const [hiddenPassword, setHiddenPassword] = useState(true);
  const [dateActiveStart, setDateActiveStart] = useState(
    moment('2000/01/01', 'YYYY/MM/DD').toDate()
  );
  const [hiddenConfirmPassword, setHiddenConfirmPassword] = useState(true);
  const [focusNation, setFocusNation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isCheckPackagePaid, setIsCheckPackagePaid] = useState(true);
  const [viewedPolicyTerm, setViewedPolicyTerm] = useState({
    policy: false,
    term: false,
    checked: false,
  });
  const datePickerRef1 = useRef();
  const confirmPassword = useRef({});
  const checkTokenTwitter =
    storage.has(STORAGE.TOKEN_TWITTER) && storage.has(STORAGE.SERECT_TOKEN_TWITTER);
  // let dataS3Update = {};

  const {
    date,
    currentAge,
    toggleCalendar,
    onChangeDate,
    isVisibleCalendar,
    setIsVisibleCalendar,
  } = useCalendar();
  const password = useRef({});
  const datePickerRef = useRef({});
  useOutsideClick(datePickerRef, (e) => {
    const isCalendar =
      e.target.tagName === 'ABBR' || e.target.className?.includes('react-calendar__tile');
    if (e.target !== datePickerRef.current && isVisibleCalendar === true && !isCalendar) {
      setIsVisibleCalendar(false);
    }
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    checkTokenTwitter &&
      dispatch(
        getInfoUserTwitter(
          {
            token: storage.get(STORAGE.TOKEN_TWITTER),
            secret: storage.get(STORAGE.SERECT_TOKEN_TWITTER),
          },
          ({ data }) => {
            console.log(data);
            setValue('name_romaji', data?.provider);
            setValue('email', data?.email);
          }
        )
      );
  }, []);

  const optionListContent = useMemo(() => {
    return (
      listCountries?.map((item) => ({
        ...item,
        value: item?.id,
        label: item?.country_name,
      })) || []
    );
  }, [listCountries]);

  useEffect(() => {
    const language = i18n.language === 'jp' ? 'ja' : i18n.language;
    dispatch(getListCountry(language));
  }, [i18n.language]);

  useEffect(() => {
    signUpFailed && setIsLoading(false);
  }, [signUpFailed]);

  password.current = watch('password', '');
  confirmPassword.current = watch('password_confirmation');
  const [isVerified, setVerified] = useState(false);
  const [sitekey, setSitekey] = useState(getEnv('REACT_APP_KEY_CAPTCHA'));

  const handleVerify = (response) => {
    if (response) {
      setVerified(true);
    }
  };
  const [key, setKey] = useState(0);

  useEffect(() => {
    setKey(key + 1);
  }, [i18n.language]);

  // const watchFullname = watch('name_japanese');
  // const watchUsername = watch('name_romaji');
  const watchGender = watch('gender', 0);
  const watchEmail = watch('email');
  const watchPassword = watch('password');
  const watchPasswordConfirm = watch('password_confirmation');
  const watchAccountName = watch('username');
  const watchAccountId = watch('account_id');
  // const watchPhoneNumber = watch('phone');
  // const watchFrontSideCard = watch('img_front', undefined);
  // const watchBackSideCard = watch('img_back', undefined);
  const watchCountryId = watch('country_id');
  const watchPolicy = watch('allow_policy');
  const watchTerms = watch('allow_terms');

  const isDisabledButton =
    //!watchFullname ||
    !isVerified ||
    !watchPassword ||
    !watchPasswordConfirm ||
    !watchEmail ||
    // !watchUsername ||
    !(watchGender !== 0 || watchGender !== 1) ||
    !watchAccountName ||
    !watchAccountId ||
    //!watchPhoneNumber ||
    //!watchFrontSideCard?.type ||
    //!watchBackSideCard?.type ||
    !watchCountryId ||
    !watchPolicy ||
    !watchTerms ||
    currentAge < 18 ||
    !isEmptyObject(errors) ||
    !date;

  const onCustomValidate = (values) => {
    return new Promise((resolve, reject) => {
      let isError = false;
      if (!date || !values.birthday) {
        setError('birthday', {
          type: 'required',
          message: t('ValidateMsg.C002_VALIDATE_BIRTHDAY'),
        });
        isError = true;
      }
      return isError === true ? reject(values) : resolve(values);
    });
  };
  const onSubmit = async (data) => {
    const result = await onCustomValidate(data);
    if (result) {
      // setIsLoading(true);
      // const resultFront = await getPresignedUrlS3(
      //   'image',
      //   'uploads/output/images/identifier',
      //   setFileNameS3(watchFrontSideCard.name)
      // );
      // if (resultFront?.data) {
      //   dataS3Update = {
      //     ...dataS3Update,
      //     front_card: resultFront?.data?.file_path,
      //     front_card_pre_signed: resultFront?.data?.pre_signed,
      //     front_card_file: watchFrontSideCard,
      //   };
      // }

      // const resultBack = await getPresignedUrlS3(
      //   'image',
      //   'uploads/output/images/identifier',
      //   setFileNameS3(watchBackSideCard.name)
      // );
      // if (resultBack?.data) {
      //   dataS3Update = {
      //     ...dataS3Update,
      //     back_card: resultBack?.data?.file_path,
      //     back_card_pre_signed: resultBack?.data?.pre_signed,
      //     back_card_file: watchBackSideCard,
      //   };
      // }

      // const {
      //   front_card,
      //   front_card_pre_signed,
      //   front_card_file,
      //   back_card,
      //   back_card_pre_signed,
      //   back_card_file,
      // } = dataS3Update;

      // if (front_card) {
      //   await uploadS3ByPresigned(front_card_pre_signed, front_card_file);
      // }

      // if (back_card) {
      //   await uploadS3ByPresigned(back_card_pre_signed, back_card_file);
      // }

      //if (front_card && back_card) {
      if (moment(data?.birthday, 'YYYY/MM/DD', true).isValid()) {
        data.birthday = moment(data?.birthday, 'YYYY/MM/DD').format('YYYY-MM-DD');
      }
      await dispatch(
        signUpUser(
          {
            ...data,
            //...dataS3Update,
            //phone: ('' + data?.phone).replace(/\D/g, ''),
          },
          checkTokenTwitter
        )
      );
      //}
    }
  };

  const listCountriesToSelectlabel = useMemo(() => {
    const listCountriesRaw = i18n.language === 'jp' ? LIST_COUNTRY_JA : LIST_COUNTRY_EN || [];
    return (
      listCountriesRaw.map((country) => ({
        label: country.country_name,
        value: country.id,
      })) || []
    );
  }, [i18n.language]);

  useOutsideClickCustom((e) => {
    if (isVisibleCalendar) {
      const { className } = e?.target;
      if (
        e?.target !== datePickerRef1?.current &&
        className !== '' &&
        !className.includes('react-calendar')
      ) {
        toggleCalendar();
      }
    }
  });

  useEffect(() => {
    if (watchCountryId) {
      setValue('country_id', watchCountryId, {
        shouldTouch: true,
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  }, [i18n.language]);

  //START Gender
  const handleOnChangeGender = (e) => {
    const { value } = e.target;
    if (+value !== 1 || value !== '1') {
      setIsCheckPackagePaid(false);
    }
    if (+value === 1 || value === '1') {
      setIsCheckPackagePaid(true);
      setValue('gender', 1);
    } else {
      setIsCheckPackagePaid(false);
      setValue('gender', 0);
    }
  };

  const customStylesReSelct = {
    ...customStyles,
    menu: (provided) => ({
      ...provided,
      maxWidth: '100%',
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      return { ...provided, opacity, color: '#282727', fontWeight: 500 };
    },
  };

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  const customOnChangeDate = (date) => {
    onChangeDate(date);
    setValue('birthday', moment(date).format('YYYY-MM-DD'));
  };

  const handleCheckPolicyTerm = (type) => {
    const viewPolicyTermState = { ...viewedPolicyTerm, [type]: true };

    // if (viewPolicyTermState.term && viewPolicyTermState.policy && !viewedPolicyTerm.checked) {
    //   setValue('allow_policy', true);
    // }

    if (type === 'term') {
      setValue('allow_terms', true);
    } else {
      setValue('allow_policy', true);
    }

    const checked = viewedPolicyTerm.checked
      ? true
      : viewPolicyTermState.term && viewPolicyTermState.policy;

    setViewedPolicyTerm({ ...viewPolicyTermState, checked });
  };

  return (
    <div>
      {(loading || isLoading) && <PopupLoading className={'popup-loading'} />}
      <HeaderListIdolV2 />
      <div className='fansite-sign-up-creator'>
        <div className='box-sign-up'>
          <div className='fansite-form'>
            <div className='title'>
              {storage.has(STORAGE.TOKEN_TWITTER) && storage.has(STORAGE.SERECT_TOKEN_TWITTER)
                ? t('SignUp.titleTwitter')
                : t('SignUp.title')}
            </div>
            <InputWrapper
              id='fsite-account-name'
              label={t('Common.nameJP')}
              required={true}
              error={errors?.username ? errors?.username?.message : ''}>
              <input
                maxLength={30}
                className='fansite-sign-up-creator__active'
                {...register('username', {
                  required: ('ValidateMsg.USERNAME_IS_REQUIRED'),
                  maxLength: {
                    value: 30,
                    message: ('ValidateMsg.C002_VALIDATE_ACCOUNT_MAXSIZE'),
                  },
                })}
                onBlur={(e) => {
                  setValue('username', e.target.value.trim());
                  if (!e.target.value.trim()) {
                    setError('username', {
                      type: 'required',
                      message: ('ValidateMsg.USERNAME_IS_REQUIRED'),
                    });
                  }
                  // clearErrors('username');
                }}
                placeholder={t('ValidateMsg.USERNAME_IS_REQUIRED')}
              />
            </InputWrapper>

            <InputWrapper
              id='fsite-account_id'
              label={t('Common.idFan')}
              required={true}
              error={errors?.account_id ? errors?.account_id?.message : ''}>
              <input
                className='fansite-sign-up-creator__active'
                {...register('account_id', {
                  required: ('ValidateMsg.C002_VALIDATE_ACCOUNT_ID_REQUIRED'),
                  maxLength: {
                    value: 30,
                    message: ('ValidateMsg.C002_VALIDATE_ACCOUNT_ID_MAXSIZE'),
                  },
                  pattern: {
                    value: patternCharacterLatinhUnderLineAndDot,
                    message: ('ValidateMsg.C002_VALIDATE_ACCOUNT_ID_FORMAT'),
                  },
                })}
                onBlur={(e) => {
                  setValue('account_id', e.target.value.trim());
                  if (!e.target.value.trim()) {
                    setError('account_id', {
                      type: 'required',
                      message: ('ValidateMsg.C002_VALIDATE_ACCOUNT_ID_REQUIRED'),
                    });
                  }
                  // clearErrors('account_id');
                }}
                placeholder={t('ValidateMsg.C002_VALIDATE_ACCOUNT_ID_REQUIRED')}
              />
            </InputWrapper>

            {/* <InputWrapper
              id='fsite-japan-name'
              label={t('SignUp.nameJapanese')}
              required={true}
              error={errors?.name_japanese ? errors?.name_japanese?.message : ''}>
              <input
                {...register('name_japanese', {
                  required: t('ValidateMsg.C002_VALIDATE_NAME_JAPAN_REQUIRED'),
                  maxLength: {
                    value: 50,
                    message: t('ValidateMsg.C002_VALIDATE_NAME_JAPAN_MAXSIZE'),
                  },
                  pattern: {
                    value: /^[ａ-ｚ﻿Ａ-Ｚぁ-んァ-ン一-龥　]+$/gu,
                    message: t('ValidateMsg.C002_VALIDATE_NAME_JAPAN_FORMAT'),
                  },
                })}
                onBlur={(e) => {
                  setValue('name_japanese', e.target.value.trim());
                  if (!e.target.value.trim()) {
                    setError('name_japanese', {
                      type: 'required',
                      message: t('ValidateMsg.C002_VALIDATE_NAME_JAPAN_REQUIRED'),
                    });
                  }
                  // clearErrors('name_japanese');
                }}
                placeholder={t('ValidateMsg.C002_VALIDATE_NAME_JAPAN_REQUIRED')}
              />
            </InputWrapper> */}

            {/* <InputWrapper
              id='fsite-full-name'
              label={t('SignUp.nameRomaji')}
              required={true}
              error={errors?.name_romaji ? errors?.name_romaji?.message : ''}>
              <input
                maxLength={50}
                {...register('name_romaji', {
                  required: t('ValidateMsg.C002_VALIDATE_NAME_ROMAJI_REQUIRED'),
                  maxLength: {
                    value: 50,
                    message: t('ValidateMsg.C002_VALIDATE_NAME_ROMAJI_MAXSIZE'),
                  },
                  pattern: {
                    value: /^[A-Za-z 　]+$/gu,
                    message: t('ValidateMsg.nameRomajiFormat'),
                  },
                })}
                onBlur={(e) => {
                  setValue('name_romaji', e.target.value.trim());
                  if (!e.target.value.trim()) {
                    setError('name_romaji', {
                      type: 'required',
                      message: t('ValidateMsg.C002_VALIDATE_NAME_ROMAJI_REQUIRED'),
                    });
                  }
                  // clearErrors('name_romaji');
                }}
                placeholder={t('ValidateMsg.C002_VALIDATE_NAME_ROMAJI_REQUIRED')}
              />
            </InputWrapper> */}

            <InputWrapper id='fsite-birthday' label={t('Common.birthday')} required={true}>
              <div className='birthday'>
                <CalendarIcon className={`${date ? '' : ''}`} />
                <input
                  placeholder='YYYY/MM/DD'
                  {...register('birthday')}
                  onClick={toggleCalendar}
                  value={date}
                  readOnly
                  className={`${isVisibleCalendar && 'active-border'}`}
                  role='button'
                />
                {isVisibleCalendar && (
                  <ReactCalendar
                    // onChange={customOnChangeDate}
                    // onClickMonth={customOnChangeMonthYear}
                    // onClickYear={customOnChangeMonthYear}
                    inputRef={datePickerRef}
                    onActiveStartDateChange={({ action, activeStartDate, value, view }) => {
                      if (activeStartDate) {
                        setDateActiveStart(moment(activeStartDate).toDate());
                      }
                    }}
                    value={date ? moment(date, 'YYYY/MM/DD').toDate() : null}
                    defaultActiveStartDate={dateActiveStart}
                    onChange={(e) => customOnChangeDate(e)}
                  />
                )}
                {currentAge < 18 && (
                  <div className='fansite-form-input__error text-danger mt-2'>
                    {t('ValidateMsg.birthday18Age')}
                  </div>
                )}
              </div>
            </InputWrapper>

            <InputWrapper
              id='fsite-gender'
              required={true}
              error={errors?.gender ? errors?.gender?.message : ''}
              label={t('Common.gender')}>
              <div className='box-radio-checkbox'>
                <Checkbox
                  register={{ ...register('gender') }}
                  type='radio'
                  onChange={handleOnChangeGender}
                  checked={isCheckPackagePaid}
                  text={t('Common.men')}
                  value={1}
                  direction='horizontal'
                />
                <Checkbox
                  register={{ ...register('gender') }}
                  type='radio'
                  onChange={handleOnChangeGender}
                  checked={!isCheckPackagePaid}
                  text={t('Common.woman')}
                  value={0}
                  direction='horizontal'
                />
              </div>
            </InputWrapper>

            <InputWrapper
              id='fsite-email'
              label={t('Common.email')}
              required={true}
              error={errors?.email ? errors?.email?.message : ''}>
              <input
                className='fansite-sign-up-creator__active'
                type='text'
                placeholder={t('ValidateMsg.emailRequired')}
                maxLength={255}
                {...register('email', {
                  required: ('ValidateMsg.emailRequired'),
                  validate: {
                    fullWidth: (value) => {
                      return (
                        (value && !patternFullWidthJp.test(value.toString().trim())) ||
                        ('ValidateMsg.TEXT_HALF_WIDTH')
                      );
                    },
                    email: (value) => {
                      return (
                        (value && patternEmail.test(value.toString().trim())) ||
                        ('ValidateMsg.EMAIL_FORMAT_INVALID')
                      );
                    },
                  },
                })}
              />
            </InputWrapper>

            <InputWrapper
              id='fsite-nation'
              label={t('Common.country')}
              required={true}
              error={errors?.country_id ? errors?.country_id?.message : ''}>
              <div className='gender'>
                <Controller
                  name='country_id'
                  control={control}
                  rules={{ required: t('ValidateMsg.required') }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      ref={field.ref}
                      value={optionListContent?.find((e) => e.value === field.value)}
                      options={optionListContent}
                      isLoading={userState.listCountryLoading}
                      onFocus={() => setFocusNation(true)}
                      onBlur={() => setFocusNation(false)}
                      onClick={() => setFocusNation(false)}
                      onChange={(val) => {
                        field.onChange(val?.id);
                        setFocusNation(false);
                        setValue('country_name', val.label);
                      }}
                      placeholder={t('SignUp.placeHolderCountry')}
                      className='nation-zindex'
                      styles={customStylesReSelct}
                      components={{
                        Option: CustomOption,
                      }}
                      noOptionsMessage={() => t('Common.searchNoResult')}
                    />
                  )}
                />
                <ArrowLeftIcon className={`${focusNation ? 'focus' : 'no-focus'}`} />
              </div>
            </InputWrapper>

            {/* <InputWrapper
              id='fsite-identify-card'
              label={t('SignUp.identification')}
              required={true}
              error={
                errors?.img_front
                  ? errors?.img_front.message
                  : errors?.img_back
                  ? errors?.img_back.message
                  : ''
              }>
              <div className='row'>
                <div className='col-sm-12 col-md-6'>
                  <DragNDrop
                    label={t('SignUp.surfaceSide')}
                    name='img_front'
                    setValue={setValue}
                    register={register}
                    setError={setError}
                    clearErrors={clearErrors}
                    maxSize={TEN_MB}
                    isSelected={watchFrontSideCard}
                    accept={acceptedImageTypes.map((item) => item).join(',')}
                  />
                </div>
                <div className='col-sm-12 col-md-6'>
                  <DragNDrop
                    label={t('SignUp.backSide')}
                    name='img_back'
                    setValue={setValue}
                    register={register}
                    setError={setError}
                    clearErrors={clearErrors}
                    maxSize={TEN_MB}
                    isSelected={watchBackSideCard}
                    accept={acceptedImageTypes.map((item) => item).join(',')}
                  />
                </div>
              </div>
            </InputWrapper> */}
            <InputWrapper
              id='fsite-password'
              label={t('Common.password')}
              required={true}
              error={errors?.password ? errors?.password?.message : ''}>
              <div className='password'>
                <input
                  className='fansite-sign-up-creator__active'
                  {...register('password', {
                    required: ('ValidateMsg.passwordRequired'),
                    minLength: {
                      value: 8,
                      message: ('ValidateMsg.minPassword'),
                    },
                    validate: {
                      noFirstSpacing: (value) =>
                        (value && patternNoFirstSpacing.test(value.toString().trim())) ||
                        ('ValidateMsg.passwordRequired'),
                      checkPass: (value) =>
                        value !== confirmPassword.current && confirmPassword.current
                          ? ('ValidateMsg.passwordNoMatchRePassword')
                          : null,
                    },
                    onChange: (e) => {
                      const { value } = e.target;
                      setValue('password', value?.replace(/\s/g, ''));
                      !(
                        !patternNoFirstSpacing.test(value.toString().trim()) ||
                        !value === confirmPassword.current
                      ) && clearErrors(['password_confirmation', 'password']);
                    },
                  })}
                  autoComplete='false'
                  placeholder={t('ValidateMsg.passwordRequired')}
                  type={`${hiddenPassword ? 'password' : 'text'}`}
                />
                {hiddenPassword ? (
                  <EyeV2Icon onClick={() => setHiddenPassword(false)} />
                ) : (
                  <HiddenV2Icon onClick={() => setHiddenPassword(true)} />
                )}
              </div>
            </InputWrapper>
            <InputWrapper
              id='fsite-confirm-password'
              label={t('SignUp.rePassword')}
              required={true}
              error={
                errors?.password_confirmation ? errors?.password_confirmation?.message : ''
              }>
              <div className='confirm-password'>
                <input
                  className='fansite-sign-up-creator__active'
                  {...register('password_confirmation', {
                    required: ('ValidateMsg.rePasswordRequired'),
                    minLength: {
                      value: 8,
                      message: ('ValidateMsg.minPassword'),
                    },
                    validate: {
                      noFirstSpacing: (value) =>
                        (value && patternNoFirstSpacing.test(value.toString().trim())) ||
                        ('ValidateMsg.rePasswordRequired'),
                      checkPass: (value) =>
                        value === password.current ||
                        ('ValidateMsg.passwordNoMatchRePassword'),
                    },
                    onChange: (e) => {
                      const { value } = e.target;
                      setValue('password_confirmation', value?.replace(/\s/g, ''));
                      !(
                        !patternNoFirstSpacing.test(value.toString().trim()) ||
                        !value === password.current
                      ) && clearErrors(['password_confirmation', 'password']);
                    },
                  })}
                  placeholder={t('ValidateMsg.rePasswordRequired')}
                  type={`${hiddenConfirmPassword ? 'password' : 'text'}`}
                />
                {hiddenConfirmPassword ? (
                  <EyeV2Icon onClick={() => setHiddenConfirmPassword(false)} />
                ) : (
                  <HiddenV2Icon onClick={() => setHiddenConfirmPassword(true)} />
                )}
              </div>
            </InputWrapper>
            <div className='text-end mb-3'>
              <span className='text'>
                {i18n.language === 'jp' && t('Common.alreadyAccount')}
                <span onClick={() => navigate('/sign-in')} className='link'>
                  {i18n.language === 'jp' ? t('Common.clickHere') : t('Common.clickHereC002')}
                </span>
                {i18n.language === 'en' && t('Common.alreadyAccountC002')}
              </span>
            </div>
            <div className='agree-policy'>
              <input
                type='checkbox'
                id='allow_terms'
                className='form-check-input mt-0'
                name='allow_terms'
                {...register('allow_terms')}
                style={{
                  cursor: 'pointer',
                }}
                onClick={() => {
                  if (!watchTerms) {
                    openInNewTab('/terms-of-use');
                  }
                }}
              />
              <label className='ps-2'>
                {i18n.language === 'en' && t('Common.agreeTo')}
                <a
                  className='link'
                  target='_blank'
                  href='/terms-of-use'
                  onClick={() => handleCheckPolicyTerm('term')}>
                  {t('Common.termsOfUse')}
                </a>{' '}
                {i18n.language === 'jp' && t('Common.agreeTo')}
              </label>
            </div>
            <div className='agree-policy'>
              <input
                type='checkbox'
                id='allow_policy'
                className='form-check-input mt-0'
                name='allow_policy'
                {...register('allow_policy')}
                style={{
                  cursor: 'pointer',
                }}
                onClick={() => {
                  if (!watchPolicy) {
                    openInNewTab('/privacy-policy');
                  }
                }}
              />
              <label className='ps-2'>
                {i18n.language === 'en' && t('Common.agreeTo')}
                <a
                  className='link'
                  target='_blank'
                  href='/privacy-policy'
                  onClick={() => handleCheckPolicyTerm('policy')}>
                  {t('Common.privacyPolicy')}
                </a>
                {i18n.language === 'jp' && t('Common.agreeTo')}
              </label>
            </div>
            <label className='note-policy-init'>{t('Common.notePolicy')}</label>
            <div style={{ marginBottom: "15px"}}>
              <ReCAPTCHA
                key={key}
                sitekey={sitekey}
                onChange={handleVerify}
                hl={i18n.language === 'jp' ? 'ja' : 'en'}
              />
            </div>
            <InputWrapper
              id='fsite-submit'
              className={`button-submit ${isDisabledButton || isLoading ? 'disabled' : ''}`}>
              <button
                className='button-red-new'
                disabled={isDisabledButton || isLoading}
                onClick={handleSubmit(onSubmit, console.log)}>
                {t('SignUp.temporaryRegistration')}
              </button>
            </InputWrapper>
          </div>
        </div>
      </div>
      <FooterListIdolV2 />
    </div>
  );
};

export default SignUp;
