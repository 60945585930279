import { Checkbox, LayoutCenter, PopupConfirmFan, PopupLoading } from 'components';
import { newPathUser } from 'constants/layout/constant';
import withCreatorRole from 'hocs/withCreatorRole';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PostRemoval } from 'store/actions/feedback';
import { isEmptyObject, patternEmail, patternFullWidthJp } from 'utils/utils';
import './styles.scss';
import { isMobile } from 'react-device-detect';
import { InputWrapper } from 'components/Input/InputWrapper';
import ReCAPTCHA from 'react-google-recaptcha';
import { getEnv } from 'configs/env';
import ListTags from './list-tags';

const PostRemovalCreator = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'all',
  });
  const recaptchaRef = useRef();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isCheckTimePost, setIsCheckTimePost] = useState(false);
  const [isCheckTimePost1, setIsCheckTimePost1] = useState(false);
  const [isCheckIssue, setIsCheckIssue] = useState(false);
  const [listChecked, setListChecked] = useState([]);
  const [dataCheckBox, setDataCheckBox] = useState([]);
  const [isVerified, setVerified] = useState(false);
  const watchContennUrl = watch('content_url', '');
  const watchName = watch('name', '');
  const watchEmail = watch('email', '');
  const watchDescription = watch('issue_description', '');
  const { t, i18n } = useTranslation();
  const { loading, status } = useSelector((state) => state.feedback);
  const listTag = [
    {
      id: 1,
      tag: '同意のないコンテンツ',
      tag_en: 'Non-consensual Content',
    },
    {
      id: 2,
      tag: '未成年者向けコンテンツ',
      tag_en: 'Underage Material',
    },
    {
      id: 3,
      tag: '売春/人身売買',
      tag_en: 'Prostitution/Trafficking',
    },
    {
      id: 4,
      tag: '個人情報',
      tag_en: 'Personally Identifiable Information',
    },
    {
      id: 5,
      tag: '兵器',
      tag_en: 'Weapons',
    },
    {
      id: 6,
      tag: '薬物',
      tag_en: 'Drugs',
    },
    {
      id: 7,
      tag: '他の',
      tag_en: 'Other',
    },
  ];

  useEffect(() => {
    setDataCheckBox(listTag);
  }, []);

  const isValidString = useMemo(() => {
    const urls = watchContennUrl.split(/[, ]+/);
    for (let url of urls) {
      if (isValidUrl(url.trim())) {
        return true;
      }
    }
    return false;
  }, [watchContennUrl]);

  function isValidUrl(url) {
    const pattern = /^(http|https):\/\/[^ "]+$/;
    return pattern.test(url);
  }
  const onSubmit = (data) => {
    setIsCheckIssue(true);
    let url = watchContennUrl?.split(/[, ]+/);
    let urls = url && Array.isArray(url) ? url.join(' ').match(/(https?|ftp):\/\/[^\s/$.?#].[^\s]*/gi) : null;
    const newData = {
      ...data,
      language: i18n.language === 'en' ? 'en' : 'jp',
      content_url: urls?.join(', '),
      report_type:
        !isCheckTimePost && i18n.language === 'en'
          ? 'Abusive or Illegal Content'
          : !isCheckTimePost && i18n.language === 'jp'
          ? '不適切または違法なコンテンツ'
          : isCheckTimePost && i18n.language === 'en'
          ? 'Copyright Infringement'
          : '著作権侵害',
      depicted_in_content:
        !isCheckTimePost1 && i18n.language === 'en'
          ? 'Yes'
          : !isCheckTimePost1 && i18n.language === 'jp'
          ? 'はい'
          : isCheckTimePost1 && i18n.language === 'en'
          ? 'No'
          : 'いいえ',
      issues: listChecked?.join(', ')
    };
    dispatch(PostRemoval(newData, () => {
      recaptchaRef?.current?.reset();
      setVerified(false);
    }));
  };

  const handleVerify = (response) => {
    if (response) {
      setVerified(true);
    }
  };

  const [key, setKey] = useState(0);

  useEffect(() => {
    setKey(key + 1);
  }, [i18n.language]);

  useEffect(() => {
    status === true && setIsOpenModal(true);
  }, [status]);

  const handleCheckTimePost = (e) => {
    const { value } = e.target;
    +value === 1 ? setIsCheckTimePost(true) : setIsCheckTimePost(false);
  };

  const handleCheckTimePost1 = (e) => {
    const { value } = e.target;
    +value === 1 ? setIsCheckTimePost1(true) : setIsCheckTimePost1(false);
  };

  const handleOnchangeTags = (e, pack) => {
    const { checked } = e.target;
    if (checked) {
      setListChecked([...listChecked, i18n.language === 'en' ? pack.tag_en : pack.tag]);
      const newListChecked = dataCheckBox.map((item) => {
        if (pack?.id === item?.id) {
          item = { ...item, checked: true };
        }
        return item;
      });
      setDataCheckBox(newListChecked);
    } else {
      let indexCheck = listChecked.indexOf(i18n.language === 'en' ? pack.tag_en : pack.tag);
      if (indexCheck !== -1) {
        listChecked.splice(indexCheck, 1);
        setListChecked(listChecked);
      }
      const newListChecked = dataCheckBox.map((item) => {
        if (pack?.id === item?.id) {
          item = { ...item, checked: false };
        }
        return item;
      });
      setDataCheckBox(newListChecked);
    }
  };

  return (
    <LayoutCenter
      titlePage={t('Help.removalRequest')}
      notLogin={true}
      isShowLeftIcon={false}
      className={'title-page-creator page-legal'}
      uiMobile={true}>
      {loading && <PopupLoading className={'popup-loading'} />}
      {isOpenModal && (
        <PopupConfirmFan
          text={t('Popup.weWillRemovalNew')}
          colorSvg='#28c76f'
          className='popup-success-post-help'>
          <div
            className='ok btn'
            onClick={() => {
              if (status) {
                setIsOpenModal(false);
              }
            }}>
            {t('Common.Ok')}
          </div>
        </PopupConfirmFan>
      )}
      <div className='container-fluid w-100 bg-white policy-page creator-post-removal-request '>
        <form onSubmit={(e) => e.preventDefault()} className='fansite-form'>
          <div className='title-page-remove' style={{ marginTop: '10px' }}>
            {t('Help.titlePage')}
          </div>
          <div className='title-content-remove'>{t('Help.titlePostContent')}</div>
          <hr></hr>
          <div className='title-page-remove' style={{ marginTop: '24px' }}>
            {t('Help.requiredInformation')}
          </div>

          <div className={`row`}>
            <div className='col-12'>
              <div className={`block-field ${!isMobile && 'd-flex'}`}>
                <div
                  className='box-field d-flex'
                  style={{ flex: '1 1', marginRight: `${!isMobile && '24px'}` }}>
                  <InputWrapper
                    className='w-100'
                    id='fsite-account-name'
                    label={t('Help.legalName')}
                    required={true}
                    error={errors?.name ? errors?.name?.message : ''}>
                    <input
                      maxLength={60}
                      className='fsite-input'
                      {...register('name', {
                        required: t('ValidateMsg.C006_VALIDATE_NAME_ROMAJI_REQUIRED'),
                      })}
                      placeholder={t('Help.legalName')}
                    />
                  </InputWrapper>
                </div>
                <div className='box-field d-flex' style={{ flex: '1 1' }}>
                  <InputWrapper
                    className='w-100'
                    id='fsite-email'
                    label={t('Help.contractMail')}
                    required={true}
                    error={errors?.email ? errors?.email?.message : ''}>
                    <input
                      className='fsite-input'
                      type='text'
                      placeholder={t('Help.contractMail')}
                      maxLength={255}
                      {...register('email', {
                        required: 'ValidateMsg.emailRequired',
                        validate: {
                          fullWidth: (value) => {
                            return (
                              (value && !patternFullWidthJp.test(value.toString().trim())) ||
                              'ValidateMsg.TEXT_HALF_WIDTH'
                            );
                          },
                          email: (value) => {
                            return (
                              (value && patternEmail.test(value.toString().trim())) ||
                              'ValidateMsg.EMAIL_FORMAT_INVALID'
                            );
                          },
                        },
                      })}
                    />
                  </InputWrapper>
                </div>
              </div>
            </div>
          </div>

          <InputWrapper
            id='fsite-companyName'
            label={t('Help.companyName')}
            error={errors?.company_name ? errors?.company_name?.message : ''}>
            <input
              className='fsite-input'
              type='text'
              placeholder={t('Help.companyName')}
              maxLength={255}
              {...register('company_name')}
            />
          </InputWrapper>
          <div className='title mb-4'>
            <InputWrapper
              id='fsite-content_url'
              label={t('Help.urlMany')}
              required={true}
              error={errors?.content_url ? errors?.content_url?.message : ''}>
              <textarea
                maxLength={255}
                style={{ marginBottom: '-5px' }}
                {...register('content_url', {
                  required: t('ValidateMsg.urlRepost'),
                })}
                className='question-content fsite-input'
              />
            </InputWrapper>
            {watchContennUrl && !isValidString && (
              <p className='fansite-form-input__error text-danger'>
                {t('ValidateMsg.invalidUrl')}
              </p>
            )}
          </div>
          <div className='title mb-4'>
            <InputWrapper
              id='fsite-type-report_type'
              label={t('Help.reporting')}
              required={true}
              error={errors?.report_type ? errors?.report_type?.message : ''}
              className='box-type-sell'>
              <div className={`box-radio-checkbox post ${isMobile && 'mobile-checkbox'}`}>
                <Checkbox
                  type='radio'
                  value={0}
                  register={{ ...register('report_type') }}
                  onChange={handleCheckTimePost}
                  text={t('Help.illegalContent')}
                  checked={!isCheckTimePost}
                />
                <Checkbox
                  style={{ marginLeft: `${isMobile ? '0px' : '30px'}` }}
                  type='radio'
                  register={{ ...register('report_type') }}
                  onChange={handleCheckTimePost}
                  text={t('Help.infringement')}
                  value={1}
                  checked={isCheckTimePost}
                />
              </div>
            </InputWrapper>
          </div>

          <div className='title mb-4'>
            <InputWrapper
              id='fsite-type-depicted_in_content'
              label={t('Help.depicted')}
              required={true}
              className='box-type-sell'>
              <div className={`box-radio-checkbox post`}>
                <Checkbox
                  checked={!isCheckTimePost1}
                  type='radio'
                  value={0}
                  register={{ ...register('depicted_in_content') }}
                  onChange={handleCheckTimePost1}
                  text={t('Help.yesDepicted')}
                />
                <Checkbox
                  checked={isCheckTimePost1}
                  style={{ marginLeft: '30px' }}
                  type='radio'
                  register={{ ...register('depicted_in_content') }}
                  onChange={handleCheckTimePost1}
                  text={t('Help.noDepicted')}
                  value={1}
                />
              </div>
            </InputWrapper>
          </div>
          <InputWrapper
            error={errors?.tag_id1 ? errors?.tag_id1?.message : ''}
            required={true}
            id='fsite-tag_id1'
            label={t('Help.Issue')}>
            {/* <div className='row'>
                {listTag?.map((item, index) => {
                  if (index < isMobile && widthSreen < 767 ? 6 : 12) {
                    return (
                      <div
                        className={`form-group d-flex mt-3 col-6 col-sm-6 col-md-4`}
                        key={index}>
                        <input
                          type='checkbox'
                          {...register('tag_id1', {
                            required: t('ValidateMsg.C104_VALIDATE_PACKAGE'),
                          })}
                          id={item.id}
                          className='form-check-input'
                          name={`tag_id[${index}]`}
                          onClick={(e) => handleOnchangeTags(e, item)}
                          checked={item.checked}
                        />
                        &nbsp;&nbsp;
                        <label htmlFor={item.id}>
                          {i18n.language === 'jp' ? item.tag : item.tag_en}
                        </label>
                      </div>
                    );
                  }
                })}
              </div> */}
            <ListTags
              handleSelectTags={handleOnchangeTags}
              data={listTag}
              limit={7}
              flgListTag={false}
            />
            {/* {!isEmptyObject(errors) && listChecked?.length === 0 && (
                <p className='fansite-form-input__error text-danger'>
                  {t(`ValidateMsg.C104_VALIDATE_PACKAGE`)}
                </p>
              )} */}
          </InputWrapper>
          <div className='mt-4'>
            <InputWrapper required={true} id='fsite-category33' label={t('Help.desProblem')}>
              <div>{t('Help.desProblemText')}</div>
            </InputWrapper>
          </div>
          <div className='title mb-4'>
            <InputWrapper
              id='fsite-issue_description'
              error={errors?.issue_description ? errors?.issue_description?.message : ''}>
              <textarea
                maxLength={255}
                {...register('issue_description', {
                  required: t('ValidateMsg.IssueRequire'),
                })}
                style={{ marginBottom: '-5px', marginTop: '-10px' }}
                className='question-content fsite-input'
              />
            </InputWrapper>
          </div>

          <div style={{ marginBottom: '15px' }}>
            <ReCAPTCHA
              ref={recaptchaRef}
              key={key}
              sitekey={getEnv('REACT_APP_KEY_CAPTCHA')}
              onChange={handleVerify}
              size='normal'
              hl={i18n.language === 'jp' ? 'ja' : 'en'}
            />
          </div>
          <div className='mt-3' style={{ marginBottom: '15px' }}>
            {t('Help.desProblemText3')}
          </div>
          <div className={`button-submit`} style={{ marginBottom: '40px' }}>
            <button
              className='button-red-new'
              type='submit'
              onClick={handleSubmit(onSubmit)}
              disabled={
                !watchDescription ||
                !watchContennUrl ||
                !watchName ||
                !watchEmail ||
                listChecked?.length === 0 ||
                !isVerified ||
                (watchContennUrl && !isValidString) ||
                !isEmptyObject(errors)
              }>
              {t('Common.send')}
            </button>
            <button className='cancel' onClick={() => navigate(`${newPathUser}sign-in`)}>
              {t('Common.buttonCancel')}
            </button>
          </div>
        </form>
      </div>
    </LayoutCenter>
  );
};

export default withCreatorRole(PostRemovalCreator);
