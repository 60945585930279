import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  getDetailRecording,
  resetActionDataPopupRecor,
  setIdScrollBackRecording,
  updateDetailRecording,
} from 'store/actions/creator';
import { formatCoin, decryptPath, linkS3VideoM3U8 } from 'utils/utils';
import { PopupGeneral, ToggleButton } from 'components';
import './index.scss';
import { ProfileIcon } from 'images';
import { convertToCurrentTime, linkS3Video } from 'utils';
import VideoCustomDetail from 'pages/creator/layout/recording/VideoCustomDetail';
import { LOGO_THUMBNAIL_COCOFAN, NO_IMAGE_LIVESTREAM } from 'utils/constant';
import { newPathUser } from 'constants/layout/constant';
import LoadingIconV2 from 'images/LoadingIconV2';
import { useState } from 'react';

const PopupRecordingDetail = ({
  classNameCustom = '',
  closeModal,
  liveKey,
  isVisible,
  setIsVisibleDel,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { recordingDetail, loading } = useSelector((state) => state.creator);
  const { t } = useTranslation();

  const [isLoadedvideo, setIsLoadedVideo] = useState(false);

  const isShowRecording = useMemo(() => {
    if (recordingDetail?.is_show === 1 || recordingDetail?.is_show === 0) {
      if (recordingDetail?.is_show === 1) return true;
      else return false;
    }
  }, [recordingDetail?.is_show]);

  useEffect(() => {
    dispatch(getDetailRecording(liveKey));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible, liveKey]);

  const handleShowRecording = (key, data) => {
    dispatch(updateDetailRecording(key, data));
  };

  const imgLink =
    decryptPath(recordingDetail?.img_background) !== 'NULL'
      ? `${recordingDetail?.img_background}`
      : LOGO_THUMBNAIL_COCOFAN;

  return (
    <>
      {(!isLoadedvideo) && (
        <div className='layer-spinner'>
          <LoadingIconV2 className='spinner-icon' />
        </div>
      )}
      <>
        <PopupGeneral
          className={`popup-recording-detail ${classNameCustom}`}
          generalClass='popup-fan-detail-view'
          closeModal={() => {
            closeModal(false);
            dispatch(resetActionDataPopupRecor());
          }}
          title={recordingDetail?.live_title}>
          <div className='mt-3'>
            <VideoCustomDetail
              src={`${linkS3VideoM3U8}${decryptPath(recordingDetail?.recording) + '#t=0.1'}`}
              thumbnail={decryptPath(imgLink)}
              className='record-detail'
              // isDetailPost
              isLiveArchive
              handleNavigate={() => {}}
              onLoadedData={() => {
                setIsLoadedVideo(true);
              }}
            />
          </div>
          <div className='gap-4 gap-sm-5 mt-3'>
            <div className='d-flex gap-2 align-items-center view-number'>
              <ProfileIcon className='profile-icon' />
              <p className='mb-0 cls-text fw-normal'>
                {formatCoin(recordingDetail?.views_count ? recordingDetail?.views_count : 0)}
              </p>
            </div>
            <div className='wrap--text'>
              {/* <p className='mb-0 cls-title fw-bold'>{recordingDetail?.live_title} </p> */}
              <p className='cls-title-2nd cls-date fw-normal'>
                {convertToCurrentTime(recordingDetail?.live_date, 'YYYY/MM/DD HH:mm')}
              </p>
            </div>
          </div>
          <div className='btn-wrapper'>
            <div
              className={`button btn-sub`}
              onClick={() =>
                dispatch(
                  setIdScrollBackRecording(
                    `item-recording-${liveKey}`,
                    navigate(`${newPathUser}post/create`, {
                      state: {
                        live_title: recordingDetail?.live_title,
                        recording: recordingDetail?.recording,
                        thumbnailRecord: imgLink,
                      },
                    })
                  )
                )
              }>
              {t('Popup.addToSubscription')}
            </div>
            <div
              className={`button btn-sale`}
              onClick={() =>
                dispatch(
                  setIdScrollBackRecording(
                    `item-recording-${liveKey}`,
                    navigate(`${newPathUser}post/create`, {
                      state: {
                        live_title: recordingDetail?.live_title,
                        recording: recordingDetail?.recording,
                        thumbnailRecord: imgLink,
                      },
                    })
                  )
                )
              }>
              {t('Popup.addToSingleSale')}
            </div>
            <div className={`button btn-add-livestream`}>
              {t('Popup.showRecording')}
              <div className='toggle'>
                <div>
                  <ToggleButton
                    id='plan_notification'
                    checked={isShowRecording}
                    onChange={(e) => {
                      let data = 1;
                      if (e) {
                        data = 1;
                      } else {
                        data = 0;
                      }
                      handleShowRecording(recordingDetail?.id, {
                        is_show: data,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <div
              className={`button btn-del`}
              onClick={() => {
                setIsVisibleDel(true);
              }}>
              {t('Popup.delete')}
            </div>
          </div>
        </PopupGeneral>
      </>
    </>
  );
};

export default PopupRecordingDetail;
