import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './styles.scss';

import { formatToDateTimeString, convertToCurrentTime } from 'utils/utils';
import { ArrowDownIcon } from 'images';

import { useCheckPasswordLivestream } from 'hooks/useCheckPasswordLive';
import { ImgCustom } from '../../../../components';
import { newPathUser } from 'constants/layout/constant';
import { AVATAR_DEFAULT } from 'utils';

export default function EventItem({ key, item, idBack, setIdAndNavigate }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [toggleShowMore, setToggleShowMore] = useState(false);

  const handleMovePage = (item) => {
    const dateTimeCurrent = convertToCurrentTime(Date.now());
    if (!item.livestream) {
      if (item.type !== 2) {
        compareTwoDay(item.time_start_event, dateTimeCurrent)
          ? setIdAndNavigate(idBack, () =>
              navigate(`${newPathUser}post/update/${item.id}`, { state: { page: 'calendar' } })
            )
          : setIdAndNavigate(idBack, () =>
              navigate(`${newPathUser}post/${item.id}`, { state: { page: 'calendar' } })
            );
      } else {
        compareTwoDay(item.time_start_event, dateTimeCurrent)
          ? setIdAndNavigate(idBack, () =>
              navigate(`${newPathUser}post/update/${item.id}`, {
                state: { page: 'calendar' },
              })
            )
          : setIdAndNavigate(idBack, () =>
              navigate(`${newPathUser}post/${item.id}`, { state: { page: 'calendar' } })
            );
      }
    } else {
      item.check_live
        ? setIdAndNavigate(idBack, () =>
            navigate(
              `${newPathUser}live/${item?.livestream?.id}/${item?.livestream?.live_key}`,
              {
                state: { page: 'calendar' },
              }
            )
          )
        : setIdAndNavigate(idBack, () =>
            navigate(`${newPathUser}live/list-livestream`, { state: { page: 'calendar' } })
          );
    }
  };
  const compareTwoDay = (dateFirst, dateSecond) => {
    return new Date(dateFirst).getTime() > new Date(dateSecond).getTime();
  };
  const [paramsLive, setParamsLive] = useState({
    password: 0,
    liveKey: null,
  });
  const listCheckPassword = window.localStorage.getItem('cpw_ls');
  const { UISetPassword, handleSetPassword } = useCheckPasswordLivestream(
    (dt) => handleMovePage(dt),
    paramsLive.liveKey,
    false,
    paramsLive.password
  );
  const onWatchLive = (element) => {
    const { livestream } = element;
    livestream?.is_password &&
    listCheckPassword !== livestream?.id?.toString() &&
    livestream?.status !== 5
      ? handleSetPassword(element)
      : handleMovePage(element);
    setParamsLive({
      password: livestream?.is_password || 0,
      liveKey: livestream?.id || null,
    });
  };
  const isShowMore = () => {
    return item?.length > 1;
  };

  return (
    <>
      {paramsLive.password === 1 && UISetPassword}
      <>
        {isShowMore() && (
          <ArrowDownIcon
            className={`icon-show-more ${toggleShowMore && 'active'}`}
            onClick={() => setToggleShowMore(!toggleShowMore)}
          />
        )}
        {item.slice(0, toggleShowMore ? item?.length : 1).map((element) => (
          <div className='item' key={key}>
            <div className='avatar prevent-select' onClick={() => onWatchLive(element)}>
              <ImgCustom
                isPlaceholderAvatar
                screen='avatar_44_44'
                src={element?.avatar ? `${element?.avatar}` : AVATAR_DEFAULT}
                alt='avt'
              />
              {element?.livestream && element?.check_live && (
                <>
                  <div className='is-live'></div>
                  <div className='is-live-text'>
                    <label className='live'>{t('Common.live')}</label>
                  </div>
                </>
              )}
            </div>
            <div onClick={() => onWatchLive(element)}>
              <div className='name'>
                <label className='text'>{element?.account_name || 'Name creator'}</label>
              </div>
              <div className='description'>
                <label className={`content ${isShowMore() ? '' : 'content-full'}`}>
                  {!element.livestream ? t('Common.post') : t('Common.liveStreaming')}
                  {` - `}
                  {element?.type === 1 || element?.type === 4
                    ? element?.content
                    : element?.title}
                </label>
                <label className='time'>
                  {'  •  '} {formatToDateTimeString(new Date(element?.time_start_event))}
                </label>
              </div>
            </div>
          </div>
        ))}
      </>
    </>
  );
}
