import { newPathUser } from 'constants/layout/constant';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PopupGeneral } from '..';
import useOutsideClickCustom from '../../hooks/useOutsideClickCustom';
import './index.scss';

const PopupChoiceTypePost = ({ classNameCustom = '', closeModal, isVisible }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const ref = useRef();
  useOutsideClickCustom((e) => {
    isVisible && closeModal(false);
  });
  return (
    <>
      {isVisible && (
        <PopupGeneral
          className={`popup-choice-type-post ${classNameCustom}`}
          closeModal={() => {
            closeModal(false);
          }}
          ref={ref}
          hiddenCloseIcon
          noTitle>
          <div className='list-choice'>
            <div
              className='btn btn-navigate'
              onClick={() =>
                navigate(`${newPathUser}post-short/create`, { state: { type: 'normal' } })
              }>
              {t('FooterTabs.timeline')}
            </div>
            <div
              className='btn btn-navigate'
              onClick={() =>
                navigate(`${newPathUser}post-short/create`, { state: { type: 'short' } })
              }>
              {t('FooterTabs.short')}
            </div>
            <div
              className='btn btn-navigate'
              onClick={() => navigate(`${newPathUser}post/create`)}>
              {t('FooterTabs.postNormalType1')}
            </div>
            <div
              className='btn btn-navigate'
              onClick={() => navigate(`${newPathUser}post/create`)}>
              {t('FooterTabs.postContentType2')}
            </div>
          </div>
        </PopupGeneral>
      )}
    </>
  );
};

export default PopupChoiceTypePost;
