import Storage from 'utils/storage';
import { getEnv } from 'configs/env';
import img_demo from 'images/imgUploadDemo.jpg';
import upload_img_red from 'images/ImageRed.png';
import upload_video_red from 'images/VideoRed.png';
import upload_camera_red from 'images/CameraRed.png';
import logo_coco_fans from 'images/LogoCocoFans.png';
import logo_share_gram from 'images/LogoShareGram.png';
import logo_warning_18 from 'images/LogoWarningAge.png';
import logo_warning_stop_age from 'images/logoWarningStopAge.png';
import logo_not_found from 'images/logoNotFound.png';
import avatar from 'images/AvatarDefault.png';
import avatarDelUser from 'images/Ava_del.png';
import avatarDelAdmin from 'images/Ava_user_delpng.png';
import avatarBlockUser from 'images/Ava_blockUser.png';
import avatarBlockAdmin from 'images/Ava_blockAdmin.png';
import avatarBlock from 'images/Block.png';
import avatarDelete from 'images/Delete.png';
import btn_remove from 'images/BtnRemove.png';
import Ranking1 from 'images/ranking1.png';
import Ranking2 from 'images/ranking2.png';
import Ranking3 from 'images/ranking3.png';
import twitter_img from 'images/n_twitter.png';
import youtube_img from 'images/n_youtube.png';
import facebook_img from 'images/n_facebook.png';
import instagram_img from 'images/n_instagram.png';
import threads_img from 'images/n_threads.png';
import Play from 'images/Play2.png';
import ImgIcon from 'images/IconImageCreatorCreateLive.png';

import Rank1BigSize from 'images/rank1_bigsize.png';
import Rank2BigSize from 'images/rank2_bigsize.png';
import Rank3BigSize from 'images/rank3_bigsize.png';

import placehoder from 'images/PlaceHoder.png';
import thumbnail_cocofan_img from 'images/ThumbnailCoCoFanV2.png';

import {
  Stamp_6,
  Stamp_10,
  Stamp_12,
  Stamp_13,
  Stamp_19,
  Stamp_2,
  Stamp_23,
  Stamp_28,
  Stamp_3,
  Stamp_5,
  effect_13,
  effect_14,
  effect_16,
  effect_19,
  effect_21,
  effect_3,
  effect_4,
  effect_6,
  effect_7,
  effect_8,
} from 'images';
import { newPathUser } from 'constants/layout/constant';

export const LIST_POINT_ICON_DONATE = [
  { icon: Stamp_6, name: 'Stamp_6', iconGif: effect_13, nameGif: 'effect_13' },
  { icon: Stamp_10, name: 'Stamp_10', iconGif: effect_14, nameGif: 'effect_14' },
  { icon: Stamp_12, name: 'Stamp_12', iconGif: effect_16, nameGif: 'effect_16' },
  { icon: Stamp_13, name: 'Stamp_13', iconGif: effect_19, nameGif: 'effect_19' },
  { icon: Stamp_19, name: 'Stamp_19', iconGif: effect_21, nameGif: 'effect_21' },
  { icon: Stamp_2, name: 'Stamp_2', iconGif: effect_3, nameGif: 'effect_3' },
  { icon: Stamp_23, name: 'Stamp_23', iconGif: effect_4, nameGif: 'effect_4' },
  { icon: Stamp_28, name: 'Stamp_28', iconGif: effect_6, nameGif: 'effect_6' },
  { icon: Stamp_3, name: 'Stamp_3', iconGif: effect_7, nameGif: 'effect_7' },
  { icon: Stamp_5, name: 'Stamp_5', iconGif: effect_8, nameGif: 'effect_8' },
  { icon: Ranking1, name: 'Stamp_RanKing1', iconGif: Ranking1, nameGif: 'Ranking1' },
];

export const STORAGE = {
  USER_ACCESS_TOKEN: 'USER_ACCESS_TOKEN',
  CURRENT_USER_KEY: 'CURRENT_USER_KEY',
  IS_ADMIN: 'IS_ADMIN',
  IS_AGENCY: 'IS_AGENCY',
  VERIFY_AGE: 'VERIFY_AGE',
  PARAMS: 'PARAMS',
  IS_AFFILIATE: 'IS_AFFILIATE',
  SEARCH_POST: 'SEARCH_POST',
  SEARCH_CREATOR: 'SEARCH_CREATOR',
  SEARCH_CREATOR_BY_TAG: 'SEARCH_CREATOR_BY_TAG',
  ERR_UNAUTHORIZED: 'ERR_UNAUTHORIZED',
  MAINTAIN: 'MAINTAIN',
  LANGUAGE: 'LANGUAGE',
  TOKEN_TWITTER: 'TOKEN_TWITTER',
  SERECT_TOKEN_TWITTER: 'SERECT_TOKEN_TWITTER',
  USER_ID: 'USER_ID',
  USER_STATUS: 'USER_STATUS',
  CS_ADMIN_ID: 'CS_ADMIN_ID',
  AGENCY_TOKEN: 'AGENCY_TOKEN',
};

export const ENV = getEnv('REACT_APP_API_SERVER');
export const ENVUSER = getEnv('REACT_APP_API_SERVER_NEW');

export const getAccessToken = `Bearer ${Storage.get(STORAGE.USER_ACCESS_TOKEN)}`;

export const updateStatus = (status, statusInfo) => ({
  type: status,
  data: statusInfo,
});

const timezone = () => {
  const moment = require('moment-timezone');
  const currentZone = moment.tz.guess();
  const day = moment.utc();
  return day.tz(currentZone).format('Z');
};

export const configParamsGet = {
  method: 'GET',
  headers: {
    timezone: timezone(),
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: getAccessToken,
  },
};

export const configParamsGetNoToken = {
  method: 'GET',
  headers: {
    timezone: timezone(),
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
};

export const configParamsPostFormData = (data) => {
  return {
    method: 'POST',
    headers: {
      Authorization: getAccessToken,
    },
    body: data,
  };
};

export const configParamsPostFormDataWithToken = (data, token) => {
  return {
    method: 'POST',
    headers: {
      Authorization: token ? `Bearer ${token}` : getAccessToken,
    },
    body: data,
  };
};

export const configParamsPostJSON = (data, token = null) => {
  return {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: token ? `Bearer ${token}` : getAccessToken,
    },
    body: JSON.stringify(data),
  };
};

export const configParamsPostNoBody = {
  method: 'POST',
  headers: {
    Authorization: getAccessToken,
  },
};

export const configParamsPatchNoBody = {
  method: 'PATCH',
  headers: {
    Authorization: getAccessToken,
  },
};

export const configParamsPostNoBodyCustomToken = (token) => {
  return {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token || Storage.get(STORAGE.USER_ACCESS_TOKEN)}`,
    },
  };
};

export const configParamsPUT = (data) => {
  return {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: getAccessToken,
    },
    body: JSON.stringify(data),
  };
};

export const configParamsPatch = (data) => {
  return {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: getAccessToken,
    },
    body: JSON.stringify(data),
  };
};

export const configParamsPUTNoToken = (data) => {
  return {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify(data),
  };
};

export const configParamsPatchNoToken = (data) => {
  return {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify(data),
  };
};

export const configParamsDELETE = {
  method: 'DELETE',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: getAccessToken,
  },
};

export const configParamsDELETEByAdmin = (data) => {
  return {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: getAccessToken,
    },
    body: JSON.stringify(data),
  };
};

export const configParamsPOSTByAdmin = (data) => {
  return {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: getAccessToken,
    },
    body: JSON.stringify(data),
  };
};

export const IMG_DEMO = img_demo;
export const UPLOAD_IMG_RED = upload_img_red;
export const UPLOAD_VIDEO_RED = upload_video_red;
export const UPLOAD_CAMERA_RED = upload_camera_red;
export const LOGO_COCO_FANS = logo_coco_fans;
export const LOGO_SHARE_GRAM = logo_share_gram;
export const AVATAR_DEFAULT = avatar;
export const AVATAR_DEL_USER= avatarDelUser;
export const AVATAR_DEL_ADMIN= avatarDelAdmin;
export const AVATAR_BLOCK_USER= avatarBlockUser;
export const AVATAR_BLOCK_ADMIN= avatarBlockAdmin;
export const AVATAR_BLOCK= avatarBlock;
export const AVATAR_DELETE= avatarDelete;
export const BTN_REMOVE = btn_remove;
export const RANKING_1 = Ranking1;
export const RANKING_2 = Ranking2;
export const RANKING_3 = Ranking3;
export const NO_IMAGE_LIVESTREAM = placehoder;
export const PLAY = Play;
export const IMG_ICON = ImgIcon;
export const TWITTER_IMG = twitter_img;
export const YOUTUBE_IMG = youtube_img;
export const FACEBOOK_IMG = facebook_img;
export const INSTAGRAM_IMG = instagram_img;
export const THREADS_IMG = threads_img;
export const RANK1_BIG_SIZE = Rank1BigSize;
export const RANK2_BIG_SIZE = Rank2BigSize;
export const RANK3_BIG_SIZE = Rank3BigSize;
export const LOGO_WARNING_18 = logo_warning_18;
export const LOGO_WARNING_STOP_AGE = logo_warning_stop_age;
export const LOGO_THUMBNAIL_COCOFAN = thumbnail_cocofan_img;
export const LOGO_NOT_FOUND = logo_not_found;

export const ADMIN_TYPE = [0, 1, 6];

export const widthSreen = window.innerWidth;

export const formatCSV_YYYYMMDD = '\uFEFF';

export const ROLE_ACCOUNT_BANNED = [2, 3, 6];

export const ROLE_ACCOUNT_NO_CALL_API = [2, 3, 4, 6];

export const STATUS_ACCEPT = [1, 3, 4, 7];
export const STATUS_ACCEPT_RANKING = [1,3,4,7]

export const STATUS_NOT_APPROVE = [0, 3, 4, 7];

export const TWITTER_LOGIN = Storage.has(
  STORAGE.TOKEN_TWITTER && STORAGE.SERECT_TOKEN_TWITTER
);

export const ERR_LOGIN_TWITTER = [
  // remove flow QC 16/11
  // 'NOT_A_CREATOR_ACCOUNT',
  // 'NOT_A_FAN_ACCOUNT',
  'ACCOUNT_NOT_FOUND',
  'ACCOUNT_ALREADY_EXISTS_ANOTHER_LINK',
  'EMAIL_EXIST_NO',
];

export const MSG_ERR_HIDE = [
  'UNAPPROVED_ACCOUNT',
  'EXCEED_POINT_AVAILABLE',
  'TOO_SOME_SUBSCRIBERS',
];

export const REGEX_HTTP =
  /^(https?:\/\/[a-zA-Z0-9_+%-]+(\.[a-zA-Z0-9+\_%-]+)*(:[0-9]{1,5})?(\/[a-zA-Z0-9+()?#~=&\._%-@]*)*)?$/;

export const SECOND_DELAY_TIMELINE = 15;

export const SECOND_TOTAL_TO_LOAD_IMAGE_SHORT_VIDEO = 60;

export const LIST_PARAMS_CREATOR_REMOVE_IN_ROLE_ADMIN = [
  `${newPathUser}profile`,
  `${newPathUser}search`,
  `${newPathUser}help`,
  `${newPathUser}post-help`,
  `${newPathUser}surveys`,
  `${newPathUser}report`,
  `${newPathUser}message`,
  `${newPathUser}calendar`,
  `${newPathUser}sale-confirmation`,
  `${newPathUser}report`,
  `${newPathUser}timeline`,
  `${newPathUser}list-short-video`,
];

export const PAYMENT_CONST = {
  CODE_VENDO: 'CODE_VENDO',
  URL_REDIRECT: 'URL_REDIRECT',
  DATA_VENDO: 'DATA_VENDO',
  JWT_VENDO: 'JWT_VENDO',
  CODE_SUB: 'CODE_SUB',
  VERIFY_VENDO: 'VERIFY',
  CODE_ROLLBACK: 'CODE_ROLLBACK',
  CREDIT_CARD_ID: 'CREDIT_CARD_ID',
};
