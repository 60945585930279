import { LayoutCenter } from 'components';
import { useTranslation } from 'react-i18next';
import { POLICY, POLICY_JP } from '../constant';
import './styles.scss';
import { Link } from 'react-router-dom';
import { newPathUser } from 'constants/layout/constant';
import { STORAGE } from 'utils';
import Storage from 'utils/storage';

const Marketting = () => {
  const { t, i18n } = useTranslation();
  return (
    <>
      {i18n.language === 'en' ? (
        <LayoutCenter
          titlePage={'Human Trafficking and Marketing Policy'}
          notLogin={true}
          isShowLeftIcon={false}
          className={'title-page-creator page-legal'}
          uiMobile={true}>
          <div className={`container-fluid w-100 bg-white policy-page creator`}>
            <div>
              DRAGON BLADE inc. (hereinafter referred to as “the Company”) has established the
              following policy (hereinafter referred to as “the Policy”) regarding human
              trafficking and marketing:
            </div>
            <br></br>
            <div style={{ marginLeft: '30px' }}>
              <div>
                <div className='text' style={{ fontWeight: '700' }}>
                  1. The company is firmly opposed to human trafficking, sexual abuse, and
                  child exploitation (hereinafter referred to as “human trafficking, etc.”), as
                  these are serious violations of human rights that cause severe mental and
                  physical pain to victims. In addition, the Company monitors our services to
                  ensure that they are not used for human trafficking, etc. The Company will
                  respond promptly and appropriately from a humanitarian perspective, if the
                  Company discovers any possibility of encouraging human trafficking, etc.
                  through our monitoring.
                </div>
                <br></br>
                <div className='text'>
                  2. From the standpoint of human rights and public order and good morals, the
                  Company will not use child abuse, underage models, drugs or alcoholic
                  beverages in the Company’s marketing materials, and will not allow third
                  parties to use similar marketing techniques.
                </div>
              </div>
            </div>
            <div className='end-text'>[Established on September 16, 2024]</div>
          </div>
        </LayoutCenter>
      ) : (
        <LayoutCenter
          titlePage={'人身取引等及びマーケティングについてのポリシー'}
          notLogin={true}
          isShowLeftIcon={false}
          className={'title-page-creator page-legal'}
          uiMobile={true}>
          <div className={`container-fluid w-100 bg-white policy-page creator`}>
            <div>
              DRAGON BLADE inc
              （以下「当社」といいます。）は、人身取引等及びマーケティングについて、以下のとおりポリシー（以下「本ポリシー」といいます。）を定めます。
            </div>
            <br></br>
            <div style={{ marginLeft: '30px' }}>
              <div>
                <div className='text' style={{ fontWeight: '700' }}>
                  1.
                  当社は、人身取引、性的虐待及び児童搾取（以下「人身取引等」といいます）が、その被害者に対して深刻な精神的・肉体的苦痛をもたらす重大な人権侵害であることから、断固として人身取引等に反対します。また、当社の提供するサービスが人身取引等に利用されないように、本サービスのモニタリングを行い、モニタリングを通じて人身取引等を助長するおそれを発見した場合には、人道的観点から迅速かつ的確な対応を致します。
                </div>
                <br></br>
                <div className='text'>
                  2.
                  当社は、人権及び公序良俗の観点から、当社の行うマーケティングにおけるマーケティング素材に関し、児童虐待、未成年者モデル、薬物や酒類をマーケティング素材として利用せず、また、第三者をして同様の素材を用いたマーケティング手法を行わせないように致します。
                </div>
              </div>
            </div>
            <div className='end-text'>[2024年09月16日制定]</div>
          </div>
        </LayoutCenter>
      )}
    </>
  );
};

export default Marketting;
