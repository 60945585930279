/* eslint-disable react-hooks/exhaustive-deps */
import {
  AccountSidebar,
  HeaderListIdolV2,
  ImgCustom,
  LayoutCreatorRight,
  MenuSidebarCustom,
  PopupFanDetail,
} from 'components';
import { InputWrapper } from 'components/FormLogin';
import InfiniteLoad from 'components/Paging/InfiniteLoad';
import { newPathUser } from 'constants/layout/constant';
import withCreatorRole from 'hocs/withCreatorRole';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { creatorGetListMyFollow, creatorGetListPackage, resetIdSubscriber, setIdSubscriber } from 'store/actions/users';
import { AVATAR_DEFAULT, CustomOption, customStyles } from 'utils';
import './index.scss';
import NoSubscriber from 'images/NoSubscriber.svg';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ArrowLeftIcon } from 'images';
import { ShimmerPostDetails } from 'react-shimmer-effects';

const customStylesReselect = {
  ...customStyles,

  singleValue: (base) => ({
    ...base,
    fontWeight: 500,
    fontSize: 14,
    position: 'absolute',
    padding: '2px 20px 2px 0',
    color: '#1a1a1a',
  }),
  dropdownIndicator: (base) => ({
    ...base,
    display: 'block',
  }),
  control: (base, state) => {
    return {
      ...base,
      width: '260px',
      boxShadow: 'none',
      svg: {
        margin: '0 8px',
        color: '#666565',
        transform: state.menuIsOpen ? 'rotate(180deg)' : '',
      },
      borderColor: state.isFocused ? '#1480FF' : '#E5E6EB',
    };
  },
  menu: (base, state) => ({
    ...base,
    border: 'none !important',
    borderRadius: '8px',
    overflow: 'hidden',
    zIndex: 2,
    width: 260,
    boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.06)',
  }),
  option: (base, state) => {
    return {
      ...base,
      position: 'relative',
      backgroundColor: state.isSelected ? '#FFDBDB' : 'transparent',
      color: '#1A1A1A',

      '&.content-right': {
        color: '#DC143C',
      },
      svg: {
        color: '#DC143C',
        display: state.isSelected ? 'block' : 'none',
        position: 'absolute',
        right: 4,
        alignItems: 'center',
        margin: 'auto',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        marginLeft: '4px',
      },
      fontWeight: state.isSelected ? 500 : 400,
      padding: '12px 24px 12px 16px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      maxWidth: '100%',
      display: 'block',
    };
  },
};
const ListSubscriber = () => {
  const { t, i18n } = useTranslation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [idFan, seIdFan] = useState(false);
  const [creatorListPackage, setCreatorListPackage] = useState([]);
  const { listSubscriber = [], pagination, dataUser, idScrollBackSubcriber, initPageSub } = useSelector((state) => state.users);
  const [page, setPage] = useState(idScrollBackSubcriber && initPageSub ? +initPageSub : 1);
  const packageIdParams = searchParams.get('packageId');
console.log('packageIdParams',packageIdParams)
  const [packageId, setPackageId] = useState(Number(packageIdParams) || idScrollBackSubcriber?.selectBack || '');

  useEffect(() => {
    if (idScrollBackSubcriber) {
      const idPost = document.getElementById(idScrollBackSubcriber.id);
      if (idPost) {
        idPost.scrollIntoView({
          block: 'center',
        });
      }
    }
  }, [idScrollBackSubcriber]);

  useEffect(() => {
    if(initPageSub < 1) {
      const top = document.getElementById('app_cocofans_0');
      top && top.scrollIntoView({ top: 0, left: 0, block: 'start' });
      setIsLoading(true);
      dispatch(creatorGetListMyFollow(page, 10, packageId, () => setIsLoading(false)));
    }
  }, [page, packageId, idScrollBackSubcriber]);

  useEffect(() => {
    dispatch(
      creatorGetListPackage('', '', ({ data }) =>
        setCreatorListPackage(
          data.map((item) => ({
            ...item,
            value: item.id,
            label: item.title,
          }))
        )
      )
    );
  }, []);

  const funCallApi = useCallback(
    (rs, id) => {
      setIsLoading(true);
      dispatch(creatorGetListMyFollow(rs, 10, id, () => setIsLoading(false)));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page]
  );

  // api list page
  const fetchNext = () => {
    const pages = pagination?.total / pagination?.per_page;
    if (pages > page) {
      funCallApi(page + 1, packageId);
      setPage(page + 1);
    } 
  };

  const onChangeSelect = (value) => {
    navigate(`/subscriber?packageId=${value?.value}`);
    setPackageId(value.value);
    setPage(1);
    funCallApi(1, value?.value);
  };

  const OptionsSelect = useMemo(
    () => [{ label: t('Creator.all'), value: '' }],
    [i18n.language]
  );

  return (
    <>
      {isVisible && (
        <PopupFanDetail
          closeModal={() => setIsVisible(false)}
          isVisible={isVisible}
          id={idFan}
        />
      )}
      <LayoutCreatorRight
        noButtonBack
        titlePage={t('MenuSiderbar.creator.subscribed')}
        className='list-subscriber'>
        <form className='fansite-form' onSubmit={(e) => e.preventDefault()}>
          <InputWrapper id='fsite-select'>
            <div className='select'>
              <Select
                options={[OptionsSelect[0], ...creatorListPackage]}
                value={creatorListPackage?.find((e) => e.value === packageId)}
                isSearchable={false}
                isClearable={false}
                defaultValue={!packageIdParams && !idScrollBackSubcriber?.selectBack && OptionsSelect[0]}
                onChange={onChangeSelect}
                placeholder={t('Creator.all')}
                width='120px'
                className='select-sub'
                styles={customStylesReselect}
                components={{
                  Option: CustomOption,
                  DropdownIndicator: () => <ArrowLeftIcon />,
                }}
              />
            </div>
          </InputWrapper>
        </form>
        <div className='subscriber-page-creator'>
          <InfiniteLoad
            loading={isLoading && listSubscriber.length > 0}
            data={listSubscriber || []}
            fetchNext={fetchNext}>
            <div className='list-subscriber-creator'>
              {isLoading && !listSubscriber.length ? (
                <div className='shimmer-loading'>
                  <ShimmerPostDetails />
                </div>
              ) : (
                <>
                  {listSubscriber.length > 0 ? (
                    listSubscriber.map((item, index) => (
                      <div className='account-subscriber account' key={index} id={item?.id}>
                        <div
                          className={`content-left`}
                          onClick={() => {
                            dispatch(setIdSubscriber({id: item?.id, selectBack: packageId}))
                            navigate(`${newPathUser}${item?.account_id}`);
                          }}>
                          <ImgCustom
                            isPlaceholderAvatar
                            src={item?.avatar ? `${item?.avatar}` : AVATAR_DEFAULT}
                            alt='avatar'
                            screen={item?.avatar ? 'recommend_top_56_56' : ''}
                            className='avatar'
                          />
                          <div>
                            <span className='name'>
                              {item?.account_name || t('Creator.accountNoName')}
                            </span>

                            <span className='list-sub'>
                              {item?.packages.map((x) => (
                                <div className='item-list-sub' key={x?.id}>
                                  {x?.title}
                                </div>
                              ))}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className='no-data'>
                      <ImgCustom src={NoSubscriber} alt='NoSubscriber' screen='' />
                      {t('Creator.SubscribersList.noSubscribersList')}
                    </div>
                  )}
                </>
              )}
            </div>
          </InfiniteLoad>
        </div>
      </LayoutCreatorRight>
    </>
  );
};

export default withCreatorRole(ListSubscriber);
