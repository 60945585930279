import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ImgCustom } from '..';
import { LOGO_COCO_FANS, STORAGE } from '../../utils/constant';
import Storage from 'utils/storage';
import {
  MessageEmailV2,
  VisaV2Icon,
  JcbV2Icon,
  MasterCardV2Icon,
  FacebooklV2,
  TwitterV2,
  InstagramV2,
} from 'images';
import ImgRTA from 'images/RTA.png';
import './FooterListIdolV2.scss';
import Epoch from 'images/Epoch.png';
import { newPathUser } from 'constants/layout/constant';
const FooterListIdolV2 = ({ className }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <div className={`block-div 2 ${className}`}>
      <div>
        {/* <div className='footer-list-idolv2'>
          <div className='content-left'>
            <div className='contact-us'>{t('Footer.FooterV2.contactUs')}</div>
            <div className='text'>{t('Footer.FooterV2.discounts')}</div>
          </div>
          <div className='content-right'>
            <div className='text-right'>
              <div style={{ marginTop: '-2px' }}>
                <MessageEmailV2 />
              </div>
              <input
                className='input-mail'
                placeholder={t('Footer.FooterV2.enterYourEmail')}></input>
            </div>
            <div className='button'>
              <button className='btn button-submit button-red-new'>{t('Footer.FooterV2.subscribe')}</button>
            </div>
          </div>
        </div> */}

        <div className='block-center row'>
          <div className='col-lg-12' style={{ marginBottom: '16px', paddingLeft: '0px' }}>
            <Link to='/' reloadDocument>
              <ImgCustom
                src={LOGO_COCO_FANS}
                height={32}
                width={143}
                alt='logo'
                className='logo-cocofans d-lg-block img-transparent'
              />
            </Link>
          </div>
          <div className='col-lg-7 content-left'>
            <div className='text-big'>{t('Footer.FooterV2.bladeINC')}</div>
            <div className='text'>
              5348 Vegas Dr, Las Vegas, NV USA 89108 <br />{' '}
              424-571-0772 | info@share-gram.com
            </div>
            {/* {(location.pathname === '/privacy-policy' || location.pathname === '/terms-of-use' || location.pathname === '/contact' || location.pathname === '/usc2257') &&
              <img
                style={{ cursor: 'pointer' }}
                onClick={() => window.open('https://www.rtalabel.org', '_blank')}
                width='88px'
                height='31px'
                src={ImgRTA}
                alt=''
              />
            } */}
          </div>
          <div className='col-lg-5 content-right'>
            <div className='row'>
              <div className='col-lg-6 text'>
                {/* <div className='text'>{t('Footer.FooterV2.weCccept')}</div>
                <div className='block-card'>
                  <VisaV2Icon />
                  <MasterCardV2Icon />
                  <JcbV2Icon />
                </div> */}
              </div>
              <div className='col-lg-6 text block-follow'>
                <div className='text'>{t('Footer.FooterV2.followUs')}</div>
                <div className='block-card mt-1'>
                  <Link to={`https://twitter.com/sharegram_info`} target='_blank' className='item-link'>
                    <TwitterV2 />
                    {/* <FacebooklV2 />
                    <InstagramV2 /> */}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr style={{ maxWidth: '1380px', margin: '35px auto 10px auto' }} />
        <div className='contact-about'>
          <div className='content-left'>
            {/* <Link className='item-link' to='javascript:void(0)'>
              {t('Footer.FooterV2.aboutUs')}
            </Link> */}
            {/* <Link className='item-link' to="javascript:void(0)">
              {t('Footer.FooterV2.help')}
            </Link> */}
            {!!Storage.get(STORAGE.USER_ACCESS_TOKEN) ? (
              <Link to={`${newPathUser}help`} target='_blank' className='item-link'>
                {t('Footer.FooterV2.help')}
              </Link>
            ) : (
              <Link to={`/contact`} target='_blank' className='item-link'>
                {t('Help.contact')}
              </Link>
            )}
            <Link className='item-link' to='/privacy-policy' target='_blank'>
              {t('Footer.FooterV2.privacyPolicy')}
            </Link>
            <Link className='item-link' to='/terms-of-use' target='_blank'>
              {t('Footer.FooterV2.termsOfUse')}
            </Link>
            <Link to='/marketing-policy' target='_blank' className='item-link'>
                {t('Footer.FooterV2.marketting')}
              </Link>
              <Link className='item-link mobile-2257' to='/usc2257' target='_blank'>
              {t('Footer.FooterV2.usc2257')}
            </Link>
            <Link className='item-link mobile-removal' to='/removal-request' target='_blank'>
              {t('Footer.FooterV2.removalRequest')}
            </Link>
          </div>
          <div className='content-right d-flex mobile-logo'>
            <div className='text'>
              {t('Footer.FooterV2.logoRerved')}
            </div>
            <div className='d-flex' style={{ marginLeft: "10px" }}>
              <img
                style={{ cursor: 'pointer'}}
                onClick={() => window.open('https://www.rtalabel.org', '_blank')}
                width='88px'
                height='31px'
                src={ImgRTA}
                alt=''
              />
              <img
                style={{ cursor: 'pointer', marginLeft: '8px' }}
                onClick={() => window.open('https://epoch.com', '_blank')}
                width='88px'
                height='31px'
                src={Epoch}
                alt=''
              />
            </div>
          </div>
        </div>
        <br />
      </div>
    </div>
  );
};

export default FooterListIdolV2;
