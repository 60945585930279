import {
  configParamsGet,
  configParamsPostFormData,
  configParamsPostJSON,
  ENV,
  updateStatus,
} from 'utils/constant';
import { callApi } from 'utils/request';
import { queryLanguage, queryString } from 'utils/utils';
import { TYPES } from './index';

const API = {
  getListFeedBack: (lan) => `${ENV}/system-feedbacks?language=${lan}`,
  getListCategoryFeedBack: (language) =>
    `${ENV}/feedback-categories?active_flag=1&language=${language}`,
  PostFeedBack: () => `${ENV}/feedbacks`,
  PostContract: () => `${ENV}/contacts`,
  getReportCategories: (queries) => `${ENV}/report-categories?${queryString(queries)}`,
  postUserReport: () => `${ENV}/reports`,
  PostRemoval: () => `${ENV}/removal-request`,
};

const getListFeedBack = (lan) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_LIST_FEEDBACK_REQUEST));
    callApi(
      API.getListFeedBack(lan),
      configParamsGet,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.GET_LIST_FEEDBACK_SUCCESS, {
            data: response,
            lan
          })
        );
      },
      async (error) => {
        await dispatch(updateStatus(TYPES.GET_LIST_FEEDBACK_FAILED, { error: error.errors }));
      }
    );
  };
};

const getListCategoryFeedBack = (language) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_LIST_FFEDBACK_CATEGORY_REQUEST));
    callApi(
      API.getListCategoryFeedBack(language),
      configParamsGet,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.GET_LIST_FFEDBACK_CATEGORY_SUCCESS, {
            data: response?.data,
          })
        );
      },
      async (error) => {
        await dispatch(
          updateStatus(TYPES.GET_LIST_FFEDBACK_CATEGORY_FAILED, { error: error.errors })
        );
      }
    );
  };
};

const PostFeedBack = (data, isLogin, handleFunc) => {
  const formData = new FormData();
  const { category_content, category_title, question_title, question_content, language } = data;
  formData.append('category_content', category_content?.label);
  formData.append('category_title', category_title);
  formData.append('question_title', question_title);
  formData.append('question_content', question_content);
  if(isLogin) {
    formData.append('email', data?.email);
    formData.append('name', data?.name);
  }
  formData.append('language', language);
  return (dispatch) => {
    dispatch(updateStatus(TYPES.POST_FEEDBACK_REQUEST));
    callApi(
      isLogin ? API.PostContract() : API.PostFeedBack(),
      configParamsPostFormData(formData),
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.POST_FEEDBACK_SUCCESS, {
            data: response,
          })
        );
        handleFunc && handleFunc(response);
      },
      (error) => {
        dispatch(updateStatus(TYPES.POST_FEEDBACK_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const PostRemoval = (data, handleFunc) => {
  const formData = new FormData();
  formData.append('name', data?.name);
  formData.append('email', data?.email);
  formData.append('company_name', data?.company_name);
  formData.append('content_url', data?.content_url);
  formData.append('report_type', data?.report_type);
  formData.append('depicted_in_content', data?.depicted_in_content);
  formData.append('issues', data?.issues);
  formData.append('issue_description', data?.issue_description);
  formData.append('language', data?.language);
  return (dispatch) => {
    dispatch(updateStatus(TYPES.POST_REMOVE_REQUEST));
    callApi(
      API.PostRemoval(),
      configParamsPostFormData(formData),
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.POST_REMOVE_SUCCESS, {
            data: response,
          })
        );
        handleFunc && handleFunc(response);
      },
      (error) => {
        dispatch(updateStatus(TYPES.POST_REMOVE_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const getReportCategories = (queries) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_REPORT_CATEGORIES_REQUEST));
    callApi(
      API.getReportCategories(queries),
      configParamsGet,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.GET_REPORT_CATEGORIES_SUCCESS, {
            data: response?.data,
          })
        );
      },
      async (error) => {
        await dispatch(
          updateStatus(TYPES.GET_REPORT_CATEGORIES_FAILED, { error: error.errors })
        );
      }
    );
  };
};

const postUserReport = (data, handleFunc) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.POST_USER_REPORT_REQUEST));
    callApi(
      API.postUserReport(),
      configParamsPostJSON(data),
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.POST_USER_REPORT_SUCCESS, {
            data: response,
          })
        );
        handleFunc && handleFunc(response);
      },
      (error) => {
        dispatch(updateStatus(TYPES.POST_USER_REPORT_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};
const resetActionFeedBack = () => (dispatch) => {
  dispatch(updateStatus('RESET_ACTION_FEEDBACK'));
};
const resetStatus = () => (dispatch) => {
  dispatch(updateStatus('RESET_STATUS_FEEDBACK'));
};

const setIdScrollBackHelp = (id, cb) => {
  return (dispatch) => {
    dispatch(
      updateStatus('SET_ID_SCROLL_BACK_HELP', {
        data: {
          id,
        },
      })
    );
    cb && cb();
  };
};

export {
  getListFeedBack,
  getListCategoryFeedBack,
  PostFeedBack,
  getReportCategories,
  postUserReport,
  resetActionFeedBack,
  resetStatus,
  setIdScrollBackHelp,
  PostRemoval
};
