import { BackToPage } from 'components';
import FooterVender from 'components/FooterVender/FooterVender';
import useRedirect from 'hooks/useRedirect';
import useUserRole from 'hooks/useUserRole';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { getUserInfo } from '../../store/actions/users';
import './Layout.scss';
import FooterListIdolV2 from 'components/FooterV2/FooterListIdolV2';

const LayoutCenter = ({
  children,
  titlePage,
  uiMobile,
  avatarHeader,
  className,
  notLogin = false,
  exactLink,
  isShowLeftIcon,
  isAdmin = false,
  navigateCreator = false,
  accountId = '',
  classNameBack = '',
}) => {
  const { pathname } = useLocation();
  const params = useParams();
  const error = useSelector((state) => state.handleError.error);
  useRedirect(error);
  const dispatch = useDispatch();
  const { isIdol } = useUserRole();

  useEffect(() => {
    if (notLogin) {
      return true;
    } else {
      dispatch(getUserInfo());
    }
  }, [pathname, params]);

  const location = useLocation();

  return (
    <>
      <div
        className={`layout-center ${className} ${
          isIdol ? 'title-page-creator' : 'title-page-fan'
        }`}>
        <div className='box-content'>
          <BackToPage
            title={titlePage}
            uiMobile={uiMobile}
            avatar={avatarHeader}
            exactLink={exactLink}
            isShowLeftIcon={isShowLeftIcon}
            isAdmin={isAdmin}
            navigateCreator={navigateCreator}
            accountId={accountId}
            className={classNameBack}
          />
          <div
            className={`layout-content ${
              location?.pathname === '/language/setting' && 'language-setting'
            }`}
            style={{ width: isIdol && notLogin ? '100%' : '' }}>
            {children}
          </div>
          <div className='footer-vender'>
            {/* <FooterVender /> */}
            <div className=''>
              <FooterListIdolV2 />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LayoutCenter;
