import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { ImgCustom } from '..';
import './MessageItem.scss';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const MessageItem = ({
  avatar,
  name = '',
  isAdmin,
  isAdminPage,
  message = '',
  dateTime = '',
  className = '',
  handleDelete = false,
  isRead = false,
  clickDetail,
  format = 'YYYY/MM/DD HH:mm',
  id,
  item
}) => {
  const { t } = useTranslation();
  const { idMessScroll } = useSelector((state) => state.message);
  useEffect(() => {
    if (idMessScroll) {
      const idPost = document.getElementById(idMessScroll.id);
      if (idPost) {
        idPost.scrollIntoView({
          block: 'center',
        });
      }
    }
  }, [idMessScroll]);

  return (
    <div className={`message-item ${className} ${isAdmin && 'avatar_admin'}`} id={id}>
      <div className={`content-left ${!isRead && 'not-read'}`} onClick={clickDetail}>
        <ImgCustom
          isPlaceholderAvatar
          screen={(!isAdmin && item?.user?.avatar) && 'avartar_navbar_60_60'}
          src={avatar}
          alt='avatar'
        />
        <div className='info'>
          <span className='name'>{isAdmin ? `${t('Acount.MessageItem.name')}` : name}</span>
          <span className='message'>{message}</span>
        </div>
      </div>
      <div className='content-right'>
        <span className='date-time'>{moment.utc(dateTime).local().format(format)}</span>
        {handleDelete && !isAdminPage && (
          <div className={`delete-btn`} onClick={() => handleDelete()}>
            {t('Common.buttonDelete')}
          </div>
        )}
      </div>
    </div>
  );
};

export default MessageItem;
