/* eslint-disable import/no-anonymous-default-export */
import { TYPES } from '../actions';
import _ from 'lodash';

const initialState = {
  listPostCreator: [],
  status: null,
  loading: false,
  loadingPostComment: false,
  error: null,
  pagination: {},
  statusPostComment: null,
  detailPost: null,
  commentDetail: [],
  loadingAction: false,
  data: [],
  listPostFollowed: [],
  listComment: null,
  listCommentPost: [],
  loadingComment: false,
  loadingPostDetail: false,
  paginationComment: {},
  loadingPost: true,
  statusPost: null,
  statusDeletePost: false,
  replyCommentDetail: [],
  loadingCommentDetail: false,
  loadingRepCommentDetail: false,
  loadingFollow: false,
  likeComment: false,
  likeRepComment: false,
  statusPostRepComment: null,
  statusEditComment: null,
  statusEditRepComment: null,
  statusBuyPost: null,
  isMinimal: false,
  listPostShortVideo: [],
  listPostTimeLine: [],
  listPostCreator: [],
  paginationTimeLine: {},
  listHighLight: [],
  loadingHighline: false,
  list3imgMypage: [],
  loadingBuyPost: false,
  check_buy_package: null,
  flagHiddenPopup: false,
  searchShortVideo: [],
  isLoadDataSearchShort: true,
  isLoadingFollowCreate: false,
  isCheckResetDetailPost: true,
  listRanking: [],
  searchSaleContent: [],
  searchSaleContentPagination: {},
  searchPostTimeline: [],
  searchPostTimelinePagination: {},
  dataInfoOther: null,
  initPageTimeline: 0,
  idTimeline: {},
  initPageHome: 0,
  idHomePage: null,
  loadingUser: false,
  idSearchPost: {},
  initPageSearchPost: 0,
  idSearchSale: {},
  initPageSeachSale: 0,
  initPageSeachVideo: 0,
  idSearchVideo: {},
  dataBookmark: [],
  listBookmarkPagination: {},
  idFavoriteTimline: {},
  initPageFavor: 0,
  renderLikeCMTTimeline: { id: 0, flg: false },
  renderLikeCMTHomepage: { id: 0, flg: false },
  renderLikeCMTBookmark: { id: 0, flg: false },
  renderLikeCMTSearchPost: { id: 0, flg: false },
  paginationPostHome: {},
  idScrollBackRecommend: null,
  pathUrlScrollBack: null,
  typeTopList: null,
  tabTimeline: null,
  isShortMypage: {},
  idKYC: {},
  listPostNoLogin: [],
  paginationTimeLineNoLogin: {},
  initPageTimelineNoLogin: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.LIST_POSTS_CREATOR_REQUEST:
      return {
        ...state,
        status: action.type,
        loading: true,
        // listPostCreator: [],
      };
    case TYPES.LIST_POSTS_TIME_LINE_REQUEST:
    case TYPES.LIST_POSTS_NO_LOGIN_REQUEST:
    case TYPES.GET_COMMENT_DETAILS_REQUEST:
      return {
        ...state,
        loadingCommentDetail: true,
      };
    case TYPES.DELETE_COMMENT_DETAILS_REQUEST:
    case TYPES.DELETE_REPLY_COMMENT_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TYPES.POST_COMMENT_DETAILS_REQUEST:
      return {
        ...state,
        statusPostComment: null,
        loadingPostComment: true,
        // loading: true,
      };
    case TYPES.POST_NORMAL_BUY_PACKAGES_REQUEST:
      return {
        ...state,
        loadingBuyPost: true,
        check_buy_package: false,
        flagHiddenPopup: false,
      };
    case TYPES.POST_LIKE_CREATOR_REQUEST:
      return {
        ...state,
        status: action.type,
      };
    case TYPES.POST_RETWEET_REQUEST_REQUEST:
      return {
        ...state,
        status: action.type,
        isLoadingFollowCreate: true,
      };
    case TYPES.GHIM_POST_MYPAGE_REQUEST:
      return {
        ...state,
        status: action.type,
        isLoadingFollowCreate: true,
      };
    case TYPES.FOLLOW_USER_COMMENT_DETAILS_REQUEST:
    case TYPES.FOLLOW_OR_UN_FOLLOW_USER_REQUEST:
      return {
        ...state,
        isLoadingFollowCreate: true,
      };
    case TYPES.UPDATE_DETAIL_RECORDING_V2_REQUEST:
    case TYPES.UPDATE_COMMENT_POST_REQUEST:
    case TYPES.CREATE_COMMENT_POST_REQUEST:
    case TYPES.LIST_3_IMG_MY_PAGE_REQUEST:
    case TYPES.EDIT_COMMENT_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TYPES.LIST_BOOKMARK_TIMELINE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TYPES.LIST_BOOKMARK_TIMELINE_SUCCESS:
      return {
        ...state,
        loading: false,
        initPageFavor: action?.data?.pagination?.current_page,
        dataBookmark:
          action?.data?.pagination?.current_page === 1
            ? action?.data?.data
            : [...state?.dataBookmark, ...action?.data?.data],
        listBookmarkPagination: action?.data?.pagination,
      };
    case TYPES.LIST_BOOKMARK_TIMELINE_FAILED:
      return {
        ...state,
        error: action.data.error,
        loading: false,
      };
    case TYPES.EDIT_COMMENT_DETAILS_SUCCESS:
      state.commentDetail = state.commentDetail.map((item) => {
        if (item.id === action.data.data.id) {
          return {
            ...item,
            metaPost: action.data?.dataMeta,
            content: action.data.data.content,
            updated_at: action.data.data.updated_at,
            is_edited: 1,
          };
        }
        return item;
      });

      state.listPostTimeLine = state.listPostTimeLine.map((item) => {
        if (item?.latest_comment?.id === action.data.data.id) {
          const newItem = { ...item };
          newItem.latest_comment.content = action.data.data.content;
          newItem.latest_comment.created_at = action.data.data.updated_at;
          newItem.latest_comment.is_edited = 1;
          newItem.latest_comment.metaPost = action.data?.dataMeta;
          return newItem;
        }
        return item;
      });

      state.listPostCreator = state.listPostCreator.map((item) => {
        if (item?.latest_comment?.id === action.data.data.id) {
          const newItem = { ...item };
          newItem.latest_comment.content = action.data.data.content;
          newItem.latest_comment.created_at = action.data.data.updated_at;
          newItem.latest_comment.is_edited = 1;
          newItem.latest_comment.metaPost = action.data?.dataMeta;
          return newItem;
        }
        return item;
      });

      return {
        ...state,
        commentDetail: state.commentDetail,
        statusEditComment: true,
        loading: false,
      };

    case TYPES.GET_META_COMMENT_OPG_SUCCESS:
      if (action.data.parentId) {
        state.replyCommentDetail = state.replyCommentDetail.map((item) => {
          if (item.id === action.data.commentId) {
            return {
              ...item,
              metaPost: action.data.data,
            };
          }
          return item;
        });
      } else {
        state.listPostTimeLine = state.listPostTimeLine.map((item) => {
          if (item?.latest_comment?.id === action.data.commentId) {
            const newItem = { ...item };
            newItem.latest_comment.metaPost = action.data.data;
            return newItem;
          }
          return item;
        });
        state.listPostCreator = state.listPostCreator.map((item) => {
          if (item?.latest_comment?.id === action.data.commentId) {
            const newItem = { ...item };
            newItem.latest_comment.metaPost = action.data.data;
            return newItem;
          }
          return item;
        });
        state.commentDetail = state.commentDetail.map((item) => {
          if (item.id === action.data.commentId) {
            return {
              ...item,
              metaPost: action.data.data,
            };
          }
          return item;
        });
      }

      return {
        ...state,
        listPostCreator: state.listPostCreator,
        commentDetail: state.commentDetail,
        replyCommentDetail: state.replyCommentDetail,
      };
    case TYPES.EDIT_COMMENT_DETAILS_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.data.error,
        loading: false,
      };
    case TYPES.EDIT_REPLY_COMMENT_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
        statusEditRepComment: null,
      };
    // LIKE COMMENT DETAIL
    case TYPES.LIKE_COMMENT_DETAIL_REQUEST:
      return {
        ...state,
        likeComment: true,
      };
    case TYPES.LIKE_REPLY_COMMENT_DETAILS_REQUEST:
      return {
        ...state,
        likeRepComment: true,
      };
    case TYPES.BEFORE_LIKE_COMMENT_DETAIL_SUCCESS:
      const { data } = action?.data;
      const likeCount = data.is_liked ? data.number_like - 1 : data.number_like + 1;
      const isLiked = data.is_liked ? 0 : 1;
      state.listPostTimeLine = state.listPostTimeLine.map((post) => {
        if (+post.id === +data?.post_id) {
          return {
            ...post,
            latest_comment: {
              ...post.latest_comment,
              number_like: likeCount,
              is_liked: isLiked,
            },
          };
        }
        return post;
      });

      state.detailPost = {
        ...state.detailPost,
        latest_comment: {
          ...state.detailPost?.latest_comment,
          number_like: likeCount,
          is_liked: isLiked,
        },
      };

      state.listPostCreator = state.listPostCreator.map((item) => {
        if (item?.share_post) {
          if (+item?.share_post.id === +data?.post_id) {
            return {
              ...item,
              share_post: {
                ...item.share_post,
                latest_comment: {
                  ...item.share_post.latest_comment,
                  number_like: likeCount,
                  is_liked: isLiked,
                },
              },
            };
          }
          return item;
        } else {
          if (+item?.id === +data?.post_id) {
            return {
              ...item,
              latest_comment: {
                ...item.latest_comment,
                number_like: likeCount,
                is_liked: isLiked,
              },
            };
          }
          return item;
        }
      });

      state.commentDetail = state.commentDetail.map((item) => {
        if (item.id === +data.id) {
          return {
            ...item,
            number_like: likeCount,
            is_liked: isLiked,
          };
        }
        return item;
      });
      state.replyCommentDetail = state.replyCommentDetail.map((item) => {
        if (item.id === data.id) {
          return {
            ...item,
            number_like: likeCount,
            is_liked: isLiked,
          };
        }
        return item;
      });
      return {
        ...state,
        detailPost: state.detailPost,
        commentDetail: state.commentDetail,
        likeComment: false,
      };
    case TYPES.FOLLOW_USER_REPLY_COMMENT_DETAILS_REQUEST:
    case TYPES.POST_REPLY_COMMENT_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
        loadingPostComment: true,
        statusPostRepComment: null,
      };
    case TYPES.LIKE_REQUEST:
      return {
        ...state,
        loadingAction: true,
        likePost: false,
      };
    case TYPES.GET_USER_INFO_OTHERS_REQUEST:
      return {
        ...state,
        status: false,
        loadingUser: true,
      };
    case TYPES.LIKE_POST_DETAILS_REQUEST:
      return {
        ...state,
      };
    case TYPES.CREATOR_GET_POST_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case TYPES.LIST_POSTS_CREATOR_SUCCESS:
      return {
        ...state,
        loading: false,
        initPageHome: action?.data?.res?.pagination?.current_page,
        pagination: action?.data?.res?.pagination,
        paginationPostHome: action?.data?.res?.pagination,
        listPostCreator:
          action?.data?.res?.pagination?.current_page === 1
            ? action?.data?.res?.data
            : [...state.listPostCreator, ...action?.data?.res?.data],
      };
    case TYPES.POST_COMMENT_DETAILS_SUCCESS:
      state.listPostTimeLine = state.listPostTimeLine.map((item) => {
        if (+item?.id === +action?.data?.data?.post_id) {
          const newItem = { ...item };
          ++newItem.number_comment;
          if (newItem.latest_comment) {
            newItem.latest_comment.id = action.data.data.id;
            newItem.latest_comment.content = action.data.data.content;
            newItem.latest_comment.created_at = action.data.data.created_at;
            newItem.latest_comment.avatar = action.data.data.avatar;
            newItem.latest_comment.account_name = action.data.data.account_name;
            newItem.latest_comment.user_id = action.data.data.user_id;
            newItem.latest_comment.number_like = action.data.data.number_like;
            newItem.latest_comment.is_liked = action.data.data.is_liked;
            newItem.latest_comment.number_comment = action.data.data.number_comment;
            newItem.latest_comment.is_edited = action.data.data.is_edited;
            newItem.latest_comment.metaPost = action.data.metaPost;
          } else {
            newItem.latest_comment = {
              id: action.data.data.id,
              avatar: action.data.data?.avatar,
              user_id: action.data.data?.user_id,
              account_name: action.data.data?.account_name,
              content: action.data.data.content,
              created_at: action.data.data.created_at,
              number_like: action.data.data.number_like,
              is_liked: action.data.data.is_liked,
              post_id: action.data.data.post_id,
              metaPost: action.data.metaPost,
              number_comment: 0,
            };
          }
          return newItem;
        } else {
          return item;
        }
      });

      state.listPostCreator = state.listPostCreator.map((item) => {
        if (item?.share_post && item?.share_type !== 2) {
          if (+item?.share_post.id === +action?.data?.data?.post_id) {
            const newItem = { ...item };
            ++newItem.share_post.number_comment;
            if (newItem.share_post.latest_comment) {
              newItem.share_post.latest_comment.id = action.data.data.id;
              newItem.share_post.latest_comment.content = action.data.data.content;
              newItem.share_post.latest_comment.created_at = action.data.data.created_at;
              newItem.share_post.latest_comment.account_name = action.data.data.account_name;
              newItem.share_post.latest_comment.avatar = action.data.data.avatar;
              newItem.share_post.latest_comment.number_like = action.data.data.number_like;
              newItem.share_post.latest_comment.is_liked = action.data.data.is_liked;
              newItem.share_post.latest_comment.number_comment =
                action.data.data.number_comment;
              newItem.share_post.latest_comment.is_edited = action.data.data.is_edited;
              newItem.share_post.latest_comment.metaPost = action.data.metaPost;
            } else {
              newItem.share_post.latest_comment = {
                id: action.data.data.id,
                avatar: action.data.data?.avatar,
                account_name: action.data.data?.account_name,
                content: action.data.data.content,
                created_at: action.data.data.created_at,
                number_like: action.data.data.number_like,
                is_liked: action.data.data.is_liked,
                post_id: action.data.data.post_id,
                metaPost: action.data.metaPost,
              };
            }
            return newItem;
          } else {
            return item;
          }
        } else {
          if (+item?.id === +action?.data?.data?.post_id) {
            const newItem = { ...item };
            ++newItem.number_comment;
            if (newItem.latest_comment) {
              newItem.latest_comment.id = action.data.data.id;
              newItem.latest_comment.content = action.data.data.content;
              newItem.latest_comment.created_at = action.data.data.created_at;
              newItem.latest_comment.account_name = action.data.data.account_name;
              newItem.latest_comment.avatar = action.data.data.avatar;
              newItem.latest_comment.number_like = action.data.data.number_like;
              newItem.latest_comment.is_liked = action.data.data.is_liked;
              newItem.latest_comment.user_id = action.data.data.user_id;
              newItem.latest_comment.is_edited = action.data.data.is_edited;
              newItem.latest_comment.metaPost = action.data.metaPost;
            } else {
              newItem.latest_comment = {
                id: action.data.data.id,
                avatar: action.data.data?.avatar,
                account_name: action.data.data?.account_name,
                content: action.data.data.content,
                created_at: action.data.data.created_at,
                number_like: action.data.data.number_like,
                is_liked: action.data.data.is_liked,
                user_id: action.data.data.user_id,
                post_id: action.data.data.post_id,
                metaPost: action.data.metaPost,
              };
            }
            return newItem;
          } else {
            return item;
          }
        }
      });

      state.dataBookmark = state.dataBookmark.map((item) => {
        if (item?.share_post && item?.share_type !== 2) {
          if (+item?.share_post.id === +action?.data?.data?.post_id) {
            const newItem = { ...item };
            //++newItem.share_post.number_comment;
            if (newItem.share_post.latest_comment) {
              newItem.share_post.latest_comment.id = action.data.data.id;
              newItem.share_post.latest_comment.content = action.data.data.content;
              newItem.share_post.latest_comment.created_at = action.data.data.created_at;
              newItem.share_post.latest_comment.account_name = action.data.data.account_name;
              newItem.share_post.latest_comment.avatar = action.data.data.avatar;
              newItem.share_post.latest_comment.number_like = action.data.data.number_like;
              newItem.share_post.latest_comment.is_liked = action.data.data.is_liked;
              newItem.share_post.latest_comment.number_comment =
                action.data.data.number_comment;
              newItem.share_post.latest_comment.is_edited = action.data.data.is_edited;
              newItem.share_post.latest_comment.metaPost = action.data.metaPost;
            } else {
              newItem.share_post.latest_comment = {
                id: action.data.data.id,
                avatar: action.data.data?.avatar,
                account_name: action.data.data?.account_name,
                content: action.data.data.content,
                created_at: action.data.data.created_at,
                number_like: action.data.data.number_like,
                is_liked: action.data.data.is_liked,
                post_id: action.data.data.post_id,
                metaPost: action.data.metaPost,
              };
            }
            return newItem;
          } else {
            return item;
          }
        } else {
          if (+item?.id === +action?.data?.data?.post_id) {
            const newItem = { ...item };
            //++newItem.number_comment;
            if (newItem.latest_comment) {
              newItem.latest_comment.id = action.data.data.id;
              newItem.latest_comment.content = action.data.data.content;
              newItem.latest_comment.created_at = action.data.data.created_at;
              newItem.latest_comment.account_name = action.data.data.account_name;
              newItem.latest_comment.avatar = action.data.data.avatar;
              newItem.latest_comment.number_like = action.data.data.number_like;
              newItem.latest_comment.is_liked = action.data.data.is_liked;
              newItem.latest_comment.user_id = action.data.data.user_id;
              newItem.latest_comment.is_edited = action.data.data.is_edited;
              newItem.latest_comment.metaPost = action.data.metaPost;
            } else {
              newItem.latest_comment = {
                id: action.data.data.id,
                avatar: action.data.data?.avatar,
                account_name: action.data.data?.account_name,
                content: action.data.data.content,
                created_at: action.data.data.created_at,
                number_like: action.data.data.number_like,
                is_liked: action.data.data.is_liked,
                user_id: action.data.data.user_id,
                post_id: action.data.data.post_id,
                metaPost: action.data.metaPost,
              };
            }
            return newItem;
          } else {
            return item;
          }
        }
      });

      let tmpNumberComment;
      if (state.detailPost) {
        tmpNumberComment = ++state.detailPost.number_comment;
      }

      state.searchShortVideo = state.searchShortVideo.map((item) => {
        if (+item.id === +action?.data?.data?.post_id) {
          const newItem = { ...item };
          newItem.number_comment = newItem?.number_comment + 1;

          return newItem;
        } else return item;
      });

      state.searchPostTimeline = state.searchPostTimeline.map((item) => {
        if (+item.id === +action?.data?.data?.post_id) {
          const newItem = { ...item };
          newItem.number_comment = newItem?.number_comment + 1;

          return newItem;
        } else return item;
      });

      state.searchSaleContent = state.searchSaleContent.map((item) => {
        if (+item.id === +action?.data?.data?.post_id) {
          const newItem = { ...item };
          newItem.number_comment = newItem?.number_comment + 1;

          return newItem;
        } else return item;
      });

      state.dataBookmark = state.dataBookmark.map((item) => {
        if (+item.id === +action?.data?.data?.post_id) {
          const newItem = { ...item };
          newItem.number_comment = newItem?.number_comment + 1;

          return newItem;
        } else return item;
      });

      return {
        ...state,
        detailPost: { ...state.detailPost, number_comment: tmpNumberComment },
        commentDetail: [
          {
            ...action.data.data,
            metaPost: action.data.metaPost,
          },
          ...state.commentDetail,
        ],
        searchShortVideo: state.searchShortVideo,
        loading: false,
        loadingPostComment: false,
        statusPostComment: true,
      };
    case TYPES.GET_COMMENT_DETAILS_SUCCESS:
      const commentDetail =
        action?.data?.data?.pagination?.current_page === 1
          ? action?.data?.data?.data
          : [...state?.commentDetail, ...action?.data?.data?.data];

      return {
        ...state,
        paginationComment: action?.data?.data?.pagination,
        loadingCommentDetail: false,
        commentDetail: commentDetail,
      };

    case TYPES.GHIM_POST_MYPAGE_SUCCESS:
      //const arrNew = moveIdToFront(state.listPostCreator, +action.data.data.id)

      return {
        ...state,
        //listPostCreator: arrNew,
        isLoadingFollowCreate: false,
      };

    case TYPES.POST_RETWEET_REQUEST_SUCCESS:
      state.dataBookmark = state.dataBookmark.map((item) => {
        if (+item.id === +action.data.data.post_id) {
          const newItem = { ...item };
          newItem.number_share = action.data.data.number_share;
          newItem.is_share = action.data.data.number_share;
          return newItem;
        }
        return item;
      });

      if (action.data.data?.is_share === 0) {
        state.listPostCreator = state.listPostCreator.filter(
          (item1) => item1?.share_post?.id !== action.data.data.post_id
        );
      }

      state.listPostCreator = state.listPostCreator.map((item) => {
        if (item?.share_post && item?.share_type !== 2) {
          if (+item?.share_post?.id === +action.data.data.post_id) {
            const newItem = { ...item };
            newItem.share_post.is_share = action.data.data.is_share;
            newItem.share_post.number_share = action.data.data.number_share;
            return newItem;
          } else return item;
        } else {
          if (+item.id === +action.data.data.post_id) {
            const newItem = { ...item };
            if (newItem.is_share === 1) {
              newItem.is_share = 0;
              --newItem.number_share;
            } else {
              newItem.is_share = 1;
              ++newItem.number_share;
            }
            return newItem;
          } else return item;
        }
      });

      state.listPostTimeLine = state.listPostTimeLine.map((item) => {
        if (+item.id === +action.data.data.post_id) {
          const newItem = { ...item };
          if (newItem.is_share === 1) {
            newItem.is_share = 0;
            --newItem.number_share;
          } else {
            newItem.is_share = 1;
            ++newItem.number_share;
          }
          return newItem;
        } else return item;
      });

      if (+action?.data?.data?.post_id === state.detailPost?.id) {
        if (action?.data?.data?.is_share === 0) {
          state.detailPost = {
            ...state.detailPost,
            number_share: state.detailPost.number_share - 1,
            is_share: 0,
          };
        } else {
          state.detailPost = {
            ...state.detailPost,
            number_share: state.detailPost.number_share + 1,
            is_share: 1,
          };
        }
      }

      return {
        ...state,
        status: action.type,
        listPostCreator: state.listPostCreator,
        listPostTimeLine: state.listPostTimeLine,
        detailPost: state.detailPost,
        isLoadingFollowCreate: false,
      };

    case TYPES.POST_LIKE_CREATOR_SUCCESS:
      const postCreator = action.data;
      const likeStatus = postCreator.is_liked ? 0 : 1;
      state.listPostCreator = state.listPostCreator.map((item) => {
        if (item?.share_post && item?.share_type !== 2) {
          if (+item?.share_post?.id === +action.data.id) {
            return {
              ...item,
              share_post: {
                ...item.share_post,
                is_liked: likeStatus,
                number_like: likeStatus
                  ? postCreator.number_like + 1
                  : postCreator.number_like - 1,
              },
            };
          }
          return item;
        } else {
          if (+item.id === +action.data.id) {
            return {
              ...item,
              is_liked: likeStatus,
              number_like: likeStatus
                ? postCreator.number_like + 1
                : postCreator.number_like - 1,
            };
          }
          return item;
        }
      });

      state.searchPostTimeline = state.searchPostTimeline.map((item) => {
        if (+item.id === +postCreator.id) {
          return {
            ...item,
            is_liked: likeStatus,
            number_like: likeStatus
              ? postCreator.number_like + 1
              : postCreator.number_like - 1,
          };
        }
        return item;
      });

      state.searchSaleContent = state.searchSaleContent.map((item) => {
        if (+item.id === +postCreator.id) {
          return {
            ...item,
            is_liked: likeStatus,
            number_like: likeStatus
              ? postCreator.number_like + 1
              : postCreator.number_like - 1,
          };
        }
        return item;
      });

      state.listPostShortVideo = state.listPostShortVideo.map((item) => {
        if (+item.id === +postCreator.id) {
          return {
            ...item,
            is_liked: likeStatus,
            number_like: likeStatus
              ? postCreator.number_like + 1
              : postCreator.number_like - 1,
          };
        }
        return item;
      });

      return {
        ...state,
        listPostCreator: state.listPostCreator,
        listPostShortVideo: state.listPostShortVideo,
        searchSaleContent: state.searchSaleContent,
      };
    // HIDDEN POST MYPAGE
    case TYPES.HIDDEN_POST_MYPAGE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TYPES.HIDDEN_POST_MYPAGE_SUCCESS:
      state.listPostCreator = state.listPostCreator.map((item) => {
        if (+item.id === +action.data.data.payload) {
          const newItem = { ...item };
          newItem.is_pinned = 0;
          if (action.data.data.data.is_hided === 1) {
            newItem.is_hided = 1;
            newItem.post_status = action.data.data.data.post_status;
          } else {
            newItem.is_hided = 0;
            newItem.post_status = action.data.data.data.post_status;
          }
          return newItem;
        } else return item;
      });

      if (+action.data.data.payload === state?.detailPost?.id) {
        state.detailPost = {
          ...state.detailPost,
          is_hided: action.data.data.data.is_hided,
          post_status: action.data.data.data.post_status,
        };
      }

      return {
        ...state,
        listPostCreator: state.listPostCreator,
        detailPost: state.detailPost,
        loading: false,
      };
    case TYPES.HIDDEN_POST_MYPAGE_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.data.error,
        loading: false,
      };
    // FOLLOW or UNFOLLOW
    case TYPES.FAN_FOLLOW_CREATOR_REQUEST:
      return {
        ...state,
        loading: true,
        isLoadingFollowCreate: true,
      };
    case TYPES.FAN_FOLLOW_CREATOR_SUCCESS:
      state.dataBookmark = state.dataBookmark.map((item) => {
        if (
          (item?.share_type === 2 || item?.share_type === 1) &&
          +item?.share_post?.user_id === +action.data.data.payload
        ) {
          if (+item?.share_post?.user_id === +action.data.data.payload) {
            const newItem = { ...item };
            if (action.data.data.data.is_followed === 1) {
              //follow
              if (+item?.user_id === +action.data.data.payload) {
                newItem.is_followed = 1;
              }
              newItem.share_post.is_followed = 1;
              if (newItem.share_post.latest_comment) {
                newItem.share_post.latest_comment.is_followed = 1;
              }
            } else {
              //Unfollow
              if (+item?.user_id === +action.data.data.payload) {
                newItem.is_followed = 0;
              }
              newItem.share_post.is_followed = 0;
              if (newItem.share_post.latest_comment) {
                newItem.share_post.latest_comment.is_followed = 0;
              }
            }
            return newItem;
          } else return item;
        } else {
          if (+item.user_id === +action.data.data.payload) {
            const newItem = { ...item };
            if (action.data.data.data.is_followed === 1) {
              //follow
              newItem.is_followed = 1;
              if (newItem.latest_comment) {
                newItem.latest_comment.is_followed = 1;
              }
            } else {
              //Unfollow
              newItem.is_followed = 0;
              if (newItem.latest_comment) {
                newItem.latest_comment.is_followed = 0;
              }
            }
            return newItem;
          } else return item;
        }
      });

      state.listPostTimeLine = state.listPostTimeLine.map((item) => {
        if (
          item?.share_type === 2 &&
          +item?.share_post?.user_id === +action.data.data.payload
        ) {
          if (+item?.share_post?.user_id === +action.data.data.payload) {
            const newItem = { ...item };
            if (action.data.data.data.is_followed === 1) {
              //follow
              if (+item?.user_id === +action.data.data.payload) {
                newItem.is_followed = 1;
              }
              newItem.share_post.is_followed = 1;
              if (newItem.share_post.latest_comment) {
                newItem.share_post.latest_comment.is_followed = 1;
              }
            } else {
              //Unfollow
              if (+item?.user_id === +action.data.data.payload) {
                newItem.is_followed = 0;
              }
              newItem.share_post.is_followed = 0;
              if (newItem.share_post.latest_comment) {
                newItem.share_post.latest_comment.is_followed = 0;
              }
            }
            return newItem;
          } else return item;
        } else {
          if (+item.user_id === +action.data.data.payload) {
            const newItem = { ...item };
            if (action.data.data.data.is_followed === 1) {
              //follow
              newItem.is_followed = 1;
              if (newItem.latest_comment) {
                newItem.latest_comment.is_followed = 1;
              }
            } else {
              //Unfollow
              newItem.is_followed = 0;
              if (newItem.latest_comment) {
                newItem.latest_comment.is_followed = 0;
              }
            }
            return newItem;
          } else return item;
        }
      });

      if (state.dataInfoOther?.id === +action.data.data.payload) {
        state.dataInfoOther = {
          ...state.dataInfoOther,
          is_followed: action.data.data.data?.is_followed,
        };
      }

      state.listPostCreator = state.listPostCreator.map((item) => {
        if (+item?.user_id === +action.data.data.payload) {
          const newItem = { ...item };
          if (action.data.data.data.is_followed === 1) {
            //follow
            newItem.is_followed = 1;
            if (newItem.latest_comment) {
              newItem.latest_comment.is_followed = 1;
            }
          } else {
            //Unfollow
            newItem.is_followed = 0;
            if (newItem.latest_comment) {
              newItem.latest_comment.is_followed = 0;
            }
          }
          return newItem;
        } else return item;
      });

      state.listPostCreator = state.listPostCreator.map((item) => {
        if (+item?.share_post?.user_id === +action.data.data.payload) {
          const newItem = { ...item };
          if (action.data.data.data.is_followed === 1) {
            //follow
            if (+item?.user_id === +action.data.data.payload) {
              newItem.is_followed = 1;
            }
            newItem.share_post.is_followed = 1;
            if (newItem.share_post.latest_comment) {
              newItem.share_post.latest_comment.is_followed = 1;
            }
          } else {
            //Unfollow
            if (+item?.user_id === +action.data.data.payload) {
              newItem.is_followed = 0;
            }
            newItem.share_post.is_followed = 0;
            if (newItem.share_post.latest_comment) {
              newItem.share_post.latest_comment.is_followed = 0;
            }
          }
          return newItem;
        } else return item;
      });

      state.listPostCreator = state.listPostCreator.map((item) => {
        if (+item?.share_post?.share_post?.user_id === +action.data.data.payload) {
          const newItem = { ...item };
          if (action.data.data.data.is_followed === 1) {
            //follow
            if (+item?.user_id === +action.data.data.payload) {
              newItem.is_followed = 1;
            }
            newItem.share_post.share_post.is_followed = 1;
            if (newItem.share_post.share_post.latest_comment) {
              newItem.share_post.share_post.latest_comment.is_followed = 1;
            }
          } else {
            //Unfollow
            if (+item?.user_id === +action.data.data.payload) {
              newItem.is_followed = 0;
            }
            newItem.share_post.share_post.is_followed = 0;
            if (newItem.share_post.share_post.latest_comment) {
              newItem.share_post.share_post.latest_comment.is_followed = 0;
            }
          }
          return newItem;
        } else return item;
      });

      state.searchShortVideo = state.searchShortVideo.map((item) => {
        if (+item.user_id === +action?.data?.data?.payload) {
          const newItem = { ...item };
          if (newItem.is_followed === 1) {
            newItem.is_followed = 0;
          } else {
            newItem.is_followed = 1;
          }

          return newItem;
        } else return item;
      });

      state.searchPostTimeline = state.searchPostTimeline.map((item) => {
        if (+item.user_id === +action?.data?.data?.payload) {
          const newItem = { ...item };
          if (newItem.is_followed === 1) {
            newItem.is_followed = 0;
          } else {
            newItem.is_followed = 1;
          }

          return newItem;
        } else return item;
      });

      state.detailPost = {
        ...state.detailPost,
        is_followed: action.data.data.data?.is_followed,
      };

      state.commentDetail = state.commentDetail?.map((item) => {
        if (+item?.user_id === +action?.data?.data?.payload) {
          return {
            ...item,
            is_followed: action.data.data.data?.is_followed,
          };
        }
        return item;
      });

      state.dataInfoOther = {
        ...state.dataInfoOther,
        status_follow: action.data.data.data?.is_followed,
      };

      state.detailCreator = {
        ...state.detailCreator,
        status_follow: action.data.data.data?.is_followed,
      };
      return {
        ...state,
        loading: false,
        searchShortVideo: state.searchShortVideo,
        detailCreator: state.detailCreator,
        detailPost: state.detailPost,
        commentDetail: state.commentDetail,
        isLoadingFollowCreate: false,
      };
    // LIST HIGH LIGHT
    case TYPES.LIST_HIGH_LIGHT_SUCCESS:
      return {
        ...state,
        loadingHighline: false,
        listHighLight: action.data.res,
      };
    case TYPES.LIST_3_IMG_MY_PAGE_SUCCESS:
      return {
        ...state,
        list3imgMypage: action.data.res,
      };
    case TYPES.DELETE_REPLY_COMMENT_DETAILS_SUCCESS:
      const deletedReplyComment = state.replyCommentDetail.find(
        (item) => item.id === action.data.data.payload
      );
      state.replyCommentDetail = state.replyCommentDetail.filter(
        (item) => item.id !== action.data.data.payload
      );

      state.commentDetail = state.commentDetail.filter((item) => {
        if (item.id === action.data.data?.idParent) {
          return {
            ...item,
            total_rep_comment: --item.total_rep_comment,
            number_comment: --item.number_comment,
          };
        }
        return item;
      });

      state.searchShortVideo = state.searchShortVideo.map((item) => {
        if (+item.id === +state?.detailPost?.id) {
          const newItem = { ...item };
          newItem.number_comment = newItem.number_comment - action?.data?.data?.data?.count;

          return newItem;
        }
        return item;
      });

      state.searchSaleContent = state.searchSaleContent.map((item) => {
        if (+item.id === +state?.detailPost?.id) {
          const newItem = { ...item };
          newItem.number_comment = newItem.number_comment - action?.data?.data?.data?.count;

          return newItem;
        }
        return item;
      });

      state.searchPostTimeline = state.searchPostTimeline.map((item) => {
        if (+item.id === +state?.detailPost?.id) {
          const newItem = { ...item };
          newItem.number_comment = newItem.number_comment - action?.data?.data?.data?.count;

          return newItem;
        }
        return item;
      });

      state.listPostCreator = state.listPostCreator.map((item) => {
        if (+item.id === +state?.detailPost?.id) {
          const newItem = { ...item };
          if (newItem.number_comment > 1) {
            newItem.isReadmore = true;
          }
          newItem.number_comment = newItem.number_comment - action?.data?.data?.data?.count;
          return newItem;
        } else return item;
      });

      state.listPostTimeLine = state.listPostTimeLine.map((item) => {
        if (+item?.id === +state?.detailPost?.id) {
          const newItem = { ...item };
          if (newItem.number_comment > 1) {
            newItem.isReadmore = true;
          }
          newItem.number_comment -= --newItem.number_comment;
          return newItem;
        } else return item;
      });

      state.dataBookmark = state.dataBookmark.map((item) => {
        if (+item?.id === +state?.detailPost?.id) {
          const newItem = { ...item };
          if (newItem.number_comment > 1) {
            newItem.isReadmore = true;
          }
          newItem.number_comment = --newItem.number_comment;
          return newItem;
        } else return item;
      });

      state.searchShortVideo = state.searchShortVideo.map((item) => {
        if (+item.id === +deletedReplyComment?.post_id) {
          const newItem = { ...item };
          newItem.number_comment = newItem?.number_comment - 1;

          return newItem;
        } else return item;
      });

      if (state?.detailPost?.id === action.data.data.payload) {
        state.detailPost = {
          ...state.detailPost,
          number_comment: state.detailPost?.number_comment - 1,
        };
      }

      return {
        ...state,
        // detailPost: {
        //   ...state.detailPost,
        //   number_comment: state.detailPost?.number_comment - 1,
        // },
        searchShortVideo: state.searchShortVideo,
        commentDetail: state.commentDetail,
        replyCommentDetail: state.replyCommentDetail,
        loading: false,
      };
    case TYPES.FAN_FOLLOW_CREATOR_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.data.error,
        loading: false,
        isLoadingFollowCreate: false,
      };
    //CREATOR_DETELE_ARTICLE
    case TYPES.CREATOR_DELETE_ARTICLE_REQUEST:
      return {
        ...state,
        loading: true,
        statusDeletePost: false,
      };
    case TYPES.CREATOR_DELETE_ARTICLE_SUCCESS:
      state.listPostCreator = state.listPostCreator.map((item) => {
        if (+item?.id === +action?.data?.data?.item?.share_post?.id) {
          const newItem = { ...item };
          --newItem.number_share;
          if (action?.data?.data?.item?.share_type === 1) {
            newItem.is_share = 0;
          }
          return newItem;
        } else return item;
      });

      state.listPostCreator = state.listPostCreator.map((item) => {
        if (+item?.share_post?.id === +action?.data?.data?.item?.share_post?.id) {
          const newItem = { ...item };
          --newItem.share_post.number_share;
          return newItem;
        } else return item;
      });

      // xoa bai viet goc quote
      state.listPostCreator = state.listPostCreator.map((item) => {
        if (+item?.share_post?.id === +action.data.data.payload && item?.share_type === 2) {
          const newItem = { ...item };
          newItem.share_post = null;
          return newItem;
        } else return item;
      });

      state.listPostCreator = state.listPostCreator.filter(
        (item) =>
          !(item?.share_type === 1 && item?.share_post?.id === action.data.data.payload)
      );

      return {
        ...state,
        loading: false,
        statusDeletePost: true,
        listPostCreator: state.listPostCreator.filter(
          (item) => item?.id !== action.data.data.payload
        ),
      };
    case TYPES.CREATOR_DELETE_ARTICLES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TYPES.CREATOR_DELETE_ARTICLES_SUCCESS:
      return {
        ...state,
        loading: false,
        listPostCreator: state.listPostCreator.filter(
          (item) => item.id !== action.data.data.payload
        ),
      };
    case TYPES.CREATOR_DELETE_ARTICLES_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.data.error,
        loading: false,
      };
    case TYPES.UPDATE_DETAIL_RECORDING_V2_SUCCESS:
      state.listPostCreator = state.listPostCreator.map((item) => {
        if (+item?.id === +action.data.data.payload) {
          const newItem = { ...item };
          if (newItem.is_show === 1) {
            newItem.is_show = 0;
          } else {
            newItem.is_show = 1;
          }
          return newItem;
        } else return item;
      });
      return {
        ...state,
        listPostCreator: state.listPostCreator,
        loading: false,
      };
    case TYPES.LIST_HIGH_LIGHT_REQUEST:
      return {
        ...state,
        loadingHighline: true,
      };
    case TYPES.CREATOR_DELETE_ARTICLE_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.data.error,
        loading: false,
      };
    // LIKE REP COMMENT
    case TYPES.LIKE_REPLY_COMMENT_DETAILS_SUCCESS:
      state.replyCommentDetail = state.replyCommentDetail.map((item) => {
        if (item.id === action.data.data.id) {
          return {
            ...item,
            number_like: action.data.data.number_like,
            is_liked: action.data.data.is_liked,
          };
        }
        return item;
      });
      return {
        ...state,
        replyCommentDetail: state.replyCommentDetail,
        likeRepComment: false,
      };
    //GET REPLY COMMENT DETAIL
    case TYPES.GET_REPLY_COMMENT_DETAILS_REQUEST:
      return {
        ...state,
        loadingRepCommentDetail: true,
      };
    case TYPES.GET_REPLY_COMMENT_DETAILS_SUCCESS:
      const convertDataReply = action.data.data.data.map((item) => {
        return {
          ...item,
          totalPage: action.data.data.pagination.total_pages,
          currentPage: action.data.data.pagination.current_page,
        };
      });
      return {
        ...state,
        loadingRepCommentDetail: false,
        paginationReplyComment: action?.data?.data?.pagination,
        replyCommentDetail: [...state.replyCommentDetail, ...convertDataReply],
      };
    case TYPES.GET_REPLY_COMMENT_DETAILS_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.data.error,
        loadingRepCommentDetail: false,
      };
    // EDIT REPLY COMMENT
    case TYPES.EDIT_REPLY_COMMENT_DETAILS_SUCCESS:
      state.replyCommentDetail = state.replyCommentDetail.map((item) => {
        if (item.id === action.data.data.id) {
          return {
            ...item,
            content: action.data.data.content,
            updated_at: action.data.data.updated_at,
            is_edited: 1,
            metaPost: action.data?.dataMeta,
          };
        }
        return item;
      });
      return {
        ...state,
        replyCommentDetail: state.replyCommentDetail,
        statusEditRepComment: true,
        loading: false,
      };
    case TYPES.FOLLOW_USER_REPLY_COMMENT_DETAILS_SUCCESS:
      state.replyCommentDetail = state.replyCommentDetail?.map((item) => {
        if (item?.user_id === action.data.data.id) {
          return {
            ...item,
            status_follow:
              action.data.data.status_follow_block || action.data.data.status_follow,
          };
        }
        return item;
      });
      state.commentDetail = state.commentDetail?.map((item) => {
        if (item?.user_id === action.data.data.id) {
          return {
            ...item,
            status_follow:
              action.data.data.status_follow_block || action.data.data.status_follow,
          };
        }
        return item;
      });
      return {
        ...state,
        commentDetail: state.commentDetail,
        replyCommentDetail: state.replyCommentDetail,
        loadingFollow: false,
      };

    case TYPES.CREATE_COMMENT_POST_SUCCESS:
      state.listPostCreator = state.listPostCreator.map((item) => {
        if (+item?.id === +action.data?.data?.data?.post_id) {
          const newItem = { ...item };
          newItem.number_comment = action?.data?.data?.data?.post?.number_comment;
          newItem.latest_comment.number_comment = ++newItem.latest_comment.number_comment;
          return newItem;
        } else return item;
      });

      state.listPostTimeLine = state.listPostTimeLine.map((item) => {
        if (+item?.id === +action.data?.data?.data?.post_id) {
          const newItem = { ...item };
          newItem.number_comment = action?.data?.data?.data?.post?.number_comment;
          newItem.latest_comment.number_comment = ++newItem.latest_comment.number_comment;
          return newItem;
        } else return item;
      });

      state.dataBookmark = state.dataBookmark.map((item) => {
        if (+item?.id === +action.data?.data?.data?.post_id) {
          const newItem = { ...item };
          newItem.number_comment = action?.data?.data?.data?.post?.number_comment;
          newItem.latest_comment.number_comment = ++newItem.latest_comment.number_comment;
          return newItem;
        } else return item;
      });

      state.searchShortVideo = state.searchShortVideo.map((item) => {
        if (+item.id === +action?.data?.data?.data?.post_id) {
          const newItem = { ...item };
          newItem.number_comment = +action?.data?.data?.data?.post?.number_comment;

          return newItem;
        } else return item;
      });

      state.searchSaleContent = state.searchSaleContent.map((item) => {
        if (+item.id === +action?.data?.data?.data?.post_id) {
          const newItem = { ...item };
          newItem.number_comment = +action?.data?.data?.data?.post?.number_comment;

          return newItem;
        } else return item;
      });

      state.searchPostTimeline = state.searchPostTimeline.map((item) => {
        if (+item.id === +action?.data?.data?.data?.post_id) {
          const newItem = { ...item };
          newItem.number_comment = +action?.data?.data?.data?.post?.number_comment;

          return newItem;
        } else return item;
      });

      return {
        ...state,
        loading: false,
      };

    case TYPES.UPDATE_COMMENT_POST_SUCCESS:
      state.listPostCreator = state.listPostCreator.map((item) => {
        if (+item.latest_comment?.id === action.data.data.data.id) {
          const newItem = { ...item };
          newItem.latest_comment.content = action.data.data.data.content;
          return newItem;
        } else return item;
      });

      state.listPostTimeLine = state.listPostTimeLine.map((item) => {
        if (+item.latest_comment?.id === action.data.data.data.id) {
          const newItem = { ...item };
          newItem.latest_comment.content = action.data.data.data.content;
          return newItem;
        } else return item;
      });

      state.dataBookmark = state.dataBookmark.map((item) => {
        if (+item.latest_comment?.id === action.data.data.data.id) {
          const newItem = { ...item };
          newItem.latest_comment.content = action.data.data.data.content;
          return newItem;
        } else return item;
      });

      return {
        ...state,
      };
    case TYPES.POST_NORMAL_BUY_PACKAGES_SUCCESS:
      state.dataBookmark = state.dataBookmark.map((item) => {
        if (item?.share_type === 1 || (item?.share_type === 2 && item?.medias?.length === 0)) {
          if (+item?.share_post?.packages[0]?.id === +action.data.res.payload) {
            const newItem = { ...item };
            if (newItem.share_post.is_media_display === 1) {
              newItem.share_post.is_media_display = 0;
            } else {
              newItem.share_post.is_media_display = 1;
            }
            return newItem;
          } else return item;
        } else {
          if (+item?.packages[0]?.id === +action.data.res.payload) {
            const newItem = { ...item };
            if (newItem.is_media_display === 1) {
              newItem.is_media_display = 0;
            } else {
              newItem.is_media_display = 1;
            }
            return newItem;
          } else return item;
        }
      });

      state.listPostTimeLine = state.listPostTimeLine.map((item) => {
        if (item?.share_post) {
          if (+item?.share_post?.packages[0]?.id === +action.data.res.payload) {
            const newItem = { ...item };
            if (newItem.share_post.is_media_display === 1) {
              newItem.share_post.is_media_display = 0;
            } else {
              newItem.share_post.is_media_display = 1;
            }
            return newItem;
          } else return item;
        } else {
          if (+item?.packages[0]?.id === +action.data.res.payload) {
            const newItem = { ...item };
            if (newItem.is_media_display === 1) {
              newItem.is_media_display = 0;
            } else {
              newItem.is_media_display = 1;
            }
            return newItem;
          } else return item;
        }
      });

      state.listPostCreator = state.listPostCreator.map((item) => {
        if (item?.share_type === 1 || (item?.share_type === 2 && item?.medias?.length === 0)) {
          if (+item?.share_post?.packages[0]?.id === +action.data.res.payload) {
            const newItem = { ...item };
            if (newItem.share_post.is_media_display === 1) {
              newItem.share_post.is_media_display = 0;
            } else {
              newItem.share_post.is_media_display = 1;
            }
            return newItem;
          } else return item;
        } else {
          if (+item?.packages[0]?.id === +action.data.res.payload) {
            const newItem = { ...item };
            if (newItem.is_media_display === 1) {
              newItem.is_media_display = 0;
            } else {
              newItem.is_media_display = 1;
            }
            return newItem;
          } else return item;
        }
      });

      state.searchPostTimeline = state.searchPostTimeline.map((item) => {
        if (+item?.packages[0]?.id === +action.data.res.payload) {
          const newItem = { ...item };
          if (newItem.is_media_display === 1) {
            newItem.is_media_display = 0;
          } else {
            newItem.is_media_display = 1;
          }
          return newItem;
        } else return item;
      });

      state.detailPost = {
        ...state.detailPost,
        is_media_display: 1,
      };

      return {
        ...state,
        listPostTimeLine: state.listPostTimeLine,
        check_buy_package: true,
        loadingBuyPost: false,
        flagHiddenPopup: true,
      };
    case TYPES.FOLLOW_USER_COMMENT_DETAILS_SUCCESS:
      state.listPostTimeLine = state.listPostTimeLine.map((item) => {
        if (+item?.latest_comment?.user_id === +action.data.data.payload) {
          const newItem = { ...item };
          if (action.data.data.data.is_followed === 1) {
            //follow
            newItem.latest_comment.is_followed = 1;
            newItem.is_followed = 0;
          } else {
            //Unfollow
            newItem.latest_comment.is_followed = 0;
            newItem.is_followed = 0;
          }
          return newItem;
        } else return item;
      });

      state.listPostCreator = state.listPostCreator.map((item) => {
        if (+item?.share_post?.share_post?.user_id === +action.data.data.payload) {
          const newItem = { ...item };
          if (action.data.data.data.is_followed === 1) {
            //follow
            if (+item?.user_id === +action.data.data.payload) {
              newItem.is_followed = 1;
            }
            newItem.share_post.share_post.is_followed = 1;
            if (newItem.share_post.share_post.latest_comment) {
              newItem.share_post.share_post.latest_comment.is_followed = 1;
            }
          } else {
            //Unfollow
            if (+item?.user_id === +action.data.data.payload) {
              newItem.is_followed = 0;
            }
            newItem.share_post.share_post.is_followed = 0;
            if (newItem.share_post.share_post.latest_comment) {
              newItem.share_post.share_post.latest_comment.is_followed = 0;
            }
          }
          return newItem;
        } else if (+item?.share_post?.user_id === +action.data.data.payload) {
          const newItem = { ...item };
          if (action.data.data.data.is_followed === 1) {
            //follow
            if (+item?.user_id === +action.data.data.payload) {
              newItem.is_followed = 1;
            }
            newItem.share_post.is_followed = 1;
            if (newItem.share_post.latest_comment) {
              newItem.share_post.latest_comment.is_followed = 1;
            }
          } else {
            //Unfollow
            if (+item?.user_id === +action.data.data.payload) {
              newItem.is_followed = 0;
            }
            newItem.share_post.is_followed = 0;
            if (newItem.share_post.latest_comment) {
              newItem.share_post.latest_comment.is_followed = 0;
            }
          }
          return newItem;
        } else if (+item?.user_id === +action.data.data.payload) {
          const newItem = { ...item };
          if (action.data.data.data.is_followed === 1) {
            //follow
            newItem.is_followed = 1;
            if (newItem.latest_comment) {
              newItem.latest_comment.is_followed = 1;
            }
          } else {
            //Unfollow
            newItem.is_followed = 0;
            if (newItem.latest_comment) {
              newItem.latest_comment.is_followed = 0;
            }
          }
          return newItem;
        } else return item;
      });

      if (action?.data?.payload === state?.detailPost?.user_id) {
        state.detailPost = {
          ...state.detailPost,
          is_followed: action.data.data.data?.is_followed,
        };
      }

      state.replyCommentDetail = state.replyCommentDetail?.map((item) => {
        if (item?.user_id === action.data.data.payload) {
          return {
            ...item,
            is_followed: action.data.data.data.is_followed,
          };
        }
        return item;
      });
      state.commentDetail = state.commentDetail?.map((item) => {
        if (item?.user_id === action.data.data.payload) {
          return {
            ...item,
            is_followed: action.data.data.data.is_followed,
          };
        }
        return item;
      });
      return {
        ...state,
        commentDetail: state.commentDetail,
        listPostCreator: state.listPostCreator,
        replyCommentDetail: state.replyCommentDetail,
        loadingFollow: false,
        isLoadingFollowCreate: false,
      };
    case TYPES.GET_USER_INFO_OTHERS_SUCCESS:
      return {
        ...state,
        loadingUser: false,
        dataInfoOther: action.data.data.data,
        status: false,
      };
    case TYPES.COLLAPSE_REPLY_COMMENT_DETAILS_SUCCESS:
      const comment_id = action.data;
      state.replyCommentDetail = state.replyCommentDetail.filter(
        (item) => item.parent_id !== comment_id
      );
      return {
        ...state,
        replyCommentDetail: state.replyCommentDetail,
      };
    // REPLY COMMENT
    case TYPES.POST_REPLY_COMMENT_DETAILS_SUCCESS:
      state.commentDetail = state.commentDetail.filter((item) => {
        if (item.id === action.data.data.parent_id) {
          return { ...item, number_comment: ++item.number_comment };
        }
        return item;
      });

      state.searchShortVideo = state.searchShortVideo.map((item) => {
        if (+item.id === +action?.data?.data?.post_id) {
          const newItem = { ...item };
          newItem.number_comment = newItem?.number_comment + 1;

          return newItem;
        } else return item;
      });
      return {
        ...state,
        loadingPostComment: false,
        detailPost: { ...state.detailPost, number_comment: ++state.detailPost.number_comment },
        commentDetail: state.commentDetail,
        replyCommentDetail: [action.data.data, ...state.replyCommentDetail],
        searchShortVideo: state.searchShortVideo,
        statusPostRepComment: true,
        loading: false,
      };
    //LIKE COMMENT DETAIL
    case TYPES.LIKE_COMMENT_DETAIL_SUCCESS:
      state.listPostTimeLine = state.listPostTimeLine.map((item) => {
        if (+item?.id === +action?.data?.data?.post_id) {
          const newItem = { ...item };
          newItem.latest_comment.number_like = action.data.data.number_like;
          newItem.latest_comment.is_liked = action.data.data.is_liked;
          return newItem;
        }
        return item;
      });

      state.detailPost = {
        ...state.detailPost,
        latest_comment: {
          ...state.detailPost?.latest_comment,

          number_like: action.data.data.number_like,
          is_liked: action.data.data.is_liked,
        },
      };

      state.listPostCreator = state.listPostCreator.map((item) => {
        if (item?.share_post) {
          if (+item?.share_post.id === +action?.data?.data?.post_id) {
            const newItem = { ...item };
            newItem.share_post.latest_comment.number_like = action.data.data.number_like;
            newItem.share_post.latest_comment.is_liked = action.data.data.is_liked;
            return newItem;
          }
          return item;
        } else {
          if (+item?.id === +action?.data?.data?.post_id) {
            const newItem = { ...item };
            newItem.latest_comment.number_like = action.data.data.number_like;
            newItem.latest_comment.is_liked = action.data.data.is_liked;
            return newItem;
          }
          return item;
        }
      });

      state.dataBookmark = state.dataBookmark.map((item) => {
        if (item?.share_post) {
          if (+item?.share_post.id === +action?.data?.data?.post_id) {
            const newItem = { ...item };
            newItem.share_post.latest_comment.number_like = action.data.data.number_like;
            newItem.share_post.latest_comment.is_liked = action.data.data.is_liked;
            return newItem;
          }
          return item;
        } else {
          if (+item?.id === +action?.data?.data?.post_id) {
            const newItem = { ...item };
            newItem.latest_comment.number_like = action.data.data.number_like;
            newItem.latest_comment.is_liked = action.data.data.is_liked;
            return newItem;
          }
          return item;
        }
      });

      state.commentDetail = state.commentDetail.map((item) => {
        if (item.id === action.data.data.id) {
          return {
            ...item,
            number_like: action.data.data.number_like,
            is_liked: action.data.data.is_liked,
          };
        }
        return item;
      });
      state.replyCommentDetail = state.replyCommentDetail.map((item) => {
        if (item.id === action.data.data.id) {
          return {
            ...item,
            number_like: action.data.data.number_like,
            is_liked: action.data.data.is_liked,
          };
        }
        return item;
      });
      return {
        ...state,
        detailPost: state.detailPost,
        commentDetail: state.commentDetail,
        likeComment: false,
      };
    case TYPES.CREATOR_GET_POST_DETAILS_SUCCESS:
      return {
        ...state,
        detailPost: action.data.data.data,
        loading: false,
        error: false,
      };
    //LIKE POST DETAIL
    case TYPES.UPDATE_LIKE_POST_DETAILS_REQUEST:
      const postDetail = action.data;
      const isPostLiked = postDetail?.is_liked ? 0 : 1;

      state.dataBookmark = state.dataBookmark.map((item) => {
        if (+item.id === +postDetail.id) {
          return {
            ...item,
            is_liked: isPostLiked,
            number_like: isPostLiked ? postDetail.number_like + 1 : postDetail.number_like - 1,
          };
        }
        return item;
      });

      state.listPostTimeLine = state.listPostTimeLine.map((item) => {
        if (+item.id === +postDetail.id) {
          return {
            ...item,
            is_liked: isPostLiked,
            number_like: isPostLiked ? postDetail.number_like + 1 : postDetail.number_like - 1,
          };
        }
        return item;
      });

      state.searchPostTimeline = state.searchPostTimeline.map((item) => {
        if (+item.id === +postDetail.id) {
          return {
            ...item,
            is_liked: isPostLiked,
            number_like: isPostLiked ? postDetail.number_like + 1 : postDetail.number_like - 1,
          };
        }
        return item;
      });

      state.listPostCreator = state.listPostCreator.map((item) => {
        if (+item.id === +postDetail.id) {
          return {
            ...item,
            is_liked: isPostLiked,
            number_like: isPostLiked ? postDetail.number_like + 1 : postDetail.number_like - 1,
          };
        }
        return item;
      });

      state.searchSaleContent = state.searchSaleContent.map((item) => {
        if (+item.id === +postDetail.id) {
          return {
            ...item,
            is_liked: isPostLiked,
            number_like: isPostLiked ? postDetail.number_like + 1 : postDetail.number_like - 1,
          };
        }
        return item;
      });

      if (state.detailPost) {
        state.detailPost = {
          ...state.detailPost,
          number_like: isPostLiked ? postDetail.number_like + 1 : postDetail.number_like - 1,
          is_liked: isPostLiked,
        };
      }

      state.searchShortVideo = state.searchShortVideo.map((item) => {
        if (+item.id === +postDetail.id) {
          return {
            ...item,
            is_liked: isPostLiked,
            number_like: isPostLiked ? postDetail.number_like + 1 : postDetail.number_like - 1,
          };
        }
        return item;
      });

      state.dataBookmark = state.dataBookmark.map((item) => {
        if (+item.id === +postDetail.id) {
          return {
            ...item,
            is_liked: isPostLiked,
            number_like: isPostLiked ? postDetail.number_like + 1 : postDetail.number_like - 1,
          };
        }
        return item;
      });

      return {
        ...state,
        detailPost: state.detailPost,
        searchShortVideo: state.searchShortVideo,
        isLoadDataSearchShort: true,
      };

    case 'UPDATE_POST_MYPAGE_WHEN_UPDATE_POST':
      state.listPostCreator = state.listPostCreator.map((item) => {
        if (+item.id === +action.data?.postDetail?.id) {
          if (action.data?.isPostSale) {
            return {
              ...item,
              title: action.data?.postDetail?.title,
              content: action.data?.postDetail?.content,
              number_premium_ticket: action.data?.postDetail?.number_premium_ticket,
              price: action.data?.postDetail?.price,
            };
          }
          return {
            ...item,
            content: action.data?.postDetail?.content,
          };
        }
        return item;
      });
      return {
        ...state,
        listPostCreator: state.listPostCreator,
      };

    case 'UPDATE_HIDDEN_LAST_COMMENT_MYPAPGE':
      state.listPostCreator = state.listPostCreator.map((item) => {
        if (+item.id === +action.data?.idPost) {
          const newItem = { ...item };
          if (+newItem.latest_comment?.id === +action.data?.idHidden) {
            newItem.latest_comment = {
              ...newItem?.latest_comment,
              is_hidden: action.data?.value,
            };
            return newItem;
          }
          return item;
        }
        return item;
      });
      return {
        ...state,
        listPostCreator: state.listPostCreator,
      };

    case TYPES.LIKE_POST_DETAILS_SUCCESS:
      state.listPostTimeLine = state.listPostTimeLine.map((item) => {
        if (+item.id === +action.data.data.post_id) {
          const newItem = { ...item };
          if (newItem.is_liked === 1) {
            newItem.is_liked = 0;
            --newItem.number_like;
          } else {
            newItem.is_liked = 1;
            ++newItem.number_like;
          }
          return newItem;
        } else return item;
      });

      state.listPostCreator = state.listPostCreator.map((item) => {
        if (+item.id === +action.data.data.post_id) {
          const newItem = { ...item };
          if (newItem.is_liked === 1) {
            newItem.is_liked = 0;
            --newItem.number_like;
          } else {
            newItem.is_liked = 1;
            ++newItem.number_like;
          }
          return newItem;
        } else return item;
      });

      const dataLikeDetail = action.data.data;
      if (state.detailPost) {
        state.detailPost = {
          ...state.detailPost,
          number_like: dataLikeDetail?.is_liked
            ? ++state.detailPost.number_like
            : --state.detailPost.number_like,
          is_liked: dataLikeDetail?.is_liked,
        };
      }

      state.searchShortVideo = state.searchShortVideo.map((item) => {
        if (+item.id === +action?.data?.data?.post_id) {
          const newItem = { ...item };
          if (newItem.is_liked === 1) {
            newItem.is_liked = 0;
            --newItem.number_like;
          } else {
            newItem.is_liked = 1;
            ++newItem.number_like;
          }

          return newItem;
        } else return item;
      });

      state.searchSaleContent = state.searchSaleContent.map((item) => {
        if (+item.id === +action?.data?.data?.post_id) {
          const newItem = { ...item };
          if (newItem.is_liked === 1) {
            newItem.is_liked = 0;
            --newItem.number_like;
          } else {
            newItem.is_liked = 1;
            ++newItem.number_like;
          }

          return newItem;
        } else return item;
      });
      return {
        ...state,
        detailPost: state.detailPost,
        searchShortVideo: state.searchShortVideo,
        isLoadDataSearchShort: false,
      };
    // func search sale content
    case TYPES.SEARCH_SALE_CONTENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TYPES.SEARCH_SALE_CONTENT_SUCCESS:
      return {
        ...state,
        loading: false,
        initPageSeachSale: action?.data?.pagination?.current_page,
        searchSaleContent:
          action?.data?.pagination?.current_page === 1
            ? action?.data?.data
            : [...state?.searchSaleContent, ...action?.data?.data],
        searchSaleContentPagination: action?.data?.pagination,
      };
    case TYPES.SEARCH_SALE_CONTENT_FAILED:
      return {
        ...state,
        error: action.data.error,
        loading: false,
      };

    // func search post timeline
    case TYPES.SEARCH_POST_TIMELINE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TYPES.SEARCH_POST_TIMELINE_SUCCESS:
      return {
        ...state,
        loading: false,
        initPageSearchPost: action?.data?.pagination?.current_page,
        searchPostTimeline:
          action?.data?.pagination?.current_page === 1
            ? action?.data?.data
            : [...state?.searchPostTimeline, ...action?.data?.data],
        searchPostTimelinePagination: action?.data?.pagination,
      };
    case TYPES.SEARCH_POST_TIMELINE_FAILED:
      return {
        ...state,
        error: action.data.error,
        loading: false,
      };

    // bookmark post detail
    case TYPES.BOOK_MARK_DETAILS_REQUEST:
      return {
        ...state,
      };
    case TYPES.BOOK_MARK_DETAILS_SUCCESS:
      state.listPostTimeLine = state.listPostTimeLine.map((item) => {
        if (+item.id === +action.data.data.post_id) {
          const newItem = { ...item };
          newItem.is_bookmarked = action.data.data?.bookmark_status ? 1 : 0;
          return newItem;
        } else return item;
      });

      state.listPostCreator = state.listPostCreator.map((item) => {
        if (+item.id === +action.data.data.post_id) {
          const newItem = { ...item };
          if (newItem.is_bookmarked === 1) {
            newItem.is_bookmarked = 0;
          } else {
            newItem.is_bookmarked = 1;
          }
          return newItem;
        } else return item;
      });

      if (state.detailPost) {
        const dataBookDetail = action.data.data;
        state.detailPost = {
          ...state.detailPost,
          number_bookmark: dataBookDetail.bookmark_status
            ? ++state.detailPost.number_bookmark
            : --state.detailPost.number_bookmark,
          is_bookmarked: dataBookDetail.bookmark_status ? 1 : 0,
        };
      }

      state.searchShortVideo = state.searchShortVideo.map((item) => {
        if (+item.id === +action?.data?.data?.post_id) {
          const newItem = { ...item };
          newItem.is_bookmarked = action.data.data?.is_bookmarked;

          return newItem;
        } else return item;
      });
      return {
        ...state,
        detailPost: state.detailPost,
        searchShortVideo: state.searchShortVideo,
        isLoadDataSearchShort: false,
      };
    case TYPES.BOOK_MARK_DETAILS_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.data.error,
      };
    case TYPES.LIST_POSTS_NO_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        initPageTimelineNoLogin: action?.data?.res?.pagination?.current_page,
        paginationTimeLineNoLogin: action?.data?.res?.pagination,
        listPostNoLogin:
          action?.data?.res?.pagination?.current_page === 1
            ? action?.data?.res?.data
            : [...state.listPostNoLogin, ...action?.data?.res?.data],
      };
    // list post timeline
    case TYPES.LIST_POSTS_TIME_LINE_SUCCESS:
      return {
        ...state,
        loading: false,
        initPageTimeline: action?.data?.res?.pagination?.current_page,
        paginationTimeLine: action?.data?.res?.pagination,
        listPostTimeLine:
          action?.data?.res?.pagination?.current_page === 1
            ? action?.data?.res?.data
            : [...state.listPostTimeLine, ...action?.data?.res?.data],
      };
    // delete comment detail
    case TYPES.DELETE_COMMENT_DETAILS_SUCCESS:
      const deletedComment = state.commentDetail.find(
        (item) => item.id === action.data.data.payload
      );

      state.commentDetail = state.commentDetail.filter(
        (item) => item.id !== action.data.data.payload
      );
      const postId = action.data.data.postId;
      state.listPostTimeLine = state.listPostTimeLine.map((item) => {
        // if(postId){
        //   return item;
        // } else {
        if (+item?.id === action.data.data.postId) {
          const newItem = { ...item };
          if (newItem.number_comment > 1) {
            newItem.isReadmore = true;
          }
          newItem.latest_comment = {};
          newItem.number_comment = newItem.number_comment - action?.data?.data?.data?.count;
          return newItem;
        } else return item;
        // }
      });

      state.listPostCreator = state.listPostCreator.map((item) => {
        if (+item?.id === +action.data.data.postId) {
          const newItem = { ...item };
          if (newItem.number_comment > 1) {
            newItem.isReadmore = true;
          }
          newItem.latest_comment = {};
          newItem.number_comment = newItem.number_comment - action?.data?.data?.data?.count;
          return newItem;
        } else return item;
      });

      state.dataBookmark = state.dataBookmark.map((item) => {
        if (+item?.id === action.data.data.postId) {
          const newItem = { ...item };
          if (newItem.number_comment > 1) {
            newItem.isReadmore = true;
          }
          newItem.latest_comment = {};
          newItem.number_comment = newItem.number_comment - action?.data?.data?.data?.count;
          return newItem;
        } else return item;
      });

      state.searchShortVideo = state.searchShortVideo.map((item) => {
        if (+item.id === +action?.data?.data?.postId) {
          const newItem = { ...item };
          newItem.number_comment = newItem.number_comment - action?.data?.data?.data?.count;

          return newItem;
        }
        return item;
      });

      state.searchSaleContent = state.searchSaleContent.map((item) => {
        if (+item.id === +action?.data?.data?.postId) {
          const newItem = { ...item };
          newItem.number_comment = newItem.number_comment - action?.data?.data?.data?.count;

          return newItem;
        }
        return item;
      });

      state.searchPostTimeline = state.searchPostTimeline.map((item) => {
        if (+item.id === +action?.data?.data?.postId) {
          const newItem = { ...item };
          newItem.number_comment = newItem.number_comment - action?.data?.data?.data?.count;

          return newItem;
        }
        return item;
      });

      state.searchShortVideo = state.searchShortVideo.map((item) => {
        if (+item.id === +deletedComment?.post_id) {
          const newItem = { ...item };
          newItem.number_comment =
            newItem?.number_comment - 1 - deletedComment?.number_comment;
          return newItem;
        } else return item;
      });

      return {
        ...state,
        detailPost: {
          ...state.detailPost,
          number_comment:
            state.detailPost?.number_comment - 1 - deletedComment?.number_comment,
        },
        commentDetail: state.commentDetail,
        listPostTimeLine: state.listPostTimeLine,
        searchShortVideo: state.searchShortVideo,
        listPostCreator: state.listPostCreator,
        dataBookmark: state.dataBookmark,
        loading: false,
      };
    // like post
    case TYPES.LIKE_SUCCESS:
      if (+action?.data?.data?.post_id === state.detailPost?.id) {
        if (action?.data?.data?.is_liked === 0) {
          state.detailPost = {
            ...state.detailPost,
            number_like: state.detailPost.number_like - 1,
            is_liked: 0,
          };
        } else {
          state.detailPost = {
            ...state.detailPost,
            number_like: state.detailPost.number_like + 1,
            is_liked: 1,
          };
        }
      }
      return {
        ...state,
        detailPost: state.detailPost,
        loadingAction: false,
      };
    // bookmark post
    case TYPES.BOOK_MARKS_REQUEST:
      return {
        ...state,
        loadingAction: true,
      };
    case TYPES.BOOK_MARKS_SUCCESS:
      const indexPostBookmark = state.dataBookmark?.findIndex(
        (item) => +item?.id === +action.data?.id
      );
      if (indexPostBookmark !== -1) {
        state.dataBookmark?.splice(indexPostBookmark, 1);
      } else {
        state.dataBookmark?.unshift(action.data);
      }

      const isBookmarked = action.data.is_bookmarked ? 0 : 1;
      state.listPostTimeLine = state.listPostTimeLine.map((item) => {
        if (+item.id === +action.data.id) {
          return {
            ...item,
            is_bookmarked: isBookmarked,
          };
        }
        return item;
      });

      state.searchSaleContent = state.searchSaleContent.map((item) => {
        if (+item.id === +action.data.id) {
          return {
            ...item,
            is_bookmarked: isBookmarked,
          };
        }
        return item;
      });

      state.listPostCreator = state.listPostCreator.map((item) => {
        if (item?.share_post && item?.share_type !== 2) {
          if (+item?.share_post?.id === +action.data.id) {
            return {
              ...item,
              share_post: {
                ...item.share_post,
                is_bookmarked: isBookmarked,
              },
            };
          }
          return item;
        } else {
          if (+item.id === +action.data.id) {
            return {
              ...item,
              is_bookmarked: isBookmarked,
            };
          }
          return item;
        }
      });

      if (action?.data?.id === state?.detailPost?.id) {
        state.detailPost = {
          ...state.detailPost,
          is_bookmarked: isBookmarked,
        };
      }

      return {
        ...state,
        detailPost: state.detailPost,
        loadingAction: false,
      };
    case TYPES.POST_REPLY_COMMENT_DETAILS_FAILED:
      return {
        ...state,
        loadingPostComment: false,
        status: action.type,
        error: action.data.error,
        statusPostRepComment: false,
        loading: false,
      };
    case TYPES.EDIT_REPLY_COMMENT_DETAILS_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.data.error,
        loading: false,
        statusEditRepComment: false,
      };
    case TYPES.BOOK_MARKS_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.data.error,
        loadingAction: false,
      };
    case TYPES.LIST_POSTS_CREATOR_FAILED:
    case TYPES.DELETE_COMMENT_DETAILS_FAILED:
    case TYPES.UPDATE_DETAIL_RECORDING_V2_FAILED:
    case TYPES.DELETE_REPLY_COMMENT_DETAILS_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.data.error,
        loading: false,
      };
    case TYPES.POST_COMMENT_DETAILS_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.data.error,
        statusPostComment: false,
        loadingPostComment: false,
        loading: false,
      };
    case TYPES.GET_COMMENT_DETAILS_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.data.error,
        loadingCommentDetail: false,
      };
    case TYPES.LIKE_REPLY_COMMENT_DETAILS_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.data.error,
        likeComment: false,
        likeRepComment: false,
      };
    case TYPES.POST_LIKE_CREATOR_FAILED:
    case TYPES.POST_RETWEET_REQUEST_FAILED:
      return {
        ...state,
        status: action.type,
        isLoadingFollowCreate: false,
      };
    case TYPES.GHIM_POST_MYPAGE_FAILED:
      return {
        ...state,
        status: action.type,
        isLoadingFollowCreate: false,
      };
    case TYPES.LIST_HIGH_LIGHT_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.data.error,
        loadingHighline: false,
      };
    case TYPES.FOLLOW_USER_REPLY_COMMENT_DETAILS_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.data.error,
        loadingFollow: false,
      };
    case TYPES.POST_NORMAL_BUY_PACKAGES_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.data.error,
        check_buy_package: action?.data?.error?.messageId,
        loadingBuyPost: false,
        flagHiddenPopup: false,
      };
    case TYPES.FOLLOW_OR_UN_FOLLOW_USER_SUCCESS:
    case TYPES.LIKE_COMMENT_DETAIL_FAILED:
    case TYPES.UPDATE_COMMENT_POST_FAILED:
    case TYPES.CREATE_COMMENT_POST_FAILED:
    case TYPES.LIST_3_IMG_MY_PAGE_FAILED:
    case TYPES.FOLLOW_USER_COMMENT_DETAILS_FAILED:
      return {
        ...state,
        isLoadingFollowCreate: false,
        loading: false,
      };
    case TYPES.LIST_POSTS_TIME_LINE_FAILED:
    case TYPES.LIKE_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.data.error,
        loadingAction: false,
        likePost: false,
      };
    case TYPES.LIKE_POST_DETAILS_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.data.error,
      };
    case TYPES.GET_USER_INFO_OTHERS_FAILED:
      return {
        ...state,
        status: false,
        error: action.data.error,
        loadingUser: false,
      };
    case TYPES.CREATOR_GET_POST_DETAILS_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.data.error,
        loading: false,
      };
    case TYPES.SEARCH_SHORT_VIDEO_SUCCESS:
      return {
        ...state,
        loading: false,
        isLoadDataSearchShort: true,
        initPageSeachVideo: action?.data?.pagination?.current_page,
        searchShortVideo:
          action?.data?.pagination?.current_page === 1
            ? action?.data?.data
            : [...state?.searchShortVideo, ...action?.data?.data].reduce(
                (accumulator, current) => {
                  let exists = accumulator.find((item) => {
                    return item.id === current.id;
                  });
                  if (!exists) {
                    accumulator = accumulator.concat(current);
                  }
                  return accumulator;
                },
                []
              ),
        searchShortVideoPagination: action?.data?.pagination,
      };

    case TYPES.SEARCH_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        searchUser:
          action?.data?.pagination?.current_page === 1
            ? action?.data?.data
            : [...state?.searchUser, ...action?.data?.data],
        searchUserPagination: action?.data?.pagination,
      };
    case TYPES.LIKE_REPLY_COMMENT_NOW_REQUEST:
      const isLike = action?.data?.is_liked ? 0 : 1;

      state.replyCommentDetail = state.replyCommentDetail.map((item) => {
        if (item.id === action.data.id) {
          return {
            ...item,
            number_like: isLike ? action.data.number_like + 1 : action.data.number_like - 1,
            is_liked: isLike,
          };
        }
        return item;
      });
      return {
        ...state,
        replyCommentDetail: state.replyCommentDetail,
        likeRepComment: false,
      };
    case TYPES.RESET_DETAIL_POST_REQUEST:
      return {
        ...state,
        isCheckResetDetailPost: false,
        detailPost: null,
        statusEditComment: null,
      };
    case TYPES.RESET_DETAIL_POST_SUCCESS:
      return {
        ...state,
        isCheckResetDetailPost: true,
      };
    case 'RESET_COMMENT_DETAIL':
      return {
        ...state,
        detailPost: null,
        commentDetail: [],
      };
    case 'RESET_ACTION_USER_NEW_PAGE':
      return {
        ...state,
        listPostTimeLine: [],
        paginationTimeLine: {},
        loadingHighline: false,
        listHighLight: [],
      };
    case 'RESET_POPUP_LOCK_POST':
      return {
        ...state,
        flagHiddenPopup: false,
      };
    case 'RESET_POST_SHORT_VIDEO':
      return {
        ...state,
        listPostCreator: [],
      };
    case 'CLEAR_IS_LOAD_DATA_SEARCH_SHORT':
      return {
        ...state,
        isLoadDataSearchShort: true,
      };
    case 'RESET_POST_SEARCH':
      return {
        ...state,
        searchPostTimeline: [],
      };
    case 'ID_TIMELINE':
      return {
        ...state,
        idTimeline: action.data,
      };
    case 'ID_KYC':
      return {
        ...state,
        idKYC: action.data,
      };
    case 'RESET_ID_TIMELINE':
      return {
        ...state,
        initPageTimeline: 0,
        idTimeline: {},
        paginationTimeLine: {},
      };
    case 'ID_HOMEPAGE':
      return {
        ...state,
        idHomePage: action.data,
      };
    case 'RESET_ID_HOMEPAGE':
      return {
        ...state,
        idHomePage: null,
        initPageHome: 0,
      };
    case 'UPDATE_BLOCK_INFO_OTHER_USER':
      if (state.dataInfoOther?.id === action.data?.id) {
        state.dataInfoOther.is_blocked = action.data?.value;
      }
      return {
        ...state,
        dataInfoOther: state.dataInfoOther,
      };
    case 'RESET_DATA_INFO_OTHER':
      return {
        ...state,
        dataInfoOther: null,
      };
    case 'ID_POST_SEARCH':
      return {
        ...state,
        idSearchPost: action.data,
      };
    case 'RESET_ID_SEARCH_POST':
      return {
        ...state,
        initPageSearchPost: 0,
        idSearchPost: {},
        searchPostTimeline: [],
      };
    case 'ID_POST_SEARCH_SALE':
      return {
        ...state,
        idSearchSale: action.data,
      };
    case 'RESET_ID_SEARCH_SALE':
      return {
        ...state,
        initPageSeachSale: 0,
        idSearchSale: {},
        searchSaleContent: [],
      };
    case 'ID_POST_SEARCH_VIDEO':
      return {
        ...state,
        idSearchVideo: action.data,
      };
    case 'RESET_ID_SEARCH_VIDEO':
      return {
        ...state,
        initPageSeachVideo: 0,
        idSearchVideo: {},
        searchPostTimeline: [],
      };
    case 'ID_FAVOR':
      return {
        ...state,
        idFavoriteTimline: action.data,
      };
    case 'RESET_ID_FAVOR':
      return {
        ...state,
        initPageFavor: 0,
        idFavoriteTimline: {},
        dataBookmark: [],
      };
    case 'ID_CMT_TIMELINE':
      return {
        ...state,
        renderLikeCMTTimeline: action.data,
      };
    case 'RESET_ID_CMT_TIMELINE':
      return {
        ...state,
        renderLikeCMTTimeline: null,
      };
    case 'ID_CMT_HOMEPAGE':
      return {
        ...state,
        renderLikeCMTHomepage: action.data,
      };
    case 'RESET_ID_CMT_HOMEPAGE':
      return {
        ...state,
        renderLikeCMTHomepage: null,
      };
    case 'ID_CMT_BOOKMARK':
      return {
        ...state,
        renderLikeCMTBookmark: action.data,
      };
    case 'ID_CMT_SEARCH_POST':
      return {
        ...state,
        renderLikeCMTSearchPost: action.data,
      };
    case 'RESET_ID_CMT_BOOKMARK':
      return {
        ...state,
        renderLikeCMTBookmark: null,
      };
    case 'SET_ID_SCROLL_BACK_RECOMMEND':
      return {
        ...state,
        idScrollBackRecommend: action.data?.data?.id || null,
        pathUrlScrollBack: action.data?.data?.pathUrl || null,
        typeTopList: action.data?.data?.typeTopList || null,
      };
    case 'TAB_TIMELINE':
      return {
        ...state,
        tabTimeline: action.data,
      };
    case 'RESET_TAB_TIMELINE':
      return {
        ...state,
        tabTimeline: null,
      };
    case 'CHECK_POPUP_PAGE_SHORT':
      return {
        ...state,
        isShortMypage: action.data,
      };
    case 'SET_PAGE_SHORT':
      const item = action.data;
      return {
        ...state,
        pageShort: item,
      };
    default:
      return state;
  }
};
