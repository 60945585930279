import { ImgCustom, LayoutCreatorRight } from 'components';
import InfiniteLoad from 'components/Paging/InfiniteLoad';
import moment from 'moment';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fanGetListLikedPost, getIDLike, resetIDFavorite } from 'store/actions/users';
import { onErrImage } from 'utils/utils';
import { newPathUser } from 'constants/layout/constant';
import '../styles.scss';
import { HeartLikeIcon, NoLikeList } from 'images';
import { resetDataFavor, resetDetailPost } from 'store/actions/usersNew';
import PopupViewFullTimeLine from 'components/Account/PopupViewFullTimeLine';
import { ShimmerPostDetails } from 'react-shimmer-effects';
import { useCallback } from 'react';
import { AVATAR_BLOCK_USER, AVATAR_DEFAULT, AVATAR_DEL_USER } from 'utils';

export default function Index() {
  const { loading, fanListLikedPost, fanListLikedPostPagination, idLike, initPageLike } =
    useSelector((state) => state.users);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(idLike && initPageLike ? +initPageLike : 1);
  const [isLoading, setIsLoading] = useState(false);
  const limit = 20;
  const [isShowPopup, setShowPopup] = useState({
    status: false,
    dataItem: {},
  });
  const { t } = useTranslation();
  const fetchNext = () => {
    const pages = fanListLikedPostPagination?.total / fanListLikedPostPagination?.per_page;
    if (pages > page) {
      funCallApi(page + 1);
      setPage(page + 1);
    }
  };

  useEffect(() => {
    if (idLike) {
      const idPost = document.getElementById(idLike.id);
      if (idPost) {
        idPost.scrollIntoView({
          block: 'center',
        });
      }
    }
  }, [idLike]);

  const funCallApi = useCallback(
    (page) => {
      setIsLoading(true);
      dispatch(fanGetListLikedPost({ page, limit }, () => setIsLoading(false)));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page]
  );

  useLayoutEffect(() => {
    if (initPageLike < 1) {
      setIsLoading(true);
      dispatch(fanGetListLikedPost({ page, limit }, () => setIsLoading(false)));
    }
  }, []);

  const checkStatus = (status) => {
    let tmp = '';
    switch (status) {
      case null: // del
        tmp = AVATAR_DEL_USER
        break;
      case 2: // stop
        tmp = AVATAR_BLOCK_USER
        break;
      default:
        tmp = AVATAR_DEFAULT;
    }
    return tmp;
  };

  return (
    <LayoutCreatorRight
      noButtonBack
      titlePage={t('Like.title')}
      className='like-list-bookmark-layout'>
      <div className='list-page list-liked-post'>
        <div className={`fan-favorite-wrapper`}>
          <ul className='header header-bookmarks'>
            <li
              className={`${isLoading && !fanListLikedPost?.length && 'disable-like'}`}
              onClick={() => {
                dispatch(resetDataFavor());
                dispatch(resetIDFavorite());
                navigate(`${newPathUser}favorite/list-post`);
              }}>
              {t('Favorite.bookmarkList')}
            </li>
            <li className='active'>{t('Favorite.likeList')}</li>
          </ul>
        </div>
        <div className='body'>
          {isLoading && !fanListLikedPost?.length ? (
            <div className='shimmer-loading'>
              <ShimmerPostDetails />
            </div>
          ) : (
            <>
              {fanListLikedPost?.length > 0 ? (
                <div className='list'>
                  <InfiniteLoad
                    loading={loading}
                    data={fanListLikedPost}
                    fetchNext={fetchNext}>
                    {fanListLikedPost?.map((item, index) => {
                      return (
                        <div className='item' key={index}>
                          <div className='date'>{moment(item.date).format('YYYY/MM/DD')}</div>
                          {item.value.map((arr_item, index1) => (
                            <div
                              className='d-flex post-item'
                              key={index1}
                              id={arr_item?.post_id}
                              onClick={() => {
                                if (arr_item?.type === 2) {
                                  dispatch(
                                    getIDLike({ id: arr_item?.post_id, tab: 2, page: page })
                                  );
                                  navigate(`${newPathUser}post/${arr_item?.post_id}`, {
                                    state: {
                                      idPostFromListLike: arr_item?.post_id,
                                    },
                                  });
                                } else {
                                  dispatch(
                                    getIDLike({ id: arr_item?.post_id, tab: 2, page: page })
                                  );
                                  navigate(`${newPathUser}post/${arr_item?.post_id}`, {
                                    state: {
                                      idPostFromListLike: arr_item?.post_id,
                                    },
                                  });
                                }
                              }}>
                              <div className='images-container'>
                                {arr_item?.avatar ? (
                                  <ImgCustom
                                    isPlaceholderAvatar
                                    src={`${arr_item?.avatar}`}
                                    alt=''
                                    onError={(e) => onErrImage(e, true)}
                                    screen='recommend_top_56_56'
                                  />
                                ) : (
                                  <img src={checkStatus(arr_item?.status)} alt='avt'></img>
                                )}
                                <HeartLikeIcon />
                              </div>
                              <div className='item-info'>
                                <div className='name'>{arr_item?.account_name}</div>
                                <div className='content'>
                                  {arr_item?.title || arr_item?.content}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      );
                    })}
                  </InfiniteLoad>
                </div>
              ) : (
                <div className='no-result'>
                  <NoLikeList />
                  {t('Like.listNull')}
                </div>
              )}
            </>
          )}
        </div>
      </div>
      {isShowPopup?.status && (
        <PopupViewFullTimeLine
          item={isShowPopup?.dataItem}
          idx={isShowPopup?.idx}
          close={() => {
            setShowPopup({
              ...isShowPopup,
              status: false,
              dataItem: {},
            });
            dispatch(resetDetailPost());
          }}
        />
      )}
    </LayoutCreatorRight>
  );
}
