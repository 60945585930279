/* eslint-disable react-hooks/exhaustive-deps */
import {
  AccountSidebar,
  FanRankingItem,
  HeaderListIdolV2,
  LayoutCreatorRight,
  MenuSidebarCustom,
  PopupFanDetail,
  PopupLoading,
} from 'components';
import { InputWrapper } from 'components/FormLogin';
import InfiniteLoad from 'components/Paging/InfiniteLoad';
import { newPathUser } from 'constants/layout/constant';
import withCreatorRole from 'hocs/withCreatorRole';
import useLoading from 'hooks/useLoading';
import { ArrowLeftIcon, CloseIcon, MenuIcon, NoRankingList } from 'images';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Select from 'react-select';
import {
  creatorGetListRankingBuyer,
  getIDRankingBuy,
  resetIDRankingBuy,
  setListRankingBuyer,
} from 'store/actions/users';
import { CustomOption, customStyles as customStylesReselect, isAdmin, isAgency } from 'utils';
import './index.scss';
import { ShimmerPostDetails } from 'react-shimmer-effects';
import { isMobile } from 'react-device-detect';
import useDebounce from 'hooks/useDebouce';
import { useLayoutEffect } from 'react';
import { useCallback } from 'react';
import { resetDataInfoOther, resetIDHomePage } from 'store/actions/usersNew';

const optionYear = new Array(11).fill(0).map((a, b) => {
  return { value: new Date().getFullYear() - b, label: new Date().getFullYear() - b };
});

const optionMonth = new Array(12).fill(0).map((a, b) => {
  return { value: b + 1, label: String(b + 1).padStart(2, '0') };
});

const ListRankingBuyer = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const {
    listRankingBuyer = [],
    pagination,
    data: dataProfile,
    dataUser,
    idRankingBuy,
    paginationRanking,
  } = useSelector((state) => state.users);
  const yearParams = searchParams.get('year');
  const monthParams = searchParams.get('month');
  const sortParams = searchParams.get('sort');
  const [page, setPage] = useState(paginationRanking ? +paginationRanking : 1);
  const [selectedYear, setSelectedYear] = useState(
    Number(yearParams) || idRankingBuy?.selectYear || new Date().getFullYear()
  );
  const [selectedMonth, setSelectedMonth] = useState(
    Number(monthParams) || idRankingBuy?.selectMonth || new Date().getMonth() + 1
  );
  const [selectedSort, setSelectedSort] = useState(
    sortParams || idRankingBuy?.selectedSort || 'desc'
  );
  const [isVisible, setIsVisible] = useState(false);
  const [idFan, seIdFan] = useState(false);
  const { state } = useLocation();
  const [focusYear, setFocusYear] = useState(false);
  const [focusMonth, setFocusMonth] = useState(false);
  const [focusSort, setFocusSort] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const customStyles = {
    ...customStylesReselect,
    control: (base, state) => {
      return {
        ...base,
        minWidth: 96,
        boxShadow: 'none',
        width: 'max-content',
        display: 'flex',
        height: '32px',
        // border: '1px solid #b1b6bb',
        borderRadius: '6px',
        svg: {
          color: '#666565',
          transform: state.menuIsOpen ? 'rotate(180deg)' : '',
        },
        borderColor: state.isFocused ? '#1480FF' : '#E5E6EB',
      };
    },
    valueContainer: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
      height: 32,
      minHeight: 32,
      width: 'auto',
    }),
    menu: (base, state) => ({
      ...base,
      width: '100%',
      minWidth: '156px',
      border: 'none !important',
      borderRadius: '8px',
      overflow: 'hidden',
      zIndex: 2,
      boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.06);',
    }),
    menuList: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
      maxHeight: 200,
    }),
    dropdownIndicator: (base) => ({
      ...base,
      display: 'block',
    }),
  };

  const OPTION_SORT_RANKING_BUYER = useMemo(
    () => [
      {
        value: 'desc',
        label: t('Creator.RankingPaymentList.descendingOrder'),
      },
      {
        value: 'asc',
        label: t('Creator.RankingPaymentList.ascendingOrder'),
      },
    ],
    [i18n.language]
  );

  useEffect(() => {
    const top = document.getElementById('app_cocofans_0');
    top && top.scrollIntoView({ top: 0, left: 0, block: 'start' });
  }, []);

  const funCallApi = useCallback(
    (rs, year, month, sort) => {
      if (dataUser?.id) {
        setIsLoading(true);
        dispatch(
          creatorGetListRankingBuyer(
            state?.account_id ? state?.account_id : dataUser?.id,
            {
              page: rs,
              limit: 10,
              sort: sort,
              year: year,
              month: month,
              active_flag: 1,
            },
            () => setIsLoading(false)
          )
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page, selectedMonth, selectedSort, selectedYear, dataUser?.id]
  );

  useEffect(() => {
    if (paginationRanking < 1) {
      if (dataUser?.id) {
        setIsLoading(true);
        dispatch(
          creatorGetListRankingBuyer(
            state?.account_id ? state?.account_id : dataUser?.id,
            {
              page: page,
              limit: 10,
              sort: selectedSort,
              year: selectedYear,
              month: selectedMonth,
              active_flag: 1,
            },
            () => setIsLoading(false)
          )
        );
      }
    }
  }, [page, dataUser?.id, selectedMonth, selectedSort, selectedYear, idRankingBuy]);

  const fetchNext = () => {
    const pages = pagination?.total / pagination?.per_page;
    if (pages > page) {
      setPage(page + 1);
      funCallApi(page + 1, selectedYear, selectedMonth, selectedSort);
    }
  };

  const handleResetSearch = () => {
    setPage(1);
    dispatch(setListRankingBuyer());
  };

  const onChangeSelectYear = (value) => {
    if (value.value !== selectedYear) {
      navigate(
        `/ranking-buyer?year=${value?.value}&month=${selectedMonth}&sort=${selectedSort}`
      );
      setSelectedYear(value.value);
      handleResetSearch();
      funCallApi(1, value.value, selectedMonth, selectedSort);
    }
  };
  const onChangeSelectMonth = (value) => {
    if (value.value !== selectedMonth) {
      navigate(
        `/ranking-buyer?year=${selectedYear}&month=${value.value}&sort=${selectedSort}`
      );
      setSelectedMonth(value.value);
      handleResetSearch();
      funCallApi(1, selectedYear, value.value, selectedSort);
    }
  };
  const onChangeSelectSort = (value) => {
    if (value.value !== selectedSort) {
      navigate(
        `/ranking-buyer?year=${selectedYear}&month=${selectedMonth}&sort=${value.value}`
      );
      setSelectedSort(value.value);
      handleResetSearch();
      funCallApi(1, selectedYear, selectedMonth, value.value);
    }
  };

  return (
    <>
      {isVisible && (
        <PopupFanDetail
          closeModal={() => setIsVisible(false)}
          isVisible={isVisible}
          id={idFan}
        />
      )}
      <LayoutCreatorRight
        noButtonBack
        titlePage={t('MenuSiderbar.creator.rankingPayment')}
        className='ranking-buyer-layout'>
        <div className='buyer-page-creator ranking-buyer'>
          <form className='fansite-form' onSubmit={(e) => e.preventDefault()}>
            <InputWrapper id='fsite-select'>
              <div
                className={`select-box ${
                  !!state?.account_id && state?.account_id !== dataUser?.id && 'disabled'
                }`}
                onBlur={() => setFocusYear(false)}
                onClick={() => setFocusYear(!focusYear)}>
                <Select
                  options={optionYear}
                  isSearchable={false}
                  isClearable={false}
                  defaultValue={
                    idRankingBuy?.selectYear
                      ? { value: idRankingBuy?.selectYear, label: idRankingBuy?.selectYear }
                      : yearParams
                      ? {
                          value: Number(yearParams),
                          label: Number(yearParams),
                        }
                      : optionYear[0]
                  }
                  onChange={onChangeSelectYear}
                  styles={customStyles}
                  isDisabled={!!state?.account_id ? state?.account_id !== dataUser?.id : false}
                  className='sel-buyer'
                  components={{
                    Option: CustomOption,
                    DropdownIndicator: () => <ArrowLeftIcon />,
                  }}
                />
              </div>
            </InputWrapper>
            <InputWrapper id='fsite-select'>
              <div
                className={`select-box ${
                  !!state?.account_id && state?.account_id !== dataUser?.id && 'disabled'
                }`}
                onBlur={() => setFocusMonth(false)}
                onClick={() => setFocusMonth(!focusMonth)}>
                <Select
                  options={optionMonth}
                  isSearchable={false}
                  isClearable={false}
                  className='sel-buyer'
                  defaultValue={
                    idRankingBuy?.selectMonth
                      ? { value: idRankingBuy?.selectMonth, label: idRankingBuy?.selectMonth }
                      : monthParams
                      ? {
                          value: Number(monthParams),
                          label: Number(monthParams),
                        }
                      : {
                          value: new Date().getMonth() + 1,
                          label: String(new Date().getMonth() + 1).padStart(2, '0'),
                        }
                  }
                  onChange={onChangeSelectMonth}
                  styles={customStyles}
                  isDisabled={!!state?.account_id ? state?.account_id !== dataUser?.id : false}
                  components={{
                    Option: CustomOption,
                    DropdownIndicator: () => <ArrowLeftIcon />,
                  }}
                />
              </div>
            </InputWrapper>
            <InputWrapper id='fsite-select'>
              <div
                className={`select-box ${
                  !!state?.account_id && state?.account_id !== dataUser?.id && 'disabled'
                }`}
                onBlur={() => setFocusSort(false)}
                onClick={() => setFocusSort(!focusSort)}>
                <label className='select-label' htmlFor='sortBox'>
                  {t('Creator.RankingPaymentList.sort')}
                </label>
                <Select
                  id='sortBox'
                  options={OPTION_SORT_RANKING_BUYER}
                  isSearchable={false}
                  value={OPTION_SORT_RANKING_BUYER?.find((e) => e.value === selectedSort)}
                  isClearable={false}
                  defaultValue={!idRankingBuy?.selectedSort && OPTION_SORT_RANKING_BUYER[0]}
                  onChange={onChangeSelectSort}
                  className='sel-buyer'
                  classNamePrefix='select-order'
                  placeholder={t('Creator.RankingPaymentList.descendingOrder')}
                  styles={{
                    ...customStyles,
                    singleValue: (provided, state) => {
                      const opacity = state.isDisabled ? 0.5 : 1;
                      return {
                        ...provided,
                        opacity,
                        fontWeight: 500,
                        fontSize: 14,
                        position: 'absolute',
                        padding: '2px 16px 2px 0',
                        color: '#282727',
                      };
                    },
                    control: (base, state) => ({
                      ...base,
                      minWidth: i18n.language === 'jp' ? 96 : 190,
                      width: 'max-content',
                      display: 'flex',
                      borderRadius: '6px',
                      boxShadow: 'none',
                      svg: {
                        color: '#666565',
                        transform: state.menuIsOpen ? 'rotate(180deg)' : '',
                      },
                      borderColor: state.isFocused ? '#1480FF' : '#E5E6EB',
                    }),
                  }}
                  isDisabled={!!state?.account_id ? state?.account_id !== dataUser?.id : false}
                  components={{
                    Option: CustomOption,
                    DropdownIndicator: () => <ArrowLeftIcon />,
                  }}
                />
              </div>
            </InputWrapper>
          </form>
          <InfiniteLoad
            loading={isLoading && listRankingBuyer?.length}
            data={listRankingBuyer || []}
            fetchNext={fetchNext}>
            <div className='list-ranking-buyer'>
              {isLoading && !listRankingBuyer.length ? (
                <ShimmerPostDetails />
              ) : (
                <>
                  {listRankingBuyer?.length > 0
                    ? listRankingBuyer.map((item, index) => {
                        return (
                          <FanRankingItem
                            key={index}
                            item={item}
                            handleClick={(id) => {
                              dispatch(resetIDHomePage());
                              dispatch(resetDataInfoOther())
                              dispatch(
                                getIDRankingBuy({
                                  id: item?.rank,
                                  selectYear: selectedYear,
                                  selectMonth: selectedMonth,
                                  selectedSort: selectedSort,
                                  page: page,
                                })
                              );
                              navigate(`${newPathUser}${id}`);
                            }}
                          />
                        );
                      })
                    : !isLoading &&
                      listRankingBuyer?.length === 0 && (
                        <div className='empty-box'>
                          <NoRankingList />
                          {t('Creator.RankingPaymentList.noRankingBillingList')}
                        </div>
                      )}
                </>
              )}
            </div>
          </InfiniteLoad>
        </div>
      </LayoutCreatorRight>
    </>
  );
};

export default withCreatorRole(ListRankingBuyer);
