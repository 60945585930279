import React, { useEffect, useState, useMemo } from 'react';
import i18n from 'translate/i18n';
  const ListTags = ({ handleSelectTags, limit, data, flgListTag }) => {
    return (
      <div className='row' style={{ marginTop: '-14px'}}>
        {!flgListTag
          ? data?.map((item, index) => {
              if (index < limit) {
                return (
                  <div className={`form-group d-flex mt-3 col-6 col-sm-6 col-md-4`} key={index}>
                    <input
                      type='checkbox'
                      id={item.id}
                      className='form-check-input'
                      name={`tag_id[${index}]`}
                      onClick={(e) => handleSelectTags(e, item)}
                      checked={item.checked}
                    />
                    &nbsp;&nbsp;
                    <label htmlFor={item.id}>{i18n.language === 'jp' ? item.tag : item.tag_en}</label>
                  </div>
                );
              }
            })
          : data?.map((item, index) => (
              <div className={`form-group d-flex mt-3 col-6 col-sm-6 col-md-4`} key={index}>
                <input
                  type='checkbox'
                  id={item.id}
                  className='form-check-input'
                  name={`tag_id[${index}]`}
                  onClick={(e) => handleSelectTags(e, item)}
                  checked={item.checked}
                />
                &nbsp;&nbsp;
                <label htmlFor={item.id}>{i18n.language === 'jp' ? item.tag : item.tag_en}</label>
              </div>
            ))}
      </div>
    );
  }
  export default React.memo(ListTags);
