/* eslint-disable import/no-anonymous-default-export */

import { getEnv } from 'configs/env';
import { TYPES } from '../actions';
import { newPathUser } from 'constants/layout/constant';

const initialState = {
  status: null,
  loading: false,
  loadingAction: false,
  error: null,
  data: [],
  pagination: {},
  listPostFollowed: [],
  //Đan : detail post
  listComment: null,
  listCommentPost: [],
  loadingComment: false,
  loadingPostDetail: false,
  paginationComment: {},
  detailPost: null,
  loadingPost: true,
  statusPost: null,
  statusDeletePost: false,
  commentDetail: [],
  replyCommentDetail: [],
  loadingCommentDetail: false,
  loadingRepCommentDetail: false,
  loadingFollow: false,
  likeComment: false,
  likeRepComment: false,
  statusPostComment: null,
  statusPostRepComment: null,
  statusEditComment: null,
  statusEditRepComment: null,
  statusBuyPost: null,
  isMinimal: false,
  isLogUser: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.LIST_MY_PAGE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TYPES.LIST_MY_PAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        pagination: action?.data?.data?.pagination,
        data:
          action?.data?.data?.pagination?.current_page === 1
            ? action?.data?.data?.data
            : [...state?.data, ...action?.data?.data?.data],
      };
    case TYPES.LIST_MY_PAGE_FAILED:
      return {
        ...state,
        loading: false,
        status: action.type,
        error: action.data.error,
      };

    case TYPES.LIKE_REQUEST:
      return {
        ...state,
        loadingAction: true,
        likePost: false,
      };
    case TYPES.LIKE_SUCCESS:
      let dataLike = [];
      dataLike = state.data.map((item) => {
        if (+item.id === +action.data.data.post_id) {
          const newItem = { ...item };
          if (newItem.user_like_status === 1) {
            newItem.user_like_status = 0;
            --newItem.number_like;
          } else {
            newItem.user_like_status = 1;
            ++newItem.number_like;
          }
          return newItem;
        } else return item;
      });
      // }
      return {
        ...state,
        data: dataLike,
        detailPost: state.detailPost,
        loadingAction: false,
      };
    case TYPES.LIKE_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.data.error,
        loadingAction: false,
        likePost: false,
      };

    case TYPES.BOOK_MARK_REQUEST:
      return {
        ...state,
        loadingAction: true,
      };
    case TYPES.BOOK_MARK_SUCCESS:
      let dataBookMark = [];
      dataBookMark = state.data.map((item) => {
        if (+item.id === +action.data.data.post_id) {
          const newItem = { ...item };
          if (newItem.user_bookmark_status === 1) {
            newItem.user_bookmark_status = 0;
            --newItem.number_bookmark;
          } else {
            newItem.user_bookmark_status = 1;
            ++newItem.number_bookmark;
          }
          return newItem;
        } else return item;
      });
      return {
        ...state,
        data: dataBookMark,
        detailPost: state.detailPost,
        loadingAction: false,
      };
    case TYPES.BOOK_MARK_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.data.error,
        loadingAction: false,
      };

    case TYPES.POST_COMMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TYPES.POST_COMMENT_SUCCESS:
      (function postComment() {
        const newPost = action.data?.data?.data;
        state.data = state.data?.map((item) => {
          return item.id === newPost.id ? newPost : item;
        });
      })();
      const newPost = action.data?.data?.data;
      ++state.detailPost.number_comment;
      return {
        ...state,
        data: [...state.data],
        detailPost: state.detailPost,
        listCommentPost: [newPost, ...state?.listCommentPost],
        loading: false,
        status: 'comment_success',
      };

    case TYPES.POST_COMMENT_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.data.error,
        loading: false,
      };

    case TYPES.LIKE_COMMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TYPES.LIKE_COMMENT_SUCCESS:
      (function likeComment() {
        const newPost = action.data.data.data;
        state.data = state.data?.map((item) => {
          return item.id === newPost.id ? newPost : item;
        });
      })();
      let dataLikeComment = state.listCommentPost.map((item) => {
        if (+item.id === +action.data.data.id) {
          const newItem = { ...item };
          if (newItem.is_liked === 1) {
            newItem.is_liked = 0;
            --newItem.number_like;
          } else {
            newItem.is_liked = 1;
            ++newItem.number_like;
          }
          return newItem;
        } else return item;
      });

      return {
        ...state,
        data: [...state.data],
        detailPost: state.detailPost,
        listCommentPost: dataLikeComment,
        loading: false,
      };
    case TYPES.LIKE_COMMENT_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.data.error,
        loading: false,
      };

    case TYPES.DELETE_COMMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TYPES.DELETE_COMMENT_SUCCESS:
      (function deleteComment() {
        const newPost = action.data.data.data[0];
        state.data = state.data?.map((item) => {
          return item.id === newPost.id ? newPost : item;
        });
        state.detailPost = state.detailPost?.map((item) => {
          return item.id === newPost.id ? newPost : item;
        });
      })();
      return {
        ...state,
        data: [...state.data],
        loading: false,
      };
    case TYPES.DELETE_COMMENT_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.data.error,
        loading: false,
      };

    case TYPES.EDIT_COMMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TYPES.EDIT_COMMENT_SUCCESS:
      (function editComment() {
        const newPost = action.data.data.data[0];
        state.data = state.data?.map((item) => {
          return item.id === newPost.id ? newPost : item;
        });
        state.detailPost = state.detailPost?.map((item) => {
          return item.id === newPost.id ? newPost : item;
        });
      })();

      return {
        ...state,
        data: [...state.data],
        loading: false,
      };
    case TYPES.EDIT_COMMENT_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.data.error,
        loading: false,
      };

    case TYPES.GET_POST_DETAIL_REQUEST:
      return {
        ...state,
        loading: true,
        loadingPostDetail: true,
      };
    case TYPES.GET_POST_DETAIL_SUCCESS:
      return {
        ...state,
        detailPost: action?.data?.data?.data,
        loading: false,
        loadingPostDetail: false,
      };
    case TYPES.GET_POST_DETAIL_FAILED:
      return {
        ...state,
        loading: false,
        loadingPostDetail: false,
      };

    case TYPES.GET_LIST_COMMENT_REQUEST:
      return {
        ...state,
        loadingComment: true,
      };
    case TYPES.GET_LIST_COMMENT_SUCCESS:
      return {
        ...state,
        paginationComment: action?.data?.data?.pagination,
        listCommentPost:
          action?.data?.data?.pagination?.current_page === 1
            ? action?.data?.data?.data
            : [...state?.listCommentPost, ...action?.data?.data?.data],
        loadingComment: false,
      };
    case TYPES.GET_LIST_COMMENT_FAILED:
      return {
        ...state,
        loadingComment: false,
      };

    //CREATOR_GET_POST_DETAIL
    case TYPES.CREATOR_GET_POST_DETAIL_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case TYPES.CREATOR_GET_POST_DETAIL_SUCCESS:
      return {
        ...state,
        detailPost: action.data.data.data,
        loading: false,
        error: false,
      };

    case TYPES.CREATOR_GET_POST_DETAIL_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.data.error,
        loading: false,
      };
    //CREATOR_POST_ARTICLE
    case TYPES.CREATOR_POST_ARTICLE_REQUEST:
      return {
        ...state,
        loading: false,
        statusPost: null,
        error: {},
      };
    case TYPES.CREATOR_POST_ARTICLE_SUCCESS:
      return {
        ...state,
        loading: false,
        statusPost: true,
        detailPost: action.data.data,
      };
    case TYPES.CREATOR_POST_ARTICLE_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.data.error,
        loading: false,
        statusPost: false,
      };

    //CREATOR_EDIT_ARTICLE
    case TYPES.CREATOR_EDIT_ARTICLE_REQUEST:
      return {
        ...state,
        statusPost: null,
        error: {},
      };
    case TYPES.CREATOR_EDIT_ARTICLE_SUCCESS:
      return {
        ...state,
        loading: false,
        statusPost: true,
      };
    case TYPES.CREATOR_EDIT_ARTICLE_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.data.error,
        loading: false,
        statusPost: false,
      };

    //CREATOR_DETELE_ARTICLE
    case TYPES.CREATOR_DELETE_POST_REQUEST:
      return {
        ...state,
        loading: true,
        statusDeletePost: false,
      };
    case TYPES.CREATOR_DELETE_POST_SUCCESS:
      return {
        ...state,
        loading: false,
        statusDeletePost: true,
      };
    case TYPES.CREATOR_DELETE_POST_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.data.error,
        loading: false,
      };

    //GET COMMENT DETAIL
    case TYPES.GET_COMMENT_DETAIL_REQUEST:
      return {
        ...state,
        loadingCommentDetail: true,
      };
    case TYPES.GET_COMMENT_DETAIL_SUCCESS:
      const commentDetail =
        action?.data?.data?.pagination?.current_page === 1
          ? action?.data?.data?.data
          : [...state?.commentDetail, ...action?.data?.data?.data];

      return {
        ...state,
        paginationComment: action?.data?.data?.pagination,
        loadingCommentDetail: false,
        commentDetail: commentDetail,
      };
    case TYPES.GET_COMMENT_DETAIL_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.data.error,
        loadingCommentDetail: false,
      };

    //GET REPLY COMMENT DETAIL
    case TYPES.GET_REPLY_COMMENT_DETAIL_REQUEST:
      return {
        ...state,
        loadingRepCommentDetail: true,
      };
    case TYPES.GET_REPLY_COMMENT_DETAIL_SUCCESS:
      const convertDataReply = action.data.data.data.map((item) => {
        return {
          ...item,
          totalPage: action.data.data.pagination.total_pages,
          currentPage: action.data.data.pagination.current_page,
        };
      });
      return {
        ...state,
        loadingRepCommentDetail: false,
        paginationReplyComment: action?.data?.data?.pagination,
        replyCommentDetail: [...state.replyCommentDetail, ...convertDataReply],
      };
    case TYPES.GET_REPLY_COMMENT_DETAIL_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.data.error,
        loadingRepCommentDetail: false,
      };
    case TYPES.COLLAPSE_REPLY_COMMENT_DETAIL_SUCCESS:
      const comment_id = action.data;
      state.replyCommentDetail = state.replyCommentDetail.filter(
        (item) => item.parent_id !== comment_id
      );
      return {
        ...state,
        replyCommentDetail: state.replyCommentDetail,
      };
    // POST COMMENT DETAIL
    case TYPES.POST_COMMENT_DETAIL_REQUEST:
      return {
        ...state,
        statusPostComment: null,
        // loading: true,
      };
    case TYPES.POST_REPLY_COMMENT_DETAIL_REQUEST:
      return {
        ...state,
        loading: true,
        statusPostRepComment: null,
      };
    case TYPES.POST_COMMENT_DETAIL_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.data.error,
        statusPostComment: false,
        loading: false,
      };
    case TYPES.POST_REPLY_COMMENT_DETAIL_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.data.error,
        statusPostRepComment: false,
        loading: false,
      };
    case TYPES.POST_COMMENT_DETAIL_SUCCESS:
      return {
        ...state,
        detailPost: { ...state.detailPost, number_comment: ++state.detailPost.number_comment },
        commentDetail: [action.data.data, ...state.commentDetail],
        loading: false,
        statusPostComment: true,
      };
    case TYPES.POST_REPLY_COMMENT_DETAIL_SUCCESS:
      state.commentDetail = state.commentDetail.filter((item) => {
        if (item.id === action.data.data.parent_id) {
          return { ...item, total_rep_comment: ++item.total_rep_comment };
        }
        return item;
      });
      return {
        ...state,
        detailPost: { ...state.detailPost, number_comment: ++state.detailPost.number_comment },
        commentDetail: state.commentDetail,
        replyCommentDetail: [action.data.data, ...state.replyCommentDetail],
        statusPostRepComment: true,
        loading: false,
      };

    // DELETE COMMENT DETAIL
    case TYPES.DELETE_COMMENT_DETAIL_REQUEST:
    case TYPES.DELETE_REPLY_COMMENT_DETAIL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TYPES.DELETE_COMMENT_DETAIL_FAILED:
    case TYPES.DELETE_REPLY_COMMENT_DETAIL_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.data.error,
        loading: false,
      };
    case TYPES.DELETE_COMMENT_DETAIL_SUCCESS:
      state.commentDetail = state.commentDetail.filter(
        (item) => item.id !== action.data.data.payload
      );
      return {
        ...state,
        detailPost: { ...state.detailPost, number_comment: --state.detailPost.number_comment },
        commentDetail: state.commentDetail,
        loading: false,
      };
    case TYPES.DELETE_REPLY_COMMENT_DETAIL_SUCCESS:
      state.replyCommentDetail = state.replyCommentDetail.filter(
        (item) => item.id !== action.data.data.payload
      );
      state.commentDetail = state.commentDetail.filter((item) => {
        if (item.id === action.data.data.parent_id) {
          return { ...item, total_rep_comment: --item.total_rep_comment };
        }
        return item;
      });
      return {
        ...state,
        detailPost: { ...state.detailPost, number_comment: action.data.data.total_comment },
        commentDetail: state.commentDetail,
        replyCommentDetail: state.replyCommentDetail,
        loading: false,
      };

    // EDIT COMMENT DETAIL
    case TYPES.EDIT_COMMENT_DETAIL_REQUEST:
      return {
        ...state,
        loading: true,
        statusEditComment: null,
      };
    case TYPES.EDIT_REPLY_COMMENT_DETAIL_REQUEST:
      return {
        ...state,
        loading: true,
        statusEditRepComment: null,
      };
    case TYPES.EDIT_COMMENT_DETAIL_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.data.error,
        loading: false,
        statusEditComment: false,
      };

    case TYPES.EDIT_REPLY_COMMENT_DETAIL_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.data.error,
        loading: false,
        statusEditRepComment: false,
      };
    case TYPES.EDIT_COMMENT_DETAIL_SUCCESS:
      state.commentDetail = state.commentDetail.map((item) => {
        if (item.id === action.data.data.id) {
          return {
            ...item,
            content: action.data.data.content,
            updated_at: action.data.data.updated_at,
            is_edited: 1,
          };
        }
        return item;
      });
      return {
        ...state,
        commentDetail: state.commentDetail,
        statusEditComment: true,
        loading: false,
      };
    case TYPES.EDIT_REPLY_COMMENT_DETAIL_SUCCESS:
      state.replyCommentDetail = state.replyCommentDetail.map((item) => {
        if (item.id === action.data.data.id) {
          return {
            ...item,
            content: action.data.data.content,
            updated_at: action.data.data.updated_at,
            is_edited: 1,
          };
        }
        return item;
      });
      return {
        ...state,
        replyCommentDetail: state.replyCommentDetail,
        statusEditRepComment: true,
        loading: false,
      };

    // LIKE COMMENT DETAIL
    case TYPES.LIKE_COMMENT_DETAIL_REQUEST:
      return {
        ...state,
        likeComment: true,
      };
    case TYPES.LIKE_REPLY_COMMENT_DETAIL_REQUEST:
      return {
        ...state,
        likeRepComment: true,
      };
    case TYPES.LIKE_COMMENT_DETAIL_FAILED:
    case TYPES.LIKE_REPLY_COMMENT_DETAIL_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.data.error,
        likeComment: false,
        likeRepComment: false,
      };
    case TYPES.LIKE_COMMENT_DETAIL_SUCCESS:
      state.commentDetail = state.commentDetail.map((item) => {
        if (item.id === action.data.data.id) {
          return {
            ...item,
            number_like: action.data.data.number_like,
            is_liked: action.data.data.is_liked,
          };
        }
        return item;
      });
      return {
        ...state,
        commentDetail: state.commentDetail,
        likeComment: false,
      };
    case TYPES.LIKE_REPLY_COMMENT_DETAIL_SUCCESS:
      state.replyCommentDetail = state.replyCommentDetail.map((item) => {
        if (item.id === action.data.data.id) {
          return {
            ...item,
            number_like: action.data.data.number_like,
            is_liked: action.data.data.is_liked,
          };
        }
        return item;
      });
      return {
        ...state,
        replyCommentDetail: state.replyCommentDetail,
        likeRepComment: false,
      };
    case TYPES.LIKE_REPLY_COMMENT_NOW_REQUEST:
      state.replyCommentDetail = state.replyCommentDetail.map((item) => {
        if (item.id === action.data.id) {
          return {
            ...item,
            number_like: action.data.number_like,
            is_liked: action.data.is_liked,
          };
        }
        return item;
      });
      return {
        ...state,
        replyCommentDetail: state.replyCommentDetail,
        likeRepComment: false,
      };
    //LIKE POST DETAIL
    case TYPES.LIKE_POST_DETAIL_REQUEST:
      return {
        ...state,
      };
    case TYPES.LIKE_POST_DETAIL_SUCCESS:
      const dataLikeDetail = action.data.data;
      state.detailPost = {
        ...state.detailPost,
        number_like: dataLikeDetail?.is_liked
          ? ++state.detailPost.number_like
          : --state.detailPost.number_like,
        is_liked: dataLikeDetail?.is_liked,
      };
      return {
        ...state,
        detailPost: state.detailPost,
      };
    case TYPES.LIKE_POST_DETAIL_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.data.error,
      };
    case TYPES.BOOK_MARK_DETAIL_REQUEST:
      return {
        ...state,
      };
    case TYPES.BOOK_MARK_DETAIL_SUCCESS:
      const dataBookDetail = action.data.data;
      state.detailPost = {
        ...state.detailPost,
        number_bookmark: dataBookDetail.bookmark_status
          ? ++state.detailPost.number_bookmark
          : --state.detailPost.number_bookmark,
        user_bookmark_status: dataBookDetail.bookmark_status ? 1 : 0,
      };
      return {
        ...state,
        detailPost: state.detailPost,
      };
    case TYPES.BOOK_MARK_DETAIL_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.data.error,
      };

    // FOLLOW COMMENT DETAIL
    case TYPES.FOLLOW_USER_COMMENT_DETAIL_REQUEST:
      return {
        ...state,
        loadingFollow: true,
      }
    case TYPES.FOLLOW_USER_REPLY_COMMENT_DETAIL_REQUEST:
      return {
        ...state,
        loadingFollow: true,
      };
    case TYPES.FOLLOW_USER_COMMENT_DETAIL_FAILED:
    case TYPES.FOLLOW_USER_REPLY_COMMENT_DETAIL_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.data.error,
        loadingFollow: false,
      };
    case TYPES.FOLLOW_USER_COMMENT_DETAIL_SUCCESS:
      state.commentDetail = state.commentDetail?.map((item) => {
        if (item?.user_id === action.data.data.id) {
          return {
            ...item,
            status_follow: action.data.data.status_follow,
          };
        }
        return item;
      });
      state.replyCommentDetail = state.replyCommentDetail?.map((item) => {
        if (item?.user_id === action.data.data.id) {
          return {
            ...item,
            status_follow: action.data.data.status_follow,
          };
        }
        return item;
      });
      return {
        ...state,
        commentDetail: state.commentDetail,
        replyCommentDetail: state.replyCommentDetail,
        loadingFollow: false,
      };
    case TYPES.FOLLOW_USER_REPLY_COMMENT_DETAIL_SUCCESS:
      state.replyCommentDetail = state.replyCommentDetail?.map((item) => {
        if (item?.user_id === action.data.data.id) {
          return {
            ...item,
            status_follow:
              action.data.data.status_follow_block || action.data.data.status_follow,
          };
        }
        return item;
      });
      state.commentDetail = state.commentDetail?.map((item) => {
        if (item?.user_id === action.data.data.id) {
          return {
            ...item,
            status_follow:
              action.data.data.status_follow_block || action.data.data.status_follow,
          };
        }
        return item;
      });
      return {
        ...state,
        commentDetail: state.commentDetail,
        replyCommentDetail: state.replyCommentDetail,
        loadingFollow: false,
      };

    case TYPES.BUY_POST_CONTENT_REQUEST:
      return {
        ...state,
        loading: true,
        statusBuyPost: null,
      };
    case TYPES.BUY_POST_CONTENT_SUCCESS:
      return {
        ...state,
        loading: false,
        statusBuyPost: true,
      };

    case TYPES.BUY_POST_CONTENT_FAILED:
      return {
        ...state,
        statusBuyPost: false,
        error: action.data.error,
        loading: false,
      };

    case 'RESET_ACTION_MY_PAGE':
      return {
        ...state,
        data: [],
        listPostFollowed: [],
        listCommentPost: [],
        commentDetail: [],
        replyCommentDetail: [],
        detailPost: null,
        loadingCommentDetail: false,
        loadingRepCommentDetail: false,
        loadingFollow: false,
        likeComment: false,
        likeRepComment: false,
        statusPostComment: null,
        statusPostRepComment: null,
        statusEditComment: null,
        statusEditRepComment: null,
        statusBuyPost: null,
        loadingPost: true,
        statusPost: null,
        statusDeletePost: false,
        loadingComment: false,
        loadingPostDetail: false,
      };
    case 'SET_MINIMAL_MENU':
      return {
        ...state,
        isMinimal: !state.isMinimal,
      };
    case 'RESET_DETAIL_POST':
      return {
        ...state,
        detailPost: null,
      };
    case 'SET_LOG_USER':
      const item = action.data;
      if (item?.pathRedirect) {
        window.sessionStorage.setItem('url_no_login', `${getEnv('REACT_APP_FAN_URL')}${newPathUser}${item?.pathRedirect}`);
      } else {
        if (!item?.type && window.location.href === `${getEnv('REACT_APP_FAN_URL')}${newPathUser}search`) {
          window.sessionStorage.setItem('url_no_login', `${getEnv('REACT_APP_FAN_URL')}${newPathUser}${item?.account_id}`);
        } else {
          switch (item?.type) {
            case 1:
            case 4:
              window.sessionStorage.setItem('url_no_login', `${getEnv('REACT_APP_FAN_URL')}${newPathUser}post/${item?.id}`);
              break;
            case 2:
              window.sessionStorage.setItem('url_no_login', `${getEnv('REACT_APP_FAN_URL')}${newPathUser}post/${item?.id}`);
              break;
            default:
              window.sessionStorage.setItem('url_no_login', window.location.href);
              break;
          }
        }
      }
      return {
        ...state,
        isLogUser: !state.isLogUser,
      };
    default:
      return state;
  }
};
