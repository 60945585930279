import {
  ImgCustom,
  ListImages,
  ListImgTimelinePostDetail,
  PopupSharePost,
  ReadMoreLess,
} from 'components';
import { newPathUser } from 'constants/layout/constant';
import {
  ChatIcon,
  FlagIcon,
  HeartActiveIcon,
  HeartIcon,
  IconBookmark,
  PlayIcon,
  SendChatIcon,
  ShareIcon,
  MuteIcon,
  MuteIconAct,
  BackIcon,
  ThreePointIcon,
  Edit3Icon,
  TrashIcon,
  FlowUnFlowIcon,
} from 'images';
import Avatar from 'images/Avatar.png';
import { useCallback, useEffect, useState, useLayoutEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { formatNumber, IMG_NO_WATER_MARK, isAdmin, linkS3Video } from 'utils';
import { Styled } from './styles';
import './detail.scss';
import ReactPlayer from 'react-player';
import { useNavigate } from 'react-router-dom';
import { getEnv } from 'configs/env';
import {
  postBookMarkDetail,
  creatorGetPostDetail,
  updatePostLikeDetail,
} from 'store/actions/usersNew';
import PopupViewFullMess from 'components/Account/PopupViewFullMess';

const DetailShortVideo = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // const { detailPost, loading, loadingAction, error, statusPostComment } = useSelector(
  //   (state) => state.myPage
  // );
  const { detailPost, loading, loadingAction, error, statusPostComment } = useSelector(
    (state) => state.usersNew
  );
  const dataProfile = useSelector((state) => state.users.data);
  const [popupCopyLink, setPopupCopyLink] = useState(null);
  const [popupDeleteComment, setPopupDeleteComment] = useState(null);
  const [checkSendChat, setCheckSendChat] = useState(null);
  const [valueComment, setValueComment] = useState('');
  const { id } = useParams();
  const [dataViewFull, setDataViewFull] = useState({});
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMute, setIsMute] = useState(false);
  const [showButton, setShowButton] = useState(true);
  const [isShowPopup, setShowPopup] = useState({
    status: false,
    dataItem: {},
  });
  const [isVisiblePopup, setIsVisiblePopup] = useState(-1);
  const refCard = useRef();
  const navigate = useNavigate();
  const widthScreen = window.innerWidth;

  const handleSharePost = (dataPC) => {
    setPopupCopyLink(dataPC);
  };

  const scrollRoot = () => {
    //setIsPlaying(true);
    if (
      !Boolean(
        refCard.current?.getBoundingClientRect()?.top < 400 &&
          refCard.current?.getBoundingClientRect()?.top > -300
      )
    ) {
      setIsPlaying(false);
    }
  };

  const navigateByLink = (postId, type) => {
    navigate(
      `${newPathUser}post${
        Boolean(type === 2)
          ? 's'
          : Boolean(type === 3 || type === 4)
          ? ''
          : ''
      }/update/${postId}`
    );
  };

  useLayoutEffect(() => {
    document.querySelector('#root').addEventListener('scroll', scrollRoot);
    return () => {
      document.querySelector('#root').removeEventListener('scroll', scrollRoot);
    };
  }, []);

  useEffect(() => {
    dispatch(creatorGetPostDetail(id, false));
  }, []);

  //API LIKE POST
  const handleLikePost = (post) => {
    dispatch(updatePostLikeDetail(post));
  };

  //API BOOKMART POST
  const handlePostBookMark = (post) => {
    dispatch(postBookMarkDetail(post?.id));
  };

  const config = {
    attributes: {
      disablePictureInPicture: false,
      controlsList: 'nodownload',
      onContextMenu: e => e.preventDefault(),
    },
  };




  return (
    <>
      {isShowPopup?.status && (
        <PopupViewFullMess
          item={detailPost}
          close={() => {
            setShowPopup({
              ...isShowPopup,
              status: false,
              dataItem: {},
            });
            //dispatch(resetDetailPost());
          }}
        />
      )}
      {popupCopyLink && (
        <PopupSharePost
          isVisiblePopup={() => setPopupCopyLink(false)}
          data={popupCopyLink || {}}
        />
      )}
      <div style={{ position: 'relative' }}>
        <div
          style={{ position: 'fixed', left: '34%', cursor: 'pointer', top: '80px' }}
          onClick={() => navigate(`${newPathUser}timeline`)}>
          <BackIcon />
        </div>
        <div className='fansite-creator-post-detail creator-post-normal-detail creator-post-short-detail-timeline'>
          <div className='content block d-flex' ref={refCard}>
            <div style={{ position: 'relative' }}>
              <ReactPlayer
                //url={`${linkS3Video}${detailPost.medias[0].url}`}
                url='https://fansite-dev-video-output.s3.us-west-2.amazonaws.com/1679546926264_VideoPrivate2.mp4'
                playing={isPlaying}
                controls={!showButton}
                onPlay={() => {
                  setIsPlaying(true);
                  setShowButton(false);
                }}
                onPause={() => {
                  setIsPlaying(false);
                  setShowButton(true);
                }}
                config={config}
                muted={isMute}
                playsInline={true}
                width='100%'
                height='100%'
                className='video-custom'
              />
              {showButton && (
                <Styled.ButtonPlay
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowButton(false);
                    setIsPlaying(true);
                  }}>
                  <PlayIcon />
                </Styled.ButtonPlay>
              )}
              <div className='mute-icon' onClick={() => setIsMute(!isMute)}>
                {!isMute ? <MuteIconAct /> : <MuteIcon />}
              </div>
              <div className='block-info'>
                <div
                  className='avatar'
                  onClick={() => navigate(`${newPathUser}${detailPost?.user?.account_id}`)}>
                  <ImgCustom
                    screen='avatar_44_44'
                    src={detailPost?.user?.avatar}
                    alt='avatar'
                  />
                  <span className='name'>{detailPost?.user?.account_name}</span>
                </div>
                <div
                  className='content'
                  style={
                    detailPost?.content.length > 180
                      ? { overflow: 'auto' }
                      : { overflow: 'hidden' }
                  }>
                  <div className='text'>
                    <ReadMoreLess
                      //content={detailPost?.content}
                      content={
                        'いつも応援してくださる方に少しでもご希望に近いことができ喜んでくれればいいなと思って始めました(〃ω〃)...続きを読む'
                      }
                      charLimit={widthScreen > 991 || widthScreen > 575 ? 55 : 25}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className='group-icon'>
                <div className='icon-like'>
                  <div className='bg-icon'>
                    {detailPost?.user_like_status === 1 ? (
                      <>
                        {/* đã like */}
                        <HeartActiveIcon
                          className={`like-icon active`}
                          onClick={() => {
                            handleLikePost(detailPost);
                          }}
                        />
                      </>
                    ) : (
                      <>
                        {/* chưa like */}
                        <HeartIcon
                          className={`like-icon`}
                          onClick={() => {
                            handleLikePost(detailPost);
                          }}
                        />
                      </>
                    )}
                  </div>
                  <p className='mb-0 cls-text' style={{ color: '#fff' }}>
                    {formatNumber(detailPost?.number_like)}
                  </p>
                </div>
                <div className='icon-like'>
                  <div className='bg-icon'>
                    <ChatIcon
                      className='chat-icon'
                      onClick={() => {
                        setShowPopup({
                          ...isShowPopup,
                          status: true,
                          dataItem: detailPost,
                        });
                      }}
                    />
                  </div>
                  <p className='mb-0 cls-text' style={{ color: '#fff' }}>
                    {formatNumber(detailPost?.number_comment) || 0}
                  </p>
                </div>
                <div className='bg-icon'>
                  <ShareIcon
                    onClick={() => {
                      handleSharePost({
                        link: `${getEnv('REACT_APP_FAN_URL')}/short-video-detail/${
                          detailPost?.id
                        }`,
                        title: detailPost?.title,
                        id: detailPost?.id,
                      });
                    }}
                    className='like-icon'
                  />
                </div>
                <div className='bg-icon'>
                  <IconBookmark
                    style={{
                      color: detailPost?.user_bookmark_status === 1 ? '#dc143c' : 'inherit',
                    }}
                    fill={detailPost?.user_bookmark_status === 1 ? '#dc143c' : 'none'}
                    onClick={() => handlePostBookMark(detailPost)}
                    className='cls-icon'
                  />
                </div>
                <div className='bg-icon'>
                  <ThreePointIcon
                    style={{ color: '#1A1A1A' }}
                    className='three-point-icon'
                    onClick={() => {
                      setIsVisiblePopup(
                        isVisiblePopup === detailPost?.id ? -1 : detailPost?.id
                      );
                    }}
                    onBlur={() => setIsVisiblePopup(-1)}
                  />
                </div>
                {isVisiblePopup === detailPost?.id && (
                  <div className='popup-edit-comment'>
                    <div
                      className='item'
                      onMouseDown={() => {
                        //handleFollowCreator(detailPost);
                        setIsVisiblePopup(-1);
                      }}>
                      <FlowUnFlowIcon />
                      <span className='text'>
                        {detailPost?.is_followed === 0
                          ? t('comment.listComment.flow')
                          : t('comment.listComment.unflow')}
                      </span>
                    </div>
                    {/* {detailPost?.user_id === userProfile?.id && ( */}
                    <div
                      className='item'
                      onMouseDown={() => {
                        navigateByLink(detailPost?.id, detailPost?.type);
                        setIsVisiblePopup(-1);
                      }}>
                      <Edit3Icon />
                      <span className='text'>{t('comment.listComment.iconEdit')}</span>
                    </div>
                    {/* )} */}
                    {/* {item?.user_id === userProfile?.id && ( */}
                    <div
                      className='item'
                      onMouseDown={() => {
                        //setIsVisiblePopDeletePost({ id: item.id });
                        setIsVisiblePopup(-1);
                      }}>
                      <TrashIcon />
                      <span className='text'>{t('Common.buttonDelete')}</span>
                    </div>
                    {/* )} */}
                    <div
                      className='item'
                      onMouseDown={() => {
                        setIsVisiblePopup(-1);
                        navigate(`${newPathUser}post-report`, {
                          state: {
                            post_id: detailPost?.id,
                          },
                        });
                      }}>
                      <FlagIcon />
                      <span className='text'>{t('comment.listComment.iconFlag')}</span>
                    </div>
                  </div>
                )}
                {/* <div
                  className='bg-icon'
                  onMouseDown={() => {
                    navigate(`${newPathUser}post-report`, {
                      state: {
                        post_id: detailPost?.id,
                      },
                    });
                  }}>
                  <FlagIcon />
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailShortVideo;
